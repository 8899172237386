
export enum CurrentPage {
    FIRST = 'FIRST',
    SECOND = 'SECOND',
    THIRD = 'THIRD',
}

export enum CurrentPageNumber {
    FIRST = 1,
    SECOND = 2,
    THIRD = 3,
}

export enum ButtonAction {
    PREV = 'PREV',
    NEXT = 'NEXT',
    SUBMIT = 'SUBMIT',
    SAVE_DRAFT = 'SAVE_DRAFT',
}

export const TabNames = {
    FIRST: 'primary_details',
    SECOND: 'bank_&_kyc_details',
    THIRD: 'authorization_details',
};

export const initialState = {
    currentPage: CurrentPage.FIRST,
    visited: {
        FIRST: true,
        SECOND: false,
        THIRD: false,
    },
};

export const editInitialState = {
    currentPage: CurrentPage.FIRST,
    visited: {
        FIRST: true,
        SECOND: true,
        THIRD: true,
    },
};


export interface NewConsignmentState {
    currentPage: CurrentPage,
    visited: Record<CurrentPage, boolean>;
}
