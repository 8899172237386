import * as React from 'react';
import BankIcon from 'assets/bank-details-icon';
import KycIcon from 'assets/Kyc-icon';
import Loader from '../../common/Loader';
import { StylesProps } from '../../../theme/jss-types';
import { stepTwoStyles } from '../create-seller-profile.styles';
import RenderFormItem from './generic-object';
import { createSellerProfileFormFields } from '../create-seller-profile.constants';
import { validateIndividualKyc, deleteImage, callDeleteImage } from '../../../network/sender-management.api';
import GreenTickIcon from 'assets/green-tick-icon';
import { ExclamationCircleFilled, LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';

import {
    Form,
    FormInstance,
    message,
} from 'antd';
import { HocOptions } from '../../common/generic-hoc.types';
import GenericHoc from '../../common/generic-hoc';
import {
    get,
} from 'lodash';
import { useTranslation } from 'react-i18next';
import { snakeToPretty } from 'library/lodash-helper';
import { NamePath } from 'antd/lib/form/interface';

const {
    useState,
    useEffect,
} = React;

const {
    senderType,
    csbvApplicable,
    bankIfscCode,
    bankName,
    bankAdCode,
    bankAccountNumber,
    bankType,
    AdCodeCertification,
    documentType,
    documentBackImage,
    documentFrontImage,
    documentNumber,
    documentDOB,
    documentFileNumber,
    senderName,
} = createSellerProfileFormFields;

const DOBFormat = 'DD/MM/YYYY';

interface StepTwoProps extends StylesProps<ReturnType<typeof stepTwoStyles>> {
    config: any;
    form: FormInstance;
    formData: any,
    setFormData: any;
    condition: boolean;
    masterData: any;
}


const StepTwo = (props: StepTwoProps) => {
    const {
        config,
        classes,
        form,
        formData,
        setFormData,
        condition,
        masterData,
    } = props;
    const {
        SELLER_TYPE_DOCUMENTS_UPLOAD_MAP,
        DOCUMENT_LABEL_MAP,
        DOCUMENT_TYPE_REQUIRED_FIELDS_MAP,
        DOCUMENT_TYPES_KYC_ENABLED,
        KYC_VALIDATION_REGEX,
    } = masterData;
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const kycDocumentValue = Form.useWatch('kyc_documents', form);
    const [senderTypeVal, setSenderTypeVal] = useState<any>(formData.sender_type);
    const [kycInProgress, setKycInProgress] = useState<Record<number, boolean>>({});
    const requiredCsbv = form.getFieldValue(csbvApplicable.key);
    const bankAccountNumberVal = form.getFieldValue(bankAccountNumber.keyPath as NamePath);
    const bankNameVal = form.getFieldValue(bankName.keyPath as NamePath);
    const bankTypeVal = form.getFieldValue(bankType.keyPath as NamePath);
    const bankIfscCodeVal = form.getFieldValue(bankIfscCode.keyPath as NamePath);
    const bankAdCodeVal = form.getFieldValue(bankAdCode.keyPath as NamePath);

    useEffect(() => {
        if (senderTypeVal !== formData.sender_type) {
            setSenderTypeVal(formData.sender_type);
            form.setFieldsValue({
                kyc_documents: [],
            });
        }
    }, [formData.sender_type]);
    const prevKycDocumentValue = React.useRef(kycDocumentValue);

    const deleteKycImage = async (body: any) => {
        try {
            const response = await deleteImage(body);
            if (response?.isSuccess) {
                await callDeleteImage(response?.data?.url);
            }
        } catch (error) {
            message.error('Failed to delete image');
        }
    };

    useEffect(() => {
        if (kycDocumentValue) {
            const validatedDocuments = formData?.validatedDocuments || {};
            interface ValidatedDocuments {
                [index: string]: any;
            }

            const newValidatedDocuments: ValidatedDocuments = {};

            kycDocumentValue.forEach((kycDocument: any, index: number) => {
                const document = validatedDocuments[index];
                if (document) {
                    const documentData = kycDocumentValue[index];
                    const requiredDocumentType = documentData?.document_type;
                    const DatailsToShowList = DOCUMENT_TYPE_REQUIRED_FIELDS_MAP[requiredDocumentType];
                    const isDocumentChanged = Object.keys(documentData).some((key) => DatailsToShowList.includes(key)
                    && documentData[key] !== document[key]);
                    if (isDocumentChanged) {
                        newValidatedDocuments[index?.toString()] = {
                            ...document,
                            isValidated: false,
                            errorMessage: '',
                        };
                    } else {
                        newValidatedDocuments[index] = document;
                    }
                }
            });
            setFormData({
                ...formData,
                validatedDocuments: newValidatedDocuments,
            });
            if (condition) {
                let body = {};
                let frontImageUrl;
                let backImageUrl;
                const newKycDocumentValue = kycDocumentValue?.map((doc: any, index: number) => {
                    const prevDoc = prevKycDocumentValue?.current?.[index];
                    if (prevDoc && doc?.document_type !== prevDoc?.document_type) {
                        body = {
                            document_number: doc?.document_number,
                            document_type: prevDoc?.document_type,
                            name: 'kyc',
                            seller_id: formData?.seller_id,
                        };
                        frontImageUrl = doc?.front_image;
                        backImageUrl = doc?.back_image;
                        return { document_type: doc.document_type };
                    }
                    const values = form.getFieldValue('kyc_documents')[index];
                    return { ...values, ...doc };
                });
                if (frontImageUrl) {
                    deleteKycImage({ ...body, type: 'front_image' });
                }
                if (backImageUrl) {
                    deleteKycImage({ ...body, type: 'back_image' });
                }
                form.setFieldsValue({ kyc_documents: newKycDocumentValue });
                prevKycDocumentValue.current = newKycDocumentValue;
            }
        }
    }, [kycDocumentValue]);

    const renderRowDescription = (FieldIcon: React.ElementType, label: string) => {
        return (
            <div className={classes.boxTitle}>
                <div className={classes.boxIcon}>
                    <FieldIcon className={classes.boxIcon} />
                </div>
                <span>{label}</span>
            </div>
        );
    };

    const commonProps = {
        config,
        form,
        formData,
    };

    const renderLine = () => {
        return <div className={classes.line} />;
    };

    const handleValidate = async (index: number) => {
        setKycInProgress({ ...kycInProgress, [index]: true });
        const data = kycDocumentValue?.[index];
        const name = form.getFieldValue(senderName.key);
        const body = {
            ...data,
            dob: data?.dob ? moment(data?.dob).format(DOBFormat) : null,
            name,
            seller_id: formData?.seller_id,
        };
        try {
            setLoading(true);
            const response = await validateIndividualKyc(body);
            if (response.isSuccess) {
                setFormData({
                    ...formData,
                    validatedDocuments: {
                        ...(formData?.validatedDocuments || {}),
                        [index]: {
                            ...kycDocumentValue?.[index],
                            isValidated: true,
                        },
                    },
                });
                message.success('Document validated successfully');
            } else {
                setFormData({
                    ...formData,
                    validatedDocuments: {
                        ...(formData?.validatedDocuments || {}),
                        [index]: {
                            ...kycDocumentValue?.[index],
                            isValidated: false,
                            errorMessage: response.errorMessage,
                        },
                    },
                });
                message.error(response.errorMessage || 'Failed to validate document');
            }
        } catch (error) {
            message.error('Failed to validate document');
        }
        setLoading(false);
        setKycInProgress({ ...kycInProgress, [index]: false });
    };

    const renderRowUnvalidated = (index: number) => {
        return (
            formData?.validatedDocuments?.[index]?.errorMessage ? (
                <h5 className={classes.unvalidated}>
                    <ExclamationCircleFilled style={{ color: '#EA2626', fontSize: 16 }} />
                    {' '}
                    Your KYC verification was unsuccessful. Error:
                    {' '}
                    {formData?.validatedDocuments?.[index]?.errorMessage}
                </h5>
            ) : (
                <h5>KYC documents need to be validated to make the profile active.</h5>
            )
        );
    };

    const renderRowValidated = (index: number) => {
        return (
            <h5 className={classes.validated}>
                <GreenTickIcon style={{ backgroundColor: '#E6F8E9', borderRadius: 10 }} />
                {' '}
                Congratulations! Your KYC verification for
                {' '}
                {snakeToPretty(DOCUMENT_LABEL_MAP?.[formData?.validatedDocuments?.[index]?.document_type])}
                {' '}
                was successful.
            </h5>
        );
    };

    const renderValidate = (index: any, disableValidate: boolean, hideValidate: boolean) => {
        if (hideValidate) return null;
        return (
            kycInProgress[index] ? (
                <h5 className={classes.validating}>
                    <LoadingOutlined style={{ color: '#006EC3' }} />
                    {' '}
                    KYC Verification in progress
                </h5>
            ) : (
                <div className={classes.validate}>
                    <div style={{
                        width: formData?.validatedDocuments?.[index]?.isValidated ? '100%' : '65%',
                    }}
                    >
                        {formData?.validatedDocuments?.[index]?.isValidated ? (
                            renderRowValidated(index)
                        ) : (
                            renderRowUnvalidated(index)
                        )}
                    </div>
                    <div
                        onClick={() => !disableValidate && handleValidate(index)}
                        style={{
                            pointerEvents: disableValidate ? 'none' : 'auto',
                            opacity: disableValidate ? 0.4 : 1,
                        }}
                    >
                        {(!formData?.validatedDocuments?.[index]?.isValidated && !hideValidate) ? (
                            <h5 className={classes.validateIcon}>VALIDATE</h5>
                        ) : null}
                    </div>
                </div>
            )
        );
    };


    const renderKycIndividual = (
        index: number, docType: any, docNumber: any, docFrontImage: any, docBackImage: any, docDOB: any, docFileNumber: any,
    ) => {
        let requiredDocumentType = 'check';
        const documentData = kycDocumentValue?.[index];
        requiredDocumentType = documentData?.document_type;
        const DatailsToShowList = DOCUMENT_TYPE_REQUIRED_FIELDS_MAP[requiredDocumentType];
        const showDocNumber = DatailsToShowList?.includes('document_number') ?? true;
        const showFrontImage = DatailsToShowList?.includes('front_image') ?? true;
        const showBackImage = DatailsToShowList?.includes('back_image') ?? true;
        const showDOB = DatailsToShowList?.includes('dob') ?? true;
        const showFileNumber = DatailsToShowList?.includes('file_number') ?? true;
        let disableValidate = !kycDocumentValue?.[index]?.document_type;
        let disableUpload = !kycDocumentValue?.[index]?.document_type;

        const requiredDocNumber = {
            ...docNumber,
        };
        const pattern = KYC_VALIDATION_REGEX.find((elem: any) => elem.document_type
            === requiredDocumentType)?.regex;
        if (pattern) {
            requiredDocNumber.rules = [
                {
                    required: true,
                    message: 'Required',
                },
                {
                    pattern: new RegExp(pattern),
                    message: 'Invalid document number!',
                },
            ];
        }
        const hideValidate = !DOCUMENT_TYPES_KYC_ENABLED?.includes(requiredDocumentType);
        if (showDocNumber) {
            disableValidate = disableValidate || !kycDocumentValue?.[index]?.document_number;
            disableUpload = disableUpload || !kycDocumentValue?.[index]?.document_number;
        }
        if (showFrontImage) {
            disableValidate = disableValidate || !kycDocumentValue?.[index]?.front_image;
        }
        if (showBackImage) {
            disableValidate = disableValidate || !kycDocumentValue?.[index]?.back_image;
        }
        if (showDOB) {
            disableValidate = disableValidate || !kycDocumentValue?.[index]?.dob;
        }
        if (showFileNumber) {
            disableValidate = disableValidate || !kycDocumentValue?.[index]?.file_number;
        }


        return (
            <div className={classes.boxFieldsBlue}>
                <div className={classes.boxFields2}>
                    <RenderFormItem
                        field={docType}
                        {...commonProps}
                    />
                    {showDocNumber && (
                        <RenderFormItem
                            field={requiredDocNumber}
                            {...commonProps}
                        />
                    )}
                </div>
                <div className={classes.boxFields2}>
                    {showDOB && (
                        <RenderFormItem
                            field={docDOB}
                            {...commonProps}
                        />
                    )}
                    {showFileNumber && (
                        <RenderFormItem
                            field={docFileNumber}
                            {...commonProps}
                        />
                    )}
                </div>
                <div className={classes.boxFields2}>
                    { showFrontImage && (
                        <RenderFormItem
                            field={{ ...docFrontImage, disabled: disableUpload }}
                            {...commonProps}
                        />
                    )}
                    {showBackImage && (
                        <RenderFormItem
                            field={{ ...docBackImage, disabled: disableUpload }}
                            {...commonProps}
                        />
                    )}
                </div>
                {hideValidate ? null : renderLine()}
                {renderValidate(index, disableValidate, hideValidate)}
            </div>
        );
    };

    const createOptions = (document_type_options: Array<string>) => {
        return document_type_options?.map((option: any) => {
            return {
                value: option,
                label: DOCUMENT_LABEL_MAP[option],
            };
        });
    };


    const requiredSenderType = form.getFieldValue(senderType.key);
    const renderKYCDetails = () => {
        const documentsData = SELLER_TYPE_DOCUMENTS_UPLOAD_MAP[requiredSenderType];
        return (
            <div className={classes.boxProprietor}>
                {documentsData?.documents?.map((document: any, index: any) => {
                    const docType = {
                        ...documentType,
                        options: createOptions(document?.document_type_options),
                        key: ['kyc_documents', index, documentType.key],
                    };
                    const docNumber = {
                        ...documentNumber,
                        key: ['kyc_documents', index, documentNumber.key],
                    };

                    const docFrontImage = {
                        ...documentFrontImage,
                        key: ['kyc_documents', index, documentFrontImage.key],
                        imageExt: ['kyc_documents', index, 'front_image_details', 'ext'],
                    };

                    const docBackImage = {
                        ...documentBackImage,
                        key: ['kyc_documents', index, documentBackImage.key],
                        imageExt: ['kyc_documents', index, 'back_image_details', 'ext'],
                    };

                    const docDOB = {
                        ...documentDOB,
                        key: ['kyc_documents', index, documentDOB.key],
                    };

                    const docFileNumber = {
                        ...documentFileNumber,
                        key: ['kyc_documents', index, documentFileNumber.key],
                    };

                    return renderKycIndividual(
                        index, docType, docNumber, docFrontImage, docBackImage, docDOB, docFileNumber,
                    );
                })}
            </div>
        );
    };


    const renderKYC = () => {
        return (
            <div className={classes.box}>
                {renderRowDescription(KycIcon, t('kyc_details'))}
                {renderKYCDetails()}
            </div>
        );
    };

    const renderBankDetails = () => {
        const isBankDetailsRequired = requiredCsbv === 'yes' || bankAccountNumberVal || bankIfscCodeVal
            || bankAdCodeVal || bankTypeVal || bankNameVal;
        const requiredBankAccountNumber = {
            ...bankAccountNumber,
            isRequired: isBankDetailsRequired,
            rules: [
                {
                    required: isBankDetailsRequired,
                    message: 'Required',
                },
                {
                    max: 50,
                    message: 'Maximum 50 characters',
                },
            ],
        };
        const requiredBankAdCode = {
            ...bankAdCode,
            isRequired: isBankDetailsRequired,
            rules: [
                {
                    required: isBankDetailsRequired,
                    message: 'Required',
                },
                {
                    pattern: /^\d{14}$/,
                    message: 'Invalid AD code!',
                },
            ],
        };
        const requiredBankIfscCode = {
            ...bankIfscCode,
            isRequired: isBankDetailsRequired,
            rules: [
                {
                    required: isBankDetailsRequired,
                    message: 'Required',
                },
                {
                    pattern: /^[A-Za-z]{4}\d{7}$/,
                    message: 'Invalid IFSC code!',
                },
            ],
        };
        const requiredBankName = {
            ...bankName,
            isRequired: isBankDetailsRequired,
            rules: [
                {
                    required: isBankDetailsRequired,
                    message: 'Required',
                },
                {
                    max: 500,
                    message: 'Maximum 500 characters',
                },
            ],
        };
        const requiredBankType = {
            ...bankType,
            isRequired: isBankDetailsRequired,
        };

        return (
            <div className={classes.boxFieldsCol}>
                <div className={classes.boxFields2}>
                    <RenderFormItem
                        {...commonProps}
                        field={requiredBankAccountNumber}
                    />
                    <RenderFormItem
                        {...commonProps}
                        field={requiredBankAdCode}
                    />
                </div>
                <div className={classes.boxFields2}>
                    <RenderFormItem
                        {...commonProps}
                        field={requiredBankIfscCode}
                    />
                    <RenderFormItem
                        {...commonProps}
                        field={requiredBankName}
                    />
                </div>
                <div className={classes.boxField1}>
                    <RenderFormItem
                        {...commonProps}
                        field={requiredBankType}
                    />
                    <RenderFormItem
                        field={AdCodeCertification}
                        {...commonProps}
                    />
                </div>
            </div>
        );
    };

    const renderBank = () => {
        return (
            <div className={classes.box}>
                {renderRowDescription(BankIcon, t('bank_details'))}
                {renderBankDetails()}
            </div>
        );
    };

    if (!condition) {
        return null;
    }

    return (
        <div className={classes.main}>
            {loading && <Loader zIndex={10} />}
            {renderBank()}
            {renderLine()}
            {renderKYC()}
        </div>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { parts_to_show } = master;
    const show_delivery_type_in_customerportal = get(parts_to_show, 'show_delivery_type_in_customerportal', false);
    return {
        showDeliveryType: show_delivery_type_in_customerportal,
        loadTypeOptions: master.config.load_type_options,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: stepTwoStyles,
    },
};

export default GenericHoc(hocConfig)(StepTwo);
