import { ThemeType } from 'theme/jss-types';
import { commonStyleSheet } from '../../library/common-styles';
import { NAVBAR_HEIGHT } from 'library/globals';

export const sellerDetailedStyles = (theme: ThemeType) => ({
    ...commonStyleSheet(theme),
    main: {
        fontFamily: 'Open Sans',
        fontSize: 12,
        width: '100%',
        '& .ant-btn-link': {
            padding: 0,
            margin: 0,
        },
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    trackingTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#111111',
        fontFamily: 'Open Sans',
        letterSpacing: 0,
    },
    hr: {
        width: '98%',
        height: 1,
        backgroundColor: '#CCCCCC',
        marginTop: 4,
    },
    arrowIcon: {
        marginRight: 8,
    },
    backText: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
        padding: '16px 0px',
        cursor: 'pointer',
    },
    address: {
        width: '49.85%',
        height: 200,
        padding: '16px 16px',
        backgroundColor: '#FFFFFF',
    },
    addresses: {
        ...commonStyleSheet(theme).flexRow,
        width: '100%',
        justifyContent: 'space-between',
        padding: '2px 0px',
    },
    referenceBox: {
        borderRadius: '0 2px 0 0',
        backgroundColor: '#FFFBE9',
        padding: 16,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    referenceValue: {
        color: '#111111',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
    },
    referenceName: {
        color: '#666666',
        fontSize: 10,
        letterSpacing: 0,
        fontFamily: 'Open Sans',
    },
    detailsBox: {
        display: 'flex',
        width: '100%',
        marginTop: 2,
        justifyContent: 'space-between',
    },
    trackingBox: {
        width: '45%',
        backgroundColor: '#FFFFFF',
        padding: '16px 24px',
    },
    consignmentDetails: {
        width: '100%',
        backgroundColor: '#FFFFFF',
        height: `calc(calc(100vh - ${NAVBAR_HEIGHT}px) - 140px)`,
    },
    itemDetails: {
        width: '100%',
        backgroundColor: '#FFFFFF',
        padding: '0 16px 16px 16px',
    },
    serviceDetails: {
        width: '100%',
        backgroundColor: '#FFFFFF',
        padding: '16px 16px',
        minHeight: '30vh',
    },
    itemsList: {
        marginTop: 20,
        width: '100%',
    },
    keyValuePair: {
        width: '30%',
        ...commonStyleSheet(theme).flexRow,
        marginBottom: 8,
    },
    itemType: {
        ...commonStyleSheet(theme).flexRow,
        marginBottom: 8,
        wordWrap: 'break-word',
    },
    addressType: {
        ...commonStyleSheet(theme).flexColumn,
        width: '50%',
        marginBottom: 8,
        wordWrap: 'break-word',
    },
    itemKey: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontWeight: 400,
        fontSize: 12,
        letterSpacing: 0,
        width: '40%',
        maxWidth: 250,
    },
    pairKey: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '50%',
    },
    pairValue: {
        color: '#111111',
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: 12,
        letterSpacing: 0,
        width: '50%',
    },
    addresDetails: {
        ...commonStyleSheet(theme).flexColumn,
        color: '#111111',
        fontFamily: 'Open Sans',
        fontWeight: 600,
        letterSpacing: 0,
        width: '100%',
    },
    addressTitle: {
        fontSize: 14,
        marginTop: 16,
        fontWeight: 600,
    },
    addressValue: {
        fontSize: 12,
        marginTop: 8,
        fontWeight: 600,
        display: 'flex',
        width: '100%',
    },
    mainDiv: {
        height: '100%',
        width: '54.8%',
        maxHeight: '75vh',
        overflow: 'scroll',
        alignItems: 'start',
    },
    bucket: {
        color: '#666666',
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0,
        padding: '6px 10px',
        marginRight: 2,
        borderRadius: '4px 4px 0 0',
        backgroundColor: '#F5F5F5',
        display: 'flex',
        cursor: 'pointer',
    },
    bucketSelected: {
        color: '#111111',
        fontWeight: 'bold',
        backgroundColor: '#FFF',
    },
    buckets: {
        ...commonStyleSheet(theme).flexRow,
        marginBottom: 16,
    },
    tabDetails: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'start',
    },
    fieldGroup: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'start',
        fontSize: 12,
        width: '33.3%',
    },
    fieldTitle: {
        display: 'inline-block',
        width: '50%',
    },
    fieldValue: {
        display: 'inline-block',
        width: '50%',
        fontWeight: 600,
    },
    box: {
        borderRadius: 4,
        margin: 16,
        backgroundColor: '#FAFAFA',
        width: '100%',
    },
    boxTitle: {
        fontWeight: 'bold',
    },
    documentGroup: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    authLetter: {
        minWidth: 80,
        textTransform: 'uppercase',
        fontSize: 12,
        fontFamily: 'Open Sans',
    },
    iframeModal: {
        width: 630,
        height: 500,
    },
    previewButton: {
        padding: 0,
        margin: 0,
        fontWeight: 600,
        fontSize: 12,
    },
});
