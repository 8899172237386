import { ConsignmentColumns, ExcelColumn, PluginOrdersColumns } from '../../types/master-data-types';
import { ConsignmentBucket } from './consignment.types';

export enum PageOptions {
    Consignments = 'consignments',
    PluginOrders = 'plugin_orders',
    ExcelUpload = 'excel_uploaded',
    PrintHistory = 'print_history',
}
export const PluginOrdersBuckets: PluginOrdersColumns<ConsignmentBucket> = {
    customer_portal_all_plugin_orders: {
        name: 'All',
        width: 8,
    },
    customer_portal_attempted_plugin_orders: {
        name: 'Booking Attempted',
        width: 8,
    },
};

export const ConsignmentBuckets: ConsignmentColumns<ConsignmentBucket> = {
    customer_portal_all: {
        name: 'All',
        width: 8,
    },
    customer_portal_softdata_upload: {
        name: 'Softdata Upload',
        width: 16,
    },
    customer_portal_pickup_awaited: {
        name: 'Pickup Awaited',
        width: 15,
    },
    customer_portal_pickup_scheduled: {
        name: 'Pickup Scheduled',
        width: 17,
    },
    customer_portal_pickup_completed: {
        name: 'Pickup Completed',
        width: 17,
    },
    customer_portal_held_up: {
        name: 'Held Up',
        width: 11,
    },
    customer_portal_in_transit: {
        name: 'In Transit',
        width: 12,
    },
    customer_portal_out_for_delivery: {
        name: 'Out For Delivery',
        width: 15,
    },
    customer_portal_delivered: {
        name: 'Delivered',
        width: 15,
    },
    customer_portal_undelivered: {
        name: 'Undelivered',
        width: 12,
    },
    customer_portal_rto: {
        name: 'RTO',
        width: 10,
    },
    customer_portal_cancelled: {
        name: 'Cancelled',
        width: 15,
    },
};

// keys for date type filter in CN
export const dateKeys = ['created_at', 'last_main_event_time'];
export const consignmentTypesKeys = ['Domestic', 'International'];

export const dateColumnKeys = ['created_at', 'last_main_event_time', 'invoice_date'];

export const dateKeysPickup = [
    'third_attempt_time',
    'second_attempt_time',
    'first_attempt_time',
    'self_first_attempt_time',
    'last_attempt_time',
    'last_delivery_attempt_time',
    'dispatched_date',
];

export const dateKeysWithStringTimestamp = [
    'pickup_time_slot_start',
    'pickup_time_slot_end',
];

export const dateKeysPrettyName: Record<string, string> = {
    created_at: 'Created At',
    last_main_event_time: 'Last Updated',
};

export const allowedEditStatus = [
    'softdata_upload', 'softdata_update', 'not_picked_up', 'pickup_awaited', 'pickup_scheduled',
];

export const consignmentCategoryTypes: Record<string, string> = {
    INTERNATIONAL: 'international',
    DOMESTIC: 'domestic',
};

export const consignmentLocationTypes: Record<string, string> = {
    CONSIGNOR: 'Consignor',
    CONSIGNEE: 'Consignee',
};

export const prettyStatusNames: Record<string, string> = {
    reachedathub: 'Reached At Hub',
    delivered: 'Delivered',
    pickup_awaited: 'Pickup Awaited',
    out_for_pickup: 'Out For Pickup',
    not_picked_up: 'Not Picked Up',
    pickup_scheduled: 'Pickup Scheduled',
    inscan_at_hub: 'In Scan at Hub',
    handover_courier_partner: 'Handover Courier Partner',
    intransittohub: 'IN Transit to Hub',
    rto: 'Return To Origin',
    attempted: 'Attempted',
    softdata_upload: 'Softdata Upload',
    created: 'Created',
    pickup_completed: 'Pickup Completed',
    outfordelivery: 'Out For Delivery',
    cancelled: 'Cancelled',
    rto_outfordelivery: 'RTO Out For Delivery',
    rto_attempted: 'RTO Attempted',
    rto_delivered: 'RTO Delivered',
};

export const prettyMovementTypes: Record<string, string> = {
    forward: 'Forward',
    reverse: 'Reverse',
    rto: 'Return to Origin',
    rvp: 'RVP',
    con: 'CON',
};

export const excelColumns: ExcelColumn[] = [
    {
        column_id: 'csvId',
        pretty_name: 'Excel ID',
        is_sortable: false,
        width: 120,
    }, {
        column_id: 'createdAt',
        pretty_name: 'Date & Time',
        is_sortable: false,
        width: 120,
    }, {
        column_id: 'numberOfConsignments',
        pretty_name: 'Total',
        is_sortable: false,
        width: 70,
    }, {
        column_id: 'isInternational',
        pretty_name: 'Consignment Category',
        is_sortable: false,
        width: 80,
    }, {
        column_id: 'softDataSuccess',
        pretty_name: 'Softdata Success',
        is_sortable: false,
        width: 80,
    }, {
        column_id: 'softDataFailure',
        pretty_name: 'Softdata Failed',
        is_sortable: false,
        width: 80,
    }, {
        column_id: 'softDataPending',
        pretty_name: 'Softdata Pending',
        is_sortable: false,
        width: 80,
    }, {
        column_id: 'bookingSuccess',
        pretty_name: 'Booking Success',
        is_sortable: false,
        width: 80,
    }, {
        column_id: 'bookingFailure',
        pretty_name: 'Booking Failure',
        is_sortable: false,
        width: 80,
    }, {
        column_id: 'bookingPending',
        pretty_name: 'Booking Pending',
        is_sortable: false,
        width: 80,
    }, {
        column_id: 'actions',
        pretty_name: 'Action',
        is_sortable: false,
        width: 50,
    },
];

export const printHistoryColumns: ExcelColumn[] = [
    {
        column_id: 'csvId',
        pretty_name: 'Excel ID',
        is_sortable: false,
        width: 150,
    }, {
        column_id: 'createdAt',
        pretty_name: 'Date & Time',
        is_sortable: false,
        width: 150,
    }, {
        column_id: 'type',
        pretty_name: 'Details',
        is_sortable: false,
        width: 150,
    }, {
        column_id: 'status',
        pretty_name: 'Status',
        is_sortable: false,
        width: 150,
    }, {
        column_id: 'failureArray',
        pretty_name: 'Error Count',
        is_sortable: false,
        width: 150,
    }, {
        column_id: 'errorMSG',
        pretty_name: 'Error Message',
        is_sortable: false,
        width: 150,
    }, {
        column_id: 'downloadErrors',
        pretty_name: 'Download Errors',
        is_sortable: false,
        width: 150,
    }, {
        column_id: 'urlArray',
        pretty_name: 'Action',
        is_sortable: false,
        width: 80,
    },
];

export const defaultPluginColumns = [{
    column_id: 'customer_reference_number',
    is_sortable: false,
    pretty_name: 'Customer Reference Number',
}, {
    column_id: 'sales_order_number',
    is_sortable: false,
    pretty_name: 'Fulfillment ID',
}, {
    column_id: 'consignee_company_name',
    is_sortable: false,
    pretty_name: 'Consignee Name',
}, {
    column_id: 'creation_date',
    is_sortable: false,
    pretty_name: 'Creation Date',
}, {
    column_id: 'consignment_type',
    is_sortable: false,
    pretty_name: 'Consignment Type',
}, {
    column_id: 'payment_mode',
    is_sortable: false,
    pretty_name: 'Payment Mode',
}, {
    column_id: 'app_name',
    is_sortable: false,
    pretty_name: 'App Name',
}, {
    column_id: 'source',
    is_sortable: false,
    pretty_name: 'Source',
}, {
    column_id: 'service_type',
    is_sortable: false,
    pretty_name: 'Service Type',
}, {
    column_id: 'last_updated_at',
    is_sortable: false,
    pretty_name: 'Last Updated At',
}];

export const pluginErrorColumns = [{
    column_id: 'booking_error',
    is_sortable: false,
    pretty_name: 'Booking Error',
}, {
    column_id: 'booking_attempts',
    is_sortable: false,
    pretty_name: 'Booking Attempts',
}];


export const defaultConsignmentColumns = [{
    column_id: 'reference_number',
    is_sortable: false,
    pretty_name: 'Consignment Number',
}, {
    column_id: 'consignment_type',
    is_sortable: false,
    pretty_name: 'Consignment Type',
}, {
    column_id: 'commodity_name',
    is_sortable: false,
    pretty_name: 'Commodity Name',
}, {
    column_id: 'sender_name',
    is_sortable: false,
    pretty_name: 'Sender Name',
}, {
    column_id: 'status',
    is_sortable: false,
    pretty_name: 'Status',
}, {
    column_id: 'hub_name',
    is_sortable: false,
    pretty_name: 'Hub Name',
}, {
    column_id: 'load_type',
    is_sortable: false,
    pretty_name: 'Load Type',
}];
