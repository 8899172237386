import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps, ThemeType } from 'theme/jss-types';
import { formFields } from 'components/create-consignment/create-modal.constants';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
    Col,
    Form,
    FormInstance,
    Input,
} from 'antd';
import { commonStyleSheet } from 'library/common-styles';
import { formRules } from 'library/constants';

const styles = (theme: ThemeType) => ({
    formItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        '& .ant-checkbox + span': {
            color: '#111111',
            fontSize: 14,
            fontWeight: 600,
        },
    },
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
        marginLeft: 4,
    },
    referenceTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
    },
    contentType: {
        ...commonStyleSheet(theme).flexRow,
        '& .ant-form-item': {
            marginBottom: 0,
        },
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    params: any;
    form: FormInstance;
    shouldNotMakeItemTypeCheck: boolean;
}

const PieceReferenceNumberField = (props: IProps) => {
    const { t, i18n } = useTranslation();
    const {
        classes,
        params,
        separateChildCN,
        consignmentConfig,
        form,
        shouldNotMakeItemTypeCheck,
    } = props;
    const {
        ReferenceNumber,
        ItemType,
    } = formFields;
    const {
        pieceIndex,
        isRequired,
    } = params;

    const pieces = Form.useWatch('pieces', form);
    const itemType = Form.useWatch(ItemType.key, form);

    if (!shouldNotMakeItemTypeCheck && itemType !== 'non-document') {
        return <></>;
    }

    if (!consignmentConfig?.allow_multiple_piece_dimensions) {
        return <></>;
    }

    const referenceNumberKey = ['pieces', pieceIndex, ReferenceNumber.key];
    const pieceData = pieces[pieceIndex];

    let shouldFieldBeDisabled = true;
    if (!separateChildCN && pieceData.isNewPiece) {
        shouldFieldBeDisabled = false;
    }

    const formRuleRequired = {
        ...formRules.required,
        message: i18n.exists('required') ? t('required') : 'Required',
    };

    return (
        <Col span={12}>
            <div className={classes.formItem}>
                <div
                    className={classes.title}
                    style={{ width: ReferenceNumber.width }}
                >
                    {t('piece_reference_no')}
                    {isRequired(true, ReferenceNumber.key) ? '*' : ''}
                </div>
                <Form.Item
                    name={referenceNumberKey}
                    className={classes.contentType}
                    initialValue={pieceData.reference_number}
                    valuePropName={ReferenceNumber.valuePropName}
                    rules={
                        isRequired(true, ReferenceNumber.key) ? [formRuleRequired, formRules.number] : [formRules.number]
                    }
                >
                    <Input
                        type="number"
                        min={0}
                        value={pieceData.reference_number}
                        disabled={shouldFieldBeDisabled}
                    />
                </Form.Item>
            </div>
        </Col>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { config, parts_to_show } = master;
    const separateChildCN = parts_to_show?.separate_child_cns;
    return {
        consignmentConfig: get(config, 'customer_portal_consignment_config', {}),
        separateChildCN,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(PieceReferenceNumberField);
