import React from 'react';
import {
    Modal, Button, Space, Divider, Table, Tag, message,
    Tooltip,
} from 'antd';
import { User } from './user.types';
import { InfoCircleOutlined } from '@ant-design/icons';
import { getRoles, getUsers } from 'network/user-management';

interface UserDetailModalProps {
    visible: boolean;
    user: User | null;
    onClose: () => void;
    onEdit: () => void;
}

const UserDetailModal: React.FC<UserDetailModalProps> = ({
    visible, user, onClose, onEdit,
}) => {
    const [userRoleList, setUserRoleList] = React.useState<{ id: string; value: string }[]>([]);
    const [userPermissionsList, setUserPermissionsList] = React.useState<{ [key: string]: string[] }>({});
    const fetchUserRolesedit = async (userId: number) => {
        const rolesData = await getRoles(userId);
        if (rolesData.isSuccess) {
            const roles_List = rolesData.data.userRoleGroupArr[0].rolesList || [];
            const userRoles = roles_List.map((role: { role_id: string; role_name: string }) => ({
                id: role.role_id,
                value: role.role_name,
            }));
            setUserRoleList(userRoles);
        } else {
            message.error(rolesData.errorMessage);
        }
    };
    React.useEffect(() => {
        const fetchData = async () => {
            const usersData = await getUsers(); // { username: '' }
            if (!usersData.isSuccess) {
                message.error(usersData.errorMessage);
            } else {
                setUserPermissionsList(usersData?.data?.userPermssionList || []);
            }
        };
        fetchData();
    }, []);
    React.useEffect(() => {
        if (user) {
            fetchUserRolesedit(user.id);
        }
    }, [user]);


    const columns = [
        {
            title: 'S.N.',
            dataIndex: 'serialNumber',
            key: 'serialNumber',
            render: (_: any, __: any, index: number) => index + 1, // Render index as S.N.
        },
        {
            title: 'User Roles',
            dataIndex: 'role',
            key: 'role',
            render: (text: string) => text,
        },
        {
            title: 'Permissions',
            dataIndex: 'permissions',
            key: 'permissions',
            render: (_: any, record: any) => {
                const permissions = userPermissionsList[record.role] || [];
                return (
                    <Tooltip title={permissions.length > 0 ? permissions.join(', ') : 'No permissions'}>
                        <InfoCircleOutlined style={{ cursor: 'pointer' }} />
                    </Tooltip>
                );
            },
        },
    ];

    if (!user) return null;

    return (
        <Modal
            title={(
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>{user.user_code}</span>
                    <Space style={{ paddingRight: '5px' }}>
                        <Tag color={user.is_active ? 'green' : 'red'}>
                            {user.is_active ? 'Active' : 'Inactive'}
                        </Tag>
                    </Space>
                </div>
            )}
            visible={visible}
            footer={[
                <span key="created" style={{ float: 'left' }}>
                    Created:
                    {' '}
                    {new Date(user.created_at).toLocaleDateString()}
                </span>,
                <Button key="edit" type="primary" onClick={onEdit}>
                    Edit
                </Button>,
            ]}
            onCancel={onClose}
        >
            <h3>User Details</h3>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                <div style={{ flexBasis: '48%', marginBottom: '8px' }}>
                    <strong>User Code:</strong>
                    {' '}
                    {user.user_code}
                </div>
                <div style={{ flexBasis: '48%', marginBottom: '8px' }}>
                    <strong>Name:</strong>
                    {' '}
                    {user.username}
                </div>
                <div style={{ flexBasis: '48%', marginBottom: '8px' }}>
                    <strong>Phone Number:</strong>
                    {' '}
                    {user.phone}
                </div>
                <div style={{ flexBasis: '48%', marginBottom: '8px' }}>
                    <strong>Email:</strong>
                    {' '}
                    {user.email}
                </div>
            </div>
            <Divider />
            <h3>User Roles</h3>
            <Table
                columns={columns}
                dataSource={userRoleList.map((role) => ({
                    key: role.id, // Add a unique key for each row
                    role: role.value,
                    permissions: 'N/A', // Placeholder, adjust according to your permissions data
                }))}
                pagination={false}
                bordered
            />
        </Modal>
    );
};

export default UserDetailModal;
