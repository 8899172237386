import { Routes } from '../library/constants';

const isConsignmentTab = (location: any) => {
    if (location.pathname.includes(Routes.CONSIGNMENT_DETAILS)) {
        return true;
    }
    switch (location.pathname) {
        case `/${Routes.PLUGIN_ORDERS}`:
        case `/${Routes.EXCEL_UPLOAD}`:
        case `/${Routes.PRINT_HISTORY}`:
        case `/${Routes.CONSIGNMENTS}`: return true;
        case Routes.CONSIGNMENT_DETAILS: return true;
        default: return false;
    }
};

const isConsignorManagementTab = (location: any) => {
    if (location.pathname.includes(Routes.CONSIGNOR_MANAGEMENT)) {
        return true;
    }
    switch (location.pathname) {
        case `/${Routes.CONSIGNOR_MANAGEMENT}`: return true;
        default: return false;
    }
};

const isWalletTab = (location: any) => {
    switch (location.pathname) {
        case `/${Routes.WALLET_LEDGER}`:
        case `/${Routes.WALLET_WEIGHT_MISMATCH}`: return true;
        default: return false;
    }
};

const isSettingsTab = (location: any) => {
    switch (location.pathname) {
        case `/${Routes.SETTINGS_HEADER}`:
        case `/${Routes.SETTINGS_PRODUCT}`:
        case `/${Routes.SETTINGS_CHANNEL}`:
        case `/${Routes.SETTINGS_ACCOUNT}`: return true;
        default: return false;
    }
};

const isReportsTab = (location: any) => {
    switch (location.pathname) {
        case `/${Routes.REMITTANCE_REPORT}`: return true;
        case `/${Routes.INVOICE_REPORT}`: return true;
        default: return false;
    }
};

const isAsnTab = (location: any) => {
    switch (location.pathname) {
        case `/${Routes.ASN}`:
        case `/${Routes.ASN_EXCEL_UPLOAD}`: return true;
        default: return false;
    }
};

const isAnalyticsTab = (location: any) => {
    switch (location.pathname) {
        case `/${Routes.ANALYTICS}`:
            return true;
        default:
            if (location.pathname.startsWith(`/${Routes.ANALYTICS_DASHBOARD}`)) {
                return true;
            }
            return false;
    }
};

const isAPIPlaygroundTab = (location: any) => {
    switch (location.pathname) {
        case `/${Routes.API_PLAYGROUND}`:
            return true;
        default:
            if (location.pathname.startsWith(`/${Routes.API_PLAYGROUND}`)) {
                return true;
            }
            return false;
    }
};

export const isSSOLoginRoute = (location: string = window.location.pathname): boolean => {
    if (location?.startsWith(`/${Routes.SSO_LOGIN_REQUEST}`)) {
        return true;
    }
    return false;
};

export const getCurrentPage = (location: any): string => {
    let currentPage = location.pathname.slice(1);
    const isWallet = isWalletTab(location);
    const isConsignment = isConsignmentTab(location);
    const isSettings = isSettingsTab(location);
    const isReports = isReportsTab(location);
    const isAsn = isAsnTab(location);
    const isAnalytics = isAnalyticsTab(location);
    const isConsignorTab = isConsignorManagementTab(location);

    if (!currentPage || isConsignment) {
        currentPage = Routes.CONSIGNMENTS;
    }
    if (isWallet) {
        currentPage = Routes.WALLET;
    }
    if (isSettings) {
        currentPage = Routes.SETTINGS;
    }
    if (isAsn) {
        currentPage = Routes.ASN;
    }
    if (isReports) {
        currentPage = Routes.REPORT;
    }
    if (isAnalytics) {
        currentPage = Routes.ANALYTICS;
    }
    if (isAPIPlaygroundTab(location)) {
        currentPage = Routes.API_PLAYGROUND;
    }
    if (isConsignorTab) {
        currentPage = Routes.CONSIGNOR_MANAGEMENT;
    }
    return currentPage;
};

export const getConsignmentsRoute = (): string => {
    const route = `/${Routes.CONSIGNMENTS}`;
    return route;
};

export const getDefaultLandingRoute = (): string => {
    const route = window.localStorage.getItem('defaultLandingUrl') || `/${Routes.CONSIGNMENTS}`;
    return route;
};

export const getPluginOrdersRoute = (): string => {
    const route = `/${Routes.PLUGIN_ORDERS}`;
    return route;
};

export const getVirtualInventoryRoute = (): string => {
    const route = `/${Routes.VIRTUAL_INVENTORY}`;
    return route;
};

export const getPickupRoute = (): string => {
    const route = `/${Routes.PICKUPS}`;
    return route;
};
export const getUserManagement = (): string => {
    const route = `/${Routes.USER_MANAGEMENT}`;
    return route;
};
export const getDownloadsRoute = (): string => {
    const route = `/${Routes.DOWNLOADS}`;
    return route;
};

export const getForgotPasswordRoute = (): string => {
    const route = `/${Routes.FORGOT}`;
    return route;
};

export const getLoginRoute = (): string => {
    const route = `/${Routes.LOGIN}`;
    return route;
};

export const getLoginRouteCP = (): string => {
    const route = `/${Routes.LOGIN}?:token`;
    return route;
};

export const getSuperUserLoginRoute = (): string => {
    const route = `/${Routes.SUPER_LOGIN}`;
    return route;
};

export const getCustomerActivationRoute = (): string => {
    const route = `/${Routes.CUSTOMER_ACTIVATION}`;
    return route;
};

export const getResetPasswordRoute = (): string => {
    const route = `/${Routes.RESET_PASSWORD}`;
    return route;
};


export const getExcelUploadRoute = (): string => {
    const route = `/${Routes.EXCEL_UPLOAD}`;
    return route;
};

export const getPrintHistoryRoute = (): string => {
    const route = `/${Routes.PRINT_HISTORY}`;
    return route;
};

export const getConsignmentDetailsRoute = (): string => {
    const route = `/${Routes.CONSIGNMENT_DETAILS}/:referenceNumber`;
    return route;
};

export const getAdvanceShippingNoteRoute = (): string => {
    const route = `/${Routes.ASN}`;
    return route;
};

export const getAsnExcelUploadRoute = (): string => {
    const route = `/${Routes.ASN_EXCEL_UPLOAD}`;
    return route;
};

export const getAnalyticsRoute = (): string => {
    const route = `/${Routes.ANALYTICS}`;
    return route;
};

export const getAPIPlaygroundRoute = (): string => {
    const route = `/${Routes.API_PLAYGROUND}`;
    return route;
};

export const getWalletRoute = (): string => {
    const route = `/${Routes.WALLET}`;
    return route;
};

export const getWalletLedgerRoute = (): string => {
    const route = `/${Routes.WALLET_LEDGER}`;
    return route;
};

export const getBlockedLedgerRoute = (): string => {
    const route = `/${Routes.BLOCKED_LEDGER}`;
    return route;
};

export const getWeightMismatchRoute = (): string => {
    const route = `/${Routes.WALLET_WEIGHT_MISMATCH}`;
    return route;
};

export const getSettingIframeRoute = (): string => {
    const route = `/${Routes.SETTINGS}`;
    return route;
};

export const getHeaderMappingRoute = (): string => {
    const route = `/${Routes.SETTINGS_HEADER}`;
    return route;
};

export const getPieceHeaderMappingRoute = (): string => {
    const route = `/${Routes.SETTINGS_PIECE_HEADER}`;
    return route;
};

export const getProductMappingRoute = (): string => {
    const route = `/${Routes.SETTINGS_PRODUCT}`;
    return route;
};

export const getSettingsAccountRoute = (): string => {
    const route = `/${Routes.SETTINGS_ACCOUNT}`;
    return route;
};

export const getSettingsChannelRoute = (): string => {
    const route = `/${Routes.SETTINGS_CHANNEL}`;
    return route;
};

export const getAddressBookRoute = (): string => {
    const route = `/${Routes.ADDRESS_BOOK}`;
    return route;
};

export const getRtoDashboardRoute = (): string => {
    const route = `/${Routes.RTO_DASHBOARD}`;
    return route;
};

export const getSenderManagementRoute = (): string => {
    const route = `/${Routes.CONSIGNOR_MANAGEMENT}`;
    return route;
};

export const getReportsIframeRoute = (): string => {
    const route = `/${Routes.REPORT}`;
    return route;
};

export const getRemittanceReportsRoute = (): string => {
    const route = `/${Routes.REMITTANCE_REPORT}`;
    return route;
};

export const getInvoiceReportsRoute = (): string => {
    const route = `/${Routes.INVOICE_REPORT}`;
    return route;
};

export const getAdditionalServicesRoute = (): string => {
    const route = `/${Routes.ADDITIONAL_SERVICES}`;
    return route;
};
