import * as React from 'react';
import {
    Form,
    Input,
    Button,
    Select,
    Table,
    Space,
    Popconfirm,
    message,
    Switch,
    Popover,
    Tooltip,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { getUsers, createUser, updateUser } from 'network/user-management';
import { InfoCircleFilled, InfoCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import PasswordRules from 'routing/PasswordRules';

interface UserRole {
    id: string;
    value: string;
}

interface AddUserProps {
    editData?: Record<string, any> | null;
    onSubmit: (values: any) => void;
    onCancel: () => void;
    userRoleList: UserRole[];
    // rolesList: UserRole[];
    userPermissionsList: { [key: string]: string[] };
    password_policy: any;
}

interface TableData {
    key: number;
    userRoles: string;
    permissions: string;
}

const AddUser: React.FC<AddUserProps> = ({
    editData,
    onSubmit,
    onCancel,
    userRoleList,
    // rolesList,
    userPermissionsList,
    password_policy,
}) => {
    const { t, i18n } = useTranslation();
    const [form] = Form.useForm();
    const [tableData, setTableData] = React.useState<TableData[]>([]);
    const [password, setPassword] = React.useState<string | undefined>();
    const [conPassword, setConPassword] = React.useState<string | undefined>();
    const [isPasswordCorrect, setIsPasswordCorrect] = React.useState<boolean>(false);
    const [rolesList, setRolesList] = React.useState<{ id: string; value: string }[]>([]);
    // const [countryCode, setCountryCode] = React.useState<string | undefined>();

    React.useEffect(() => {
        if (editData) {
            form.setFieldsValue({
                ...editData,
                password: undefined, // Explicitly set to undefined to ensure the field is not filled
                conPassword: undefined,
            });
        } else {
            form.resetFields();
        }

        // Initialize table data from userRoleList
        const initialTableData: TableData[] = userRoleList.map((role, index) => ({
            key: index + 1,
            userRoles: role.value,
            permissions: userPermissionsList[role.id]?.join(', ') || '',
        }));
        setTableData(initialTableData);
    }, [editData, form, userRoleList]);
    React.useEffect(() => {
        const fetchUserRoles = async () => {
            const usersData = await getUsers();
            if (usersData.isSuccess) {
                setRolesList(usersData?.data?.userRoleList || []);
            } else {
                message.error(usersData.errorMessage);
            }
        };
        fetchUserRoles();
    });
    const formRuleRequired = {
        required: true,
        message: i18n.exists('required') ? t('required') : 'Required',
    };

    const onFinish = async (values: any) => {
        const parent_id = window.localStorage.getItem('childCustomerId');

        if (parent_id) {
            // eslint-disable-next-line no-param-reassign
            values.parent_id = parent_id;
        }
        if (!editData) {
            // eslint-disable-next-line no-param-reassign
            values.is_active = true;
        }
        const selectedRoles = values.user_roles || [];
        const tableRoles = tableData.map((item) => {
            const matchedRole = userRoleList.find((role) => role.value === item.userRoles);
            return matchedRole ? matchedRole.id : null;
        }).filter(Boolean);
        const allRoles = Array.from(new Set([...selectedRoles, ...tableRoles]));
        // eslint-disable-next-line no-param-reassign
        values.user_roles = allRoles;
        let result = null;
        if (editData) {
            result = await updateUser(values);
            if (result && result.isSuccess) {
                message.success(t('User Updated Successfully'));
            }
        } else {
            result = await createUser(values);
            if (result && result.isSuccess) {
                message.success(t('User Added Successfully'));
            }
        }
        if (!result || !result.isSuccess) {
            message.error(result?.errorMessage || '');
        }
        onSubmit(values);
        form.resetFields();
        onSubmit(values);
        form.resetFields();
    };
    const formFields = {
        password: {
            key: 'password',
        },
    };
    const renderPasswordRules = () => {
        return (
            <PasswordRules
                password_policy={password_policy}
                password={password}
                conPassword={conPassword}
                enableSubmit={() => setIsPasswordCorrect(true)}
            />
        );
    };
    const renderRules = (key: string) => {
        if (key !== formFields.password.key || Object.keys(password_policy || {}).length === 0) {
            return null;
        }
        return (
            <Popover
                content={renderPasswordRules()}
                title="Password Rules"
                trigger={['click', 'hover']}
            >
                <InfoCircleFilled style={{ marginLeft: 4 }} />
            </Popover>
        );
    };
    // const countryCodes: any[] = [
    //     { code: '+91', country: 'India' },
    // ];
    const handleDelete = (key: number) => {
        setTableData((prevData) => prevData.filter((item) => item.key !== key));
        message.info('Deleted successfully');
    };

    const renderCustomLabel = (labelText: string, isRequired: boolean) => (
        <>
            {labelText}
            {' '}
            {isRequired && <span style={{ color: 'red', fontSize: '0.75em' }}>*</span>}
        </>
    );

    const columns = [
        {
            title: <span style={{ fontSize: '12px' }}>S.N.</span>,
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: <span style={{ fontSize: '12px' }}>User Roles</span>,
            dataIndex: 'userRoles',
            key: 'value',
        },
        {
            title: <span style={{ fontSize: '12px' }}>Permissions</span>,
            dataIndex: 'permissions',
            key: 'permissions',
            render: (_: any, record: any) => {
                const permissions = userPermissionsList[record.userRoles] || [];
                return (
                    <Tooltip title={permissions.length > 0 ? permissions.join(', ') : 'No permissions'}>
                        <InfoCircleOutlined style={{ cursor: 'pointer' }} />
                    </Tooltip>
                );
            },
        },
        {
            title: <span style={{ fontSize: '12px' }}>Action</span>,
            key: 'action',
            render: (_: any, record: TableData) => (
                <Space size="middle">
                    <Popconfirm
                        title="Are you sure delete this?"
                        onConfirm={() => handleDelete(record.key)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="link" danger icon={<DeleteOutlined />} />
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <h3>User Details</h3>
            <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
                initialValues={editData || {}}
                requiredMark={false}
            >
                <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                        name="user_code"
                        label={renderCustomLabel(t('User Code'), true)}
                        rules={[formRuleRequired]}
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                    >
                        <Input placeholder={t('User Code')} disabled={!!editData} />
                    </Form.Item>
                    {editData && (
                        <Form.Item
                            label={renderCustomLabel(t('Login Username'), true)}
                            name="username"
                            rules={[formRuleRequired]}
                            style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginLeft: '16px' }}
                        >
                            <Input placeholder={t('name')} disabled />
                        </Form.Item>
                    )}
                </Form.Item>

                <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                        rules={[formRuleRequired]}
                        label={renderCustomLabel(t('Name'), true)}
                        name="name"
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                    >
                        <Input placeholder={t('name')} />
                    </Form.Item>
                    <Form.Item
                        label={renderCustomLabel(t('Email'), true)}
                        name="email"
                        rules={[
                            formRuleRequired,
                            { type: 'email', message: t('invalid_email') },
                        ]}
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginLeft: '16px' }}
                    >
                        <Input placeholder={t('Email')} />
                    </Form.Item>
                </Form.Item>

                <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                        label={renderCustomLabel(t('phone'), true)}
                        name="phone"
                        rules={[
                            formRuleRequired,
                            {
                                pattern: /^(?!.*(\d)\1{9})((0[1-9]\d{9})|(0[6-9]\d{9}|(?:(91|\+91)?[6-9]\d{9})))$/,
                                message: t('invalid_phone'),
                            },
                        ]}
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                    >
                        <Input placeholder={t('Enter 10 Digit Phone Number')} />
                    </Form.Item>
                    <Form.Item
                        label={renderCustomLabel(t('City'), true)}
                        name="city"
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginLeft: '16px' }}
                    >
                        <Input
                            placeholder={t('City')}
                        />
                    </Form.Item>
                </Form.Item>

                <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                        name="password"
                        label={(
                            <span>
                                {renderCustomLabel(t('password'), true)}
                                {renderRules(formFields.password.key)}
                            </span>
                        )}
                        rules={!editData ? [formRuleRequired] : []}
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                    >
                        {/* <Input.Password /> */}
                        <Input.Password
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Item>

                    <Form.Item
                        name="conPassword"
                        label={t('confirm_password')}
                        dependencies={['password']}
                        required={!editData}
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(t('password_mismatch'));
                                },
                            }),
                        ]}
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginLeft: '16px' }}
                    >
                        <Input.Password
                            value={conPassword}
                            onChange={(e) => setConPassword(e.target.value)}
                        />
                    </Form.Item>
                </Form.Item>

                <Form.Item style={{ marginBottom: 0 }}>
                    <h3>{t('Assign Roles')}</h3>
                    <Form.Item
                        name="user_roles"
                        label={renderCustomLabel(t('User Roles'), !editData)}
                        rules={!editData ? [{ required: true, message: t('Please select at least one role') }] : []}
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                    >
                        <Select
                            mode="multiple"
                            placeholder={t('Select Roles')}
                            allowClear
                        >
                            {rolesList?.map((role) => (
                                <Select.Option key={role.id} value={role.id}>
                                    {role.value}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form.Item>

                {editData && (
                    <>
                        <Table
                            columns={columns}
                            dataSource={tableData}
                            pagination={false}
                            rowKey="key"
                        />
                    </>
                )}

                {editData && (
                    <Form.Item
                        label={t('Status')}
                        name="is_active"
                        valuePropName="checked"
                    >
                        <Switch checkedChildren={t('Active')} unCheckedChildren={t('Inactive')} />
                    </Form.Item>
                )}

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {!isPasswordCorrect}
                        {t('submit')}
                    </Button>
                    <Button onClick={onCancel} style={{ marginLeft: '10px' }}>
                        {t('cancel')}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default AddUser;
