/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const AuthorisationIconUtil = (props: any) => (
    <svg width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M1.12598 18.375C1.12598 18.0435 1.25767 17.7255 1.49209 17.4911C1.72651 17.2567 2.04446 17.125 2.37598 17.125H23.626C23.9575 17.125 24.2754 17.2567 24.5099 17.4911C24.7443 17.7255 24.876 18.0435 24.876 18.375V25.25C24.876 25.5815 24.7443 25.8995 24.5099 26.1339C24.2754 26.3683 23.9575 26.5 23.626 26.5H2.37598C2.04446 26.5 1.72651 26.3683 1.49209 26.1339C1.25767 25.8995 1.12598 25.5815 1.12598 25.25V18.375Z" stroke="black" strokeWidth="1.6" fill="white" />
        <path d="M1.12598 22.125C1.12598 21.7935 1.25767 21.4755 1.49209 21.2411C1.72651 21.0067 2.04446 20.875 2.37598 20.875H23.626C23.9575 20.875 24.2754 21.0067 24.5099 21.2411C24.7443 21.4755 24.876 21.7935 24.876 22.125V25.25C24.876 25.5815 24.7443 25.8995 24.5099 26.1339C24.2754 26.3683 23.9575 26.5 23.626 26.5H2.37598C2.04446 26.5 1.72651 26.3683 1.49209 26.1339C1.25767 25.8995 1.12598 25.5815 1.12598 25.25V22.125Z" fill="black" />
        <path d="M9.72785 8.78976C9.77118 8.66829 9.85102 8.56321 9.95643 8.48891C10.0618 8.41461 10.1876 8.37474 10.3166 8.37476H15.6854C15.8143 8.37474 15.9401 8.41461 16.0455 8.48891C16.1509 8.56321 16.2308 8.66829 16.2741 8.78976L19.251 17.1248H6.75098L9.72785 8.78976Z" stroke="black" fill="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.251 1.49976H10.751C8.88702 1.49976 7.37598 3.01079 7.37598 4.87476C7.37598 6.73872 8.88702 8.24976 10.751 8.24976H15.251C17.1149 8.24976 18.626 6.73872 18.626 4.87476C18.626 3.01079 17.1149 1.49976 15.251 1.49976Z" fill="black" stroke="black" strokeWidth="1.6" />
    </svg>

);

const SealAndSignatureIcon = (props: any) => (
    <Icon component={(innerProps: any) => {
        return AuthorisationIconUtil({ ...innerProps, ...props });
    }}
    />
);

export default SealAndSignatureIcon;
