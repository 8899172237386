import { ThemeType } from 'theme/jss-types';
import { commonStyleSheet } from '../../library/common-styles';

const commonStyles = (theme: ThemeType) => ({
    ...commonStyleSheet(theme),
    formitem: {
        padding: '0px',
        fontSize: '12px',
        fontFamily: 'Open Sans',
        fontWeight: 400,
        color: '#333',
        marginLeft: '4px',
        marginBottom: '10px',
        display: 'flex',
    },
    label: {
        display: 'flex',
        alignItems: 'center',
    },
    inputform: {
        marginLeft: '2px',
        height: '32px',
    },
    line: {
        height: 1,
        margin: '12px 0px',
        width: '100%',
        backgroundColor: '#EDEDED',
    },
    main: {
        width: '100%',
        overflow: 'auto',
        maxHeight: 'calc(100vh - 210px)',
        minHeight: 'calc(100vh - 210px)',
        '& .ant-input, .ant-input-affix-wrapper': {
            boxSizing: 'border-box',
            height: 32,
            border: '1px solid #999999',
            borderRadius: 4,
            marginRight: 2,
        },
        '& .ant-input-number-input': {
            boxSizing: 'border-box',
            height: 32,
            border: '1px solid #999999',
            borderRadius: 4,
            marginRight: 2,
        },
        '& .ant-select:not(.ant-select-customize-input) .ant-select-selector': {
            boxSizing: 'border-box',
            height: 32,
            border: '1px solid #999999',
            borderRadius: 4,
            marginRight: 2,
        },
        '& .ant-checkbox-group-item:last-child': {
            display: 'flex',
            color: '#111111',
            fontFamily: 'Open Sans',
            fontSize: 14,
            fontWeight: 600,
            letterSpacing: 0,
        },
        '& .ant-form-item-control-input': {
            minHeight: 24,
        },
        '& .ant-form-item': {
            marginBottom: 0,
        },
        '& .ant-radio-wrapper': {
            display: 'flex',
            color: '#111111',
            fontFamily: 'Open Sans',
            fontSize: 14,
            fontWeight: 600,
            letterSpacing: 0,
            margin: '4px 24px 4px 0px',
        },
        '& .ant-picker': {
            boxSizing: 'border-box',
            height: 32,
            border: '1px solid #999999',
            borderRadius: 4,
            marginRight: 2,
        },
        '& .ant-form-item-control-input-content': {
            display: 'flex',
            flexDirection: 'column',
        },
        '& .ant-form-item-explain-error': {
            fontSize: '10px !important',
        },
    },
    boxField1: {
        display: 'flex',
        marginBottom: '7px',
    },
    boxFieldsCol: {
        width: '60%',
        ...commonStyleSheet(theme).flexColumn,
    },
    boxFieldsBlue: {
        width: '60%',
        backgroundColor: '#F3F7F9',
        padding: '12px',
        marginTop: '12px',
        ...commonStyleSheet(theme).flexColumn,
    },
    validate: {
        display: 'flex',
    },
    validated: {
        color: '#008D16',
        marginLeft: '10px',
        // cursor: 'pointer',
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        fontWeight: 600,
    },
    validating: {
        color: '#006EC3',
        marginLeft: '10px',
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        fontWeight: 600,
    },
    unvalidated: {
        color: '#EA2626',
        marginLeft: '10px',
        // cursor: 'pointer',
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        fontWeight: 600,
    },
    validateIcon: {
        color: '#006EC3',
        marginLeft: '10px',
        cursor: 'pointer',
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        fontWeight: 600,
        size: 12,
    },
    boxFieldsCol2: {
        // width: '100%',
        // ...commonStyleSheet(theme).flexColumn,
    },
    boxFields: {
        marginBottom: '7px',
        width: '50%',
        display: 'flex',
    },
    singleBoxFields: {
        marginBottom: '7px',
    },
    boxFields2: {
        // width: '60%',
        display: 'flex',
        marginBottom: '7px',
    },
    documentList: {
        // width: '60%',
        display: 'flex',
        flexDirection: 'column',
        width: '60%',
    },
    docimage: {
        width: '100%',
        height: 'auto',
    },
    docModal: {
        maxHeight: '65vh',
        // minHeight: '65vh',
        overflowY: 'auto',
    },
    document: {
        // width: '60%',
        marginTop: '3%',
        display: 'flex',
    },
    serialno: {
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        backgroundColor: '#F5F5F5',
        color: '#111111',
        display: 'flex',
        fontWeight: '600',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '3%',
    },
    docName: {
        width: '20%',
        display: 'flex',
        fontWeight: '500',
        // alignItems: 'center',
    },
    accept: {
        width: '20%',
        display: 'flex',
        fontWeight: '600',
        alignItems: 'center',
        color: '#008D16',
    },
    reject: {
        width: '20%',
        display: 'flex',
        fontWeight: '600',
        alignItems: 'center',
        color: '#EA2626',
    },
    boxProprietor: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
    },
    box: {
        ...commonStyleSheet(theme).flexRow,
        padding: 20,
        alignItems: 'normal',
        boxSizing: 'border-box',
        borderRadius: 4,
        backgroundColor: '#FFFFFF',
        width: '100%',
    },
    acceptButton: {
        backgroundColor: 'green',
        color: 'white',
        border: 'none',
        padding: '10px 20px',
        textAlign: 'center',
        textDecoration: 'none',
        display: 'inline-block',
        fontSize: 16,
        margin: '4px 2px',
        cursor: 'pointer',
        borderRadius: '4px',
        alignItems: 'center',
        transitionDuration: '0.4s',
    },
    rejectButton: {
        backgroundColor: 'red',
        color: 'white',
        border: 'none',
        padding: '10px 20px',
        textAlign: 'center',
        textDecoration: 'none',
        display: 'inline-block',
        fontSize: 16,
        margin: '4px 2px',
        cursor: 'pointer',
        transitionDuration: '0.4s',
    },
    boxTitle: {
        ...commonStyleSheet(theme).flexColumn,
        fontSize: 16,
        color: '#333333',
        fontWeight: 'bold',
        width: '15%',
    },
    // boxFields: {
    //     width: '50%',
    // },
    boxIcon: {
        width: '100%',
        height: 30,
        fontSize: 16,
        color: '#333333',
        textAlign: 'left',
    },
    closeIcon: {
        marginRight: 8,
        cursor: 'pointer',
        color: '#000000',
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: 16,
    },
});

export const createSellerProfileStyles = (theme: ThemeType) => ({
    ...commonStyles(theme),
    main: {
        fontFamily: 'Open Sans',
        letterSpacing: 0,
        '& .ant-drawer-header': {
            padding: 12,
        },
        '& .ant-drawer-body': {
            padding: 0,
            backgroundColor: '#FFF',
        },
        '& .ant-btn-primary': {
            borderRadius: 4,
        },
        // marginBottom: 100,
    },
    newButton: {
        height: 32,
        borderRadius: '4px',
        fontSize: 12,
        backgroundColor: `${theme.primaryColor} !important`,
        border: `1px solid ${theme.primaryColor}`,
        color: '#FFFFFF',
        fontFamily: 'Open Sans',
        fontWeight: 600,
        letterSpacing: 0,
        marginLeft: '16px',
    },
    newButtonDisabled: {
        height: 32,
        borderRadius: '4px',
        fontSize: 12,
        backgroundColor: '#EDEDED !important',
        border: '1px solid #EDEDED',
        color: '#FFFFFF',
        fontFamily: 'Open Sans',
        fontWeight: 600,
        letterSpacing: 0,
    },
    nextPreButton: {
        color: theme.primaryColor,
        fontWeight: 'bold',
    },
    header: {
        ...commonStyleSheet(theme).flexRow,
        fontWeight: 600,
        fontSize: 20,
        color: '#000000',
        width: '100%',
        maxHeight: 57,
        justifyContent: 'space-between',
        padding: 0,
        fontFamily: 'Open Sans',
        letterSpacing: 0,
    },
    booking: {
        ...commonStyleSheet(theme).flexRow,
        backgroundColor: '#FFF',
        alignItems: 'center',
        padding: '24px 20px',
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        boxSizing: 'border-box',
        boxShadow: '2px 2px 6px 0 rgba(0,0,0,0.05)',
    },
    pageSelector: {
        ...commonStyleSheet(theme).flexRow,
        boxSizing: 'border-box',
        border: '1px solid #FAF6EF',
        borderRadius: 4,
        backgroundColor: '#FFF8E7',
        boxShadow: '2px 2px 6px 0 rgba(0,0,0,0.05)',
    },
    tabSelector: {
        padding: 20,
        ...commonStyleSheet(theme).flexRow,
        alignItems: 'center',
        width: '33.3%',
        cursor: 'pointer',
    },
    filledCircle: {
        height: 20,
        width: 20,
        borderRadius: 10,
        border: '2px solid #FFF',
    },
    tabName: {
        marginLeft: 8,
        color: '#111111',
        fontSize: 14,
    },
    tabDescription: {
        fontSize: 10,
        marginLeft: 8,
    },
    tabIcon: {
        height: 24,
        width: 24,
        borderRadius: 12,
    },
    footer: {
        ...commonStyleSheet(theme).flexRow,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        bottom: 0,
        color: '#FFFFFF',
        width: '100%',
        height: 65,
        padding: '0 20px',
        fontSize: 14,
        backgroundColor: 'white',
        opacity: 1,
        zIndex: 5,
    },
    boxFieldsCol: {
        width: '80%',
        ...commonStyleSheet(theme).flexColumn,
    },
});

export const stepOneStyles = (theme: ThemeType) => ({
    ...commonStyles(theme),
    newAddressTitle: {
        color: '#333333',
        fontSize: 12,
        marginBottom: 4,
    },
    mobileInput: {
        width: 200,
    },
    verify: {
        font: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        fontWeight: 600,
        lineHeight: '12px',
        marginTop: '4px',
    },
    verifiedText: {
        color: '#008D16',
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600,
        marginRight: 5,
    },
    exclamation: {
        marginRight: 8,
        fontWeight: 600,
        color: '#FA5F26',
    },
    otpModalHeader: {
        marginLeft: 8,
        fontWeight: 600,
        fontSize: 16,
        fontFamily: 'Open Sans',
        lineHeight: '16px',
    },
    phone: {
        marginLeft: 8,
        fontWeight: 600,
    },
    resend: {
        fontWeight: 600,
        marginLeft: 10,
        font: 'Open Sans',
        fontSize: 12,
    },
    resendDisabled: {
        color: '#ccc',
        fontWeight: 600,
        font: 'Open Sans',
        fontSize: 12,
        marginLeft: 10,
        cursor: 'not-allowed',
        '&$resendDisabled:hover': {
            color: '#ccc',
        },
    },
    newAddressItem: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 16,
        marginRight: 8,
    },
    mobileInputCode: {
        height: 30,
        width: 50,
        margin: 0,
        padding: 0,
        '& .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector': {
            marginLeft: 0,
            marginRight: 0,
            paddingLeft: 0,
            paddingRight: 0,
        },
        '& .ant-select-selector, ant-select-selection-item, ant-input-group-addon': {
            marginLeft: 0,
            marginRight: 0,
            paddingLeft: '0px !important',
            paddingRight: '0px !important',
        },
    },
});

export const stepTwoStyles = (theme: ThemeType) => ({
    ...commonStyles(theme),
});
export const stepThreeStyles = (theme: ThemeType) => ({
    ...commonStyles(theme),
    main: {
        ...commonStyles(theme).main,
        '& .ant-radio-wrapper': {
            display: 'inline',
            color: '#111111',
            fontFamily: 'Open Sans',
            fontSize: 14,
            fontWeight: 600,
            letterSpacing: 0,
        },
    },
});
