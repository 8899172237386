/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const tick = (props: any) => (
    <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M3.26799 5.92598C3.07733 5.92598 2.88668 5.85263 2.74735 5.71327L0.547494 3.51275C0.261512 3.22669 0.261512 2.76458 0.547494 2.47851C0.833476 2.19244 1.29545 2.19244 1.58143 2.47851L3.21666 4.11423L6.36979 0.329343C6.62644 0.0212712 7.08841 -0.0227392 7.40373 0.233987C7.71171 0.490714 7.7557 0.952822 7.49905 1.26823L3.83262 5.66926C3.70063 5.83063 3.50997 5.92598 3.30465 5.93332C3.29732 5.93332 3.28265 5.93332 3.27532 5.93332L3.26799 5.92598Z" fill="#008D16" />
    </svg>

);

const GreenTickIcon = (props: any) => (
    <Icon component={(innerProps: any) => {
        return tick({ ...innerProps, ...props });
    }}
    />
);

export default GreenTickIcon;
