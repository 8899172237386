import { FormField, InputTypes } from 'components/create-consignment/create-consignment.types';
import {
    addressProofTypeList,
    bankTypeList,
    documentTypeList,
} from 'components/sender-management/sender-management.constants';

export const createSellerProfileFormFields: Record<string, FormField> = {
    senderCode: {
        label: 'Consignor Code',
        placeholder: 'Enter Consignor Code',
        type: InputTypes.Input,
        key: 'seller_code',
        valuePropName: 'value',
        isRequired: true,
        formitemCss: {
            flexDirection: 'column',
        },
        inputformCss: {
            width: '90%',
        },
    },
    senderType: {
        label: 'Consignor Type',
        placeholder: 'Select Consignor Type',
        type: InputTypes.Select,
        options: [],
        key: 'sender_type',
        valuePropName: 'value',
        isRequired: true,
        formitemCss: {
            flexDirection: 'column',
        },
        inputformCss: {
            width: '30%',
        },
    },
    csbvApplicable: {
        label: 'Is CSB V Applicable',
        placeholder: 'Select CSB Applicable',
        type: InputTypes.Radio,
        key: 'is_csbv_applicable',
        defaultValue: 'no',
        valuePropName: 'value',
        options: [
            {
                value: 'yes',
                label: 'Yes',
            },
            {
                value: 'no',
                label: 'No',
            },
        ],
        formitemCss: {
            flexDirection: 'column',
        },
        inputformCss: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    addressType: {
        label: 'Address Type',
        type: InputTypes.Radio,
        disabled: true,
        key: 'address_type',
        keyPath: ['location_details', 'address_type'],
        rules: [
            {
                required: true,
                message: 'Required',
            },
        ],
        isRequired: true,
        valuePropName: 'value',
        options: [
            {
                value: 'individual',
                label: 'Individual',
            },
            {
                value: 'business',
                label: 'Business',
            },
        ],
        formitemCss: {
            flexDirection: 'column',
        },
        inputformCss: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    senderName: {
        label: 'Name',
        placeholder: 'Enter Consignor Name',
        type: InputTypes.Input,
        rules: [
            {
                pattern: /^[\x20-\x7E]*$/,
                message: 'Only ASCII characters are allowed',
            },
            {
                required: true,
                message: 'Required',
            },
        ],
        key: 'seller_name',
        valuePropName: 'value',
        isRequired: true,
        formitemCss: {
            flexDirection: 'column',
            flexGrow: '0.3',
        },
        inputformCss: {
            width: '90%',
        },
    },
    senderCompanyName: {
        label: 'Company Name',
        placeholder: 'Enter Consignor Company Name',
        type: InputTypes.Input,
        key: 'company_name',
        valuePropName: 'value',
        rules: [
            {
                max: 50,
                message: 'Maximum 50 characters',
            },
        ],
        formitemCss: {
            flexDirection: 'column',
            flexGrow: '0.3',
        },
        inputformCss: {
            width: '95%',
        },
    },
    senderAddressLine1: {
        label: 'Address Line 1',
        placeholder: 'Enter Consignor Address Line 1',
        type: InputTypes.Input,
        rules: [
            {
                min: 3,
                message: 'Minimum 3 characters',
            },
            {
                required: true,
                message: 'Required',
            },
            {
                pattern: /^[\x20-\x7E]*$/,
                message: 'Only ASCII characters are allowed',
            },
        ],
        key: 'address_line_1',
        keyPath: ['location_details', 'address_line_1'],
        valuePropName: 'value',
        isRequired: true,
        formitemCss: {
            flexDirection: 'column',
            flexGrow: '1',
        },
        inputformCss: {
            width: '95%',
        },
    },
    senderAddressLine2: {
        label: 'Address Line 2',
        placeholder: 'Enter Consignor Address Line 2',
        type: InputTypes.Input,
        key: 'address_line_2',
        keyPath: ['location_details', 'address_line_2'],
        valuePropName: 'value',
        rules: [
            {
                min: 3,
                message: 'Minimum 3 characters',
            },
            {
                pattern: /^[\x20-\x7E]*$/,
                message: 'Only ASCII characters are allowed',
            },
        ],
        formitemCss: {
            flexDirection: 'column',
            flexGrow: '1',
        },
        inputformCss: {
            width: '95%',
        },
    },
    senderPincode: {
        label: 'Pincode',
        placeholder: 'Enter Consignor Pincode',
        type: InputTypes.Input,
        key: 'pincode',
        keyPath: ['location_details', 'pincode'],
        valuePropName: 'value',
        rules: [
            {
                required: true,
                message: 'Required',
            },
            {
                pattern: /^\d{6}$/,
                message: 'Invalid Pincode',
            },
        ],
        isRequired: true,
        formitemCss: {
            flexDirection: 'column',
            flexGrow: '1',
        },
        inputformCss: {
            width: '90%',
        },
    },
    senderCity: {
        label: 'City',
        placeholder: 'Enter Consignor City',
        type: InputTypes.Input,
        key: 'city',
        keyPath: ['location_details', 'city'],
        valuePropName: 'value',
        isRequired: true,
        formitemCss: {
            flexDirection: 'column',
            flexGrow: '1',
        },
        inputformCss: {
            width: '90%',
        },
    },
    senderState: {
        label: 'State',
        placeholder: 'Enter Consignor State',
        type: InputTypes.Input,
        isRequired: true,
        key: 'state',
        keyPath: ['location_details', 'state'],
        valuePropName: 'value',
        formitemCss: {
            flexDirection: 'column',
            flexGrow: '1',
        },
        inputformCss: {
            width: '90%',
        },
    },
    senderCountry: {
        label: 'Country',
        placeholder: 'Enter Consignor Country',
        type: InputTypes.Input,
        key: 'country',
        defaultValue: 'India',
        keyPath: ['location_details', 'country'],
        valuePropName: 'value',
        isRequired: true,
        formitemCss: {
            flexDirection: 'column',
            flexGrow: '1',
        },
        inputformCss: {
            width: '90%',
        },
    },
    latitude: {
        label: 'Latitude',
        placeholder: 'Enter Latitude',
        type: InputTypes.Input,
        key: 'latitude',
        keyPath: ['location_details', 'latitude'],
        valuePropName: 'value',
        formitemCss: {
            flexDirection: 'column',
        },
        inputformCss: {
            width: '90%',
        },
    },
    longitude: {
        label: 'Longitude',
        placeholder: 'Enter Longitude',
        type: InputTypes.Input,
        key: 'longitude',
        keyPath: ['location_details', 'longitude'],
        valuePropName: 'value',
        formitemCss: {
            flexDirection: 'column',
        },
        inputformCss: {
            width: '90%',
        },
    },
    senderMobileNumber: {
        label: 'Mobile Number',
        placeholder: 'Enter Consignor Mobile Number',
        type: InputTypes.Input,
        key: 'phone',
        valuePropName: 'value',
        isRequired: true,
        formitemCss: {
            flexDirection: 'column',
            flexGrow: '1',
        },
        inputformCss: {
            width: '93%',
        },
    },
    senderAlternateMobileNumber: {
        label: 'Alternate Mobile Number',
        placeholder: 'Enter Consignor Alternate Mobile Number',
        type: InputTypes.Input,
        key: 'alternate_phone',
        valuePropName: 'value',
        formitemCss: {
            flexDirection: 'column',
            flexGrow: '1',
        },
        inputformCss: {
            width: '93%',
        },
    },
    senderEmail: {
        label: 'Email',
        placeholder: 'Enter Consignor Email',
        type: InputTypes.Input,
        key: 'sender_email',
        rules: [
            {
                pattern: /\S+@\S+\.\S+/,
                message: 'Invalid email',
            },
            {
                required: true,
                message: 'Required',
            },
        ],
        isRequired: true,
        valuePropName: 'value',
        formitemCss: {
            flexDirection: 'column',
            flexGrow: '1',
        },
        inputformCss: {
            width: '93%',
        },
    },
    addressProofType: {
        label: 'Address Proof Type',
        placeholder: 'Select Address Proof Type',
        type: InputTypes.Select,
        options: addressProofTypeList,
        isRequired: true,
        key: 'address_proof_type',
        keyPath: ['location_details', 'address_proof_type'],
        valuePropName: 'value',
        formitemCss: {
            flexDirection: 'column',
            width: '30%',
        },
        inputformCss: {
            width: '90%',
        },

    },
    addressProofFront: {
        label: 'Front Image',
        placeholder: 'Upload Address Proof Front',
        type: InputTypes.Upload,
        isRequired: true,
        key: 'address_proof_front_image',
        maxFileSize: 3,
        valuePropName: 'value',
        showPreview: true,
        formitemCss: {
            flexDirection: 'column',
            flexGrow: '0.1',
        },
        inputformCss: {
            width: '90%',
        },
    },
    addressProofBack: {
        label: 'Back Image',
        placeholder: 'Upload Address Proof Back',
        type: InputTypes.Upload,
        isRequired: true,
        maxFileSize: 3,
        key: 'address_proof_back_image',
        valuePropName: 'value',
        showPreview: true,
        formitemCss: {
            flexDirection: 'column',
            flexGrow: '1',
        },
        inputformCss: {
            width: '90%',
        },
    },
    bankAccountNumber: {
        label: 'Account Number',
        placeholder: 'Enter Consignor Bank Account Number',
        type: InputTypes.Input,
        key: 'account_number',
        keyPath: ['bank_details', 'account_number'],
        valuePropName: 'value',
        formitemCss: {
            flexDirection: 'column',
            width: '50%',
        },
        inputformCss: {
            width: '90%',
        },
    },
    bankAdCode: {
        label: 'AD Code',
        placeholder: 'Enter Consignor Bank AD Code',
        type: InputTypes.Input,
        key: 'ad_code',
        keyPath: ['bank_details', 'ad_code'],
        valuePropName: 'value',
        formitemCss: {
            flexDirection: 'column',
            width: '50%',
        },
        inputformCss: {
            width: '90%',
        },
    },
    bankIfscCode: {
        label: 'IFSC Code',
        placeholder: 'Enter Consignor Bank IFSC Code',
        type: InputTypes.Input,
        key: 'ifsc_code',
        keyPath: ['bank_details', 'ifsc_code'],
        valuePropName: 'value',
        formitemCss: {
            flexDirection: 'column',
            width: '50%',
        },
        inputformCss: {
            width: '90%',
        },
    },
    bankName: {
        label: 'Bank Name',
        placeholder: 'Enter Consignor Bank Name',
        type: InputTypes.Input,
        key: 'bank_name',
        keyPath: ['bank_details', 'bank_name'],
        valuePropName: 'value',
        formitemCss: {
            flexDirection: 'column',
            width: '50%',
        },
        inputformCss: {
            width: '90%',
        },
    },
    bankType: {
        label: 'Bank Type',
        placeholder: 'Enter Consignor Bank Type',
        type: InputTypes.Radio,
        key: 'bank_type',
        keyPath: ['bank_details', 'bank_type'],
        valuePropName: 'value',
        options: bankTypeList,
        formitemCss: {
            flexDirection: 'column',
            flexGrow: '1',
            width: '50%',
        },
        inputformCss: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    AdCodeCertification: {
        label: 'AD Code Certification',
        type: InputTypes.Upload,
        key: 'ad_code_certificate',
        imageExt: ['bank_details', 'image_details', 'ext'],
        valuePropName: 'value',
        smallMessage1: 'Supported file types: .JPG, .JPEG, .PDF',
        smallMessage2: 'Max file size: 3MB',
        maxFileSize: 3,
        showPreview: true,
        formitemCss: {
            flexGrow: '1',
            display: 'flex',
            flexDirection: 'column',
            width: '50%',
        },
        inputformCss: {
            width: '90%',
        },
        innerFormItemCss: {
            display: 'flex',
        },
        labelCss: {
            flexGrow: '1',
        },
    },
    documentType: {
        label: 'Document Type',
        placeholder: 'Select Document Type',
        type: InputTypes.Select,
        key: 'document_type',
        options: documentTypeList,
        valuePropName: 'value',
        isRequired: true,
        rules: [
            {
                required: true,
                message: 'Required',
            },
        ],
        formitemCss: {
            flexDirection: 'column',
            width: '50%',
        },
        inputformCss: {
            width: '90%',
        },
    },
    documentNumber: {
        label: 'Document Number',
        placeholder: 'Enter Document Number',
        type: InputTypes.Input,
        key: 'document_number',
        valuePropName: 'value',
        isRequired: true,
        rules: [
            {
                required: true,
                message: 'Required',
            },
        ],
        formitemCss: {
            flexDirection: 'column',
            width: '50%',
        },
        inputformCss: {
            width: '90%',
        },
    },
    documentFileNumber: {
        label: 'Document File Number',
        placeholder: 'Enter Document File Number',
        type: InputTypes.Input,
        key: 'file_number',
        valuePropName: 'value',
        isRequired: true,
        rules: [
            {
                required: true,
                message: 'Required',
            },
        ],
        formitemCss: {
            flexDirection: 'column',
            width: '50%',
        },
        inputformCss: {
            width: '90%',
        },
    },
    documentDOB: {
        label: 'Document Date of Birth',
        placeholder: 'Enter Document DOB',
        type: InputTypes.Date,
        key: 'dob',
        valuePropName: 'value',
        isRequired: true,
        rules: [
            {
                required: true,
                message: 'Required',
            },
        ],
        formitemCss: {
            flexDirection: 'column',
            width: '50%',
        },
        inputformCss: {
            width: '90%',
        },
    },
    documentFrontImage: {
        label: 'Front Image',
        placeholder: 'Upload Document Front',
        type: InputTypes.Upload,
        maxFileSize: 3,
        key: 'front_image',
        showPreview: true,
        valuePropName: 'value',
        isRequired: true,
        rules: [
            {
                required: true,
                message: 'Required',
            },
        ],
        formitemCss: {
            flexDirection: 'column',
            width: '51%',
        },
        inputformCss: {
            width: '90%',
        },
    },
    documentBackImage: {
        label: 'Back Image',
        placeholder: 'Upload Document Back',
        type: InputTypes.Upload,
        maxFileSize: 3,
        key: 'back_image',
        showPreview: true,
        valuePropName: 'value',
        isRequired: true,
        rules: [
            {
                required: true,
                message: 'Required',
            },
        ],
        formitemCss: {
            display: 'flex',
            flexDirection: 'column',
        },
        inputformCss: {
            width: '90%',
        },
    },
    embedSignature: {
        label: 'Upload Signature',
        placeholder: 'Upload Signature',
        type: InputTypes.Upload,
        key: 'signature_image',
        valuePropName: 'value',
        imageExt: ['signature_image_details', 'ext'],
        isRequired: true,
        rules: [
            {
                required: true,
                message: 'Required',
            },
        ],
        maxFileSize: 3,
        showPreview: true,
        formitemCss: {
            flexDirection: 'column',
            width: '60%',
        },
        innerFormItemCss: {
            display: 'flex',
        },
        labelCss: {
            minWidth: '33%',
        },
    },
    embedSeal: {
        label: 'Upload Seal',
        placeholder: 'Upload Seal',
        type: InputTypes.Upload,
        key: 'seal_image',
        maxFileSize: 3,
        valuePropName: 'value',
        imageExt: ['seal_image_details', 'ext'],
        smallMessage1: 'Supported file types: .JPG, .JPEG',
        smallMessage2: 'Max file size: 3MB',
        showPreview: true,
        formitemCss: {
            flexDirection: 'column',
            width: '60%',
        },
        innerFormItemCss: {
            display: 'flex',
            marginBottom: '4px',
        },
        labelCss: {
            minWidth: '33%',
        },
    },
};

export const getRequiredKeys = () => {
    const required = {
        FIRST: [
            createSellerProfileFormFields.senderCode.key,
            createSellerProfileFormFields.senderType.key,
            createSellerProfileFormFields.senderName.key,
            createSellerProfileFormFields.senderAddressLine1.keyPath,
            createSellerProfileFormFields.senderCity.keyPath,
            createSellerProfileFormFields.senderState.keyPath,
            createSellerProfileFormFields.senderCountry.keyPath,
            createSellerProfileFormFields.senderPincode.keyPath,
            createSellerProfileFormFields.senderMobileNumber.key,
        ] as string[],
        SECOND: [],
        THIRD: [],
    };
    return required;
};
