import * as React from 'react';
import PrimaryIcon from '../../create-consignment/single-consignment-icons/primary';
import AddressIcon from '../../create-consignment/single-consignment-icons/address';
import Loader from '../../common/Loader';
import {
    getCityState,
} from '../../../network/consignments.api';
import { useDebounce } from 'hooks/use-debounce';
import { StylesProps } from '../../../theme/jss-types';
import { stepOneStyles } from '../create-seller-profile.styles';
import RenderFormItem from './generic-object';
import { createSellerProfileFormFields } from '../create-seller-profile.constants';
import { ExclamationCircleFilled, LoadingOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { sendOTPToVerify, verifyOTP, checkCodeAvailaibility } from '../../../network/sender-management.api';

import {
    Form,
    FormInstance,
    Input,
    Modal,
    Button,
    message,
} from 'antd';
import { HocOptions } from '../../common/generic-hoc.types';
import GenericHoc from '../../common/generic-hoc';
import { useTranslation } from 'react-i18next';
import { FormField } from 'components/create-consignment/create-consignment.types';
import { NamePath } from 'antd/lib/form/interface';
import { useRef } from 'react';

const {
    useState,
    useEffect,
} = React;

const {
    senderCode,
    senderType,
    csbvApplicable,
    addressType,
    senderName,
    senderCompanyName,
    senderAddressLine1,
    senderAddressLine2,
    senderPincode,
    senderCity,
    senderCountry,
    // latitude,
    // longitude,
    senderMobileNumber,
    senderAlternateMobileNumber,
    senderEmail,
    senderState,
    addressProofBack,
    addressProofFront,
    addressProofType,
} = createSellerProfileFormFields;

interface StepOneProps extends StylesProps<ReturnType<typeof stepOneStyles>> {
    config: any;
    form: FormInstance;
    formData: any;
    setFormData: any;
    condition: boolean;
    masterData: any;
    isEdit: boolean;
}


const StepOne = (props: StepOneProps) => {
    const {
        config,
        classes,
        form,
        formData,
        setFormData,
        condition,
        isEdit,
        masterData,
    } = props;
    const { t } = useTranslation();
    const customerPortalConfig = config?.config?.customer_portal_config;
    const emailRegex = customerPortalConfig?.email_validation_regex
        ? new RegExp(customerPortalConfig?.email_validation_regex)
        : /\S+@\S+\.\S+/;
    const phoneRegex = config?.parts_to_show?.remove_regex ? null : config?.config?.phone_regex;
    const [phoneRegExp, setPhoneRegExp] = useState<RegExp>(/^(\+91\s?|\(?0\)?)?\d{10}$/);
    const [validPhone, setValidPhone] = useState<boolean | undefined>(true);
    const [isOTPModalVisible, setIsOTPModalVisible] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [isPhoneValid, setIsPhoneValid] = useState<boolean>(false);
    const [otp, setOtp] = useState('');
    const [resending, setResending] = useState(false);

    const {
        SELLER_TYPE_DOCUMENTS_UPLOAD_MAP, DOCUMENT_LABEL_MAP, RETRY_TIMEOUT_MS,
        DOCUMENT_TYPE_REQUIRED_FIELDS_MAP,
    } = masterData;

    const [timer, setTimer] = useState(RETRY_TIMEOUT_MS / 1000);

    const commonProps = {
        config,
        form,
        formData,
    };

    if (isEdit) {
        senderCode.disabled = true;
        senderType.disabled = true;
    } else {
        senderCode.disabled = false;
        senderType.disabled = false;
    }

    const saveCityState = async () => {
        setLoading(true);
        const pincodeValue = form.getFieldValue(senderPincode.keyPath as NamePath);
        try {
            const response = await getCityState({
                pincode: pincodeValue,
                isLTL: false,
            });
            if (response.isSuccess) {
                form.resetFields([senderCity?.keyPath as NamePath, senderState?.keyPath as NamePath]);
                form.setFields([
                    {
                        name: senderCity?.keyPath as NamePath,
                        value: response.data.city || '',
                    },
                    {
                        name: senderState?.keyPath as NamePath,
                        value: response.data.state || '',
                    },
                ]);
            } else {
                message.error(response?.errorMessage || 'Failed to fetch city and state');
            }
        } catch (e) {
            message.error('Failed to fetch city and state');
        }
        setLoading(false);
    };

    useEffect(() => {
        if (form.isFieldTouched(senderMobileNumber.key)) {
            form.validateFields([senderMobileNumber.key]);
        }
    }, [phoneRegExp]);

    useEffect(() => {
        if (phoneRegex) {
            const phoneRegexToSet = new RegExp(phoneRegex.replace(/^\//, '').replace(/\/$/, ''));
            setPhoneRegExp(phoneRegexToSet);
        }
    }, []);

    const formPincode = Form.useWatch(senderPincode.keyPath as NamePath, form);
    const formSenderCode = Form.useWatch(senderCode.key, form);
    const formSenderType = Form.useWatch(senderType.key, form);
    const formAddressProofType = Form.useWatch(addressProofType.keyPath as NamePath, form);
    const formMobile = Form.useWatch(senderMobileNumber.key, form);
    const requiredAddressType = Form.useWatch(addressType.keyPath as NamePath, form);
    const formCompanyName = Form.useWatch(senderCompanyName.key as NamePath, form);
    const debounceTimePincodeString = useDebounce(formPincode);
    const debounceTimeSenderCodeString = useDebounce(formSenderCode);
    const [prevFormAddressProofType, setPrevFormAddressProofType] = React.useState(formAddressProofType);
    const prevRequiredAddressType = useRef(null);

    React.useEffect(() => {
        if (condition && prevFormAddressProofType !== undefined && prevFormAddressProofType !== formAddressProofType) {
            form.setFields([
                {
                    name: ['address_proof', 0, 'front_image'] as NamePath,
                    value: undefined,
                },
                {
                    name: ['address_proof', 1, 'back_image'] as NamePath,
                    value: undefined,
                },
            ]);
        }
        setPrevFormAddressProofType(formAddressProofType);
    }, [formAddressProofType]);

    useEffect(() => {
        if (condition && prevRequiredAddressType.current && prevRequiredAddressType.current !== requiredAddressType) {
            form.resetFields([senderName?.key as NamePath, senderCompanyName?.key as NamePath]);
        }
        prevRequiredAddressType.current = requiredAddressType;
    }, [requiredAddressType]);

    const createOptions = (document_type_options: Array<string>) => {
        return document_type_options?.map((option: any) => {
            return {
                value: option,
                label: DOCUMENT_LABEL_MAP[option],
            };
        });
    };

    React.useEffect(() => {
        if (formPincode?.length === 6) {
            saveCityState();
        }
    }, [debounceTimePincodeString]);

    React.useEffect(() => {
        if (condition) {
            if (formData?.verified_mobile_number !== formMobile && formMobile) {
                setFormData({
                    ...formData,
                    is_phone_verified: false,
                });
            }
        }
    }, [formMobile]);

    React.useEffect(() => {
        if (formSenderCode && !senderCode.disabled) {
            checkCodeAvailaibility({ seller_code: formSenderCode }).then((response) => {
                if (response?.data?.available === false) {
                    message.error('Seller Code already exists');
                }
            });
        }
    }, [debounceTimeSenderCodeString]);

    let interval: NodeJS.Timeout | null = null;

    useEffect(() => {
        if (isOTPModalVisible && timer > 0) {
            interval = setInterval(() => {
                setTimer(timer - 1);
            }, 1000);
        } else if (!isOTPModalVisible) {
            setTimer(RETRY_TIMEOUT_MS / 1000);
        }

        return () => {
            if (interval !== null) {
                clearInterval(interval);
            }
        };
    }, [isOTPModalVisible, timer]);


    const renderRowDescription = (FieldIcon: React.ElementType, label: string) => {
        return (
            <div className={classes.boxTitle}>
                <div className={classes.boxIcon}>
                    <FieldIcon className={classes.boxIcon} />
                </div>
                <span>{label}</span>
            </div>
        );
    };

    const renderLine = () => {
        return <div className={classes.line} />;
    };


    const isValidPhoneNumber = (num: string | undefined) => {
        if (phoneRegExp === null || !num) {
            setIsPhoneValid(true);
            return true;
        }
        const isValid = phoneRegExp?.test(num);
        setIsPhoneValid(isValid);
        return isValid;
    };

    const handleOk = async () => {
        setLoading(true);
        try {
            if (!otp) {
                message.error('Please enter OTP');
                setLoading(false);
                return;
            }
            const body: { type: string; phone: any; seller_id?: any; otp: any } = {
                type: isEdit ? 'update' : 'create',
                phone: form.getFieldValue(senderMobileNumber.key),
                otp,
            };
            if (isEdit) {
                body.seller_id = formData.seller_id;
            }
            const response = await verifyOTP(body);
            if (response.isSuccess) {
                setFormData({
                    ...formData,
                    seller_id: response?.data?.seller_id,
                    verified_mobile_number: form.getFieldValue(senderMobileNumber.key),
                    is_phone_verified: true,
                });
                message.success('OTP verified successfully');
                setOtp('');
                setIsOTPModalVisible(false);
            } else {
                message.error(response?.errorMessage || 'Failed to verify OTP');
            }
        } catch (e) {
            message.error('Failed to verify OTP');
        }
        setLoading(false);
    };

    const handleCancel = () => {
        setOtp('');
        setIsOTPModalVisible(false);
    };

    const sendOTP = async (resend? : boolean) => {
        if (!resend) {
            setLoading(true);
        }
        const phoneno = form.getFieldValue(senderMobileNumber.key);
        if (!phoneno || !isValidPhoneNumber(phoneno)) {
            message.error('Invalid phone no.');
            setLoading(false);
            return;
        }
        const body: { type: string; phone: any; seller_id?: any; } = {
            type: isEdit ? 'update' : 'create',
            phone: phoneno,
        };
        if (isEdit) {
            body.seller_id = formData.seller_id;
        }
        try {
            const response = await sendOTPToVerify(body);
            if (response?.isSuccess) {
                setIsOTPModalVisible(true);
                message.success(response?.message || 'OTP sent successfully');
            } else {
                message.error(response?.errorMessage || 'Failed to send OTP');
            }
        } catch (e) {
            message.error('Failed to send OTP');
        }
        setLoading(false);
    };

    const handleOtpChange = (e: any) => {
        setOtp(e.target.value);
    };

    const handleResend = async () => {
        setResending(true);
        setOtp('');
        await sendOTP(true);
        setResending(false);
        setTimer(RETRY_TIMEOUT_MS / 1000);
    };
    const renderResend = () => {
        if (timer === 0) {
            return ' Resend OTP';
        }
        return ` Resend OTP in ${timer} Seconds`;
    };

    const renderOTPModal = () => {
        const phoneNumber = form.getFieldValue(senderMobileNumber.key);
        return (
            <Modal
                title={
                    (
                        <div className={classes.otpModalHeader}>
                            <ExclamationCircleFilled className={classes.exclamation} style={{ color: '#FA5F26' }} />
                            Verify Mobile Number
                        </div>
                    )
                }
                visible={isOTPModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>
                            Didn’t receive OTP?
                        </span>
                        <a
                            key="resend"
                            className={timer > 0 ? classes.resendDisabled : classes.resend}
                            onClick={timer > 0 ? undefined : handleResend}
                        >
                            {resending ? <LoadingOutlined color="#006EC3" /> : renderResend()}
                        </a>
                    </div>,
                    <Button key="submit" type="primary" disabled={loading} onClick={handleOk}>
                        VERIFY
                    </Button>,
                ]}
            >
                <div>
                    <span>
                        OTP has been sent to
                    </span>
                    <span className={classes.phone}>
                        {phoneNumber}
                    </span>

                </div>

                <Input placeholder="Enter OTP" value={otp} onChange={handleOtpChange} />
            </Modal>
        );
    };

    const renderPhoneNumber = (field: FormField) => {
        return (
            <div>
                <div className={classes.formitem} style={field.formitemCss ? { ...field.formitemCss } : {}}>
                    <div className={classes.label} style={field.labelCss ? { ...field.labelCss } : {}}>
                        {t(field.label)}
                        {field.isRequired ? '*' : ''}
                    </div>
                    <div className={classes.inputform} style={field.inputformCss ? { ...field.inputformCss } : {}}>
                        <Form.Item
                            name={field.key}
                            rules={[{
                                validator: (rule, value, cb) => {
                                    if (!isValidPhoneNumber(value)) {
                                        return cb('Invalid phone no.');
                                    }
                                    return cb();
                                },
                            }, {
                                required: field.isRequired,
                                message: 'Required',
                            },
                            ]}
                        >
                            <Input
                                style={validPhone ? {} : { borderColor: 'red' }}
                                className={classes.mobileInput}
                                placeholder={field.placeholder}
                                onChange={
                                    (e) => {
                                        form.setFieldsValue({
                                            [field.key]: e.target.value,
                                        });
                                        const isValid = isValidPhoneNumber(e.target.value);
                                        if (isValid !== validPhone) {
                                            setValidPhone(isValid);
                                        }
                                    }
                                }
                            />
                        </Form.Item>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        {field === senderMobileNumber && isPhoneValid && formData?.is_phone_verified
                            && (
                                <span className={classes.verifiedText}>
                                    <CheckCircleOutlined className={classes.verifiedText} />
                                    VERIFIED
                                </span>
                            )}
                        {field === senderMobileNumber && isPhoneValid && !formData?.is_phone_verified
                            && (
                                <a onClick={() => sendOTP()} className={classes.verify}>
                                    VERIFY
                                </a>
                            )}
                    </div>
                </div>
            </div>
        );
    };

    const renderAddressDetails = () => {
        const senderTypeAddressList = SELLER_TYPE_DOCUMENTS_UPLOAD_MAP?.[formSenderType];
        const requiredAddressProofType = {
            ...addressProofType,
            disabled: !formSenderType,
            tooltipMessage: 'Please select a consignor type first to enable address options.',
            options: createOptions(senderTypeAddressList?.address_proof_options),
        };
        if (formSenderType
            && !senderTypeAddressList?.address_proof_options?.find((elem: any) => elem === formAddressProofType)) {
            form.setFields([
                {
                    name: addressProofType.keyPath as NamePath,
                    value: undefined,
                },
                {
                    name: ['address_proof', 0, 'front_image'] as NamePath,
                    value: undefined,
                },
                {
                    name: ['address_proof', 1, 'back_image'] as NamePath,
                    value: undefined,
                },
            ]);
        }
        if (formSenderType) {
            form.setFields([
                {
                    name: addressType.keyPath as NamePath,
                    value: formSenderType === 'individual'
                        || formSenderType === 'foreign_nationals_residing_in_india' ? 'individual' : 'business',
                },
            ]);
        }
        const addressProofFrontImage = {
            ...addressProofFront,
            disabled: !formAddressProofType,
            key: ['address_proof', 0, 'front_image'],
            imageExt: ['location_details', 'front_image_details', 'ext'],
        };
        const addressProofBackImage = {
            ...addressProofBack,
            disabled: !formAddressProofType,
            key: ['address_proof', 1, 'back_image'],
            imageExt: ['location_details', 'back_image_details', 'ext'],
        };
        const DatailsToShowList = DOCUMENT_TYPE_REQUIRED_FIELDS_MAP[formAddressProofType] || [];
        const showAddressBackImage = DatailsToShowList.includes('back_image');
        const senderNameField = {
            ...senderName,
            disabled: requiredAddressType === 'business',
        };
        if (requiredAddressType === 'business') {
            form.setFields([
                {
                    name: senderName.key,
                    value: formCompanyName,
                },
            ]);
        }
        const requiredSenderCompanyName = {
            ...senderCompanyName,
            rules: [
                {
                    required: true,
                    message: 'Required',
                },
                {
                    max: 50,
                    message: 'Maximum 50 characters',
                },
            ],
            isRequired: true,
        };
        if (!requiredSenderCompanyName.isRequired && !form.getFieldValue(senderCompanyName.key)) {
            form.setFields([
                {
                    name: senderCompanyName.key,
                    errors: [],
                },
            ]);
        }
        const requiredSenderEmail = {
            ...senderEmail,
            rules: [
                {
                    pattern: emailRegex,
                    message: 'Invalid email',
                },
                {
                    required: true,
                    message: 'Required',
                },
            ],
        };

        return (
            <div className={classes.boxFieldsCol2}>
                <div className={classes.boxField1}>
                    <RenderFormItem
                        {...commonProps}
                        field={addressType}
                    />
                </div>
                <div className={classes.boxField1}>
                    <RenderFormItem
                        {...commonProps}
                        field={senderNameField}
                    />
                    {requiredAddressType === 'business' ? (
                        <RenderFormItem
                            {...commonProps}
                            field={requiredSenderCompanyName}
                        />
                    ) : null}
                </div>
                <div className={classes.boxField1}>
                    <RenderFormItem
                        {...commonProps}
                        field={senderAddressLine1}
                    />
                    <RenderFormItem
                        {...commonProps}
                        field={senderAddressLine2}
                    />
                </div>
                <div className={classes.boxField1}>
                    <RenderFormItem
                        {...commonProps}
                        field={senderPincode}
                    />
                    <RenderFormItem
                        {...commonProps}
                        field={senderCity}
                    />
                    <RenderFormItem
                        {...commonProps}
                        field={senderState}
                    />
                    <RenderFormItem
                        {...commonProps}
                        field={senderCountry}
                    />
                </div>
                {/* <div className={classes.boxField1}>
                    <RenderFormItem
                        {...commonProps}
                        field={latitude}
                    />
                    <RenderFormItem
                        {...commonProps}
                        field={longitude}
                    />
                </div> */}
                <div className={classes.boxField1}>
                    {renderPhoneNumber(senderMobileNumber)}
                    {renderPhoneNumber(senderAlternateMobileNumber)}
                    <RenderFormItem
                        field={requiredSenderEmail}
                        {...commonProps}
                    />
                </div>
                <div className={classes.boxField1}>
                    <RenderFormItem
                        field={requiredAddressProofType}
                        {...commonProps}
                    />
                    <RenderFormItem
                        field={addressProofFrontImage}
                        {...commonProps}
                    />
                    {showAddressBackImage && (
                        <RenderFormItem
                            field={addressProofBackImage}
                            {...commonProps}
                        />
                    )}
                </div>
            </div>
        );
    };

    const renderAddress = () => {
        return (
            <div className={classes.box}>
                {renderRowDescription(AddressIcon, t('address_details'))}
                {renderAddressDetails()}
            </div>
        );
    };

    const renderPrimaryDetails = () => {
        return (
            <div className={classes.boxFieldsCol}>
                <div className={classes.boxFields}>
                    <RenderFormItem
                        field={senderCode}
                        {...commonProps}
                    />
                </div>
                <div className={classes.singleBoxFields}>
                    <RenderFormItem
                        field={senderType}
                        {...commonProps}
                    />
                </div>
                <div className={classes.singleBoxFields}>
                    <RenderFormItem
                        field={csbvApplicable}
                        {...commonProps}
                    />
                </div>
            </div>
        );
    };

    const renderPrimary = () => {
        return (
            <div className={classes.box}>
                {renderRowDescription(PrimaryIcon, t('primary_details'))}
                {renderPrimaryDetails()}
            </div>
        );
    };

    if (!condition) {
        return null;
    }

    return (
        <div className={classes.main}>
            {loading && <Loader zIndex={10} />}
            {renderPrimary()}
            {renderLine()}
            {renderAddress()}
            {renderOTPModal()}
        </div>
    );
};

const mapStateToProps = () => {
    // const { master } = state;
    return {
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: stepOneStyles,
    },
};

export default GenericHoc(hocConfig)(StepOne);
