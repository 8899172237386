const config = {
  CSP_VIOLATION_REPORT_URI: 'http://35.85.44.168:5002/report/customer_portal_dtdc',
  AUTH_RSA_PUBLIC_KEY : "-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA50+FNoYTr7zS06JTuBgyZ1ah2zExIhDoxcAPtDmq2mP3VwcmRV4AcFy39wo8suYNYPQqfkNC52Py/kLdB/FHYJyHSc1v0vXVV/27RRv33ygPfN5gVTmfDeRFTto3klQQM3SiUuYRhtYO9iV20xqpvLuiPLynRZlGZoXZYa+ZOLyITrj4+gRZGT5YAYiaprORq2TOJIYCdUwfGDawHhhsdwvfeJL89WcXiNrmjzVt1G1/7CmwYM72/pY0tfpXlN2IkhrCSUOMSLkDzPXFT76fF8o+O+PKGUDksguU0rCQFo2f6TWQqqJB/H+yXvkYIG1Yd+i/dvxl1A6ropHVVXNqkQIDAQAB-----END PUBLIC KEY-----",
  CAPTCHA_SITE_KEY: '6LeZtqspAAAAAFxie0yQLSErrxQQwXBaxuvgS1Ls',
  API_DOC_FRONTEND_BASE_URL: 'https://apiplayground.shipsy.in',
  DTDC_SRF_PORTAL: 'https://srf.dtdc.com/index.asp',
      API_BASE_URL: 'https://pxapi.dtdc.in',
      ROUTE_OPTIMISATION_BASE_URL: '',
      BUSINESS_BOOKING_API_URL: 'https://obbv2prod.dtdc.in',
      RENDER_PERSISTENT_IFRAME_OPS: true,
      useOnlyForLocalTestingOrgId: '1',
      LOGO_URL: 'https://shipsy-public-assets.s3.amazonaws.com/generic/file-5fd13c93-273e-47f7-9e9d-2c24c1c6f3d8.jpg',
      allowedTrackingEvents: ['ITD', 'DLV', 'OUTDLV', 'NONDLV', 'ARH', 'BKD'],
      IFRAME_NON_STRICT_MODE: true,
      CP_BASE_URL: 'https://dtdc.customerportal.shipsy.in',
      GA_MID: 'G-Z2GRVQFK3Q',
      ANALYTICS_FRONTEND_BASE_URL: 'customeranalyticsdtdcdashboard.shipsy.io',
      displayConfig: {
          "tatamotors": {
               customerReferenceNumberList: "Invoice Number"
          },
      },
      "allowedFilters": [
            "referenceNumberList",
            "customerReferenceNumberList",
            "customerIdList",
            "bookingServiceTypeIdList",
            "consignmentType"
      ],
      "allowedColumns": [
      "reference_number",
      "status",
      "attempt_count",
      "created_at",
      "is_cod",
      "cod_amount",
      "rto_reference_number",
      "is_rto",
      "customer_reference_number",
      "weight",
      "chargeable_weight",
      "volumetric_weight",
      "num_pieces",
      "receiver_name",
      "receiver_phone",
      "poc_image",
      "delivery_kyc_type",
      "delivery_kyc_number",
      "expected_delivery_date",
      "destination_address_line_1",
      "destination_address_line_2",
      "destination_address_line_3",
      "destination_city",
      "destination_state",
      "destination_pincode",
      "last_delivery_attempt_time",
      "task_failure_reason",
      "consignment_type",
      "latest_npr_reason",
      "cod_settlement_status",
      "service_type",
      "destination_name",
      "destination_phone",
      "customer_code",
      "revised_expected_delivery_date",
      "sender_name",
      "origin_city",
      "origin_pincode",
      "sender_phone",
      "sender_address_line_1",
      "sender_address_line_2",
      "sender_address_line_3",
      "sender_city",
      "sender_pincode",
      "sender_state"
  ],
      "custom_csp_prefix": {
          "base-uri": "*.dtdc.in",
          "script-src": "*.dtdc.in",
          "style-src": "*.dtdc.in",
          "default-src": "*.dtdc.in",
          "font-src": "*.dtdc.in",
          "connect-src": "*.dtdc.in",
          "frame-src": "*.dtdc.in",
          "media-src": "*.dtdc.in"
      },
"SELLER_PROFILE_API_URL": "https://sellerprofiledtdcprod.shipsy.io",
"CONSIGNMENT_LABEL_API_URL": "https://elabelprod.dtdc.in"
  };
  module.exports.default = config;
