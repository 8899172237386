import {
    Button,
    Form,
    FormInstance,
    Input,
    InputNumber,
    message,
    Radio,
    Select,
    Upload,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import * as React from 'react';
import withStyles from 'react-jss';
import { StylesProps } from '../../../theme/jss-types';
import { FormField, InputTypes } from '../create-consignment.types';
import AddressIcon from '../single-consignment-icons/address';
import { ConsignorAddressType, consignorFormFields } from '../create-modal.constants';
import { consignorDetailsStyles } from '../single-consignment.styles';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { getCustomerDetails } from 'network/settings-product';
import Loader from 'components/common/Loader';
import { UploadFile } from 'antd/lib/upload/interface';
import Helper from 'library/Helper';
import { getS3Urls, uploadImageToS3 } from 'network/common.api';
import { useTranslation } from 'react-i18next';
import { getCityState } from 'network/consignments.api';

const {
    ConsignorCompanyName,
    OriginType,
    ConsignorTaxID,
    ConsignorGSTINo,
    ConsignorIECNo,
    Consignor_PAN,
    ConsignorKycDocType,
    ConsignorKycDocNo,
    Sender_Bank_Account_No,
    Sender_Bank_AD_Code,
    Sender_Bank_Name,
    Sender_Bank_IFSC_Code,
    NEFI_Flag,
    CPCCode,
    Cess_Value,
    Cust_Seller_code,
    ConsignorName,
    ConsignorAddressLine1,
    ConsignorAddressLine2,
    ConsignorCity,
    ConsignorState,
    ConsignorCountry,
    ConsignorPincode,
    ConsignorPhone,
    ConsignorEmail,
} = consignorFormFields;

// const { useState } = React;
interface StepTwoProps extends StylesProps<ReturnType<typeof consignorDetailsStyles>> {
    formData: any;
    form: FormInstance;
    setFormData: any;
    fieldsToShowInternational: Record<any, boolean>;
    customerPortalConfig: any;
    setConsignorAddressType: any;
    consignorAddressType: string;
}

const ConsignorDetailsInteranation = (props: StepTwoProps) => {
    const {
        form,
        classes,
        formData,
        setFormData,
        fieldsToShowInternational,
        customerPortalConfig,
        setConsignorAddressType,
        consignorAddressType,
    } = props;
    const [loading, setLoading] = React.useState<boolean>(true);
    const [fileList, setFileList] = React.useState<UploadFile[]>([]);
    const [backFileList, setBackFileList] = React.useState<UploadFile[]>([]);
    const [uploadingFile, setUploadingFile] = React.useState<boolean>(false);
    const mandatoryFieldShipmentCommercial = customerPortalConfig?.mandatory_shipment_commercial || {};
    const { t } = useTranslation();

    React.useEffect(() => {
        form.setFieldsValue(formData);
    }, []);

    const fetchCityState = async (pincode: string, currentCity: string, currentCountry: string, currentState: string) => {
        setLoading(true);
        try {
            const response = await getCityState({
                pincode,
                isLTL: false,
            });
            form.setFieldsValue({
                [ConsignorCity.key]: response?.data?.city || currentCity,
                [ConsignorState.key]: response?.data?.state || currentState,
                [ConsignorCountry.key]: response?.data?.country || currentCountry,
            });
        } catch (error) {
            message.error('Unable to fetch city and state from pincode');
        }
        setLoading(false);
    };

    React.useEffect(() => {
        const {
            consignorPincode, consignorCity, consignorState, consignorCountry,
        } = form.getFieldsValue();
        if (consignorPincode && consignorPincode.length === 6) {
            fetchCityState(consignorPincode, consignorCity, consignorCountry, consignorState);
        }
    }, [Form.useWatch(ConsignorPincode.key, form)]);

    const isRequired = (key: string) => {
        if (formData.shipmentPurpose === 'COMMERCIAL' && mandatoryFieldShipmentCommercial[key]
            && formData.courierType === 'non-document') {
            return mandatoryFieldShipmentCommercial[key];
        }
        if ([ConsignorAddressLine1.key,
            ConsignorName.key,
            ConsignorCity.key,
            ConsignorPincode.key,
            ConsignorPhone.key,
            ConsignorEmail.key,
        ].includes(key)) {
            return true;
        }
        return false;
    };

    const prefillData = (data: any) => {
        const frontList: UploadFile[] = data?.kyc_details?.kyc_url ? [
            {
                uid: '1',
                name: `${data?.kyc_details?.kyc_doc_type}_front`,
                status: 'done',
                url: data?.kyc_details?.kyc_url,
            },
        ] : [];
        const backList: UploadFile[] = data?.kyc_details?.kyc_back_url ? [
            {
                uid: '1',
                name: `${data?.kyc_details?.kyc_doc_type}_back`,
                status: 'done',
                url: data?.kyc_details?.kyc_back_url,
            },
        ] : [];
        form.setFieldsValue({
            [Sender_Bank_Account_No.key]: data?.bank_details?.account_number,
            [Sender_Bank_IFSC_Code.key]: data?.bank_details?.branch_code,
            [Sender_Bank_AD_Code.key]: data?.bank_details?.bank_code,
            [Sender_Bank_Name.key]: data?.bank_details?.bank_name,
            [ConsignorKycDocNo.key]: data?.kyc_details?.kyc_doc_number,
            consignorKycFrontImage: data?.kyc_details?.kyc_url,
            consignorKycBackImage: data?.kyc_details?.kyc_back_url,
            [ConsignorKycDocType.key]: data?.kyc_details?.kyc_doc_type,
            [ConsignorGSTINo.key]: data?.customer_gstin_number,
            [ConsignorIECNo.key]: data?.customer_iec_number,
            [ConsignorName.key]: data?.consignor_address?.name,
            [ConsignorAddressLine1.key]: data?.consignor_address?.addressLine1,
            [ConsignorAddressLine2.key]: data?.consignor_address?.addressLine2,
            [ConsignorCity.key]: data?.consignor_address?.city,
            [ConsignorState.key]: data?.consignor_address?.state,
            [ConsignorCountry.key]: data?.consignor_address?.country || 'India',
            [ConsignorPincode.key]: data?.consignor_address?.pincode,
            [ConsignorPhone.key]: data?.consignor_address?.phone,
            [ConsignorEmail.key]: data?.consignor_address?.email,
            [Consignor_PAN.key]: data?.customer_pan,
        });
        setFileList(frontList);
        setBackFileList(backList);
        setConsignorAddressType(ConsignorAddressType.profile);
        setFormData({
            ...formData,
            consignorKycFrontImage: data?.kyc_details?.kyc_url,
            consignorKycBackImage: data?.kyc_details?.kyc_back_url,
            ConsignorKycDocList: frontList,
            ConsignorKycDocBackList: backList,
            consignorAddress: {
                id: 'consignorAddress',
                name: data?.consignor_address?.[ConsignorName.key],
                addressLine1: data?.consignor_address?.[ConsignorAddressLine1.key],
                addressLine2: data?.consignor_address?.[ConsignorAddressLine2.key],
                cityName: data?.consignor_address?.[ConsignorCity.key],
                stateName: data?.consignor_address?.[ConsignorState.key],
                countryName: data?.consignor_address?.[ConsignorCountry.key],
                pincode: data?.consignor_address?.[ConsignorPincode.key],
                phone: data?.consignor_address?.[ConsignorPhone.key],
                email: data?.consignor_address?.[ConsignorEmail.key],
                alternatePhone: '',
                locationId: '',
                locationCode: '',
            },
        });
    };

    const loadDetails = async (fetchFromCustomer: boolean) => {
        setLoading(true);
        if (fetchFromCustomer) {
            const response = await getCustomerDetails();
            if (!response.isSuccess) {
                message.error(response.errorMessage);
            } else {
                prefillData(response?.data[0] || {});
            }
        } else {
            form.setFieldsValue({
                [ConsignorCompanyName.key]: formData[ConsignorCompanyName.key],
                [OriginType.key]: formData[OriginType.key],
                [ConsignorTaxID.key]: formData[ConsignorTaxID.key],
                [ConsignorGSTINo.key]: formData[ConsignorGSTINo.key],
                [ConsignorIECNo.key]: formData[ConsignorIECNo.key],
                [Consignor_PAN.key]: formData[Consignor_PAN.key],
                [ConsignorKycDocType.key]: formData[ConsignorKycDocType.key],
                [ConsignorKycDocNo.key]: formData[ConsignorKycDocNo.key],
                [Sender_Bank_Account_No.key]: formData[Sender_Bank_Account_No.key],
                [Sender_Bank_AD_Code.key]: formData[Sender_Bank_AD_Code.key],
                [Sender_Bank_IFSC_Code.key]: formData[Sender_Bank_IFSC_Code.key],
                [NEFI_Flag.key]: formData[NEFI_Flag.key],
                [CPCCode.key]: formData[CPCCode.key],
                [Cess_Value.key]: formData[Cess_Value.key],
                [ConsignorName.key]: formData[ConsignorName.key],
                [ConsignorAddressLine1.key]: formData[ConsignorAddressLine1.key],
                [ConsignorAddressLine2.key]: formData[ConsignorAddressLine2.key],
                [ConsignorCity.key]: formData[ConsignorCity.key],
                [ConsignorState.key]: formData[ConsignorState.key],
                [ConsignorCountry.key]: formData[ConsignorCountry.key] || 'India',
                [ConsignorPincode.key]: formData[ConsignorPincode.key],
                [ConsignorPhone.key]: formData[ConsignorPhone.key],
                [ConsignorEmail.key]: formData[ConsignorEmail.key],
            });
            setFileList(formData?.ConsignorKycDocList || []);
            setBackFileList(formData?.ConsignorKycDocBackList || []);
        }
        setLoading(false);
    };

    React.useEffect(() => {
        loadDetails(false);
    }, []);

    const handleFileUpload = async (key: string, imageType: string, file: any) => {
        const extention = Helper.getFileExtension(file?.name);

        if (!['JPG', 'JPEG', 'PNG'].includes(extention.toUpperCase())) {
            message.error('only images are allowed');
            return;
        }

        setUploadingFile(true);
        const storage = window.localStorage;
        const clientId = storage.getItem('userId');

        const response = await getS3Urls({
            clientId,
            imageType,
            contentType: file?.type,
        });

        if (response.isSuccess) {
            const upload = await uploadImageToS3(file, response?.data?.result?.imageSignedUrl, file?.type);

            if (upload.isSuccess) {
                const changedfileList: UploadFile[] = [
                    {
                        uid: file?.id,
                        name: file?.name,
                        status: 'done',
                        url: response?.data?.result?.imageUrl,
                    },
                ];
                if (key === 'consignorKycFrontImage') {
                    setFileList(changedfileList);
                } else {
                    setBackFileList(changedfileList);
                }
                setFormData({
                    ...formData,
                    [key]: response?.data?.result?.imageUrl,
                    ConsignorKycDocList: key === 'consignorKycFrontImage' ? changedfileList : fileList,
                    ConsignorKycDocBackList: key === 'consignorKycBackImage' ? changedfileList : backFileList,
                });
                form.setFieldsValue({
                    [key]: response?.data?.result?.imageUrl,
                });
            } else {
                message.error(upload.errorMessage);
            }
        } else {
            message.error(response.errorMessage);
        }
        setUploadingFile(false);
    };

    const validateInput = (key: string, value: any, callback: any) => {
        if (value) {
            switch (key) {
                case ConsignorEmail.key: return (Helper.validateEmail(value) ? callback() : callback('Invalid email'));
                case ConsignorPincode.key: return value.length === 6 && Number(value)
                    ? callback() : callback('Invalid pincode');
                default: return callback();
            }
        } else {
            return callback();
        }
    };

    const isFieldDisabled = (field: FormField) => {
        if (consignorAddressType === ConsignorAddressType.addressBook
            && [ConsignorName.key,
                ConsignorAddressLine1.key,
                ConsignorAddressLine2.key,
                ConsignorCity.key,
                ConsignorState.key,
                ConsignorCountry.key,
                ConsignorPincode.key,
                ConsignorPhone.key,
                ConsignorEmail.key].includes(field.key)
        ) {
            return true;
        }
        return field.disabled;
    };

    const renderInput = (field: FormField) => (
        <Form.Item
            name={field.key}
            initialValue={field.defaultValue}
            valuePropName={field.valuePropName}
            rules={[
                { required: isRequired(field.key), message: t('required') },
                {
                    validator: (rule, value, cb) => validateInput(field.key, value, cb),
                }]}
        >
            <Input
                type={field.type}
                min={0}
                placeholder={field.placeholder}
                style={{ width: field.width }}
                value={field.defaultValue?.toString()}
                width={field.width}
                disabled={isFieldDisabled(field)}
            />
        </Form.Item>
    );

    const renderInputNumber = (field: FormField) => (
        <Form.Item
            name={field.key}
            initialValue={field.defaultValue}
            valuePropName={field.valuePropName}
            rules={[{ required: isRequired(field.key), message: t('required') }]}
        >
            <InputNumber
                type={field.type}
                min={0}
                placeholder={field.placeholder}
                style={{ width: field.width }}
                width={field.width}
            />
        </Form.Item>
    );

    const renderSelect = (field: FormField) => (
        <Form.Item
            name={field.key}
            initialValue={field.defaultValue}
            valuePropName={field.valuePropName}
            rules={[{ required: isRequired(field.key), message: t('required') }]}
        >
            <Select
                options={field.options}
                placeholder={field.placeholder}
                style={{ width: field.width }}
                allowClear
            />
        </Form.Item>
    );

    const renderRadio = (field: FormField) => (
        <Form.Item
            name={field.key}
            initialValue={field.defaultValue}
            valuePropName={field.valuePropName}
            rules={[{ required: isRequired(field.key), message: t('required') }]}
        >
            <Radio.Group
                options={field.options}
                style={{ width: field.width }}
            />
        </Form.Item>
    );

    const renderTextArea = (field: FormField) => (
        <Form.Item
            name={field.key}
            initialValue={field.defaultValue}
            valuePropName={field.valuePropName}
            rules={[{ required: isRequired(field.key), message: t('required') }]}
        >
            <TextArea
                placeholder={field.placeholder}
                style={{ width: field.width }}
                value={field.defaultValue?.toString()}
            />
        </Form.Item>
    );

    const renderFormItem = (field: FormField | undefined) => {
        if (!field) {
            return (
                <div style={{ padding: '10px 5%', width: '50%', display: 'inline-block' }} />
            );
        }
        if (!fieldsToShowInternational[field.key]) return null;
        switch (field.type) {
            case InputTypes.Input:
                return (
                    <div style={{ padding: '10px 5%', width: '50%', display: 'inline-block' }}>
                        <div className={classes.title}>
                            {field.label}
                            {isRequired(field.key) ? '*' : ''}
                        </div>
                        {renderInput(field)}
                    </div>
                );
            case InputTypes.Number:
                return (
                    <div style={{ padding: '10px 5%', width: '50%', display: 'inline-block' }}>
                        <div className={classes.title}>
                            {field.label}
                            {isRequired(field.key) ? '*' : ''}
                        </div>
                        {renderInputNumber(field)}
                    </div>
                );
            case InputTypes.Select:
                return (
                    <div style={{ padding: '10px 5%', width: '50%', display: 'inline-block' }}>
                        <div className={classes.title}>
                            {field.label}
                            {isRequired(field.key) ? '*' : ''}
                        </div>
                        {renderSelect(field)}
                    </div>
                );
            case InputTypes.Textarea:
                return (
                    <div style={{ padding: '10px 5%', width: '100%', display: 'inline-block' }}>
                        <div className={classes.title}>
                            {field.label}
                            {isRequired(field.key) ? '*' : ''}
                        </div>
                        {renderTextArea(field)}
                    </div>
                );
            case InputTypes.Radio:
                return (
                    <div style={{ padding: '10px 5%', width: '50%', display: 'inline-block' }}>
                        <div className={classes.title}>
                            {field.label}
                            {isRequired(field.key) ? '*' : ''}
                        </div>
                        {renderRadio(field)}
                    </div>
                );
            default:
                return (<h1>N/A</h1>);
        }
    };

    const renderUpload = (key: string) => {
        return (
            <Form.Item
                name={key}
                rules={[{ required: isRequired(key), message: t('required') }]}
            >
                <Upload
                    showUploadList
                    maxCount={1}
                    fileList={key === 'consignorKycFrontImage' ? fileList : backFileList}
                    onRemove={() => {
                        if (key === 'consignorKycFrontImage') {
                            setFileList([]);
                            form.setFieldsValue({
                                consignorKycFrontImage: undefined,
                            });
                            setFormData({
                                ...formData,
                                consignorKycFrontImage: undefined,
                                ConsignorKycDocList: [],
                            });
                        } else {
                            setBackFileList([]);
                            form.setFieldsValue({
                                consignorKycBackImage: undefined,
                            });
                            setFormData({
                                ...formData,
                                consignorKycBackImage: undefined,
                                ConsignorKycDocBackList: [],
                            });
                        }
                    }}
                    customRequest={(upload) => handleFileUpload(key, 'kyc', upload?.file)}
                    disabled={uploadingFile}
                >
                    <UploadOutlined />
                    <span style={{ fontSize: 12, marginLeft: 6 }}> Upload </span>
                </Upload>
            </Form.Item>
        );
    };

    const renderKycDoc = () => {
        return (
            <div style={{ alignItems: 'top' }}>
                <div
                    style={
                        {
                            padding: '10px 0px 10px 5%',
                            width: '20%',
                            display: 'inline-block',
                            verticalAlign: 'top',
                        }
                    }
                >
                    <div className={classes.title}>
                        {ConsignorKycDocType.label}
                        {isRequired(ConsignorKycDocType.key) ? '*' : ''}
                    </div>
                    {renderSelect(ConsignorKycDocType)}
                </div>
                <div
                    style={
                        {
                            padding: '10px 0px 10px 10px',
                            width: '20%',
                            display: 'inline-block',
                            verticalAlign: 'top',
                        }
                    }
                >
                    <div className={classes.title}>
                        {ConsignorKycDocNo.label}
                        {isRequired(ConsignorKycDocNo.key) ? '*' : ''}
                    </div>
                    {renderInput(ConsignorKycDocNo)}
                </div>
                <div
                    style={
                        {
                            padding: '10px 0px 10px 10px',
                            maxWidth: '25%',
                            display: 'inline-block',
                            verticalAlign: 'top',
                        }
                    }
                >
                    <div className={classes.title}>
                        KYC Doc Front
                        {isRequired('consignorKycFrontImage') ? '*' : ''}
                    </div>
                    <Button
                        style={{
                            borderRadius: 4,
                            fontSize: 12,
                        }}
                    >
                        {renderUpload('consignorKycFrontImage')}
                    </Button>
                </div>
                <div
                    style={
                        {
                            padding: '10px 0px 10px 10px',
                            maxWidth: '25%',
                            display: 'inline-block',
                            verticalAlign: 'top',
                        }
                    }
                >
                    <div className={classes.title}>
                        KYC Doc Back
                    </div>
                    <Button
                        style={{
                            borderRadius: 4,
                            fontSize: 12,
                        }}
                    >
                        {renderUpload('consignorKycBackImage')}
                    </Button>
                </div>
            </div>
        );
    };

    return (
        <div className={classes.main}>
            {loading && <Loader zIndex={10} />}
            <div className={classes.box}>
                <div className={classes.boxTitle}>
                    <div className={classes.boxIcon}>
                        <AddressIcon className={classes.boxIcon} />
                    </div>
                    <span>Consignor Details</span>
                </div>
                <div className={classes.addressDetails}>
                    <div className={classes.addressBox}>
                        <Form form={form}>
                            { renderFormItem(Cust_Seller_code) }
                            { renderFormItem(ConsignorCompanyName) }
                            {/* { renderFormItem(ConsignorTaxID) } */}
                            { renderFormItem(OriginType) }
                            <div style={{ padding: '10px 5%', width: '100%', display: 'inline-block' }}>
                                <Button type="link" onClick={() => loadDetails(true)}>
                                    <DownloadOutlined />
                                    {' '}
                                    <b>Import Details from Customer Profile</b>
                                </Button>
                            </div>
                            { renderFormItem(ConsignorName) }
                            { renderFormItem(ConsignorAddressLine1) }
                            { renderFormItem(ConsignorAddressLine2) }
                            { renderFormItem(ConsignorCity) }
                            { renderFormItem(ConsignorState) }
                            { renderFormItem(ConsignorCountry) }
                            { renderFormItem(ConsignorPincode) }
                            { renderFormItem(ConsignorPhone) }
                            { renderFormItem(ConsignorEmail) }
                            { renderFormItem(ConsignorIECNo) }
                            { renderFormItem(ConsignorGSTINo) }
                            { renderFormItem(Consignor_PAN) }
                            { renderKycDoc() }
                            { renderFormItem(Sender_Bank_Account_No) }
                            { renderFormItem(Sender_Bank_AD_Code) }
                            { renderFormItem(Sender_Bank_IFSC_Code) }
                            { renderFormItem(Sender_Bank_Name) }
                            { renderFormItem(NEFI_Flag) }
                            { renderFormItem(CPCCode) }
                            {/* { renderFormItem(Cess_Value) } */}
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withStyles(consignorDetailsStyles)(ConsignorDetailsInteranation);
