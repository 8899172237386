import { TableColumn } from './user.types';

export enum userPages{
    users = 'USERS',
}

export const userColumns: TableColumn[] = [{
    key: 'id',
    pretty_name: 'S. No',
}, {
    key: 'user_code',
    pretty_name: 'User Code',
}, {
    key: 'name',
    pretty_name: 'Name',
},
{
    key: 'phone',
    pretty_name: 'Phone Number',
}, {
    key: 'city',
    pretty_name: 'City',
}, {
    key: 'username',
    pretty_name: 'Login Username',
}, {
    key: 'is_active',
    pretty_name: 'Status',
}, {
    key: 'created_at',
    pretty_name: 'Creation Date',
}, {
    key: 'updated_at',
    pretty_name: 'Last Updated',
}, {
    key: 'Action',
    pretty_name: 'Action',
}];
