import * as React from 'react';
import SealAndSignatureIcon from 'assets/Authorisation-icon';
import AuthorisationLetters from 'assets/Authorisation-letters';
import Loader from '../../common/Loader';
import { StylesProps } from '../../../theme/jss-types';
import { stepThreeStyles } from '../create-seller-profile.styles';
import RenderFormItem from './generic-object';
import { createSellerProfileFormFields } from '../create-seller-profile.constants';
import GreenTickIcon from 'assets/green-tick-icon';
import CrossIcon from 'assets/red-cross-icon';
// import { getUnsignedAuthorisationLetter } from '../../../network/sender-management.api';

import {
    FormInstance,
    Modal,
    Button,
} from 'antd';

import { HocOptions } from '../../common/generic-hoc.types';
import GenericHoc from '../../common/generic-hoc';
import { useTranslation } from 'react-i18next';
import { NamePath } from 'antd/lib/form/interface';
import { CONSIGNMENT_LABEL_API_URL } from 'library/globals';

const {
    useState,
} = React;

const {
    embedSignature,
    embedSeal,
    addressType,
} = createSellerProfileFormFields;

interface StepTwoProps extends StylesProps<ReturnType<typeof stepThreeStyles>> {
    config: any;
    form: FormInstance;
    formData: any;
    setFormData: any;
    condition: boolean
    masterData: any;

}


const StepThree = (props: StepTwoProps) => {
    const {
        config,
        classes,
        form,
        formData,
        setFormData,
        condition,
        masterData,
    } = props;
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentDoc, setCurrentDoc] = useState({ name: 'Document', id: 'doc', docLink: '' });
    const { AUTHORIZATION_LETTER_VALUES, AUTHORIZATION_LETTER_PRETTY_NAMES } = masterData;
    const selectedAddressType = form.getFieldValue(addressType.keyPath as NamePath);

    const renderRowDescription = (FieldIcon: React.ElementType, label: string) => {
        return (
            <div className={classes.boxTitle}>
                <div className={classes.boxIcon}>
                    <FieldIcon className={classes.boxIcon} />
                </div>
                <span>{label}</span>
            </div>
        );
    };

    const commonProps = {
        config,
        form,
        formData,
    };


    const handleViewClick = async (doc: any) => {
        setLoading(true);
        setCurrentDoc({ id: doc.id, name: doc.name, docLink: doc.docLink });
        setIsModalVisible(true);
    };

    const handleOk = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        const authLetters = formData?.authorization_letters?.filter((doc: any) => doc.type !== currentDoc.id) || [];
        setFormData({
            ...formData,
            authorization_letters: [
                ...authLetters,
                {
                    type: currentDoc.id,
                    is_verified: true,
                    timestamp: Date.now(),
                },
            ],
        });
        setIsModalVisible(false);
        setLoading(false);
    };

    const handleCancel = () => {
        const authLetters = formData?.authorization_letters?.filter((doc: any) => doc.type !== currentDoc.id) || [];
        setFormData({
            ...formData,
            authorization_letters: [
                ...authLetters,
                {
                    type: currentDoc.id,
                    is_verified: false,
                    timestamp: Date.now(),
                },
            ],
        });
        setIsModalVisible(false);
        setLoading(false);
    };

    const renderView = (doc: any) => {
        return (
            <div className={classes.validate}>
                <div onClick={() => handleViewClick(doc)}>
                    <h5 className={classes.validateIcon}>VIEW</h5>
                </div>
            </div>
        );
    };

    const renderDocumentsList = () => {
        const AuthorizationLettersList = AUTHORIZATION_LETTER_VALUES?.sort().map((value: string, index: number) => {
            return {
                serialNo: index + 1,
                name: AUTHORIZATION_LETTER_PRETTY_NAMES[value],
                id: value,
                docLink: '',
            };
        });

        const authLettersSelectedMap = formData?.authorization_letters?.reduce((acc: any, doc: any) => {
            acc[doc.type] = doc.is_verified;
            return acc;
        }, {});

        return (
            <div className={classes.documentList}>
                {AuthorizationLettersList.map((doc: any) => {
                    return (
                        <div className={classes.document}>
                            <div className={classes.serialno}>
                                {doc.serialNo}
                            </div>
                            <div className={classes.docName}>{doc.name}</div>
                            <div className={classes.docName}>
                                {renderView(doc)}
                            </div>
                            { authLettersSelectedMap?.[doc.id] === true
                            && (
                                <div className={classes.accept}>
                                    <GreenTickIcon style={{ backgroundColor: '#E6F8E9', borderRadius: 10 }} />
                                    ACCEPTED
                                </div>
                            )}
                            { authLettersSelectedMap?.[doc.id] === false
                            && (
                                <div className={classes.reject}>
                                    <CrossIcon style={{ backgroundColor: '#FFE9E9', borderRadius: 10 }} />
                                    REJECTED
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderFooter = () => {
        const selectedLetter = formData?.authorization_letters?.find((letter: any) => letter.type === currentDoc.id) || {};
        if (selectedLetter?.is_verified) {
            return null;
        }
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                <Button
                    style={{
                        fontSize: 16,
                        margin: '4px',
                        cursor: 'pointer',
                        borderRadius: '4px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    onClick={handleCancel}
                >
                    Reject
                </Button>
                <Button
                    style={{
                        fontSize: 16,
                        margin: '4px',
                        cursor: 'pointer',
                        borderRadius: '4px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    onClick={handleOk}
                >
                    Accept
                </Button>
            </div>
        );
    };

    const handleClose = () => {
        setIsModalVisible(false);
        setLoading(false);
    };

    const renderAuthorisationDocument = () => {
        return (
            <Modal
                key={currentDoc?.id}
                title={currentDoc?.name}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleClose}
                width="50%"
                footer={renderFooter()}
            >
                <div className={classes.docModal}>
                    {loading && <Loader zIndex={10} />}
                    <iframe
                        title="template"
                        // eslint-disable-next-line max-len
                        src={`${CONSIGNMENT_LABEL_API_URL}/public/getDocumentForSeller?template=${currentDoc.id}`}
                        style={{
                            width: '100%', minHeight: '65vh',
                        }}
                        onLoad={() => setLoading(false)}
                    />
                </div>
            </Modal>
        );
    };



    const renderSealAndSignatureDetails = () => {
        const embedSealField = {
            ...embedSeal,
            isRequired: selectedAddressType === 'business',
            rules: [
                {
                    required: selectedAddressType === 'business',
                    message: 'Required',
                },
            ],
        };
        return (
            <div className={classes.boxFieldsCol}>
                <div className={classes.boxField1}>
                    <RenderFormItem
                        field={embedSignature}
                        {...commonProps}
                    />
                </div>
                <div className={classes.boxField1}>
                    <RenderFormItem
                        field={embedSealField}
                        {...commonProps}
                    />
                </div>
            </div>
        );
    };

    const renderSealAndSignature = () => {
        return (
            <div className={classes.box}>
                {renderRowDescription(SealAndSignatureIcon, t('seal&signature'))}
                {renderSealAndSignatureDetails()}
            </div>
        );
    };

    const renderAuthorisationLetters = () => {
        return (
            <div className={classes.box}>
                {renderRowDescription(AuthorisationLetters, t('authorization_letter'))}
                {renderDocumentsList()}
            </div>
        );
    };

    const renderLine = () => {
        return <div className={classes.line} />;
    };

    if (!condition) return null;
    return (
        <div className={classes.main}>
            {loading && <Loader zIndex={10} />}
            {renderSealAndSignature()}
            {renderLine()}
            {renderAuthorisationLetters()}
            {renderAuthorisationDocument()}
        </div>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    return {
        loadTypeOptions: master.config.load_type_options,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: stepThreeStyles,
    },
};

export default GenericHoc(hocConfig)(StepThree);
