import { ThemeType } from 'theme/jss-types';

/* eslint-disable max-len */
export const userManagementStyles = (theme: ThemeType) => ({
    subAccount: {
        borderRadius: '0 2px 0 0',
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        marginTop: 24,
        '& .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before': {
            backgroundColor: 'inherit',
        },
        '& .ant-table-thead > tr > th': {
            padding: 12,
            borderRight: 'none',
            backgroundColor: '#EFF4FC',
            color: '#333333',
            fontFamily: 'Open Sans',
            fontSize: 12,
            letterSpacing: 0,
            wordWrap: 'break-word',
            whiteSpace: 'break-spaces',
        },
        '& .ant-table-tbody > tr > td': {
            padding: 12,
            whiteSpace: 'break-spaces',
            wordWrap: 'break-word',
        },
        '& .ant-dropdown-menu-submenu-popup ul, .ant-dropdown-menu-submenu-popup li': {
            width: 80,
        },
    },
    drawerBody: {
        '& .ant-drawer-body': {
            padding: 0,
        },
    },
    AddUser: {
        position: 'fixed', // Change from 'absolute' to 'fixed'
        width: 548,
        height: 768,
        right: 0,
        top: 0,
        background: '#FFFFFF',
        zIndex: 1000, // Ensure it appears above other elements
    },
    main: {
        backgroundColor: '#FFF',
        borderRadius: '0 2px 0 0',
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        '& .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before': {
            backgroundColor: 'inherit',
        },
        '& .ant-popover-inner-content': {
            width: '100%',
        },
        '& .ant-table-thead > tr > th': {
            padding: 12,
            borderRight: 'none',
            backgroundColor: '#EFF4FC',
            color: '#333333',
            fontFamily: 'Open Sans',
            fontSize: 12,
            letterSpacing: 0,
            wordWrap: 'break-word',
            whiteSpace: 'break-spaces',
        },
        '& .ant-table-tbody > tr > td': {
            padding: 12,
            whiteSpace: 'break-spaces',
            wordWrap: 'break-word',
        },
        '& .ant-dropdown-menu-submenu-popup ul, .ant-dropdown-menu-submenu-popup li': {
            width: 80,
        },
    },
    title: {
        margin: '0 24px',
        padding: '8px 0',
        borderBottom: '0.5px solid #979797',
        fontSize: 16,
        fontWeight: 'bold',
        color: '#111111',
        display: 'flex',
        fontFamily: 'Open Sans',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    newButton: {
        height: 32,
        fontSize: 12,
        backgroundColor: theme.primaryColor,
        border: `1px solid ${theme.primaryColor}`,
        color: '#FFFFFF',
        fontFamily: 'Open Sans',
        fontWeight: 600,
        letterSpacing: 0,
        padding: '0px 24px',
        borderRadius: 4,
        marginLeft: 12,
    },
    leftSection: {
        display: 'flex',
        alignItems: 'center',
    },
    rightSection: {
        display: 'flex',
        alignItems: 'center',
    },
    cellValue: {
        // whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: '#111111',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
        wordWrap: 'break-word',
        whiteSpace: 'break-spaces',
    },
    table: {
        '& .ant-table table': {
            backgroundColor: '#FFF',
        },
    },
});
