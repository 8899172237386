import { Dispatch } from 'redux';
import { Buckets } from '../library/Types';
import { fetchSellers } from '../network/sender-management.api';
import { ReduxStore } from '../reducers/redux.types';

import {
    endLoading,
    startLoading,
    setGenericData,
} from './generic-action';

const bucket = Buckets.SENDERS;
// eslint-disable-next-line max-len
export const loadSenders = () => async (dispatch: Dispatch<any>, store: () => ReduxStore) => {
    dispatch(startLoading(bucket));

    const { generic } = store();
    const { SENDERS } = generic;
    const { pagination } = SENDERS;
    const { filters, data } = SENDERS;
    dispatch(setGenericData(bucket, []));

    let last_row_id;
    if (pagination.nextOrPrev === 'next') {
        last_row_id = data?.sendersData?.[pagination.resultPerPage - 1]?.seller_id;
    } else if (pagination.nextOrPrev === 'prev') {
        last_row_id = data?.sendersData?.[0]?.seller_id;
    } else {
        last_row_id = undefined;
    }

    const response = await fetchSellers({
        ...filters,
        pagination_params: {
            last_row_id,
            results_per_page: 50,
            nav_dir: pagination.nextOrPrev,
        },
    });

    const sendersData: any[] = [];
    const currentPage = ((Number)(pagination.currentPageNumber) - 1);
    const recordsPerPage = (Number)(pagination.resultPerPage);
    const offset = currentPage * recordsPerPage;

    response?.data?.forEach((row: any, index: string) => {
        const sender = {
            ...row,
            serialNumber: offset + index + 1,
        };
        sendersData.push(sender);
    });

    const dataToUpdate = {
        sendersData,
        isNextPresent: response?.pagination?.is_next_present,
    };

    if (response.isSuccess) {
        dispatch(setGenericData(bucket, dataToUpdate));
    } else {
        dispatch(setGenericData(bucket, []));
    }
    dispatch(endLoading(bucket));
};
