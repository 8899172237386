import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps, ThemeType } from 'theme/jss-types';
import { formFields } from 'components/create-consignment/create-modal.constants';
import { useTranslation } from 'react-i18next';
import {
    Col,
    Form,
    FormInstance,
    Input,
    Select,
} from 'antd';
import { commonStyleSheet } from 'library/common-styles';
import { formRules } from 'library/constants';

const styles = (theme: ThemeType) => ({
    formItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        '& .ant-checkbox + span': {
            color: '#111111',
            fontSize: 14,
            fontWeight: 600,
        },
    },
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
        marginLeft: 4,
    },
    referenceTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
    },
    contentType: {
        ...commonStyleSheet(theme).flexRow,
        '& .ant-form-item': {
            marginBottom: 0,
        },
    },
    formItemWeight: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    params: any;
    form: FormInstance;
    shouldNotMakeItemTypeCheck: boolean;
    isRenderedFromLayout: boolean | undefined;
}

const WeightField = (props: IProps) => {
    const { t, i18n } = useTranslation();
    const {
        classes,
        params,
        autoCalculateDetails,
        form,
        pieceSubLayout,
        envelopeSubLayout,
        documentSubLayout,
        isRenderedFromLayout,
    } = props;
    let {
        shouldNotMakeItemTypeCheck,
    } = props;
    const {
        WeightUnit,
        ConsignmentCategory,
        Weight,
        ItemType,
    } = formFields;
    const { pieceIndex, isRequired } = params;

    const formRuleRequired = {
        ...formRules.required,
        message: i18n.exists('required') ? t('required') : 'Required',
    };

    const consignmentCategory = Form.useWatch(ConsignmentCategory.key, form);
    const itemType = Form.useWatch(ItemType.key, form);
    const pieces = Form.useWatch('pieces', form);
    const isPieceLevel = pieceIndex !== null && pieceIndex !== undefined;
    const pieceData = isPieceLevel ? pieces[pieceIndex] : null;
    shouldNotMakeItemTypeCheck = shouldNotMakeItemTypeCheck || false;

    if (!isRenderedFromLayout) {
        if (itemType === 'document' && !documentSubLayout.includes('weight')) {
            return <></>;
        }
        if (itemType === 'envelope' && !envelopeSubLayout.includes('weight')) {
            return <></>;
        }
        if ((!itemType || itemType === 'non-document') && !pieceSubLayout.includes('weight')) {
            return <></>;
        }
    }

    const shouldDisable = () => {
        if (shouldNotMakeItemTypeCheck) {
            return !isPieceLevel && consignmentCategory === 'international' && autoCalculateDetails;
        }

        return !isPieceLevel && consignmentCategory === 'international'
            && itemType === 'non-document' && autoCalculateDetails;
    };

    const renderWeightUnit = () => {
        return (
            <Form.Item
                name={isPieceLevel ? ['pieces', pieceIndex, WeightUnit.key] : WeightUnit.key}
                key={WeightUnit.key}
                initialValue={isPieceLevel ? pieceData.weightUnit : WeightUnit.defaultValue}
                rules={isRequired(isPieceLevel, WeightUnit.key) ? [formRuleRequired] : undefined}
                className={classes.contentType}
            >
                <Select
                    placeholder={WeightUnit.placeholder}
                    options={WeightUnit.options}
                    showArrow={false}
                    dropdownMatchSelectWidth={false}
                    defaultValue={isPieceLevel ? pieceData.weightUnit : undefined}
                    disabled={shouldDisable()}
                >
                    {WeightUnit.options?.map((item: any) => {
                        return (
                            <Select.Option
                                key={item.value}
                                value={item.value}
                            >
                                {item.label}
                            </Select.Option>
                        );
                    })}
                </Select>
            </Form.Item>
        );
    };

    return (
        <Col span={12}>
            <div className={classes.formItemWeight}>
                <div className={classes.title}>
                    {t(Weight.key)}
                    {isRequired(isPieceLevel, Weight.key) ? '*' : null}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Form.Item
                        name={isPieceLevel ? ['pieces', pieceIndex, Weight.key] : Weight.key}
                        className={classes.contentType}
                        style={{ flex: 1 }}
                        initialValue={Weight.defaultValue}
                        valuePropName={Weight.valuePropName}
                        rules={isRequired(isPieceLevel, Weight.key) ? [formRuleRequired] : undefined}
                    >
                        <Input
                            type="number"
                            min={0}
                            placeholder={t(Weight.key)}
                            style={{ width: '100%', flex: 1 }}
                            disabled={shouldDisable()}
                        />
                    </Form.Item>
                    {renderWeightUnit()}
                </div>
            </div>
        </Col>
    );
};

const mapStateToProps = (state: any) => {
    const { master } = state;
    const { config } = master;
    const customerPortalConfig = config?.customer_portal_config;
    const autoCalculateDetails = customerPortalConfig?.auto_calculate_single_cn_fields_international;
    const formSubLayout = config?.customer_portal_order_creation_config?.sub_layout || {};
    const documentSubLayout = formSubLayout?.document || [];
    const pieceSubLayout = formSubLayout?.non_document || [];
    const envelopeSubLayout = formSubLayout?.envelope || [];
    return {
        autoCalculateDetails,
        pieceSubLayout,
        documentSubLayout,
        envelopeSubLayout,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(WeightField);
