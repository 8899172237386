/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const AuthorisationIconUtil = (props: any) => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M27 18.3333V3.88889C27 3.12271 26.6956 2.38791 26.1539 1.84614C25.6121 1.30436 24.8773 1 24.1111 1H3.88889C3.12271 1 2.38791 1.30436 1.84614 1.84614C1.30436 2.38791 1 3.12271 1 3.88889V24.1111C1 24.8773 1.30436 25.6121 1.84614 26.1539C2.38791 26.6956 3.12271 27 3.88889 27H18.3333M27 18.3333V18.5818C26.9998 19.3479 26.6954 20.0826 26.1536 20.6242L20.6242 26.1536C20.0826 26.6954 19.3479 26.9998 18.5818 27H18.3333M27 18.3333H21.2222C20.456 18.3333 19.7212 18.6377 19.1795 19.1795C18.6377 19.7212 18.3333 20.456 18.3333 21.2222V27M6.77778 6.77778H21.2222H6.77778ZM6.77778 12.5556H21.2222H6.77778ZM6.77778 18.3333H12.5556H6.77778Z" fill="black" />
        <path d="M27 18.3333V3.88889C27 3.12271 26.6956 2.38791 26.1539 1.84614C25.6121 1.30436 24.8773 1 24.1111 1H3.88889C3.12271 1 2.38791 1.30436 1.84614 1.84614C1.30436 2.38791 1 3.12271 1 3.88889V24.1111C1 24.8773 1.30436 25.6121 1.84614 26.1539C2.38791 26.6956 3.12271 27 3.88889 27H18.3333M27 18.3333V18.5818C26.9998 19.3479 26.6954 20.0826 26.1536 20.6242L20.6242 26.1536C20.0826 26.6954 19.3479 26.9998 18.5818 27H18.3333M27 18.3333H21.2222C20.456 18.3333 19.7212 18.6377 19.1795 19.1795C18.6377 19.7212 18.3333 20.456 18.3333 21.2222V27M6.77778 6.77778H21.2222M6.77778 12.5556H21.2222M6.77778 18.3333H12.5556" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

);

const AuthorisationLetters = (props: any) => (
    <Icon component={(innerProps: any) => {
        return AuthorisationIconUtil({ ...innerProps, ...props });
    }}
    />
);

export default AuthorisationLetters;
