import * as React from 'react';
import { CheckCircleFilled, InfoCircleFilled } from '@ant-design/icons';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { useTranslation } from 'react-i18next';
import { StylesProps } from 'theme/jss-types';

const styles = {
    rule: {
        fontFamily: 'Open Sans',
        letterSpacing: 0,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    rulList: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minWidth: 250,
    },
    ruleText: {
        width: '90%',
        fontSize: 12,
    },
    ruleIcon: {
        width: '6%',
        fontSize: 14,
    },
    checkIcon: {
        color: 'green',
    },
};

const rules = (t: any, passwordPolicy: any) => ({
    is_digit_required: {
        regex: '^(?=.*[0-9])',
        label: t('is_digit_required'),
    },
    max_password_length: {
        label: `${t('max_password_length')} ${passwordPolicy?.max_password_length?.val || ''}`,
        max_length: passwordPolicy?.max_password_length?.val,
    },
    min_password_length: {
        label: `${t('min_password_length')} ${passwordPolicy?.min_password_length?.val || ''}`,
        min_length: passwordPolicy?.min_password_length?.val,
    },
    is_lower_case_required: {
        regex: '^(?=.*[a-z])',
        label: t('is_lower_case_required'),
    },
    is_upper_case_required: {
        regex: '^(?=.*[A-Z])',
        label: t('is_upper_case_required'),
    },
    is_special_character_required: {
        regex: '^(?=.*[!@#$%^&*?])',
        label: t('is_special_character_required'),
    },
});

interface PasswordRulesProps extends StylesProps<typeof styles> {
    password_policy: any;
    password: string;
    conPassword?: string;
    enableSubmit: () => void;
}

const PasswordRules = (props: PasswordRulesProps) => {
    const {
        password_policy,
        classes,
        password,
        enableSubmit,
        conPassword,
    } = props;

    const { t } = useTranslation();

    const rulesList = rules(t, password_policy) as Record<string, any>;

    const isRuleSatified = (rule: any) => {
        if (!password) {
            return false;
        }
        if (rule.regex) {
            const regex = new RegExp(rule.regex);
            return regex.test(password);
        }
        if (rule?.max_length && password.length > rule.max_length) {
            return false;
        }
        if (rule?.min_length && password.length < rule.min_length) {
            return false;
        }

        return true;
    };

    React.useEffect(() => {
        if (!password) {
            return;
        }
        const incorrect = Object.keys(password_policy || {}).some((key: any) => {
            const isRuleActive = Boolean(password_policy[key]?.val);

            const rule = rulesList[key];

            if (!isRuleActive || !rule) {
                return false;
            }

            const isSatisfied = isRuleSatified(rule);
            return !isSatisfied;
        });

        if (!incorrect && conPassword === password) {
            enableSubmit();
        }
    }, [password, conPassword]);

    const renderIcon = (isSatisfied: boolean) => {
        if (isSatisfied) {
            return (
                <CheckCircleFilled
                    style={{ color: '#27B479' }}
                />
            );
        }

        return (
            <InfoCircleFilled
                style={{ color: '#EA2626' }}
            />
        );
    };

    const renderRule = (key: string) => {
        const isRuleActive = Boolean(password_policy[key]?.val);

        const rule = rulesList[key];

        if (!isRuleActive || !rule) {
            return null;
        }

        const isSatisfied = isRuleSatified(rule);

        return (
            <div
                key={key}
                className={classes.rule}
            >
                <div className={classes.ruleIcon}>
                    {renderIcon(isSatisfied)}
                </div>
                <span className={classes.ruleText}>
                    {rule.label}
                </span>
            </div>
        );
    };

    const renderRulesTitle = () => {
        if (!Object.keys(password_policy || {}).length) {
            return null;
        }
        return (
            <div
                className={classes.ruleText}
                style={{ marginBottom: 10 }}
            >
                {t('password_requirements')}
            </div>
        );
    };

    return (
        <div className={classes.rulList}>
            {renderRulesTitle()}
            {Object.keys(password_policy || {}).map((key: any) => renderRule(key))}
        </div>
    );
};

const hocConfig: HocOptions = {
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(PasswordRules);
