export const senderManagementColumns: Record<string, string> = {
    seller_code: 'Consignor Code',
    seller_name: 'Consignor Name',
    sender_type: 'Consignor Type',
    company_name: 'Consignor Company Name',
    status: 'Profile Status',
    created_at: 'Created At',
    updated_at: 'Last Updated At',
    authorization_letter: 'Authorization Letter',
    action: 'Action',
};


export const addressProofTypeList = [
    {
        value: 'aadhaar_card',
        label: 'Aadhar Card',
    },
    {
        value: 'passport',
        label: 'Passport',
    },
    {
        value: 'voterId',
        label: 'Voter ID',
    },
    {
        value: 'rationCard',
        label: 'Ration Card',
    },
    {
        value: 'others',
        label: 'Others',
    },
];

export const bankTypeList = [
    {
        value: 'Government',
        label: 'Government',
    },
    {
        value: 'Private',
        label: 'Private',
    },
];

export const documentTypeList = [
    {
        label: 'Pan Card',
        value: 'Pan Card',
    },
    {
        value: 'aadhar Card',
        label: 'Aadhar Card',
    },
    {
        label: 'GST Certificate',
        value: 'GST Certificate',
    },
];

export const statusToColorMap = {
    Verified: { color: '#28A745', backgroundColor: '#d4edda' },
    Unverified: { color: '#FD7E14', backgroundColor: '#ffe5d4' },
    Failed: { color: '#DC3545', backgroundColor: '#f8d7da' },
    Pending: { color: '#FFC107', backgroundColor: '#fff3cd' },
    Active: { color: '#007bff', backgroundColor: '#d1ecf1' },
    Inactive: { color: '#6C757D', backgroundColor: '#e2e3e5' },
};
