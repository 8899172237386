import { DownloadOutlined, PrinterOutlined, ReloadOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import * as React from 'react';
import { HocOptions } from '../common/generic-hoc.types';
import { ConsignmentCategory, NextOrPrev, Pagination } from '../../library/Types';
import { StylesProps, ThemeType } from '../../theme/jss-types';
import { Master, ExcelColumn } from '../../types/master-data-types';
import Paginator from '../common/paginator';
import { excelColumns } from './consignments.constants';
import {
    DatePicker, message, Table, Menu, Dropdown, Button, Tooltip,
} from 'antd';
import {
    getExcels,
    printExcelA4,
} from '../../network/excel-upload';
import GenericHoc from '../common/generic-hoc';
import authHelper from '../../auth/auth-helper';
import { BUSINESS_BOOKING_API_URL, NAVBAR_HEIGHT } from '../../library/globals';
import {
    EXCEL_DOWNLOAD,
    EXCEL_DOWNLOAD_ERRORS,
    EXCEL_NON_INITIATED,
    EXCEL_PENDING,
} from '../../network/api.constants';
import { useTranslation } from 'react-i18next';

const menuStyle: React.CSSProperties = {
    padding: 12,
    borderBottom: '1px solid #CCCCCC',
    fontWeight: 'bold',
    fontSize: 12,
};
const styles = {
    main: {
        '& .ant-table-tbody > tr > td': {
            padding: '14px 0px',
        },
        '& .ant-picker': {
            width: 130,
            border: '1px solid #999999',
            height: 24,
            fontSize: 9,
            borderRadius: 12,
        },
        '& .ant-picker-input > input': {
            fontSize: 12,
        },
    },
    filters: {
        display: 'flex',
        flexDirection: 'column',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginRight: 20,
    },
    filter: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 24,
    },
    extraFilters: {
        borderRadius: '0 2px 0 0',
        backgroundColor: '#FFFFFF',
        padding: '12px 16px',
        display: 'flex',
        marginBottom: 4,
        justifyContent: 'space-between',
    },
    dateFilters: {
        display: 'flex',
    },
    cellValue: {
        color: '#111111',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
    },
    cellNa: {
        color: '#999999',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
    },
    table: {
        '& .ant-table table': {
            backgroundColor: '#FFF',
        },
    },
    filterText: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        marginRight: 8,
    },
};

interface ExcelUploadProps extends StylesProps<typeof styles> {
    goToPrintHistory: () => void;
    uiTheme: ThemeType;
    master: Master;
}

const ExcelUpload = (props: ExcelUploadProps) => {
    const {
        classes, goToPrintHistory, uiTheme, master,
    } = props;
    const [loading, setLoading] = React.useState<boolean>(true);
    const [excels, setExcels] = React.useState<any>([]);
    const [dates, setDates] = React.useState({
        startDate: moment().subtract(1, 'M'),
        endDate: moment(),
    });
    const { t } = useTranslation();
    const [pagination, setPagination] = React.useState<Pagination>({
        nextOrPrev: 'first',
        currentPageNumber: 1,
        resultPerPage: 5,
        isNextPresent: false,
        lastUpdatedAt: null,
    });
    const [toggle, setToggle] = React.useState<boolean>(true);

    const enable_customer_config = master?.parts_to_show?.enable_customer_config || false;
    const customerProperties = master?.properties;
    let showShipmentLabelA4 = true;
    let showShippingLabelA6 = true;
    let showshippingLabelPod = true;
    let showAddressLabelA4 = true;
    let showAddressLabel4X2 = true;
    let showRoutingLabelA4 = true;
    let showRoutingLabel4x4 = true;
    let showShippingLabel4X6 = true;
    let useThirdPartyLabel = false;
    if (customerProperties && enable_customer_config) {
        showShipmentLabelA4 = customerProperties?.a4_shipping_label;
        showShippingLabelA6 = customerProperties?.a6_shipping_label;
        showShippingLabel4X6 = customerProperties?.x46_shipping_label;
        showshippingLabelPod = customerProperties?.shipping_label_pod;
        showAddressLabelA4 = customerProperties?.a4_address_label;
        showAddressLabel4X2 = customerProperties?.x42_address_label;
        showRoutingLabelA4 = customerProperties?.a4_routing_label;
        showRoutingLabel4x4 = customerProperties?.x44_routing_label;
        useThirdPartyLabel = customerProperties?.use_third_party_label;
    }

    const showShippingLabelButton = showShipmentLabelA4 || showShippingLabelA6;

    const loadData = async () => {
        setLoading(true);
        const response = await getExcels({
            startDate: dates.startDate.format('YYYY-MM-DD'),
            endDate: dates.endDate.format('YYYY-MM-DD'),
            currentPage: pagination.currentPageNumber,
            nextOrPrev: pagination.nextOrPrev,
            resultPerPage: pagination.resultPerPage,
            lastUpdatedAt: pagination.lastUpdatedAt,
        });
        if (response.isSuccess) {
            setExcels(response?.data?.csvUploaded || []);
            setPagination({
                ...pagination,
                isNextPresent: response?.data?.nextPageExist,
            });
        } else {
            message.error(response.errorMessage);
        }
        setLoading(false);
    };

    React.useEffect(() => {
        loadData();
    }, [dates, toggle]);

    const getDisabled = (current: Moment) => {
        return current && moment(current) >= moment();
    };

    const renderDateField = (key: 'startDate' | 'endDate') => {
        const name = key === 'startDate' ? 'From' : 'To';
        const inputName = t(`${name}_date`);
        return (
            <div className={classes.filter}>
                <span className={classes.filterText}>
                    {inputName}
                </span>
                <DatePicker
                    value={dates[key]}
                    disabledDate={(current) => getDisabled(current)}
                    placeholder={`Select ${inputName}`}
                    allowClear={false}
                    onChange={(value) => {
                        setDates({
                            ...dates,
                            [key]: moment(value),
                        });
                    }}
                />
            </div>
        );
    };

    const handlePagination = (nextOrPrev: NextOrPrev) => {
        const { currentPageNumber } = pagination;
        let newPageNumber = currentPageNumber;
        let newUpdatedAt = null;
        if (nextOrPrev === 'first') {
            newPageNumber = 1;
            newUpdatedAt = null;
        } else if (nextOrPrev === 'next') {
            newPageNumber = currentPageNumber + 1;
            const lastExcel = excels[excels.length - 1];
            newUpdatedAt = lastExcel.createdAt;
        } else {
            newPageNumber = currentPageNumber - 1;
            const firstExcel = excels[0];
            newUpdatedAt = firstExcel.createdAt;
        }
        const newPagination = {
            ...pagination,
            nextOrPrev,
            lastUpdatedAt: newUpdatedAt,
            currentPageNumber: newPageNumber,
        };
        setPagination(newPagination);
        setToggle(!toggle);
    };

    const renderRefresh = () => {
        return (
            <Tooltip title={t('reload')}>
                <Button
                    size="small"
                    type="ghost"
                    style={{
                        color: uiTheme.primaryColor,
                        fontSize: 14,
                    }}
                    onClick={() => setToggle(!toggle)}
                >
                    <ReloadOutlined />
                </Button>
            </Tooltip>
        );
    };

    const renderExtraFilters = () => {
        return (
            <div className={classes.extraFilters}>
                <div className={classes.dateFilters}>
                    {renderDateField('startDate')}
                    {renderDateField('endDate')}
                    {renderRefresh()}
                </div>
                <Paginator
                    currentPageNumber={pagination.currentPageNumber}
                    isNextPresent={pagination.isNextPresent}
                    onNextClick={() => handlePagination('next')}
                    onPrevClick={() => handlePagination('prev')}
                    onFirstClick={() => handlePagination('first')}
                />
            </div>
        );
    };

    const renderText = (text: string) => {
        return (
            <div
                className={classes.cellValue}
            >
                {text}
            </div>
        );
    };

    const getDownloadLink = (feature: string, row: any) => {
        const headers = authHelper.getAuthenticationHeaders();
        const linkArray = [`${BUSINESS_BOOKING_API_URL}${feature}?`];
        linkArray.push(`csvId=${row.csvId}`);
        linkArray.push(`header-customer-user-id=${headers['customer-user-id']}`);
        linkArray.push(`header-access-token=${headers['access-token']}`);
        linkArray.push(`header-user-id=${headers['user-id']}`);
        linkArray.push(`header-organisation-id=${headers['organisation-id']}`);
        return linkArray.join('&');
    };

    const renderDownloadOptions = (row: any) => {
        return (
            <Menu>
                <Menu.Item
                    style={menuStyle}
                >
                    <a href={getDownloadLink(EXCEL_DOWNLOAD, row)}>{t('download_data')}</a>
                </Menu.Item>
                <Menu.Item
                    style={menuStyle}
                >
                    <a href={getDownloadLink(EXCEL_DOWNLOAD_ERRORS, row)}>{t('download_errors')}</a>
                </Menu.Item>
                <Menu.Item
                    style={menuStyle}
                >
                    <a href={getDownloadLink(EXCEL_PENDING, row)}>{t('download_pending_softdata')}</a>
                </Menu.Item>
                <Menu.Item
                    style={menuStyle}
                >
                    <a href={getDownloadLink(EXCEL_NON_INITIATED, row)}>
                        {t('download_not_initiated_bookings')}
                    </a>
                </Menu.Item>
            </Menu>
        );
    };

    const handleResponse = (response: any) => {
        if (response.isSuccess) {
            message.success('Success');
            goToPrintHistory();
        } else {
            message.error(response.errorMessage);
        }
    };

    const handlePrint = async (row: any, printType: string, isSmall = false) => {
        const fileBuffer = await printExcelA4({
            csvId: encodeURIComponent(row.csvId),
            printType,
            isSmall,
        });
        handleResponse(fileBuffer);
    };

    const renderPrintOptions = (row: any) => {
        return (
            <Menu>
                {showShippingLabelButton && (
                    <Menu.SubMenu
                        title={(
                            <span
                                style={{
                                    ...menuStyle,
                                    padding: 0,
                                    border: 'none',
                                }}
                            >
                                {t('shipping_label')}
                            </span>
                        )}
                        style={{
                            ...menuStyle,
                            padding: 6,
                        }}
                    >
                        {showShipmentLabelA4 && (
                            <Menu.Item
                                style={menuStyle}
                                onClick={() => handlePrint(row, 'Shipping_Label', false)}
                            >
                                {t('label_a4')}
                            </Menu.Item>
                        )}
                        {showShippingLabelA6 && (
                            <Menu.Item
                                style={menuStyle}
                                onClick={() => handlePrint(row, 'Shipping_Label', true)}
                            >
                                {t('label_a6')}
                            </Menu.Item>
                        )}
                        {showShippingLabel4X6 && (
                            <Menu.Item
                                style={menuStyle}
                                onClick={() => handlePrint(row, 'Shipping_Label_4*6')}
                            >
                                {t('label_4x6')}
                            </Menu.Item>
                        )}
                    </Menu.SubMenu>
                )}
                {showshippingLabelPod && (
                    <Menu.Item
                        style={menuStyle}
                        onClick={() => handlePrint(row, 'Shipping_Label_POD')}
                    >
                        {t('shipping_label_pod')}
                    </Menu.Item>
                )}
                {showRoutingLabel4x4 && (
                    <Menu.Item
                        style={menuStyle}
                        onClick={() => handlePrint(row, 'Routing_Label_4*4')}
                    >
                        {t('routing_label')}
                        4*4
                    </Menu.Item>
                )}
                {showRoutingLabelA4 && (
                    <Menu.Item
                        style={menuStyle}
                        onClick={() => handlePrint(row, 'Routing_Label_A4')}
                    >
                        {t('routing_label')}
                        A4
                    </Menu.Item>
                )}
                {showAddressLabel4X2 && (
                    <Menu.Item
                        style={menuStyle}
                        onClick={() => handlePrint(row, 'Address_Label_4*2')}
                    >
                        {t('address_label')}
                        4*2
                    </Menu.Item>
                )}
                {showAddressLabelA4 && (
                    <Menu.Item
                        style={menuStyle}
                        onClick={() => handlePrint(row, 'Address_Label_A4')}
                    >
                        {t('address_label')}
                        A4
                    </Menu.Item>
                )}
            </Menu>
        );
    };

    const renderColumn = (text: string, column: ExcelColumn, row: any) => {
        if (column.column_id === 'actions') {
            return (
                <div className={classes.row}>

                    {!useThirdPartyLabel ? (
                        <Dropdown
                            overlay={renderPrintOptions(row)}
                        >
                            <PrinterOutlined />
                        </Dropdown>
                    ) : null}
                    <Dropdown
                        overlay={renderDownloadOptions(row)}
                    >
                        <DownloadOutlined style={{ margin: '0 4px' }} />
                    </Dropdown>
                </div>
            );
        }
        if (column.column_id === 'isInternational') {
            let consignmentCategory = ConsignmentCategory.DOMESTIC;
            if (text) {
                consignmentCategory = ConsignmentCategory.INTERNATIONAL;
            }
            return renderText(consignmentCategory);
        }
        if (text === null) {
            return <div className={classes.cellNa}>-NA-</div>;
        }
        return renderText(text);
    };

    const getColumns = (): any[] => {
        /* eslint-disable no-nested-ternary */
        const columns: any = excelColumns.map((column: ExcelColumn) => {
            return {
                key: column.column_id,
                title: column.column_id === 'isInternational' ? t('consignment_category') : column.column_id === 'createdAt'
                    ? t('date_time') : t(column.column_id),
                dataIndex: column.column_id,
                ellipsis: true,
                bordered: false,
                width: column.width,
                render: (text: string, row: any) => renderColumn(text, column, row),
            };
        });
        return columns;
    };

    const renderTable = () => {
        return (
            <Table
                bordered={false}
                pagination={false}
                className={classes.table}
                loading={loading}
                rowKey={(row: any) => row.csvId}
                columns={getColumns()}
                dataSource={excels}
                locale={{
                    emptyText: <div className={classes.cellNa}>—NA—</div>,
                }}
                rowSelection={{
                    onSelect: (row) => (row),
                    onSelectAll: (rows) => (rows),
                }}
                scroll={{
                    y: `calc((100vh - ${NAVBAR_HEIGHT}px) - 160px)`,
                }}
            />
        );
    };

    return (
        <div className={classes.main}>
            {renderExtraFilters()}
            {renderTable()}
        </div>
    );
};

const mapStateToProps = (state: any) => {
    const { master, uiTheme } = state;
    return {
        master,
        uiTheme,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
    connectRouter: true,
    connectTranslession: true,
};

export default GenericHoc(hocConfig)(ExcelUpload);
