import apiProvider from './api-provider';
import AuthHelper from '../auth/auth-helper';
import { ResponseType } from 'axios';
import qs from 'qs';


export const GET = async (
    url: string,
    params?: any,
    responseType?: ResponseType,
    headers: any = window.localStorage.getItem('isChildCustomer') === 'true'
        ? AuthHelper.getChildAuthenticationHeaders() : AuthHelper.getAuthenticationHeaders(),
): Promise<any> => {
    try {
        const response = await apiProvider.get(url, {
            params,
            paramsSerializer(input: any) {
                return qs.stringify(input, { arrayFormat: 'repeat' });
            },
            headers,
            responseType,
        });
        return {
            fileName: responseType === 'blob' && response.headers['Content-Disposition'],
            isSuccess: true,
            data: responseType === 'blob' ? response.data : response?.data?.data || response?.data,
            status: response.status,
        };
    } catch (err: any) {
        const errorMessage = AuthHelper.getErrorMessageCommon(err, responseType);
        return {
            errorMessage,
            isSuccess: false,
            status: err?.response?.status,
        };
    }
};

export const POST = async (
    url: string,
    body: any,
    isBlob = false,
    headers: any = window.localStorage.getItem('isChildCustomer') === 'true'
        ? AuthHelper.getChildAuthenticationHeaders() : AuthHelper.getAuthenticationHeaders(),
): Promise<any> => {
    let responseType: ResponseType | undefined;
    if (isBlob) {
        responseType = 'blob';
    }
    try {
        const meta = {
            headers,
            responseType,
        };
        const response = await apiProvider.post(url, body, meta);
        const dataToReturn = {
            isSuccess: true,
            data: response?.data?.data || response?.data,
            message: response?.data?.message,
            pagination: response?.data?.pagination_params,
            filename: '',
        };
        if (isBlob) {
            const disposition = response.headers['content-disposition'];
            if (disposition) {
                const matches = disposition.match(/filename="(.*)"/);
                dataToReturn.filename = matches?.length > 0 ? decodeURI(matches[1]) : '';
            }
            dataToReturn.data = response.data;
        }
        return dataToReturn;
    } catch (err: any) {
        const errorMessage = AuthHelper.getErrorMessageCommon(err, responseType);
        return {
            errorMessage,
            isSuccess: false,
            errFailures: err.response?.data?.error?.failures,
        };
    }
};

export const PUTV2 = async (
    url: string,
    body: any,
    isBlob = false,
    headers: any = AuthHelper.getAuthenticationHeaders(),
): Promise<any> => {
    let responseType: ResponseType | undefined;
    if (isBlob) {
        responseType = 'blob';
    }
    try {
        const meta = {
            headers,
            responseType,
        };
        const response = await apiProvider.put(url, body, meta);
        const dataToReturn = {
            isSuccess: true,
            data: response?.data?.data || response?.data,
            message: response?.data?.message,
            filename: '',
        };
        if (isBlob) {
            const disposition = response.headers['content-disposition'];
            if (disposition) {
                const matches = disposition.match(/filename="(.*)"/);
                dataToReturn.filename = matches?.length > 0 ? decodeURI(matches[1]) : '';
            }
            dataToReturn.data = response.data;
        }
        return dataToReturn;
    } catch (err: any) {
        const errorMessage = AuthHelper.getErrorMessageCommon(err, responseType);
        return {
            errorMessage,
            isSuccess: false,
            errFailures: err.response?.data?.error?.failures,
        };
    }
};

export const PUT = async (url: string, file: any, type: string) => {
    try {
        const response = await apiProvider.put(
            url, file,
            {
                headers: {
                    'Content-Type': type,
                },
            },
        );
        return {
            response,
            isSuccess: true,
        };
    } catch (err) {
        const errorMessage = AuthHelper.getErrorMessageCommon(err);
        return {
            errorMessage,
            isSuccess: false,
        };
    }
};

export const DELETE = async (url: string) => {
    try {
        const response = await apiProvider.delete(
            url,
        );
        return {
            response,
            isSuccess: true,
        };
    } catch (err) {
        const errorMessage = AuthHelper.getErrorMessageCommon(err);
        return {
            errorMessage,
            isSuccess: false,
        };
    }
};
