import * as React from 'react';
import { senderManagementStyles } from './sender-management.styles';
import { StylesProps, ThemeType } from '../../theme/jss-types';
import { Pagination, NextOrPrev, Buckets } from '../../library/Types';
import Paginator from '../common/paginator';
import { Master } from 'types/master-data-types';
import { RouteChildrenProps } from 'react-router';
import { HocOptions } from '../common/generic-hoc.types';
import GenericHoc from '../common/generic-hoc';
import { ReduxStore } from '../../reducers/redux.types';
import { loadSenders } from '../../actions/senders-actions';
import { bindActionCreators } from 'redux';
import { NAVBAR_HEIGHT } from 'library/globals';
import { senderManagementColumns, statusToColorMap } from './sender-management.constants';
import { useTranslation } from 'react-i18next';
import {
    MoreOutlined, ExclamationCircleFilled, ExclamationCircleOutlined,
} from '@ant-design/icons';
import { CreateActions } from '../create-consignment/create-modal.constants';
import {
    deactivateSeller,
    fetchSellerMasterData,
    fetchSellerDetails,
    setDefaultSeller,
} from '../../network/sender-management.api';
import CreateSellerProfile from './create-seller-profile';
import moment from 'moment';
import Loader from '../common/Loader';
import { createSellerProfileFormFields } from './create-seller-profile.constants';
import { Routes } from '../../library/constants';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'hooks/use-debounce';
import { snakeToPretty } from 'library/lodash-helper';

import {
    Button,
    message,
    Table,
    Input,
    Dropdown,
    Menu,
    Select,
    Modal,
    Tooltip,
    Drawer,
} from 'antd';

import {
    applyFilters,
    setFilters,
    setPagination,
    clearSelection,
} from '../../actions/generic-action';
import Helper from 'library/Helper';
import FilterBlue from 'assets/filter-blue';
import Cross from 'assets/cross';



interface senderManagementProps
    extends StylesProps<ReturnType<typeof senderManagementStyles>>,
    RouteChildrenProps {
    filters: Record<string, any>;
    loading: boolean;
    loadsenders: (searchObject: any) => void;
    pagination: Pagination,
    sendersList: any,
    setpagination: (bucketId: Buckets.SENDERS, newPagination: Partial<Pagination>) => void;
    setfilters: (bucketId: Buckets.SENDERS, filters: any) => void;
    applyfilters: (bucketId: Buckets.SENDERS) => void;
    master: Master,
    uiTheme: ThemeType;
    defaultFilters: Record<string, any>;
    clearselection: (bucketId: Buckets.SENDERS) => void;
}

const {
    senderType,
} = createSellerProfileFormFields;

let statusTypes: { value: string; label: string; }[] = [];

const {
    useEffect,
    useState,
} = React;

const SenderManagement = (props: senderManagementProps) => {
    const [sendersSelected, setSendersSelected] = useState<string[]>([]);
    const [toggle, setToggle] = useState<boolean>(true);
    const [editData, setEditData] = React.useState<any>();
    const [inputConsignorCode, setInputConsignorCode] = useState('');
    const [inputConsignorName, setInputConsignorName] = useState('');
    const [senderTypeValue, setSenderTypeValue] = useState('');
    const [inputConsignorCompanyName, setInputConsignorCompanyName] = useState('');
    const [senderStatusValue, setSenderStatusValue] = useState('');
    const [inputConsignorPhone, setInputConsignorPhone] = useState('');
    const [masterData, setMasterData] = useState<any>();
    const [pageLoading, setPageLoading] = useState<boolean>(false);
    const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
    const [deactivateTxt, setDeactivateTxt] = useState<string>('');
    const [selectedRow, setSelectedRow] = useState<any>();
    const [formVisible, setFormVisible] = React.useState<CreateActions | undefined>(
    );
    const [moreFilterVisible, setMoreFiltersVisible] = useState<boolean>(false);

    const { t } = useTranslation();

    const {
        classes,
        filters,
        loading,
        loadsenders,
        pagination,
        sendersList,
        setpagination,
        master,
        setfilters,
        applyfilters,
        uiTheme,
        defaultFilters,
        clearselection,
    } = props;

    const bannerHeight = (master?.config?.customer_portal_config?.password_policy_banner_expiry_date
        && new Date(master?.config?.customer_portal_config?.password_policy_banner_expiry_date) <= new Date()) ? 0 : 50;

    useEffect(() => {
        loadsenders({});
    }, [toggle, filters]);
    const history = useHistory();

    const getMasterData = async () => {
        setPageLoading(true);
        try {
            const response = await fetchSellerMasterData();
            if (response.isSuccess) {
                setMasterData(response?.data || {});
            } else {
                message.error(response.errorMessage || 'Error while fetching master data');
            }
        } catch (err) {
            message.error('Error while fetching master data');
        }
        setPageLoading(false);
    };

    useEffect(() => {
        getMasterData();
    }, []);

    useEffect(() => {
        senderType.options = Object.entries(masterData?.SELLER_TYPE || {}).map(([, value]) => {
            return ({
                value: value as string,
                label: masterData?.SELLER_TYPE_LABEL?.[value as string],
            });
        });
        statusTypes = Object.entries(masterData?.SELLER_STATUS || {}).map(([, value]) => {
            return ({
                value: value as string,
                label: snakeToPretty(value as string),
            });
        });
    }, [masterData]);

    const debouncedConsignorCode = useDebounce(inputConsignorCode, 500);
    const debouncedConsignorName = useDebounce(inputConsignorName, 500);
    const debouncedConsignorPhone = useDebounce(inputConsignorPhone, 500);

    useEffect(() => {
        const newFilers: any = {
            ...filters,
        };
        newFilers.seller_code = debouncedConsignorCode;
        newFilers.seller_name = debouncedConsignorName;
        newFilers.phone = debouncedConsignorPhone;
        if (!Helper.equal(filters, newFilers)) {
            setfilters(Buckets.SENDERS, newFilers);
            applyfilters(Buckets.SENDERS);
        }
    }, [debouncedConsignorCode, debouncedConsignorName, debouncedConsignorPhone]);

    const renderText = (row: any, text: string | number) => {
        return (
            <div
                className={classes.cellValue}
                onClick={() => history.push(`${Routes.CONSIGNOR_MANAGEMENT}/${row.seller_id}`)}
            >
                {text}
            </div>
        );
    };

    const renderStatustext = (row: any, text: string | number) => {
        // const isVerified = text === 'Verified';
        // const isNotVerified = text === 'Unverified';
        // const isFailed = text === 'Failed';
        // const isPending = text === 'Pending';
        // const isActive = text === 'Active';
        // const isInactive = text === 'Inactive';
        // let style = {};
        // if (isVerified) {
        //     style = { color: '#28A745', backgroundColor: '#d4edda' };
        // }
        // if (isNotVerified) {
        //     style = { color: '#FD7E14', backgroundColor: '#ffe5d4' };
        // }
        // if (isFailed) {
        //     style = { color: '#DC3545', backgroundColor: '#f8d7da' };
        // }
        // if (isPending) {
        //     style = { color: '#FFC107', backgroundColor: '#fff3cd' };
        // }
        // if (isActive) {
        //     style = { color: '#007bff', backgroundColor: '#d1ecf1' };
        // }
        // if (isInactive) {
        //     style = { color: '#6C757D', backgroundColor: '#e2e3e5' };
        // }
        const style = statusToColorMap[text as keyof typeof statusToColorMap] || {};

        return (
            <div
                className={classes.statusCellValue}
                style={style}
                onClick={() => history.push(`${Routes.CONSIGNOR_MANAGEMENT}/${row.seller_id}`)}
            >
                <div>
                    {text}
                </div>
            </div>
        );
    };

    const handleEdit = async (row: any) => {
        setPageLoading(true);
        try {
            const response = await fetchSellerDetails({ seller_id: row.seller_id });
            if (response.isSuccess) {
                const data = response.data;
                setEditData(data);
                setFormVisible(CreateActions.createSeller);
            } else {
                message.error(response.errorMessage || 'Error while fetching seller details');
            }
        } catch (error) {
            message.error('Error while fetching seller details');
        }
        setPageLoading(false);
    };


    const handleDisable = async (row: any) => {
        setSelectedRow(row);
        setConfirmationModal(true);
    };
    const handleDeactivate = async () => {
        setPageLoading(true);
        try {
            const response = await deactivateSeller({ seller_id: selectedRow.seller_id });
            if (response.isSuccess) {
                message.success('Seller Deactivated Successfully');
            } else {
                message.error(response.errorMessage || 'Error while deactivating seller');
            }
        } catch (error) {
            message.error('Error while deactivating seller');
        }
        setDeactivateTxt('');
        setConfirmationModal(false);
        setPageLoading(false);
        await loadsenders({});
    };

    const handleSetDefault = async (row: any) => {
        setPageLoading(true);
        try {
            const response = await setDefaultSeller({ seller_id: row?.seller_id });
            if (response.isSuccess) {
                message.success(`Seller ${row?.seller_code} is set as default  Successfully`);
            } else {
                message.error(response.errorMessage || 'Error while setting default seller');
            }
        } catch (error) {
            message.error('Error while setting default seller');
        }
        setPageLoading(false);
        await loadsenders({});
    };


    const renderConfirmationModal = () => {
        return (
            <Modal
                title={(
                    <div style={{ fontWeight: 600 }}>
                        <ExclamationCircleFilled style={{ color: '#EA2626', fontSize: 16 }} />
                        &nbsp;
                        {t('Confirm Action')}
                    </div>
                )}
                visible={confirmationModal}
                okText="Yes, Deactivate"
                cancelText="No"
                onOk={handleDeactivate}
                okButtonProps={{
                    disabled: deactivateTxt !== 'Deactivate',
                    type: deactivateTxt !== 'Deactivate' ? 'ghost' : 'primary',
                }}
                onCancel={() => {
                    setDeactivateTxt('');
                    setConfirmationModal(false);
                }}
            >
                <h4>
                    You won&apos;t be able to re-activate this profile.
                    Are you sure you want to deactivate this profile?
                    <br />
                    Enter the phrase &quot;Deactivate&quot; to confirm the deactivation.
                </h4>
                <Input placeholder="Deactivate" value={deactivateTxt} onChange={(e) => setDeactivateTxt(e.target.value)} />
            </Modal>
        );
    };

    const renderActions = (row: any) => {
        if (row.status === 'Inactive') {
            return null;
        }
        const menu = (
            <Menu>
                <Menu.Item key="0" className={classes.menuItemStyle} onClick={() => handleEdit(row)}>
                    Edit
                </Menu.Item>
                <Menu.Item key="1" className={classes.menuItemStyle} onClick={() => handleDisable(row)}>
                    Deactivate
                </Menu.Item>
                <Menu.Item key="2" className={classes.menuItemStyle} onClick={() => handleSetDefault(row)}>
                    Set As Default
                </Menu.Item>
            </Menu>
        );
        return (
            <Dropdown overlay={menu} trigger={['click']}>
                <MoreOutlined style={{ fontSize: '20px' }} />
            </Dropdown>
        );
    };

    const renderBlueText = (row: any, text: string | number) => {
        return (
            <div
                className={classes.cellValue}
                style={{ color: '#0D237D', fontWeight: 600 }}
                onClick={() => history.push(`${Routes.CONSIGNOR_MANAGEMENT}/${row.seller_id}`)}
            >
                {text}
            </div>
        );
    };

    const formatDateTime = (dateTime: any) => {
        return dateTime ? moment(dateTime).format('lll') : 'Not Available';
    };

    const renderColumn = (text: any, row: any, column: string) => {
        if (column === 'action') {
            return renderActions(row);
        }
        if (typeof text === 'boolean') {
            return renderText(row, text ? 'Yes' : 'No');
        }
        if (column === 'created_at' || column === 'updated_at' || column === 'last_verified_at') {
            return renderText(row, formatDateTime(text));
        }
        if (column === 'status') {
            return renderStatustext(row, text);
        }
        if (column === 'seller_code') {
            return renderBlueText(row, text);
        }
        if (column === 'authorization_letter') {
            const authLetters = row.authorization_letters;
            let authCount = 0;
            let authNames = '';
            if (authLetters) {
                authCount = authLetters?.filter((letterObj: any) => letterObj?.is_verified).length;
                authNames = authLetters?.filter((letterObj: any) => letterObj?.is_verified)
                    ?.map((letterObj: any) => (
                        masterData?.AUTHORIZATION_LETTER_PRETTY_NAMES?.[letterObj?.type] || letterObj?.type))
                    ?.sort()
                    ?.join(', ');
            }
            return (
                <div style={{ fontWeight: 600 }}>
                    {authCount}
                    {authCount ? (
                        <Tooltip title={authNames} overlayClassName={classes.customTooltip}>
                            <ExclamationCircleOutlined
                                style={{
                                    color: '#909090',
                                    cursor: 'pointer',
                                    marginLeft: 5,
                                }}
                            />
                        </Tooltip>
                    ) : null}
                </div>
            );
        }
        return renderText(row, text);
    };

    const getFixed = (column: string) => {
        switch (column) {
            case 'action': return 'right';
            default: return undefined;
        }
    };

    const getWidth = (id: string) => {
        switch (id) {
            case 'name':
            case 'phone':
            case 'alternatePhone': return 120;
            case 'authorization_letter': return 65;
            case 'status': return 60;
            case 'action': return 40;
            case 'created_at':
            case 'updated_at':
                return 90;
            default: return 80;
        }
    };

    const getColumns = (): any[] => {
        const columns: any = Object.keys(senderManagementColumns).map((column: string) => {
            return {
                key: column,
                title: t(senderManagementColumns[column]),
                dataIndex: column,
                width: getWidth(column),
                ellipsis: true,
                fixed: getFixed(column),
                render: (text: string, row: any) => renderColumn(text, row, column),
            };
        });
        return columns;
    };

    const renderConsignorCodeSearch = () => {
        return (
            <div className={classes.search}>
                <div className={classes.label}>
                    Consignor Code
                </div>
                <Input
                    value={inputConsignorCode}
                    placeholder={t('Search')}
                    className={classes.searchInput}
                    onChange={(e) => {
                        const searchString = e.target.value;
                        setInputConsignorCode(searchString);
                    }}
                />
            </div>
        );
    };

    const renderConsignorNameSearch = () => {
        return (
            <div className={classes.search}>
                <div className={classes.label}>
                    Consignor Name
                </div>
                <Input
                    value={inputConsignorName}
                    placeholder={t('Search')}
                    className={classes.searchInput}
                    onChange={(e) => {
                        const searchString = e.target.value;
                        setInputConsignorName(searchString);
                    }}
                />
            </div>
        );
    };

    const renderConsignorPhoneSearch = () => {
        return (
            <div className={classes.search}>
                <div className={classes.label}>
                    Consignor Phone
                </div>
                <Input
                    value={inputConsignorPhone}
                    placeholder={t('Search')}
                    className={classes.searchInput}
                    onChange={(e) => {
                        const searchString = e.target.value;
                        setInputConsignorPhone(searchString);
                    }}
                />
            </div>
        );
    };

    const renderConsignorCompanyNameSearch = () => {
        return (
            <div className={classes.row}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span className={classes.label}>
                        Consignor Company Name
                    </span>
                </div>
                <Input
                    placeholder={t('Search')}
                    className={classes.searchInput}
                    onChange={(e) => {
                        const searchString = e.target.value;
                        setInputConsignorCompanyName(searchString);
                    }}
                    defaultValue={filters.company_name}
                />
            </div>
        );
    };

    const renderSenderType = () => {
        return (
            <div className={classes.row}>
                <div className={classes.label}>
                    {t('Consignor Type')}
                </div>
                <Select
                    placeholder={t('Select Consignor Type')}
                    className={classes.searchInput}
                    options={senderType?.options || []}
                    allowClear
                    filterSort={
                        (optionA, optionB) => (
                            (optionA?.label ?? '') as string)?.toLowerCase()
                            .localeCompare(((optionB?.label ?? '') as string)?.toLowerCase())
                    }
                    onChange={
                        (value) => {
                            setSenderTypeValue(value);
                        }
                    }
                    defaultValue={filters.sender_types?.length ? filters.sender_types[0] : null}
                    onClear={() => setSenderTypeValue('')}
                />
            </div>
        );
    };

    const renderSenderStatus = () => {
        return (
            <div className={classes.row}>
                <div className={classes.label}>
                    {t('Consignor Status')}
                </div>
                <Select
                    placeholder={t('Select Consignor Status')}
                    className={classes.searchInput}
                    options={statusTypes || []}
                    allowClear
                    filterSort={
                        (optionA, optionB) => (
                            (optionA?.label ?? '') as string)?.toLowerCase()
                            .localeCompare(((optionB?.label ?? '') as string)?.toLowerCase())
                    }
                    onChange={
                        (value) => {
                            setSenderStatusValue(value);
                        }
                    }
                    defaultValue={filters.status_list?.length ? filters.status_list[0] : null}
                    onClear={() => setSenderStatusValue('')}
                />
            </div>
        );
    };

    const handleCreateButton = async (event: any) => {
        event.stopPropagation();
        setEditData(undefined);
        setFormVisible(CreateActions.createSeller);
    };


    const renderCreateProfileButton = () => {
        return (
            <Button
                type="primary"
                className={classes.createBtn}
                onClick={(e) => handleCreateButton(e)}
            >
                {t('Create Profile')}
            </Button>
        );
    };

    const handlePagination = (nextOrPrev: NextOrPrev) => {
        const { currentPageNumber } = pagination;
        let newPageNumber = currentPageNumber;
        if (nextOrPrev === 'first') {
            newPageNumber = 1;
        } else if (nextOrPrev === 'next') {
            newPageNumber = currentPageNumber + 1;
        } else {
            newPageNumber = currentPageNumber - 1;
        }
        const newPagination = {
            ...pagination,
            nextOrPrev,
            currentPageNumber: newPageNumber,
        };
        setpagination(Buckets.SENDERS, newPagination);
        setToggle(!toggle);
    };

    const renderPaginator = () => {
        return (
            <div style={{ direction: 'ltr' }}>
                <Paginator
                    currentPageNumber={pagination.currentPageNumber}
                    isNextPresent={sendersList?.isNextPresent}
                    onNextClick={() => handlePagination('next')}
                    onPrevClick={() => handlePagination('prev')}
                    onFirstClick={() => handlePagination('first')}
                />
            </div>
        );
    };

    const renderRightFilters = () => {
        return (
            <div className={classes.rightFilters}>
                {renderCreateProfileButton()}
                {renderPaginator()}
            </div>
        );
    };

    const isMoreFilterAdded = () => {
        const isFilterApplied = Object.keys(filters).some((filter) => {
            if (['seller_code', 'seller_name', 'phone'].includes(filter)) {
                return false;
            }
            return filters[filter] !== defaultFilters[filter];
        });
        return isFilterApplied;
    };

    const handleClose = (refresh = false) => {
        if (refresh) {
            const newFilters: any = {
                ...filters,
            };
            newFilters.sender_types = senderTypeValue ? [senderTypeValue] : [];
            newFilters.company_name = inputConsignorCompanyName;
            newFilters.status_list = senderStatusValue ? [senderStatusValue] : [];
            if (!Helper.equal(filters, newFilters)) {
                setfilters(Buckets.SENDERS, newFilters);
                applyfilters(Buckets.SENDERS);
            }
        } else {
            clearselection(Buckets.SENDERS);
        }
        setMoreFiltersVisible(false);
    };

    const renderResetFiltersOption = () => {
        const filterApplied = isMoreFilterAdded();
        if (!filterApplied) {
            return null;
        }
        return (
            <div
                className={classes.resetFilter}
                onClick={() => {
                    setInputConsignorCode('');
                    setInputConsignorName('');
                    setInputConsignorPhone('');
                    setSenderStatusValue('');
                    setSenderTypeValue('');
                    setInputConsignorCompanyName('');
                    setfilters(Buckets.SENDERS, {
                        ...defaultFilters,
                    });
                    applyfilters(Buckets.SENDERS);
                }}
            >
                {t('reset_filters')}
            </div>
        );
    };

    const renderMoreFiltersOption = () => {
        const filterApplied = isMoreFilterAdded();
        return (
            <div
                className={classes.moreFilter}
                onClick={() => setMoreFiltersVisible(true)}
                style={{
                    color: filterApplied ? uiTheme.primaryColor : '#111111',
                    fontWeight: filterApplied ? 600 : 500,
                }}
            >
                <FilterBlue style={{ marginRight: 2 }} />
                <span>{t('more_filters')}</span>
            </div>
        );
    };

    const renderLeftFilters = () => {
        return (
            <div className={classes.leftFilters}>
                {renderConsignorCodeSearch()}
                {renderConsignorNameSearch()}
                {renderConsignorPhoneSearch()}
                {renderMoreFiltersOption()}
                {renderResetFiltersOption()}
            </div>
        );
    };

    const renderLine = () => {
        return <div className={classes.hr} />;
    };

    const renderFooter = () => {
        return (
            <div className={classes.footer}>
                <Button
                    onClick={() => handleClose(true)}
                    type="primary"
                    style={{ marginLeft: 10, marginRight: 20 }}
                >
                    {t('apply_filters')}
                </Button>
                <Button
                    onClick={() => handleClose()}
                    type="dashed"
                >
                    {t('cancel')}
                </Button>
            </div>
        );
    };

    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div>
                    <Cross onClick={() => setMoreFiltersVisible(false)} alt="close" className={classes.closeIcon} />
                    <span>
                        {t('more_filters')}
                    </span>
                </div>
                {isMoreFilterAdded() && (
                    <span
                        className={classes.resetFilter}
                        onClick={() => {
                            setInputConsignorCode('');
                            setInputConsignorName('');
                            setInputConsignorPhone('');
                            setSenderStatusValue('');
                            setSenderTypeValue('');
                            setInputConsignorCompanyName('');
                            setfilters(Buckets.SENDERS, {
                                ...defaultFilters,
                            });
                            applyfilters(Buckets.SENDERS);
                            handleClose();
                        }}
                    >
                        {t('reset_filters')}
                    </span>
                )}
            </div>
        );
    };

    const renderBody = () => {
        return (
            <div className={classes.drawerBody}>
                {renderSenderType()}
                {renderConsignorCompanyNameSearch()}
                {renderSenderStatus()}
            </div>
        );
    };

    const renderMoreFilters = () => {
        if (!moreFilterVisible) {
            return null;
        }
        return (
            <Drawer
                className={classes.moreFilters}
                visible
                width="30%"
                title={renderHeader()}
                onClose={() => setMoreFiltersVisible(false)}
                closable={false}
            >
                {renderBody()}
                {renderLine()}
                {renderFooter()}
            </Drawer>
        );
    };

    const renderExtraFilters = () => {
        return (
            <div className={classes.extraFilters}>
                {renderLeftFilters()}
                {renderRightFilters()}
            </div>
        );
    };

    const renderFilters = () => {
        return (
            <div className={classes.filters}>
                {renderExtraFilters()}
            </div>
        );
    };

    const handleFormClose = () => {
        setFormVisible(undefined);
        setEditData(undefined);
        loadsenders({});
    };

    const renderCreateSellerProfile = () => {
        if (formVisible === CreateActions.createSeller) {
            return (
                <CreateSellerProfile
                    visible={formVisible === CreateActions.createSeller}
                    onClose={handleFormClose}
                    editData={editData}
                    masterData={masterData}
                    config={master}
                />
            );
        }
        return null;
    };

    const renderTable = () => {
        return (
            <Table
                bordered={false}
                pagination={false}
                loading={loading}
                rowKey={(row) => row.seller_id}
                columns={getColumns()}
                locale={{
                    emptyText: <div className={classes.cellNa}>-NA-</div>,
                }}
                rowSelection={{
                    onSelect: (row, selected: any) => {
                        let newArray = [...sendersSelected];
                        if (selected && !newArray.includes(row.seller_id)) {
                            newArray.push(row.seller_id);
                        } else {
                            newArray = newArray.filter((item) => (item !== row.seller_id));
                        }
                        setSendersSelected(newArray);
                    },
                    onSelectAll: (selected, selectedRows) => {
                        const newArray = selectedRows.map((item) => (item.seller_id));
                        setSendersSelected(newArray);
                    },
                }}
                className={classes.table}
                dataSource={sendersList?.sendersData?.map((item: any) => ({
                    ...item,
                    status: snakeToPretty(item?.status),
                    sender_type: snakeToPretty(item?.sender_type),
                })) || []}
                scroll={{
                    y: `calc(((100vh - ${NAVBAR_HEIGHT}px) - 110px) - ${bannerHeight}px)`,
                }}
            />
        );
    };

    if (pageLoading) {
        return <Loader zIndex={100} />;
    }
    return (
        <div className={classes.main}>
            {renderFilters()}
            {renderTable()}
            {renderCreateSellerProfile()}
            {renderConfirmationModal()}
            {renderMoreFilters()}
        </div>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    const { generic } = state;
    const { SENDERS } = generic;
    const {
        loading,
        data,
        pagination,
        appliedFilters,
        defaultFilters,
    } = SENDERS;
    return {
        loading,
        defaultFilters,
        sendersList: data,
        uiTheme: state.uiTheme,
        filters: appliedFilters,
        pagination,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    const actions = {
        loadsenders: loadSenders,
        setpagination: setPagination,
        setfilters: setFilters,
        applyfilters: applyFilters,
        clearselection: clearSelection,
    };
    return bindActionCreators(actions, dispatch);
};

const hocConfig: HocOptions = {
    connectJss: {
        useJss: true,
        styleSheet: senderManagementStyles,
    },
    connectRedux: {
        useRedux: true,
        mapStateToProps,
        mapDispatchToProps,
    },
    connectRouter: true,
    connectTranslession: true,
};

const SenderManagements = GenericHoc(hocConfig)(SenderManagement);
export default SenderManagements;

