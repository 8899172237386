import * as React from 'react';
import { useGenericState } from '../../hooks/use-generic-state';
import { StylesProps, ThemeType } from '../../theme/jss-types';
import { createSellerProfileStyles } from './create-seller-profile.styles';
import StepOne from './create-seller-profile-steps/step-one';
import StepTwo from './create-seller-profile-steps/step-two';
import StepThree from './create-seller-profile-steps/step-three';
import withStyles from 'react-jss';
import { createSellerProfile, updateSellerProfile, checkCodeAvailaibility } from '../../network/sender-management.api';
import { ReduxStore } from 'reducers/redux.types';
import GenericHoc from 'components/common/generic-hoc';
import { loadSenders } from '../../actions/senders-actions';
import { HocOptions } from 'components/common/generic-hoc.types';
import Cross from '../../assets/cross';
import moment from 'moment';
import _ from 'lodash';
import {
    CheckCircleFilled,
    ExclamationCircleFilled,
    LeftOutlined,
    RightOutlined,
} from '@ant-design/icons';
import {
    ButtonNames,
} from '../create-consignment/create-modal.constants';
import { getRequiredKeys, createSellerProfileFormFields } from './create-seller-profile.constants';
import {
    ButtonAction,
    CurrentPage,
    CurrentPageNumber,
    NewConsignmentState,
    initialState,
    editInitialState,
    TabNames,
} from './create-seller-profile.types';
import {
    Button,
    Drawer,
    Form,
    message,
    Modal,
} from 'antd';
import { useTranslation } from 'react-i18next';

interface SellerProps extends StylesProps<ReturnType<typeof createSellerProfileStyles>> {
    config: any;
    onClose: () => void;
    setSuccess: (data: any) => void;
    uiTheme: ThemeType;
    editData?: any;
    source?: string;
    actionType?: any;
    masterData?: any;
    loadsenders: () => void;
}

const CreateSellerProfile = (props: SellerProps) => {
    const {
        config,
        classes,
        onClose,
        uiTheme,
        editData,
        source,
        masterData,
    } = props;

    const {
        senderCode,
    } = createSellerProfileFormFields;

    const { t, i18n } = useTranslation();
    const [form] = Form.useForm();

    const [creating, setCreating] = React.useState<boolean>(false);
    const [closeConfirm, setCloseConfirm] = React.useState<boolean>(false);
    const [state, setState] = useGenericState<NewConsignmentState>(editData ? editInitialState : initialState);
    const [formData, setFormData] = useGenericState<any>({
        ...editData,
    });

    const getKycDocsForEdit = (kycDocs: any) => {
        const { SELLER_TYPE_DOCUMENTS_UPLOAD_MAP } = masterData;

        const documentsData = SELLER_TYPE_DOCUMENTS_UPLOAD_MAP[editData.sender_type]?.documents?.map((obj: any) => {
            const availableDocOptions = obj.document_type_options || [];
            // find single doc in kycDocs which also exists in availableDocOptions
            const matchedDoc = kycDocs.find((doc: any) => availableDocOptions.includes(doc.document_type)) || {};
            return {
                ...matchedDoc,
                dob: matchedDoc.dob ? moment(matchedDoc.dob, 'DD/MM/YYYY') : undefined,
            };
        });
        return documentsData || [];
    };

    const getValidatedDocsForEdit = (kycDocs: any) => {
        const { SELLER_TYPE_DOCUMENTS_UPLOAD_MAP } = masterData;

        const documentsData = SELLER_TYPE_DOCUMENTS_UPLOAD_MAP[editData.sender_type]?.documents?.map((obj: any) => {
            const availableDocOptions = obj.document_type_options || [];
            // find single doc in kycDocs which also exists in availableDocOptions
            const matchedDoc = kycDocs.find((doc: any) => availableDocOptions.includes(doc.document_type)) || {};
            return {
                ...matchedDoc,
                isValidated: matchedDoc.is_verified,
                errorMessage: matchedDoc.verification_failure_reason,
            };
        });
        return documentsData || [];
    };

    React.useEffect(() => {
        if (editData) {
            const updateFormData = async () => {
                if (editData.kyc_documents) {
                    editData.kyc_documents = getKycDocsForEdit(editData?.kyc_documents);
                }

                form.setFieldsValue({
                    ...editData,
                    sender_email: editData.email,
                    address_proof: [
                        {
                            front_image: editData?.location_details?.address_proof_front_image,
                        },
                        {
                            back_image: editData?.location_details?.address_proof_back_image,
                        },
                    ],
                    ad_code_certificate: editData?.bank_details?.ad_code_certificate_image,
                    is_csbv_applicable: editData?.is_csbv_applicable ? 'yes' : 'no',
                });
            };
            updateFormData();
            setFormData({
                ...formData,
                validatedDocuments: getValidatedDocsForEdit(editData?.kyc_documents),
                is_phone_verified: true,
                verified_mobile_number: editData.phone,
            });
        }
    }, [editData, form]);

    const TitleTabNames = {
        ...TabNames,
    };

    const updateFormData = () => {
        const formValues = form.getFieldsValue();
        const updatedData = {
            ...formData,
            ...formValues,
        };
        setFormData(updatedData);
    };

    const pagesToShow = Object.keys(CurrentPage);

    React.useEffect(() => {
        setState({
            visited: {
                ...state.visited,
                [state.currentPage]: true,
            },
        });
    }, [state.currentPage]);


    const checkError = (page: CurrentPage, formValues: any = {}) => {
        const required = getRequiredKeys();
        const requiredKeys = required[page];
        const data = {
            ...formValues,
            ...formData,
        };
        const err = requiredKeys.some((key: string) => _.get(data, key) === undefined);
        return err;
    };

    const { currentPage } = state;

    const customCheck = async () => {
        await form.validateFields();
    };

    const handleTabChange = async (page: CurrentPage) => {
        updateFormData();
        await customCheck();
        if (currentPage === CurrentPage.FIRST && formData.is_phone_verified === false) {
            message.error('Please verify your mobile number');
            return;
        }
        if (page === currentPage) {
            return;
        }
        setState({ currentPage: page });
    };

    const renderFilledCircle = (page: CurrentPage) => {
        const activePage = page === currentPage;
        return (
            <div
                className={activePage ? classes.filledCircle : classes.filledCircle}
            />
        );
    };

    const renderCircle = (page: CurrentPage) => {
        const activePage = page === currentPage;
        return (
            <div
                className={classes.tabIcon}
                style={{
                    border: activePage ? `2px solid ${uiTheme.primaryColor}`
                        : '2px solid #CCCCCC',
                    backgroundColor: activePage ? uiTheme.primaryColor : '#FFF',
                }}
            >
                {renderFilledCircle(page)}
            </div>
        );
    };

    const complete = () => {
        return (
            <CheckCircleFilled
                style={{
                    color: '#27B479',
                    fontSize: 24,
                }}
            />
        );
    };

    const inComplete = () => {
        return (
            <ExclamationCircleFilled
                style={{
                    color: '#EA2626',
                    fontSize: 24,
                }}
            />
        );
    };

    const completeIncompleteIcon = (page: CurrentPage) => {
        const err = checkError(page);
        if (err) {
            return inComplete();
        }
        return complete();
    };

    const renderTabIcon = (page: CurrentPage) => {
        if (currentPage === page) {
            return renderCircle(page);
        }
        switch (page) {
            case CurrentPage.FIRST: {
                if (state.visited.FIRST) {
                    return completeIncompleteIcon(page);
                }
                return renderCircle(page);
            }
            case CurrentPage.SECOND: {
                if (state.visited.SECOND) {
                    return completeIncompleteIcon(page);
                }
                return renderCircle(page);
            }
            case CurrentPage.THIRD: {
                if (state.visited.THIRD) {
                    return completeIncompleteIcon(page);
                }
                return renderCircle(page);
            }
            default: return null;
        }
    };

    const renderTabName = (page: CurrentPage) => {
        return (
            <div
                className={classes.tabName}
            >
                {t(TitleTabNames[page])}
            </div>
        );
    };

    const renderTabDetails = (page: CurrentPage) => {
        const activePage = page === currentPage;
        return (
            <div
                style={{
                    color: activePage ? '#111111' : '#666666',
                    fontWeight: activePage ? 'bold' : 'normal',
                }}
            >
                {renderTabName(page)}
            </div>
        );
    };

    const renderTab = (page: CurrentPage) => {
        const activePage = page === currentPage;
        return (
            <div
                key={page}
                className={classes.tabSelector}
                onClick={() => handleTabChange(page)}
                style={{
                    borderBottom: activePage
                        ? `4px solid ${uiTheme.secondryColor}` : 'none',
                }}
            >
                {renderTabIcon(page)}
                {renderTabDetails(page)}
            </div>
        );
    };

    const renderPageSelector = () => {
        return (
            <div
                className={classes.pageSelector}
            >
                {pagesToShow.map((page) => renderTab(page as CurrentPage))}
            </div>
        );
    };

    const commonProps = {
        config,
        form,
        formData,
        setFormData,
        masterData,
    };

    const renderStepTwo = () => {
        return (
            <StepTwo
                condition={currentPage === CurrentPage.SECOND}
                {...commonProps}
            />
        );
    };

    async function getAPIBody() {
        updateFormData();
        const formValues = form.getFieldsValue();
        const updatedData = {
            ...formData,
            ...formValues,
        };
        const updatedKycDocuments = updatedData.kyc_documents
            ? updatedData.kyc_documents.map((doc: any) => ({
                ...doc,
                dob: doc.dob ? moment(doc.dob).format('DD/MM/YYYY') : null,
                front_image: undefined,
                front_image_details: undefined,
                back_image: undefined,
                back_image_details: undefined,
            }))
            : [];
        if (updatedData.bank_details) {
            delete updatedData.bank_details.image_details;
            Object.keys(updatedData.bank_details).forEach((key) => {
                if (!updatedData.bank_details[key]) {
                    delete updatedData.bank_details[key];
                }
            });
            if (updatedData.bank_details && !Object.keys(updatedData.bank_details).length) {
                delete updatedData.bank_details;
            }
        }
        if (updatedData.location_details?.address_type === 'individual') {
            delete updatedData.company_name;
        }
        const apiBody = {
            seller_id: updatedData.seller_id,
            phone: updatedData.phone,
            alternate_phone: updatedData.alternate_phone,
            email: updatedData.sender_email,
            seller_code: updatedData.seller_code,
            seller_name: updatedData.seller_name,
            company_name: updatedData.company_name,
            sender_type: updatedData.sender_type,
            is_csbv_applicable: updatedData.is_csbv_applicable === 'yes',
            authorization_letters: updatedData.authorization_letters || [],
            bank_details: updatedData.bank_details,
            kyc_documents: updatedKycDocuments,
            location_details: updatedData.location_details || {},
        };
        return {
            apiBody,
        };
    }

    const renderStepThree = () => {
        return (
            <StepThree
                condition={currentPage === CurrentPage.THIRD}
                {...commonProps}
                getAPIBody={getAPIBody}
            />
        );
    };

    const renderStepOne = () => {
        return (
            <StepOne
                condition={currentPage === CurrentPage.FIRST}
                {...commonProps}
                isEdit={!!editData}
                source={source}
            />
        );
    };

    function getNextPage(action: ButtonAction) {
        let currentPageNumber = CurrentPageNumber[currentPage];
        if (action === ButtonAction.NEXT && currentPageNumber < pagesToShow.length) {
            currentPageNumber += 1;
        } else if (action === ButtonAction.PREV) {
            currentPageNumber -= 1;
        }
        switch (currentPageNumber) {
            case 1: return CurrentPage.FIRST;
            case 2: return CurrentPage.SECOND;
            case 3:
            default:
                return CurrentPage.THIRD;
        }
    }

    async function handlePageChange(action: ButtonAction) {
        updateFormData();
        await customCheck();
        if (currentPage === CurrentPage.FIRST && formData.is_phone_verified === false) {
            message.error('Please verify your mobile number');
            return;
        }
        const nextPage = getNextPage(action);
        setState({ currentPage: nextPage });
    }

    const checkSellerCodeAvailability = async () => {
        const seller_code = form.getFieldValue(senderCode.key);
        const availabalityResponse = await checkCodeAvailaibility({ seller_code });
        if (!availabalityResponse?.data?.available) {
            message.error('Seller Code already exists');
            return false;
        }
        return true;
    };

    async function checkForErrors() {
        updateFormData();
        await customCheck();
        const visited = Object.values(state.visited).every((val) => val);
        if (!visited) {
            message.error('Please fill all the details');
            return;
        }
        if (formData.is_phone_verified === false) {
            message.error('Please verify your mobile number');
            return;
        }
        if (!editData) {
            const isSellerCodeAvailable = await checkSellerCodeAvailability();
            if (!isSellerCodeAvailable) {
                return;
            }
        }
        const {
            apiBody,
        } = await getAPIBody();
        setCreating(true);
        try {
            if (editData) {
                const response = await updateSellerProfile(apiBody);
                if (response.isSuccess) {
                    onClose();
                } else {
                    message.error(response.errorMessage);
                }
            } else {
                const response = await createSellerProfile(apiBody);
                if (response.isSuccess) {
                    message.success('Seller Created Successfully');
                    onClose();
                } else {
                    message.error(response.errorMessage);
                }
            }
        } catch {
            message.error('Something went wrong');
        }
        setCreating(false);
    }

    function handleAction(action: ButtonAction) {
        switch (action) {
            case ButtonAction.PREV:
            case ButtonAction.NEXT: return handlePageChange(action);
            case ButtonAction.SUBMIT: return checkForErrors();
            default: return null;
        }
    }

    const renderButton = (name: string, action: ButtonAction) => {
        const btnClass = classes.nextPreButton;

        return (
            <Button
                type={(action === ButtonAction.SUBMIT) ? 'primary' : 'link'}
                onClick={() => handleAction(action)}
                style={{ marginRight: 16 }}
                loading={creating}
                className={btnClass}
            >
                {action === ButtonAction.PREV && <LeftOutlined />}
                {name}
                {action === ButtonAction.NEXT && <RightOutlined />}
            </Button>
        );
    };

    const renderLeftButton = () => {
        switch (currentPage) {
            case CurrentPage.SECOND:
            case CurrentPage.THIRD: return renderButton(t(ButtonNames.PREV), ButtonAction.PREV);
            case CurrentPage.FIRST:
            default: return null;
        }
    };

    const renderRightButton = () => {
        switch (currentPage) {
            case CurrentPage.FIRST:
            case CurrentPage.SECOND: return renderButton(t(ButtonNames.NEXT), ButtonAction.NEXT);
            case CurrentPage.THIRD:
            default: return null;
        }
    };

    const renderFooter = () => {
        return (
            <div className={classes.footer}>
                <div>
                    {renderLeftButton()}
                    {renderRightButton()}
                </div>
                <div>
                    {renderButton(t(ButtonNames.SUBMIT), ButtonAction.SUBMIT)}
                </div>
            </div>
        );
    };
    const handleSubmit = () => {
        setCreating(true);
        onClose();
    };

    const renderCloseConfirmModal = () => {
        return (
            <Modal
                title={(
                    <div style={{ fontWeight: 600 }}>
                        <ExclamationCircleFilled style={{ color: '#EA2626', fontSize: 16 }} />
                        &nbsp;
                        {t('Confirm Action')}
                    </div>
                )}
                visible={closeConfirm}
                onCancel={() => setCloseConfirm(false)}
                footer={[
                    <Button key="back" type="text" onClick={() => setCloseConfirm(false)}>
                        {t('No, Continue')}
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleSubmit}>
                        {t('Yes, Cancel')}
                    </Button>,
                ]}
            >
                { i18n.exists('exit_seller_create_form')
                    ? t('exit_seller_create_form')
                    : ('Exiting will result in unsaved changes to your seller creation.'
                    + ' Do you want to cancel creating seller now?')}
            </Modal>
        );
    };

    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div className={classes.flexRow}>
                    <Cross onClick={() => setCloseConfirm(true)} alt="close" className={classes.closeIcon} />
                    <span>{editData ? 'Edit Profile' : t('create_profile')}</span>
                </div>
            </div>
        );
    };

    return (
        <Drawer
            visible
            width="80%"
            title={renderHeader()}
            footer={renderFooter()}
            onClose={() => setCloseConfirm(true)}
            className={classes.main}
            closable={false}
            footerStyle={{
                padding: 0,
                margin: 0,
            }}
        >
            <Form form={form}>
                {renderPageSelector()}
                {renderStepOne()}
                {renderStepTwo()}
                {renderStepThree()}
                {renderCloseConfirmModal()}
            </Form>
        </Drawer>
    );
};
const mapStateToProps = (state: ReduxStore) => {
    return {
        uiTheme: state.uiTheme,
        loadsenders: loadSenders,
    };
};
const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectRouter: true,
    connectTranslession: true,
};


export default withStyles(createSellerProfileStyles)(GenericHoc(hocConfig)(CreateSellerProfile));
