// import authHelper from 'auth/auth-helper';
// import { Header, Product } from 'components/settings-product/settings.types';
import { API_BASE_URL } from 'library/globals';
import { GET, POST } from './api-hander.service';
import {
    CREATE_USER,
    UPDATE_USER,
    GET_USERS,
    GET_ROLES,
    DOWNLOAD_USERS,
} from './api.constants';

// export const getUsers = () => {
//     return GET(`${API_BASE_URL}${GET_USERS}`);
// };

export const getUsers = (filters: any = {}) => {
    const query = new URLSearchParams(filters).toString();
    return GET(`${API_BASE_URL}${GET_USERS}?${query}`);
};

export const getRoles = (id : number) => {
    return GET(`${API_BASE_URL}${GET_ROLES}?id=${id}`);
};

export const createUser = (body: any) => {
    return POST(`${API_BASE_URL}${CREATE_USER}`, body);
};

export const updateUser = (body: any) => {
    return POST(`${API_BASE_URL}${UPDATE_USER}`, body);
};

export const downloadUsers = () => {
    return GET(`${API_BASE_URL}${DOWNLOAD_USERS}`);
};
