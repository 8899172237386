import { CloseOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
    Button,
    Checkbox,
    DatePicker,
    Drawer,
    Form,
    Input,
    message,
    Popconfirm,
    Radio,
    Select,
} from 'antd';
import { uniqueId } from 'lodash';
import * as React from 'react';
import withStyles from 'react-jss';
import { StylesProps, ThemeType } from 'theme/jss-types';
import Cross from '../../assets/cross';
import { useTranslation } from 'react-i18next';
import { schedulePickupStyles } from './consignment-schedule-pickup.styles';
import CreateAddress from 'components/address/create-address';
import Loader from 'components/common/Loader';
import { FormField } from 'components/create-consignment/create-consignment.types';
import { PickupAddress } from 'components/pickup/create-pickup/pickup.types';
import { deleteAddress, fetchPickupAddress } from 'network/pickup.api';
import { pickupFormFields } from 'components/pickup/create-pickup/create-pickup.constants';
import Helper from 'library/Helper';
import { getTimeSlots, schedulePickup } from 'network/consignments.api';
import moment, { Moment } from 'moment';
import { TimeSlot } from 'library/Types';
import { formRules } from 'library/constants';
import { useDebounce } from 'hooks/use-debounce';
import { ReduxStore } from 'reducers/redux.types';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';

const {
    AddressOption,
    AddressLine,
    PickupTime,
    ItemType,
} = pickupFormFields;

interface CancellationProps extends StylesProps<ReturnType<typeof schedulePickupStyles>> {
    onClose: (reload: boolean, params?: any) => void;
    consignments: string[];
    uiTheme: ThemeType;
}

const SchedulePickupModal = (props: CancellationProps) => {
    const {
        classes,
        onClose,
        consignments,
        uiTheme,
    } = props;

    const [scheduling, setScheduling] = React.useState<boolean>(false);
    const [inputValue, setInputValue] = React.useState<string | undefined>();
    const [list, setList] = React.useState<{ id: string, cn: string }[]>([]);
    const [addressType, setAddressType] = React.useState<string>('saved');
    const [loading, setLoading] = React.useState<boolean>(true);
    const [toggle, setToggle] = React.useState<boolean>(true);
    const { t, i18n } = useTranslation();
    const [addressList, setAddressList] = React.useState<PickupAddress[]>([]);
    const [searchString, setSearchString] = React.useState<string>('');
    const [form] = Form.useForm();
    const [selectedAddress, setSelected] = React.useState<PickupAddress | null>(null);
    const [pickupDate, setPickupDate] = React.useState<Moment | null>(moment());
    const [timeSlots, setTimeSlots] = React.useState<TimeSlot[]>([]);
    const [editAddress, setEditAddress] = React.useState<PickupAddress | undefined>();
    const formRuleRequired = {
        ...formRules.required,
        message: i18n.exists('required') ? t('required') : 'Required',
    };


    const loadAddress = async () => {
        setLoading(true);
        const response = await fetchPickupAddress({
            // showDefaultBy: 'src' : undefined,
            searchString,
        });
        if (response.isSuccess) {
            setAddressList(response?.data || []);
        }
        setLoading(false);
    };

    React.useEffect(() => {
        loadAddress();
        setList(consignments.map((item: string) => ({
            id: uniqueId(),
            cn: item,
        })));
    }, []);

    const debounceTime = useDebounce(searchString);
    React.useEffect(() => {
        loadAddress();
    }, [debounceTime, addressType, toggle]);

    const handlePickupDate = (date: Moment | null) => {
        setPickupDate(date);
        form.setFieldsValue({ date });
        form.resetFields([PickupTime.key]);
    };

    const loadTimeSlots = async () => {
        const response = await getTimeSlots({
            pickupDate: moment(pickupDate).format('DD/MM/YYYY').toString(),
        });
        setTimeSlots(Helper.isValidArray(response?.data) ? response?.data : []);
    };

    React.useEffect(() => {
        loadTimeSlots();
    }, [pickupDate]);


    const renderTime = () => {
        const field = PickupTime;
        return (
            <div className={classes.columnRight}>
                <div>
                    Time Slot
                </div>
                <Form.Item
                    name={field.key}
                    key={field.key}
                    initialValue={field.defaultValue}
                    valuePropName={field.valuePropName}
                    rules={[formRuleRequired]}
                >
                    <Select
                        placeholder={t('time_slot')}
                    >
                        {timeSlots.map((item) => {
                            const name = `${item.start} - ${item.end}`;
                            return (
                                <Select.Option key={name} value={name}>
                                    {name}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </Form.Item>
            </div>
        );
    };

    const renderItemType = () => {
        const field = ItemType;
        return (
            <div className={classes.flexRow} style={{ padding: '0 24px' }}>
                <div className={classes.itemType}>
                    <div>
                        {t('item_type')}
                        :
                    </div>
                    <div>
                        <Form.Item
                            name={field.key}
                            key={field.key}
                            initialValue={field.defaultValue}
                            valuePropName={field.valuePropName}
                            rules={[formRuleRequired]}
                        >
                            <Radio.Group
                                style={{ width: field.width }}
                                options={field.options || undefined}
                            />
                        </Form.Item>
                    </div>
                </div>
            </div>
        );
    };

    const handleSubmit = async () => {
        if (!selectedAddress) {
            message.error('Select Address');
            return;
        }
        const values = await form.validateFields();
        setScheduling(true);
        const consignmentsList = list.map((item) => (item.cn));
        const pickupTime = values.pickupTime?.split('-');
        const apiBody: any = {
            consignments: consignmentsList,
            courierType: values.courierType.toString().toUpperCase(),
            customer_code: window.localStorage.getItem('userCode'),
            pickupSlot: {
                start: pickupTime[0].trim(),
                end: pickupTime[1].trim(),
                date: moment(pickupDate).format('DD/MM/YYYY'),
            },
            pickupAddress: selectedAddress,
            totalItems: consignmentsList?.length,
            totalWeight: 1,
            pickupType: 'BUSINESS',
            childClient: {
                code: window.localStorage.getItem('userCode'),
                id: window.localStorage.getItem('userId') || null,
            },
            organisation_id: window.localStorage.getItem('organisationId'),
            auth_customer_id: window.localStorage.getItem('userId') || null,

        };
        const response = await schedulePickup({
            ...apiBody,
        });

        setScheduling(false);

        if (!response.isSuccess) {
            message.error(response.errorMessage);
        } else if (response?.data?.failureArray?.length) {
            onClose(true, {
                failures: response?.data?.failureArray?.map((item: any) => {
                    return {
                        reason: item.failureReason,
                        reference_number: item.reference_number,
                    };
                }),
            });
        } else if (response?.data?.failureReason) {
            message.error(response.data.failureReason);
            return;
        } else {
            message.success('Successful');
            onClose(true);
        }
        onClose(true);
    };

    const renderSerial = (serial: number) => {
        return (
            <span className={classes.serial}>
                {serial + 1}
            </span>
        );
    };

    const renderReferenceNumber = (item: { cn: string, id: string }) => {
        return (
            <span className={classes.reference}>
                {item.cn}
            </span>
        );
    };

    const renderActions = (item: { cn: string, id: string }) => {
        return (
            <CloseOutlined
                style={{ color: 'red' }}
                onClick={() => {
                    const newList = list.filter((listItem) => listItem.id !== item.id);
                    setList(newList);
                }}
                className={classes.icon}
            />
        );
    };

    const renderListItem = (item: { cn: string, id: string }, idx: number) => {
        return (
            <div className={classes.itemBox}>
                {renderSerial(idx)}
                {renderReferenceNumber(item)}
                {renderActions(item)}
            </div>
        );
    };

    const renderList = () => {
        return (
            <div className={classes.list}>
                {list.map((item, idx) => {
                    return renderListItem(item, idx);
                })}
            </div>
        );
    };

    const isDisabled = () => {
        if ((!list || !list.length)) {
            return true;
        }
        return false;
    };

    const renderInput = () => {
        return (
            <div className={classes.row} style={{ margin: '0 24px' }}>
                <span className={classes.fieldTitle}>{t('cn_number')}</span>
                <Input
                    style={{ width: '70%' }}
                    placeholder={t('reference_number_input')}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onPressEnter={(e: any) => {
                        const newReference = {
                            id: uniqueId(),
                            cn: e.target.value,
                        };
                        setList([...list, newReference]);
                        setInputValue('');
                    }}
                    className={classes.input}
                />
                <Button
                    type="ghost"
                    style={{
                        boxSizing: 'border-box',
                        border: `1px solid ${uiTheme.primaryColor}`,
                        borderRadius: 3,
                        color: uiTheme.primaryColor,
                        fontSize: 12,
                        fontWeight: 'bold',
                    }}
                    disabled={Boolean(!inputValue)}
                    onClick={() => {
                        if (!inputValue) {
                            return;
                        }
                        const newReference = {
                            id: uniqueId(),
                            cn: inputValue,
                        };
                        setList([...list, newReference]);
                        setInputValue('');
                    }}
                >
                    {t('add')}
                </Button>
            </div>
        );
    };

    const renderSerialTitle = () => {
        return (
            <span className={classes.serialTitle}>
                {t('serialNumber')}
            </span>
        );
    };

    const renderReferenceTitle = () => {
        return (
            <span className={classes.referenceTitle}>
                {t('ref_number')}
            </span>
        );
    };

    const renderActionTitle = () => {
        return (
            <span className={classes.actionTitle}>
                {t('action')}
            </span>
        );
    };

    const renderColumns = () => {
        return (
            <div className={classes.columnRow}>
                {renderSerialTitle()}
                {renderReferenceTitle()}
                {renderActionTitle()}
            </div>
        );
    };

    const renderTitle = () => {
        return (
            <div className={classes.title}>
                {t('consignments')}
            </div>
        );
    };

    const renderAddressSearch = () => {
        return (
            <div className={classes.searchAddress}>
                <span className={classes.searchText} />
                <div>
                    <Input
                        placeholder={t('search')}
                        type="Search Address"
                        onChange={(e) => setSearchString(e.target.value)}
                        style={{ width: 200 }}
                    />
                </div>
            </div>
        );
    };

    const renderAddressType = (field: FormField) => {
        if (Array.isArray(field.options) && field.options.length > 0) {
            field.options.forEach((item: any) => {
                /* eslint-disable no-param-reassign */
                item.label = t(item.value);
            });
        }
        return (
            <div className={classes.addressType}>
                <div className={classes.justifySpaceBetween}>
                    <div>
                        Select Address
                        <Radio.Group
                            value={addressType}
                            onChange={(e) => setAddressType(e.target.value)}
                            options={field.options}
                            style={{
                                display: 'flex',
                                flexDirection: field.direction,
                            }}
                        />
                    </div>
                    {addressType === 'saved' ? renderAddressSearch() : null}
                </div>
            </div>
        );
    };

    const renderAddressDetails = (item: PickupAddress) => {
        const customerCode = window.localStorage.getItem('userCode');
        return (
            <div className={classes.addressLineDetails}>
                <div style={{ minWidth: '30%' }}>
                    {item.addressLine1 || ''}
                    {item.addressLine1 ? ', ' : ''}
                    {item.addressLine2 || ''}
                </div>
                <div style={{ minWidth: '30%', marginLeft: '10%' }}>
                    {item.pincode || ''}
                    {item.pincode && item.cityName ? ' - ' : ''}
                    {item.cityName || ''}
                    <br />
                    {item.stateName || ''}
                    {item.stateName ? ', ' : ''}
                    {item.countryName || ''}
                </div>
                <div style={{ minWidth: '30%', textAlign: 'right' }}>
                    {item.phone
                        ? (
                            <>
                                {item.phone}
                                <br />
                            </>
                        ) : null}
                    {item.alternatePhone
                        ? (
                            <>
                                {item.alternatePhone}
                                <br />
                            </>
                        ) : null}
                    {item.email ? item.email : null}
                    <b>{`${item.locationId || ''}`}</b>
                    <br />
                    <b>{item.locationId ? item.locationCode || `${customerCode}_${item.locationId}` : ''}</b>
                </div>
            </div>
        );
    };

    const isCurrentAddressSelected = (item: PickupAddress) => {
        return Boolean(selectedAddress?.id === item.id);
    };

    const renderEdit = (item: PickupAddress) => {
        return (
            <EditOutlined
                onClick={(e) => {
                    Helper.stops(e as any);
                    setAddressType('createnew');
                    setEditAddress(item);
                }}
                style={{ color: uiTheme.primaryColor, marginRight: 8 }}
            />
        );
    };

    const handleDelete = async (e: any, id: string) => {
        Helper.stops(e);
        const result = await deleteAddress({ id });
        if (result.isSuccess) {
            message.success('Address Deleted Successfully');
            setToggle(!toggle);
        } else {
            message.error(result.errorMessage);
        }
    };

    const renderDelete = (item: PickupAddress) => {
        return (
            <Popconfirm
                placement="topLeft"
                title={t('delete_address_text')}
                onConfirm={(e) => handleDelete(e, item.id)}
                okText={t('ok_text')}
                cancelText={t('cancel')}
                onCancel={(e) => Helper.stops(e as any)}
            >
                <DeleteOutlined
                    onClick={(e) => {
                        Helper.stops(e as any);
                    }}
                    style={{ color: 'red' }}
                />
            </Popconfirm>
        );
    };

    const renderCheckbox = (item: PickupAddress) => {
        return (
            <Checkbox
                key={item.id}
                checked={isCurrentAddressSelected(item)}
                className={classes.checkbox}
            >
                <div className={classes.addressName}>
                    <span>
                        <span style={{ display: 'inline-block', minWidth: '100px', fontWeight: 'bold' }}>
                            {item.name || ''}
                        </span>
                    </span>
                    <div style={{ justifyContent: 'flex-end' }}>
                        {renderEdit(item)}
                        {renderDelete(item)}
                    </div>
                </div>
            </Checkbox>
        );
    };

    const renderAddressLine = (item: PickupAddress) => {
        let className = classes.addressLine;
        const isCurrentSelected = isCurrentAddressSelected(item);
        if (isCurrentSelected) {
            className = [classes.addressLineSelected, classes.addressLine].join(' ');
        }
        return (
            <div
                key={item.id}
                onClick={(e) => {
                    Helper.stops(e as any);
                    // remove selection if clicked on same row
                    if (isCurrentSelected) {
                        setSelected(null);
                    } else {
                        // save address
                        setSelected(item);
                    }
                }}
                className={className}
            >
                {renderCheckbox(item)}
                {renderAddressDetails(item)}
            </div>
        );
    };

    const renderAddressList = () => {
        if (loading) {
            return <Loader zIndex={10} />;
        }
        return (
            <Form.Item
                key={AddressLine.key}
                name={AddressLine.key}
                className={classes.addressList}
            >
                <div className={classes.addressList}>
                    {addressList.map((item) => renderAddressLine(item))}
                </div>
            </Form.Item>
        );
    };

    const renderExisting = () => {
        return (
            <>
                {renderAddressList()}
            </>
        );
    };

    const renderAddressFormUtil = () => {
        if (addressType === 'createnew') {
            return (
                <div style={{ padding: '0px 24px' }}>
                    <CreateAddress
                        page="Pickup"
                        editData={editAddress}
                        onClose={() => {
                            setAddressType('saved');
                            form.setFieldsValue({
                                [AddressOption.key]: 'saved',
                            });
                        }}
                        phoneRegex={null}
                        isInternationalAddress={false}
                    />
                </div>
            );
        }
        return renderExisting();
    };

    const renderAddress = () => {
        return (
            <div className={classes.boxFields}>
                {renderAddressType(AddressOption)}
                {renderAddressFormUtil()}
            </div>
        );
    };

    const renderDate = () => {
        return (
            <div className={classes.columnLeft} style={{ paddingRight: 24 }}>
                <div>
                    Select Date
                </div>
                <Form.Item
                    key="date"
                    name="date"
                    rules={[formRuleRequired]}
                    initialValue={moment()}
                >
                    <DatePicker
                        onChange={(val) => handlePickupDate(val)}
                        allowClear={false}
                        value={pickupDate}
                        style={{
                            width: '100%',
                            borderRadius: 6,
                            border: '1px solid #999999',
                            height: 32,
                            fontSize: 14,
                            color: '#111111',
                            position: 'relative',
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderBody = () => {
        return (
            <Form form={form}>
                <div className={classes.justifySpaceBetween} style={{ padding: '0 24px' }}>
                    {renderDate()}
                    {renderTime()}
                </div>
                {renderItemType()}
                {renderAddress()}
                {renderTitle()}
                {renderInput()}
                {renderColumns()}
                {renderList()}
            </Form>
        );
    };

    const renderHeader = () => {
        return (
            <div className={classes.header}>
                <div>
                    <Cross
                        onClick={() => onClose(false)}
                        alt="close"
                        className={classes.closeIcon}
                    />
                    <span>
                        Schedule for Pickup
                    </span>
                </div>
                <Button
                    type="primary"
                    loading={scheduling || loading}
                    onClick={() => handleSubmit()}
                    disabled={isDisabled()}
                >
                    Schedule
                </Button>
            </div>
        );
    };

    return (
        <Drawer
            title={renderHeader()}
            visible
            width="45%"
            className={classes.main}
            onClose={() => onClose(false)}
            closable={false}
        >
            {renderBody()}
        </Drawer>
    );
};
const mapStateToProps = (state: ReduxStore) => {
    return {
        uiTheme: state.uiTheme,
    };
};
const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectRouter: true,
    connectTranslession: true,
};


export default withStyles(schedulePickupStyles)(GenericHoc(hocConfig)(SchedulePickupModal));
