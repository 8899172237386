/* eslint-disable max-len */
import * as React from 'react';
import GenericHoc from './generic-hoc';
import CreateModal from '../create-consignment/create-modal';
// import SingleConsignment from '../create-consignment/single-consignment';
// import DashboardIcon from '../assets/dashboard';
import ConsignmentsIcon from '../../assets/consignments';
import PickupsIcon from '../../assets/pickups';
// import WeightMismatchIcon from '../assets/weigh-mismatch';
import VirtualInventoryIcon from '../../assets/virtual-inventory';
import WalletIcon from '../../assets/wallet';
// import InvoiceIcon from '../assets/invoice';
// import HelpIcon from '../assets/help';
// import PluginIcon from '../../assets/plugin';
// import TutorialIcon from '../../assets/tutorial';
import BulkConsignments from '../create-consignment/bulk-consignments';
import PincodeService from '../pincode-service/service-availability';
import TrackConsignment from '../track-consignment/track-consignment';
import LocationSearch from '../location-search/location-search';
import CreatePickup from '../pickup/create-pickup/create-pickup';
import { Routes, languageCodeMapping } from '../../library/constants';
import {
    getAddressBookRoute,
    getConsignmentsRoute,
    getCurrentPage,
    getDownloadsRoute,
    getHeaderMappingRoute,
    getPickupRoute,
    getVirtualInventoryRoute,
    getWalletLedgerRoute,
    getAdvanceShippingNoteRoute,
    getAnalyticsRoute,
    getRtoDashboardRoute,
    getRemittanceReportsRoute,
    getAdditionalServicesRoute,
    getAPIPlaygroundRoute,
    getUserManagement,
    getSenderManagementRoute,
} from '../../routing/routing-helper';
import { RouteChildrenProps } from 'react-router';
import { CreateActions } from '../create-consignment/create-modal.constants';
import { StylesProps, ThemeType } from '../../theme/jss-types';
import { bindActionCreators } from 'redux';
import { HocOptions } from './generic-hoc.types';
import { loadInitialData } from '../../actions/load-initial-data';
import {
    DeliveredProcedureOutlined,
    DownloadOutlined,
    PlusOutlined,
    SettingOutlined,
    AppstoreOutlined,
    UserOutlined,
    NotificationOutlined,
} from '@ant-design/icons';
import { Pincodes } from '../../library/Types';
import SuccessPopup from '../create-consignment/success-popup';
import moment from 'moment';
import {
    Button, Divider, Menu, message, Select, Tooltip,
} from 'antd';
import { loadConsignments } from '../../actions/consignment-actions';
import { Master } from 'types/master-data-types';
import { printConsignment, getChildList, loginToChildCustomer } from 'network/consignments.api';
import Helper from 'library/Helper';
import { NAVBAR_HEIGHT } from 'library/globals';
import { loadPickups } from 'actions/pickup-actions';
import AddressBookIcon from 'assets/address-book';
import BulkPickups from 'components/pickup/create-pickup/bulk-pickups';
import FailureList from '../consignments/cancellation-failure';
import RTODashboardIcon from 'assets/rto-dashboard-icon';
import ReportsIcon from 'assets/reports';
import ManagementIcon from 'assets/sender-management-icon';
import { useTranslation } from 'react-i18next';
import { setSideMenuCollapsedAction } from 'actions/ui-theme';
import CollapseMenuIcon from 'assets/CollapseMenuIcon';
import UserProfileIcon from 'assets/user-profile';
import SingleHyperlocal from '../create-hyperlocal/single-hyperlocal';
import SingleConsignmentNew from 'components/create-consignment/single-cn-creation/single-consignment-new';
import SingleConsignment from 'components/create-consignment/single-consignment';
import AdditionalServiceIcon from 'assets/additional-services-icon';
import APIIcon from 'assets/api-doc';
import CustomerSupportSVG from 'assets/customer-support';
import { OlvyWidget } from '@olvyhq/widget-react';

// import BulkHyperlocal from '../create-hyperlocal/bulk-hyperlocal';

const styles = (theme: ThemeType) => ({
    main: {
        minHeight: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
        backgroundColor: '#f3eee6',
        '& .ant-menu, .ant-menu-inline-collapsed': {
            backgroundColor: '#f3eee6',
            fontSize: 14,
            width: theme.isSideMenuCollapsed ? 50 : '96%',
            // height: 32,
            fontWeight: 400,
        },
        '& .ant-menu-inline-collapsed .ant-menu-item': {
            justifyContent: 'flex-start',
            alignItems: 'center',
            display: 'flex',
            padding: '2px 8px',
            marginLeft: '8px',
        },
        '& .ant-menu-inline .ant-menu-item': {
            paddingLeft: '10px !important',
        },
        '& .ant-menu-inline-collapsed .ant-menu-item-selected .anticon': {
            // margin: 0,
            // padding: 0,
            height: '36px !important',
        },
        '& .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected': {
            backgroundColor: theme.secondryColor,
            color: '#FFF',
            fontSize: 16,
            width: theme.isSideMenuCollapsed ? 32 : '95%',
            height: 32,
            fontWeight: 700,
            letterSpacing: 0,
            padding: '2px 8px',
            marginLeft: '8px',
        },
        '& .ant-menu-inline .ant-menu-item::after': {
            borderRight: 'none',
        },
        '& .ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input': {
            borderRadius: 16,
            border: '1px solid #999999',
        },
        '& .ant-menu-item': {
            '@media (max-height: 650px)': {
                marginBottom: '0px !important',
                marginTop: '0px !important',
            },
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    collapseButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 24,
        height: 24,
        borderRadius: '4px !important',
        // color: '#FFFFFF',
        backgroundColor: `${theme.primaryColor} !important`,
        color: '#FFFFFF !important',
        border: '1px solid #F2EFE8 !important',
        boxShadow: '0px 1px 2px rgba(89, 89, 89, 0.24) !important',
        zIndex: 501,
        padding: 0,
        '&:hover': {
            backgroundColor: '#FFFFFF !important',
            color: 'black !important',
        },
    },
    divider: {
        width: '100%',
        margin: 0,
        padding: 0,
        marginTop: 10,
    },
    sideMenu: {
        height: '85vh',
        width: '100%',
        '& .ant-menu-item': {
            margin: theme.isSideMenuCollapsed ? 0 : 8,
            fontSize: 14,
            borderRadius: 6,
            color: '#111111',
            borderRight: 'none',
            transition: 'none',
            fontFamily: 'Open Sans',
            fontWeight: 400,
            width: theme.isSideMenuCollapsed ? 32 : '92%',
            height: 32,
        },
        '& .ant-menu-item .ant-menu-item-icon': {
            transition: 'none',
        },
        '& .ant-menu, .ant-menu-inline-collapsed': {
            margin: 0,
        },
    },
    sideMenuBottom: {
        height: theme.isSideMenuCollapsed ? '10vh' : '8vh',
        width: '100%',
        '& .ant-menu-item': {
            width: '90%',
            margin: 8,
            height: 32,
            padding: 0,
            opacity: '0.7',
            borderRadius: 16,
            borderRight: 'none',
            transition: 'none',
            backgroundColor: '#FFFFFF',
        },
        marginBottom: '20px',
    },
    olvySeller: {
        fontWeight: 600,
        background: 'none !important',
        animation: 'olvySeller 0.8s infinite',
        animationDirection: 'alternate',
    },
    bottomText: {
        width: 200,
        color: theme.primaryColor,
        fontFamily: 'Open Sans',
        fontSize: 10,
        fontWeight: 600,
        letterSpacing: 0,
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0 20px 0',
        paddingLeft: '15%',
        cursor: 'pointer',
    },
    buttonText: {
        fontSize: 20,
        ...(theme.isSideMenuCollapsed
            ? {
                marginTop: 2,
                marginLeft: 12,
            }
            : {}),
    },
    selectedLanguageIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 12,
        fontWeight: 500,
        width: '32px !important',
        height: '32px !important',
        borderRadius: '16px !important',
        background: '#FFFFFF',
        border: '1px solid #F2EFE8 !important',
        textTransform: 'capitalize',
        textAlign: 'center',
        marginLeft: 10,
    },
    childDropdown: {
        width: 150,
        '& .ant-select-selection--single': {
            borderRadius: 12,
        },
        '& .ant-select': {
            borderRadius: 12,
        },
    },
    addNewButton: {
        marginLeft: '5%',
        color: `${theme.primaryColor} !important`,
        fontSize: 16,
        fontWeight: 600,
        fontFamily: 'Open Sans',
        boxSizing: 'border-box',
        height: 48,
        border: '1px solid #FCFCFC',
        borderRadius: 24,
        backgroundColor: '#FFFFFF',
        boxShadow: '2px 2px 4px 0 rgba(0,0,0,0.24)',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        '&:hover': {
            border: '1px solid #FCFCFC',
        },
    },
});

const buttonStyle: React.CSSProperties = {
    // marginTop: 10,
    // marginLeft: 18,
    paddingLeft: 12,
    color: '#082E78',
    fontSize: 12,
    fontWeight: 600,
    fontFamily: 'Open Sans',
    boxSizing: 'border-box',
    height: 32,
    border: '1px solid #FCFCFC',
    borderRadius: 24,
    backgroundColor: '#FFFFFF',
    boxShadow: '2px 2px 4px 0 rgba(0,0,0,0.24)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    position: 'relative',
};

interface SideMenuProps extends RouteChildrenProps, StylesProps<ReturnType<typeof styles>> {
    loadconsignments: () => void;
    loadpickups: () => void;
    loadinitial: () => void;
    setSideMenuCollapsed: (isSideMenuCollapsed: boolean) => void;
    master: Master;
    i18n: any;
    isRTL: boolean;
}
// const permissions = {
//     showFinancialsMenu: true,
//     showAnalyticsSection: false,
//     showSettingsSection: true,
//     showUserManagement: false,
//     showWalletSectionCustomerProfile: true,
//     showAddressBook: false,
//     showApiPlayground: true,
//     showRTODashboard: false,
// };
const SideMenu = (props: SideMenuProps) => {
    const {
        classes,
        location,
        history,
        loadconsignments,
        loadpickups,
        loadinitial,
        master,
        i18n,
        setSideMenuCollapsed,
        isRTL,
    } = props;
    const [isMenuCollapsed, setMenuCollapsed] = React.useState<boolean>(
        localStorage.getItem('isMenuCollapsed') === null
            ? false
            : localStorage.getItem('isMenuCollapsed') === true.toString(),
    );
    const [createVisible, setCreateVisibility] = React.useState<boolean>(false);
    const [current, setCurrent] = React.useState<string>(Routes.CONSIGNMENTS);
    const [pincodes, setPincodes] = React.useState<Pincodes>({});
    const [showTooltip, setShowTooltip] = React.useState<boolean>(false);
    const [referenceNumber, setReferenceNumber] = React.useState<string>();
    const [formVisible, setFormVisible] = React.useState<CreateActions | undefined>(
        // CreateActions.SingleConsignment,
    );
    const customerPermissions = master?.customerUserPermissions;
    const customerDetails = master?.CustomerUserDetails;
    const customerProperties = master?.properties;
    const freshdeskJWTSSOSPInitiationUrl = master?.config?.freshdesk_customer_login_sp_request_url;
    let showVirtualInventory = master?.parts_to_show?.virtual_series;
    let showRTODashboard = master?.config?.customer_portal_config?.show_rto_dashboard;
    // const RTODashboardURL = master?.config?.customer_portal_config?.rto_dashboard_url;
    const [successVisible, setSuccessVisible] = React.useState<any>({});
    const phoneRegex = master?.parts_to_show?.remove_regex ? null : master?.config?.phone_regex;
    const storage = window.localStorage;
    const enableAsn = master?.parts_to_show?.enable_advance_shipping_note_feature || false;
    const enable_customer_config = master?.parts_to_show?.enable_customer_config || false;
    const is_international_customer = master?.config?.is_international_customer || false;
    let showFinancialsMenu = master?.config?.customer_portal_config?.show_financials_menu;
    let showAnalyticsSection = true;
    let showSettingsSection = true;
    let showUserManagement = true;
    let showWalletSectionCustomerProfile = true;
    let showAddressBook = true;
    let showApiPlayground = true;
    let showSenderManagement = false;
    let useThirdPartyLabel = false;
    if (customerProperties && enable_customer_config) {
        showRTODashboard = showRTODashboard && customerProperties?.rto_dashboard;
        // TODO: remove this. Hardcoded for now
        showAnalyticsSection = (is_international_customer ? false : customerProperties?.Analytics);
        showVirtualInventory = showVirtualInventory && customerProperties?.virtual_inventory && customerPermissions?.virtual_inventory;
        showSenderManagement = is_international_customer;
        const showHeaderMapping = customerProperties?.header_mapping_settings;
        const showProductCodeMapping = customerProperties?.product_code_mapping_settings;
        const showAccountSetting = customerProperties?.account_settings;
        showWalletSectionCustomerProfile = (customerProperties?.Wallet || customerProperties?.weight_mismatch) && customerPermissions?.wallet_view;
        showSettingsSection = (showHeaderMapping || showProductCodeMapping || showAccountSetting) && customerPermissions?.setting;
        showWalletSectionCustomerProfile = (customerProperties?.Wallet || customerProperties?.weight_mismatch) && customerPermissions?.wallet_view;
        showFinancialsMenu = showFinancialsMenu && (customerProperties?.Invoices || customerProperties?.cod_remittance) && customerPermissions?.financials_view;
        showAddressBook = (customerProperties?.address_book && customerPermissions?.address_book_update);
        showApiPlayground = (customerProperties?.api_playground && customerPermissions?.api_playground);
        useThirdPartyLabel = customerProperties?.use_third_party_label;
        // showUserManagement = true || customerDetails?.is_customer_user;
        showUserManagement = customerDetails?.enable_customer_user && customerPermissions?.user_management;
    }

    const [failures, setFailures] = React.useState<any>({
        visible: false,
        list: [],
    });
    const { t } = useTranslation();

    const [childClients, setChildClients] = React.useState<any>([]);
    const [selectedCustomer, setSelectedCustomer] = React.useState<any>(window.localStorage.getItem('childCustomerCode'));

    const allow_child_login_on_parent = master?.parts_to_show?.allow_child_login_on_parent || false;

    const loadChildCustomer = async () => {
        const result = await getChildList();
        setChildClients(result?.data || []);
        const customer = result?.data?.length ? result.data[0].customer_code
            : window.localStorage.getItem('childCustomerCode');
        setSelectedCustomer(customer);
    };


    const handleCloseForm = () => {
        setFormVisible(undefined);
        setPincodes({});
    };

    React.useEffect(() => {
        const currentPage = getCurrentPage(location);
        setCurrent(currentPage);
        loadChildCustomer();
    }, [location]);

    React.useEffect(() => {
        if (localStorage.getItem('isMenuCollapsed') !== isMenuCollapsed.toString()) {
            localStorage.setItem('isMenuCollapsed', isMenuCollapsed.toString());
        }
        setSideMenuCollapsed(isMenuCollapsed);
    }, [isMenuCollapsed]);

    const getRoute = (action: any) => {
        switch (action.key) {
            case 'pickups': return getPickupRoute();
            case 'virtual-inventory': return getVirtualInventoryRoute();
            case 'downloads': return getDownloadsRoute();
            case 'wallet': return getWalletLedgerRoute();
            case 'settings': return getHeaderMappingRoute();
            case 'asn': return getAdvanceShippingNoteRoute();
            case 'analytics': return getAnalyticsRoute();
            case 'api-playground': return getAPIPlaygroundRoute();
            case 'address-book': return getAddressBookRoute();
            case 'rto-dashboard': return getRtoDashboardRoute();
            case 'reports': return getRemittanceReportsRoute();
            case Routes.ADDITIONAL_SERVICES: return getAdditionalServicesRoute();
            case 'user-management': return getUserManagement();
            case Routes.CONSIGNOR_MANAGEMENT: return getSenderManagementRoute();
            case 'consignments':
            default: return getConsignmentsRoute();
        }
    };
    const handleTabChange = (action: any) => {
        if (action.key === 'RTODashboard') return;
        const route = getRoute(action);
        history.push(route);
    };

    const closeModal = () => {
        setCreateVisibility(false);
    };

    const handleCreate = (e: any, action: CreateActions) => {
        e.stopPropagation();
        setFormVisible(action);
        closeModal();
    };

    const renderCreateModal = () => {
        if (!createVisible) {
            return null;
        }
        return (
            <CreateModal
                onClose={() => closeModal()}
                setPincodes={setPincodes}
                setReferenceNumber={setReferenceNumber}
                pincodes={pincodes}
                referenceNumber={referenceNumber}
                handleCreate={(e: any, action: CreateActions) => handleCreate(e, action)}
                isRTL={isRTL}
            />
        );
    };

    const renderButton = () => {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                width: '100%',
                marginBottom: isMenuCollapsed ? 10 : 20,
            }}
            >
                <Button
                    style={{
                        ...buttonStyle,
                        width: isMenuCollapsed ? 32 : '90%',
                        height: isMenuCollapsed ? 32 : 42,
                        fontSize: 16,
                    }}
                    onClick={() => setCreateVisibility(true)}
                    className={classes.addNewButton}
                    shape="round"
                >
                    <PlusOutlined className={classes.buttonText} />
                    {isMenuCollapsed ? '' : t('new_request')}
                    {renderCreateModal()}
                </Button>
            </div>
        );
    };


    const renderExpandCollapseIcon = () => {
        return (
            <Button
                className={classes.collapseButton}
                onClick={() => {
                    setShowTooltip(false);
                    setMenuCollapsed(!isMenuCollapsed);
                }}
                style={{
                    transform: isMenuCollapsed ? '' : 'scaleX(-1)',
                    transition: 'transform 0.3s',
                    // position: 'absolute',
                    ...(
                        isRTL
                            ? { right: isMenuCollapsed ? 40 : 190 }
                            : { left: isMenuCollapsed ? 40 : 190 }
                    ),
                    top: 50,
                }}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
            >
                { showTooltip
                    ? (
                        <Tooltip
                            title={isMenuCollapsed ? t('Expand Menu') : t('Collapse Menu')}
                            visible={showTooltip}
                        >
                            <CollapseMenuIcon width="12px" height="9px" />
                        </Tooltip>
                    ) : (
                        <CollapseMenuIcon width="12px" height="9px" />
                    )}
            </Button>
        );
    };

    const handleChildCustomerChange = async (val:any) => {
        const parentCode = storage.getItem('userCode') || null;
        storage.setItem('isChildCustomer', 'false');
        // if customer is child customer , call login to child customer api
        if (val !== parentCode) {
            const login_obj = {
                customer_code: val,
                parent_customer_id: storage.getItem('userId'),
            };
            storage.setItem('isChildCustomer', 'true');
            const res = await loginToChildCustomer(login_obj);
            if (res.isSuccess) {
                if (res?.data?.Customer) {
                    storage.setItem('childCustomerId', res.data.Customer.id);
                    storage.setItem('childCustomerCode', res.data.Customer.code);
                    storage.setItem('childCustomerUserId', res?.data?.CustomerUserDetails?.id);
                }
                if (res?.data?.access_token) {
                    storage.setItem('childAccessToken', res.data.access_token.id);
                }
                loadinitial();
                loadconsignments();
            } else {
                message.error(res.errorMessage);
            }
        } else {
            const userId = storage.getItem('userId') || '';
            const token = storage.getItem('authToken') || '';
            const userCode = storage.getItem('userCode') || '';
            const customerUserId = storage.getItem('customerUserId') || '';
            storage.setItem('isChildCustomer', 'false');
            storage.setItem('childCustomerId', userId);
            storage.setItem('childCustomerCode', userCode);
            storage.setItem('childAccessToken', token);
            storage.setItem('childCustomerUserId', customerUserId);
            loadinitial();
            loadconsignments();
        }
    };

    const renderDropdown = () => {
        if (!allow_child_login_on_parent) {
            return null;
        }
        const optionList = childClients?.map((item: any) => {
            return {
                value: item.customer_code,
                label: item.customer_name,
            };
        });
        if (isMenuCollapsed) {
            return (
                <Menu.Item
                    key="customerSelected"
                    icon={<UserProfileIcon />}
                    style={{
                        backgroundColor: 'white',
                        width: 32,
                        height: 32,
                        borderRadius: 16,
                        paddingTop: 5,
                        marginLeft: 8,
                        paddingLeft: 8,
                    }}
                >
                    {selectedCustomer}
                </Menu.Item>
            );
        }
        return (
            <div style={{ margin: '0 20px 20px 20px', borderRadius: 4 }}>
                <Select
                    placeholder="Select Account"
                    dropdownMatchSelectWidth={false}
                    className={classes.childDropdown}
                    defaultValue={selectedCustomer}
                    onChange={handleChildCustomerChange}
                    showSearch
                    allowClear
                    options={optionList}
                    filterOption={(query, option: any) => {
                        return option?.label?.toUpperCase()?.includes(query.toUpperCase());
                    }}
                />
            </div>
        );
    };

    const handleCreation = (action: CreateActions, data: any) => {
        setFormVisible(undefined);
        setSuccessVisible({
            action,
            data,
            creationTime: moment().format('DD MMM YYYY | hh:mm A'),
        });
    };

    const renderSingleConsignment = () => {
        if (formVisible !== CreateActions.SingleConsignment && formVisible !== CreateActions.FTLHyperlocal) {
            return null;
        }
        const isConfigEnabled = master.config?.customer_portal_order_creation_config?.is_enabled;
        return (
            isConfigEnabled
                ? (
                    <SingleConsignmentNew
                        config={master}
                        onClose={handleCloseForm}
                        setSuccess={(data: any) => {
                            handleCreation(CreateActions.SingleConsignment, data);
                        }}
                        loadconsignments={loadconsignments}
                        isRTL={isRTL}
                        actionType={formVisible}
                    />
                )
                : (
                    <SingleConsignment
                        config={master}
                        onClose={handleCloseForm}
                        setSuccess={(data: any) => {
                            handleCreation(CreateActions.SingleConsignment, data);
                        }}
                        loadconsignments={loadconsignments}
                        actionType={formVisible}
                        isRTL={isRTL}
                    />
                )
        );
    };

    const renderBulkConsignment = () => {
        if (formVisible !== CreateActions.BulkConsignment) {
            return null;
        }
        return (
            <BulkConsignments
                onClose={handleCloseForm}
                config={{
                    name: t('add_consignment'),
                    uploadName: t('consignments'),
                }}
                allowInternational={master?.parts_to_show?.allow_international_booking || false}
                showServiceTypeOption={
                    master?.config?.customer_portal_config?.download_static_international_sample_file || false
                }
                itemArrayInDomestic={master?.parts_to_show?.enable_piece_items_detail || false}
            />
        );
    };

    const renderPincodeService = () => {
        if (formVisible !== CreateActions.PincodeAvailability) {
            return null;
        }
        return (
            <PincodeService
                pincodes={pincodes}
                onClose={handleCloseForm}
            />
        );
    };

    const renderTrack = () => {
        if (formVisible !== CreateActions.Track) {
            return null;
        }
        return (
            <TrackConsignment
                referenceNumber={referenceNumber}
                onClose={handleCloseForm}
            />
        );
    };

    const renderLocationSearch = () => {
        if (formVisible !== CreateActions.LocationSearch) {
            return null;
        }
        return (
            <LocationSearch
                onClose={handleCloseForm}
            />
        );
    };

    const renderSinglePickup = () => {
        if (formVisible !== CreateActions.SinglePickup) {
            return null;
        }
        return (
            <CreatePickup
                onClose={handleCloseForm}
                loadpickups={loadpickups}
                setSuccess={(data: any) => {
                    setFormVisible(undefined);
                    message.success(`Pickup Created, PickupId = ${data.pickupId}`);
                }}
                phoneRegex={phoneRegex}
                config={master?.config}
            />
        );
    };

    const renderBulkPickup = () => {
        if (formVisible !== CreateActions.BulkPickup) {
            return null;
        }
        return (
            <BulkPickups
                onClose={handleCloseForm}
                config={{
                    name: t('add_bulk_pickup'),
                    uploadName: t('bulk_pickup'),
                }}
                success={() => {
                    loadpickups();
                    history.push('/pickups');
                }}
                setFailure={(failureList: any) => {
                    setFormVisible(undefined);
                    setFailures({
                        visible: true,
                        list: failureList,
                        referenceNumberTitle: 'row',
                    });
                }}
            />
        );
    };

    const handlePrintLabel = (fileBuffer: any, fileName: string) => {
        if (fileBuffer.isSuccess) {
            const name = fileBuffer.filename || fileName;
            Helper.downloadFileData(
                fileBuffer.data,
                name,
                true,
                'pdf',
            );
            message.success('Success');
        } else {
            message.error('Failed to generate PDF!');
        }
    };

    const handlePrint = async (referenceNumbers: any, isSmall: boolean, fileName: string) => {
        const fileBuffer = await printConsignment({
            isSmall,
            referenceNumbers,
            useThirdPartyLabel,
        });
        handlePrintLabel(fileBuffer, fileName);
    };

    const handleProtipActions = (action: string) => {
        if (action === 'view') {
            setSuccessVisible({});
            history.push(getConsignmentsRoute());
            loadconsignments();
        }
        if (action === 'print') {
            const refNumber = successVisible.data.referenceNumber;
            handlePrint([refNumber], true, `Shipping_Label_${refNumber}.pdf`);
        }
        if (action === 'close') {
            setSuccessVisible({});
        }
        if (action === 'addSuppServices') {
            setSuccessVisible({});
            localStorage.setItem('supplementaryServiceConsignmentReferenceNumber', successVisible.data.referenceNumber);
            history.push(getAdditionalServicesRoute());
        }
    };

    const renderSuccess = () => {
        if (!successVisible.action) {
            return null;
        }
        return (
            <SuccessPopup
                // successArray={[1, 2]}
                // failureArray={[1, 2]}
                action={successVisible.action}
                data={successVisible.data}
                creationTime={successVisible.creationTime}
                handleAction={handleProtipActions}
            />
        );
    };

    const renderSingleHyperlocal = () => {
        if (formVisible !== CreateActions.SingleHyperlocal) {
            return null;
        }
        return (
            <SingleHyperlocal
                // config={master}
                onClose={handleCloseForm}
                visible
                // setSuccess={(data: any) => {
                //     handleCreation(CreateActions.SingleHyperlocal, data);
                // }}
                loadconsignments={loadconsignments}
            />
        );
    };

    const renderBulkHyperlocal = () => {
        if (formVisible !== CreateActions.BulkHyperlocal) {
            return null;
        }
        return null;
        // return (
        //     <BulkHyperlocal
        //         onClose={handleCloseForm}
        //         config={{
        //             name: t('add_consignment'),
        //             uploadName: t('consignments'),
        //         }}
        //         allowInternational={master?.parts_to_show?.allow_international_booking || false}
        //         showServiceTypeOption={
        //             master?.config?.customer_portal_config?.download_static_international_sample_file || false
        //         }
        //         itemArrayInDomestic={master?.parts_to_show?.enable_piece_items_detail || false}
        //     />
        // );
    };

    const renderForms = () => {
        if (!formVisible) {
            return null;
        }
        return (
            <>
                {renderSingleHyperlocal()}
                {renderBulkHyperlocal()}
                {renderSingleConsignment()}
                {renderBulkConsignment()}
                {renderSinglePickup()}
                {renderBulkPickup()}
                {renderPincodeService()}
                {renderTrack()}
                {renderLocationSearch()}
            </>
        );
    };

    // const openWindowWithPostRequest = () => {
    //     const customerCode = storage.getItem('userCode');
    //     if (!RTODashboardURL || !customerCode) {
    //         return;
    //     }
    //     const winName = 'RTO Dashboard';
    //     const winURL = `${RTODashboardURL}?custcode=${customerCode}`;
    //     const windowoption = 'resizable=yes,height=600,width=800,location=0,menubar=0,scrollbars=1';
    //     const form = document.createElement('form');
    //     form.setAttribute('method', 'post');
    //     form.setAttribute('action', winURL);
    //     form.setAttribute('target', winName);
    //     document.body.appendChild(form);
    //     window.open('', winName, windowoption);
    //     form.target = winName;
    //     form.submit();
    //     document.body.removeChild(form);
    // };
    const menuItemList = [{
        key: Routes.CONSIGNMENTS,
        icon: <ConsignmentsIcon />,
        label: t('consignments'),
        is_visible: customerPermissions?.consignment_ops,
    }, {
        key: Routes.ADDITIONAL_SERVICES,
        icon: <AdditionalServiceIcon />,
        label: t('supplementary_services'),
        is_visible: master?.config?.customer_portal_config?.enable_supplementary_services_module,
    }, {
        key: Routes.PICKUPS,
        icon: <PickupsIcon />,
        label: t('pickups'),
        is_visible: customerPermissions?.pickup_ops,
    }, {
        key: Routes.ADDRESS_BOOK,
        icon: <AddressBookIcon />,
        label: t('address_book'),
        is_visible: showAddressBook,
    }, {
        key: Routes.VIRTUAL_INVENTORY,
        icon: <VirtualInventoryIcon />,
        label: t('virtual_inventory'),
        is_visible: showVirtualInventory,
    }, {
        key: Routes.DOWNLOADS,
        icon: <DownloadOutlined />,
        label: t('downloads'),
        is_visible: true,
    }, {
        key: Routes.ANALYTICS,
        icon: <AppstoreOutlined />,
        label: t('Dashboard'),
        is_visible: showAnalyticsSection,
    }, {
        key: Routes.WALLET,
        icon: <WalletIcon />,
        label: t('wallet'),
        is_visible: master?.Customer?.cnc_flag && showWalletSectionCustomerProfile,
    }, {
        key: Routes.REPORT,
        icon: <ReportsIcon />,
        label: t('Financials'),
        is_visible: (showFinancialsMenu && (master?.Customer?.consignee_type === 'B2C' || !master?.Customer?.is_cpdp)),
    }, {
        key: Routes.SETTINGS,
        icon: <SettingOutlined />,
        label: t('settings'),
        is_visible: showSettingsSection,
    }, {
        key: Routes.USER_MANAGEMENT,
        icon: <UserOutlined />,
        label: t('User Management'),
        is_visible: showUserManagement,
    }, {
        key: Routes.RTO_DASHBOARD,
        icon: <RTODashboardIcon />,
        label: t('RTO Dashboard'),
        is_visible: showRTODashboard,
    }, {
        key: Routes.ASN,
        icon: <WalletIcon />,
        label: t('ASN'),
        is_visible: enableAsn,
    }, {
        key: Routes.API_PLAYGROUND,
        icon: <APIIcon />,
        label: t('API Playground'),
        is_visible: showApiPlayground,
    }, {
        key: Routes.CONSIGNOR_MANAGEMENT,
        icon: <ManagementIcon />,
        label: t('Consignor Admin'),
        is_visible: showSenderManagement,
    }];

    const renderMenu = () => {
        const filteredMenuItemList = menuItemList.filter((item) => item.is_visible);

        let topMenuItem = master?.config?.top_menu_item?.filter(Helper.onlyUnique);

        if (!Helper.isValidArray(topMenuItem)) {
            topMenuItem = [];
        }
        const topmenuItems = topMenuItem?.map((item) => item.toLowerCase()) || [];

        let priorityMenuItems = topmenuItems?.map((item) => {
            const menuItem = filteredMenuItemList.find((menu) => menu.key === item);
            return menuItem;
        }) || [];
        priorityMenuItems = priorityMenuItems.filter((item) => item);
        const remainingMenuItems = filteredMenuItemList.filter((item) => !topmenuItems?.includes(item.key)) || [];

        const finalmenuItemList = [...priorityMenuItems, ...remainingMenuItems];

        return (
            <>
                <Divider type="horizontal" className={classes.divider} />
                {finalmenuItemList.map((item: any) => {
                    return (
                        <Menu.Item
                            key={item.key}
                            icon={item.icon}
                        >
                            {item.label}
                        </Menu.Item>
                    );
                })}
                {
                    freshdeskJWTSSOSPInitiationUrl && (
                        <Menu.Item
                            onClick={() => {
                                window.open(freshdeskJWTSSOSPInitiationUrl);
                            }}
                            icon={<CustomerSupportSVG />}
                        >
                            {t('Customer Support')}
                        </Menu.Item>
                    )
                }
            </>
        );
    };

    const handleLanguageChange = (val: any) => {
        i18n.changeLanguage(val, (err: any) => {
            if (err) {
                message.error(err);
                return;
            }
            message.success('Language changed successfully');
            window.location.reload();
        });
    };

    const renderBottomMenu = () => {
        const oldBaseURL = master?.config?.customer_portal_config?.old_cp_base_url;
        const show_old_portal_link = master?.config?.customer_portal_config?.show_old_portal_link;
        let languageList: any = [];
        const { config } = master;
        const { customer_portal_ops_config, available_language_list } = config;
        const isLanguageChangeAllowed = customer_portal_ops_config?.language_config?.config_value?.is_language_change_allowed
         || false;
        if (customer_portal_ops_config?.language_config?.config_value) {
            const allowed_language = customer_portal_ops_config.language_config
                ?.config_value?.allowed_language_list_customer_portal || [];
            if (Array.isArray(available_language_list) && Array.isArray(allowed_language)
                 && available_language_list.length > 0 && allowed_language.length > 0) {
                languageList = available_language_list
                    .filter((item: any) => allowed_language.includes(item.language_name));
            }
        }
        return (
            <>
                <OlvyWidget
                    config={{ workspaceAlias: 'shipsy' }}
                    targetElement={(
                        <Menu.Item
                            className={classes.olvySeller}
                            id="olvy-seller"
                            icon={<NotificationOutlined />}
                        >
                            What&apos;s New!
                        </Menu.Item>
                    )}
                />
                {
                    isLanguageChangeAllowed
                    && (
                        <div>
                            {
                                isMenuCollapsed ? (
                                    <div className={classes.selectedLanguageIcon}>
                                        {i18n.language}
                                    </div>
                                ) : (
                                    <Select
                                        placeholder="Translate x"
                                        placement="topRight"
                                        defaultValue={languageCodeMapping[i18n.language].prettyName}
                                        onChange={handleLanguageChange}
                                        style={{
                                            width: '150px',
                                            borderRadius: '4px',
                                            marginLeft: '5%',
                                            marginRight: '5%',
                                        }}
                                    >
                                        {languageList?.map((item: any) => {
                                            return (
                                                <Select.Option
                                                    key={item.key}
                                                    value={item.key}
                                                >
                                                    {item.language_name}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                )
                            }
                        </div>
                    )
                }
                {show_old_portal_link && (
                    <Menu.Item
                        style={{
                            color: '#262626',
                            fontSize: 10,
                            fontWeight: 600,
                            letterSpacing: 0,
                        }}
                        key={Routes.PLUGIN}
                        icon={<DeliveredProcedureOutlined />}
                        onClick={() => Helper.redirectToOldPortal(oldBaseURL)}
                    >
                        {t('switch_to_old_portal')}
                    </Menu.Item>
                )}
                {/* <Menu.Item key={Routes.PLUGIN} icon={<PluginIcon />}>
                Plugin
            </Menu.Item>
            <Menu.Item key={Routes.TUTORIAL} icon={<TutorialIcon />}>
                Tutorial Video
            </Menu.Item> */}
            </>
        );
    };

    const renderFailure = () => {
        if (!failures.visible || !failures?.list?.length) {
            return null;
        }
        return (
            <FailureList
                failureList={failures.list}
                onClose={() => setFailures({ visible: false })}
                referenceNumberTitle={failures?.referenceNumberTitle}
            />
        );
    };

    if (master.fetchingMaster) {
        return null;
    }

    return (
        <div className={classes.main} style={{ width: isMenuCollapsed ? 48 : 200 }}>
            {renderExpandCollapseIcon()}
            <Menu
                style={{ overflowY: 'auto', overflowX: 'hidden' }}
                mode="inline"
                selectable
                theme="light"
                selectedKeys={[current]}
                className={classes.sideMenu}
                onClick={handleTabChange}
                inlineCollapsed={isMenuCollapsed}
            >
                {renderButton()}
                {renderDropdown()}
                {renderMenu()}
            </Menu>
            <Menu
                mode="inline"
                selectable
                selectedKeys={[]}
                theme="light"
                className={classes.sideMenuBottom}
                inlineCollapsed={isMenuCollapsed}
            >
                {renderBottomMenu()}
            </Menu>
            {renderForms()}
            {renderSuccess()}
            {renderFailure()}
        </div>
    );
};

const mapStateToProps = (state: any) => {
    const { master, navigation, uiTheme } = state;
    return {
        master,
        navigation,
        isRTL: uiTheme?.isRTL,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    const actions = {
        loadconsignments: loadConsignments,
        loadpickups: loadPickups,
        loadinitial: loadInitialData,
        setSideMenuCollapsed: setSideMenuCollapsedAction,
    };
    return bindActionCreators(actions, dispatch);
};

const hocConfig: HocOptions = {
    connectRouter: true,
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
    connectRedux: {
        useRedux: true,
        mapStateToProps,
        mapDispatchToProps,
    },
    connectTranslession: true,
};

export default GenericHoc(hocConfig)(SideMenu);
