/* eslint-disable @typescript-eslint/no-unused-expressions */
import * as React from 'react';
import { StylesProps } from '../../../theme/jss-types';
import { FormField, InputTypes } from '../create-consignment.types';
import { formFields } from '../create-modal.constants';
import { stepFourStyles } from '../single-consignment.styles';
import PaymentIcon from '../single-consignment-icons/payment';
import { ReduxStore } from '../../../reducers/redux.types';
import { HocOptions } from 'components/common/generic-hoc.types';
import GenericHoc from 'components/common/generic-hoc';
import { formRules } from 'library/constants';
import { DownloadOutlined } from '@ant-design/icons';
import AddressIcon from '../single-consignment-icons/address';
import ContentsIcon from '../single-consignment-icons/content';
import ServicesIcon from '../single-consignment-icons/services';
import PrimaryIcon from '../single-consignment-icons/primary';
import {
    Alert,
    Button,
    DatePicker,
    Form,
    FormInstance,
    Input,
    message,
    Radio,
    Select,
} from 'antd';
import moment from 'moment';
import Loader from 'components/common/Loader';
import { getCurrencyCodes, getIncoTerms } from 'network/consignments.api';
import { Master, ModeType } from 'types/master-data-types';
import { useTranslation } from 'react-i18next';
import { verifySRFNumber } from 'network/miscellaneous.api';
import Helper from 'library/Helper';

const {
    Amount,
    Mode,
    EwayBillNo,
    InvoiceType,
    InvoiceDate,
    InvoiceNumber,
    IncoTerms,
    // Export_Inv_Date,
    FOB_Value,
    InsuranceValue,
    InsuranceValueCurrency,
    FOB_Value_Currency,
    TotalGSTPaidAmnt,
    TotalGSTPaidCurrency,
    Freight_Cost,
    Freight_Cost_Currency,
    InFavour,
    EwayBillType,
    EwayBillNumber,
    TransactionType,
    InwardTransactionSubTypes,
    OutwardTransactionSubTypes,
    DocumentTypes,
    TransportationModes,
    DocumentDate,
    ApproxDistance,
    DocumentNumber,
    SenderName,
    SenderAddressLineOne,
    SenderAddressLineTwo,
    SenderPincode,
    SenderCity,
    SenderState,
    SenderCountry,
    SenderPhoneNo,
    SenderGSTIN,
    RecieverName,
    RecieverAddressLineOne,
    RecieverAddressLineTwo,
    RecieverPincode,
    RecieverCity,
    RecieverState,
    RecieverCountry,
    RecieverPhoneNo,
    RecieverGSTIN,
    ProductName,
    SRFNumber,
    HsnValue,
    ProductDescription,
    ProductQuantity,
    // ProductInvoiceNumber,
    ProductValue,
    ProductCGST,
    ProductSGST,
    ProductIGST,
    ProductCESS,
    ProductUnit,
    IsCOD,
} = formFields;
interface StepFourProps extends StylesProps<ReturnType<typeof stepFourStyles>> {
    formData: any;
    form: FormInstance;
    fieldsToShowInternational: Record<any, boolean>;
    config: Master;
    currencyListDomestic: string[],
    modeTypes: string[],
    setFormData: any,
    showEwayBill: boolean,
}

const StepFour = (props: StepFourProps) => {
    const {
        classes,
        form,
        formData,
        fieldsToShowInternational,
        config,
        currencyListDomestic,
        modeTypes,
        setFormData,
        showEwayBill,
    } = props;
    const { t, i18n } = useTranslation();
    const [isCOD, setIsCOD] = React.useState<boolean>(false);
    const [inFavour, setInfavour] = React.useState<string>('');
    const [ewayBillType, setEwayBillType] = React.useState(formData?.ewayBillType);
    const [transactionType, setTransactionType] = React.useState<string | null>(null);
    const renderEwayBillOption = formData?.ewayBill?.type || formData?.ewayBillType;
    const dateTime = formData[DocumentDate.key];
    const [validatedSRFNumber, setValidatedSRFNumber] = React.useState<string>('');
    const [isLoding, setIsLoading] = React.useState<boolean>(false);
    const ewayDocumentDate = moment(dateTime).local();
    const formRuleRequired = {
        ...formRules.required,
        message: i18n.exists('required') ? t('required') : 'Required',
    };
    const setChangeData = (key: any, value: any) => {
        setFormData({
            ...formData,
            ewayBill: {
                ...formData.ewayBill,
                transaction: 'outward',
                reasonTransport: '1',
                documentType: 'Tax Invoice',
                invoiceDate: moment().format('dddd, MMMM Do YYYY, h:mm:ss a'),
                [key]: value,
            },
        });
    };
    const setEwayData = (key: any, value: any) => {
        setFormData({
            ...formData,
            ewayBill: {
                type: 'ewaybill_number',
                [key]: value,
            },
        });
    };

    const [loading, setLoading] = React.useState<boolean>(false);
    const [IncoTermsList, setIncoTermsList] = React.useState<any>([]);
    const [currencyList, setCurrencyList] = React.useState<any[]>([]);
    const incoTermsValue = Form.useWatch('incoTerms', form);
    const invoiceTypeValue = Form.useWatch('invoiceType', form);
    const customerPortalConfig = config?.config?.customer_portal_config || {};
    const showSRFRequest = customerPortalConfig?.show_srf_request && !config?.Customer?.is_cpdp;
    const mandatoryFieldNonDocIntl = customerPortalConfig?.mandatory_field_non_document_international || {};
    const mandatoryFieldShipmentCommercial = customerPortalConfig?.mandatory_shipment_commercial || {};
    const codAmountValidation = customerPortalConfig?.restrict_less_than_1_cod_amount || false;
    const itemPartialDelivery = config?.config?.item_partial_delivery;
    const consignmentConfig = config?.config?.customer_portal_consignment_config;

    const prefillData = () => {
        form.setFieldsValue({
            [Amount.key]: formData[Amount.key],
            [Mode.key]: formData[Mode.key],
            ewayBill: {
                [ProductValue.key]: formData.declaredValue,
                [DocumentDate.key]: ewayDocumentDate,
            },
            [SenderName.key]: formData?.srcAddress?.name,
            [SenderAddressLineOne.key]: formData?.srcAddress?.addressLine1,
            [SenderAddressLineTwo.key]: formData?.srcAddress?.addressLine2,
            [SenderPincode.key]: formData?.srcAddress?.pincode,
            [SenderCity.key]: formData?.srcAddress?.cityName,
            [SenderState.key]: formData?.srcAddress?.stateName,
            [SenderCountry.key]: formData?.srcAddress?.countryName,
            [SenderPhoneNo.key]: formData?.srcAddress?.phone,
            [RecieverName.key]: formData?.dstAddress?.name,
            [RecieverAddressLineOne.key]: formData?.dstAddress?.addressLine1,
            [RecieverAddressLineTwo.key]: formData?.dstAddress?.addressLine2,
            [RecieverPincode.key]: formData?.dstAddress?.pincode,
            [RecieverCity.key]: formData?.dstAddress?.cityName,
            [RecieverState.key]: formData?.dstAddress?.stateName,
            [RecieverCountry.key]: formData?.dstAddress?.countryName,
            [RecieverPhoneNo.key]: formData?.dstAddress?.phone,
            [IsCOD.key]: formData[IsCOD.key],
        });
        setIsCOD(formData[IsCOD.key] === 'yes');
        setEwayBillType(renderEwayBillOption);
    };
    const checkDeclaredValueIsInt = (formData.declaredValue && !Number.isNaN(formData.declaredValue));
    const declaredValue = checkDeclaredValueIsInt ? Number(formData.declaredValue) : 0;

    const loadData = async () => {
        setLoading(true);
        const response = await getIncoTerms();
        if (!response.isSuccess) {
            message.error(response.errorMessage);
        } else {
            setIncoTermsList(
                response?.data.map((incoTerm: any) => {
                    return {
                        label: incoTerm,
                        value: incoTerm,
                    };
                }),
            );
        }
        setLoading(false);
    };

    const loadCurrencyCodes = async () => {
        setLoading(true);
        const response = await getCurrencyCodes();
        setCurrencyList(response?.data);
        setLoading(false);
    };

    React.useEffect(() => {
        loadData();
        loadCurrencyCodes();
        prefillData();
    }, []);

    const isRequired = (key: string) => {
        let required = false;
        if (formData.consignmentCategory === 'international') {
            if (formData.courierType === 'non-document') {
                required = required || mandatoryFieldNonDocIntl[key];
            }
            if (formData.shipmentPurpose === 'COMMERCIAL') {
                required = required || mandatoryFieldShipmentCommercial[key];
            }
        }
        return required;
    };

    const renderRowDescription = (FieldIcon: React.ElementType, label: string) => {
        return (
            <div className={classes.boxTitle}>
                <div className={classes.boxIcon}>
                    <FieldIcon className={classes.boxIcon} />
                </div>
                <span>{label}</span>
            </div>
        );
    };
    const renderSelect = (field: FormField) => {
        const options = modeTypes.map((mode) => {
            return (
                <Select.Option key={mode} value={mode}>
                    {t(mode)}
                </Select.Option>
            );
        });
        return (
            <div className={classes.amount}>
                <span className={classes.label}>{field.label === 'Mode' ? t('codCollectionMode') : field.label}</span>
                <Form.Item
                    name={field.key}
                    initialValue={field.defaultValue}
                    valuePropName={field.valuePropName}
                    className={classes.amount}
                >
                    <Select
                        allowClear
                        placeholder={t('mode_placeholder')}
                        style={{ width: field.width }}
                    >
                        {options}
                    </Select>
                </Form.Item>
            </div>
        );
    };
    const renderCurrency = () => {
        if (formData.consignmentCategory === 'international') return null;
        const options = currencyListDomestic.map((currency) => {
            return (
                <Select.Option key={currency} value={currency}>
                    {currency}
                </Select.Option>
            );
        });
        return (
            <Form.Item
                name="currency"
                valuePropName={formData.currency}
                initialValue={formData.currency}
            >
                <Select
                    dropdownMatchSelectWidth={false}
                    placeholder={formData.currency}
                >
                    {options}
                </Select>
            </Form.Item>
        );
    };

    const renderInFavour = (field: FormField) => {
        return (
            <div className={classes.inFavour}>
                <span className={classes.label}>{t(field.key)}</span>
                <Form.Item
                    name={field.key}
                    initialValue={field.defaultValue}
                    valuePropName={field.valuePropName}
                    className={classes.inFavour}
                >
                    <Input
                        type="text"
                        style={{ width: field.width }}
                        placeholder={t('in_favour_placeholder')}
                        size="small"
                        value={inFavour}
                        onChange={(e) => {
                            setInfavour(e.target.value);
                        }}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderNumber = (field: FormField) => {
        return (
            <div className={classes.amount}>
                <span className={classes.label}>{t(field.key)}</span>
                <Form.Item
                    name={field.key}
                    initialValue={field.defaultValue}
                    valuePropName={field.valuePropName}
                    className={classes.amount}
                    rules={[{
                        validator: (rule, value, cb) => {
                            if (value < 0) {
                                return cb(t('negative_amount_error'));
                            }
                            if (codAmountValidation && value > 0 && value < 1) {
                                return cb(t('value_less_than_one_error'));
                            }
                            if (value > 20000) {
                                return cb(t('cod_amount_limit_error'));
                            }
                            return cb();
                        },
                    }]}
                >
                    <Input
                        disabled={itemPartialDelivery && !consignmentConfig?.allow_multiple_piece_dimensions}
                        type="number"
                        style={{ width: field.width }}
                        placeholder={t('cod_amount_placeholder')}
                        size="small"
                        onChange={(e) => {
                            if (!Number(e.target.value)) {
                                form.resetFields([Mode.key]);
                            }
                        }}
                        addonAfter={renderCurrency()}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderFormItem = (field: FormField | undefined) => {
        if (!field) {
            return (
                <div style={{ padding: '10px 5%', width: '50%', display: 'inline-block' }} />
            );
        }
        if (!fieldsToShowInternational[field.key]) return null;
        switch (field.type) {
            case InputTypes.Input:
                return (
                    <div style={{ padding: '10px 5%', width: '50%', display: 'inline-block' }}>
                        <div
                            className={classes.title}
                            style={{ width: '100%', margin: '5px 0' }}
                        >
                            {field.label}
                            {isRequired(field.key) ? '*' : ''}
                        </div>
                        <Form.Item
                            name={field.key}
                            initialValue={field.defaultValue}
                            valuePropName={field.valuePropName}
                            rules={isRequired(field.key) ? [formRuleRequired] : undefined}
                        >
                            <Input
                                type={field.type}
                                min={0}
                                placeholder={field.placeholder}
                                style={{ width: field.width }}
                                value={field.defaultValue?.toString()}
                                width={field.width}
                            />
                        </Form.Item>
                    </div>
                );
            case InputTypes.Date:
                return (
                    <div style={{ padding: '10px 5%', width: '50%', display: 'inline-block' }}>
                        <div
                            className={classes.title}
                            style={{ width: '100%', margin: '5px 0' }}
                        >
                            {field.label}
                            {isRequired(field.key) ? '*' : ''}
                        </div>
                        <Form.Item
                            name={field.key}
                            initialValue={field.defaultValue}
                            valuePropName={field.valuePropName}
                            rules={isRequired(field.key) ? [formRuleRequired] : undefined}
                        >
                            <DatePicker
                                style={{ width: field.width }}
                            />
                        </Form.Item>
                    </div>
                );
            case InputTypes.Radio:
                return (
                    <div style={{ padding: '10px 5%', width: '50%', display: 'inline-block' }}>
                        <div
                            className={classes.title}
                            style={{ width: '100%', margin: '5px 0' }}
                        >
                            {field.label}
                            {isRequired(field.key) ? '*' : ''}
                        </div>
                        <Form.Item
                            name={field.key}
                            valuePropName={field.valuePropName}
                            rules={isRequired(field.key) ? [formRuleRequired] : undefined}
                        >
                            <Radio.Group
                                style={{ width: field.width }}
                                options={field.options}
                            />
                        </Form.Item>
                    </div>
                );
            case InputTypes.Select:
                return (
                    <div style={{ padding: '10px 5%', width: '50%', display: 'inline-block' }}>
                        <div
                            className={classes.title}
                            style={{ width: '100%', margin: '5px 0' }}
                        >
                            {field.label}
                            {isRequired(field.key) ? '*' : ''}
                        </div>
                        <Form.Item
                            name={field.key}
                            initialValue={field.defaultValue}
                            valuePropName={field.valuePropName}
                            rules={isRequired(field.key) ? [formRuleRequired] : undefined}
                        >
                            <Select
                                style={{ width: field.width }}
                                options={field.options || []}
                                placeholder={field.placeholder}
                            />
                        </Form.Item>
                    </div>
                );
            default:
                return null;
        }
    };

    const renderCurrencyUnit = (unitField: FormField) => {
        let options = unitField.options || [];
        if (currencyList.length > 0) {
            options = currencyList.map((currency) => {
                return (
                    {
                        // label: currency.currency_name,
                        label: currency.currency_code,
                        value: currency.currency_code,
                    }
                );
            });
        }
        options.sort((a, b) => ((a.label > b.label) ? 1 : -1));
        return (
            <Form.Item
                name={unitField.key}
                key={unitField.key}
                initialValue={unitField.defaultValue}
            >
                <Select
                    disabled={unitField.disabled}
                    placeholder={unitField.placeholder}
                    options={options}
                    showSearch
                    style={{ width: unitField.width }}
                    showArrow={false}
                    dropdownMatchSelectWidth={false}
                />
            </Form.Item>
        );
    };

    const renderCurrencyField = (field: FormField, unitField: FormField) => {
        let required = false;
        if (field === FOB_Value && incoTermsValue === 'FOB') required = true;
        if (field === TotalGSTPaidAmnt && invoiceTypeValue === 'GST') required = true;
        if (field === InsuranceValue && incoTermsValue === 'CIF') required = true;
        return (
            <div style={{ padding: '10px 5%', width: '50%', display: 'inline-block' }}>
                <div
                    className={classes.title}
                    style={{ width: '100%', margin: '5px 0' }}
                >
                    {field.label}
                    {required ? '*' : null}
                </div>
                <Form.Item
                    name={field.key}
                    initialValue={field.defaultValue}
                    valuePropName={field.valuePropName}
                    rules={[
                        {
                            required,
                            message: t('required'),
                        },
                    ]}
                    dependencies={[IncoTerms.key, InvoiceType.key]}
                >
                    <Input
                        type="number"
                        min={0}
                        placeholder={field.placeholder}
                        style={{ width: field.width }}
                        addonAfter={renderCurrencyUnit(unitField)}
                    />
                </Form.Item>
            </div>
        );
    };

    const handleVerifySRFNumber = async () => {
        const srfNumber = form.getFieldValue(SRFNumber.key);
        if (!srfNumber) {
            message.error('Please enter SRF Number First');
            return;
        }
        let volWeight = 0;
        if (formData.courierType === 'non-document') {
            Object.keys(formData?.pieces || {})?.forEach((key: any) => {
                const piece: any = formData?.pieces[key];
                volWeight += piece?.volumetricWeight;
            });
        }
        setIsLoading(true);
        const response = await verifySRFNumber({
            SRFNO: srfNumber,
            CONSUMEBY: 'FM',
            ACT_WGHT: `${Math.max(volWeight,
                Number(formData?.actualWeight || 0),
                Number(Helper.converttoKilogram(Number(formData.weight), formData.weightUnit) || 0))}`,
            DOC_TYPE: formData.courierType === 'non-document' ? 'N' : 'D',
        });
        if (response.isSuccess) {
            if (response?.data?.VALID?.toLowerCase() === 'yes') {
                if (response?.data?.SRFDETAILS?.[0]?.Customer_Code !== formData?.childClient.split('+')[0]) {
                    setValidatedSRFNumber(
                        'The SRF  Number customer code does not match.',
                    );
                } else if (response?.data?.SRFDETAILS?.[0]?.Destination_Country_Name?.toLowerCase()
                    !== formData?.dstAddress?.countryName?.toLowerCase()) {
                    setValidatedSRFNumber(
                        'The SRF Number country name does not match.',
                    );
                } else if (response?.data?.SRFDETAILS?.[0]?.Appr_Prod_Name !== formData?.serviceType) {
                    setValidatedSRFNumber(
                        'The SRF Number service type does not match.',
                    );
                } else {
                    setValidatedSRFNumber('Verified');
                }
            }
            if (response?.data?.VALID?.toLowerCase() === 'no') setValidatedSRFNumber(response?.data?.REASON);
        } else {
            setValidatedSRFNumber(response.data);
        }
        setIsLoading(false);
    };

    const renderSRFNumber = () => {
        if (!showSRFRequest) return null;
        return (
            <div style={{ padding: '10px 5%', width: '50%', display: 'inline-block' }}>
                <div
                    className={classes.title}
                    style={{ width: '100%', margin: '5px 0' }}
                >
                    {SRFNumber.label}
                    {isRequired(SRFNumber.key) ? '*' : ''}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Form.Item
                            name={SRFNumber.key}
                            valuePropName={SRFNumber.valuePropName}
                            rules={isRequired(SRFNumber.key) ? [formRules.required] : undefined}
                        >
                            <Input
                                type={SRFNumber.type}
                                placeholder={SRFNumber.placeholder}
                                width={SRFNumber.width}
                            />
                        </Form.Item>
                        <Button
                            ghost
                            type="primary"
                            loading={isLoding}
                            style={{ margin: '0 10px' }}
                            onClick={handleVerifySRFNumber}
                        >
                            Apply
                        </Button>
                    </div>
                    {validatedSRFNumber === 'Verified'
                    && (
                        <Alert
                            message="Verified"
                            type="success"
                            style={{ background: 'none', border: 'none' }}
                            showIcon
                        />
                    )}
                    {validatedSRFNumber && validatedSRFNumber !== 'Verified'
                    && (
                        <Alert
                            message={validatedSRFNumber}
                            type="error"
                            style={{ background: 'none', border: 'none' }}
                            showIcon
                        />
                    )}
                </div>
            </div>
        );
    };

    const renderInternational = () => {
        IncoTerms.options = IncoTermsList || [];
        return (
            <div style={{ margin: '10px 0' }}>
                <div className={classes.flexRow}>
                    {renderFormItem(EwayBillNo)}
                    {renderFormItem(IncoTerms)}
                </div>
                <div className={classes.flexRow}>
                    { renderCurrencyField(Freight_Cost, Freight_Cost_Currency) }
                    {renderCurrencyField(FOB_Value, FOB_Value_Currency)}
                </div>
                <div className={classes.flexRow}>
                    {renderFormItem(InvoiceType)}
                    { renderCurrencyField(TotalGSTPaidAmnt, TotalGSTPaidCurrency) }
                </div>
                {/* <div className={classes.flexRow}>
                    {renderFormItem(Export_Inv_Date)}
                </div> */}
                <div className={classes.flexRow}>
                    {renderFormItem(InvoiceDate)}
                    {renderFormItem(InvoiceNumber)}
                </div>
                <div className={classes.flexRow}>
                    {renderSRFNumber()}
                    {renderCurrencyField(InsuranceValue, InsuranceValueCurrency)}
                </div>
            </div>
        );
    };

    const renderCOD = () => {
        return (
            <div style={{ padding: '10px 5%', width: '100%', display: 'inline-block' }}>
                <div
                    className={classes.title}
                    style={{ width: '100%', margin: '5px 0' }}
                >
                    {t(IsCOD.label)}
                </div>
                <Form.Item
                    name={IsCOD.key}
                    valuePropName={IsCOD.valuePropName}
                >
                    <Radio.Group
                        style={{ width: IsCOD.width }}
                        options={IsCOD.options?.map((option) => {
                            return {
                                label: t(option.label),
                                value: option.value,
                            };
                        })}
                        onChange={(e) => {
                            setIsCOD(e.target.value === 'yes');
                        }}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderPaymentForm = () => {
        if (loading) {
            return <Loader zIndex={10} />;
        }
        return (
            <div className={classes.flexColumn} style={{ width: '100%' }}>
                { formData.consignmentCategory === 'international' ? renderInternational() : null }
                { formData.consignmentCategory === 'international' ? <div className={classes.line} /> : null }
                { formData.consignmentCategory === 'international'
                    ? null
                    : (
                        <div>
                            {renderCOD()}
                            {
                                isCOD ? (

                                    <>
                                        <div className={classes.fields}>
                                            {renderNumber(Amount)}
                                        </div>
                                        <div className={classes.fields}>
                                            {renderSelect(Mode)}
                                            {renderInFavour(InFavour)}
                                        </div>
                                    </>
                                ) : null
                            }
                        </div>
                    )}
            </div>
        );
    };

    const renderPayments = () => {
        return (
            <div className={classes.box}>
                {renderRowDescription(PaymentIcon, `${
                    formData.consignmentCategory === 'international' ? t('invoice_details') : t('payment_details')
                }`)}
                {renderPaymentForm()}
            </div>
        );
    };
    const renderEwayBillNumber = () => {
        return (
            <div className={classes.referenceItemEway}>
                <Form.Item
                    name={['ewayBill', EwayBillNumber.key]}
                    className={classes.referenceTitle}
                    initialValue={EwayBillNumber.defaultValue}
                    valuePropName={EwayBillNumber.valuePropName}
                    rules={ewayBillType === 'ewaybill_number' ? [formRuleRequired] : undefined}
                >
                    <Input
                        placeholder={EwayBillNumber.placeholder}
                        style={{ width: EwayBillNumber.width }}
                        onChange={(e) => setEwayData(EwayBillNumber.key, e.target.value)}
                    />
                </Form.Item>
            </div>
        );
    };
    const renderEwayBill = () => {
        if (ewayBillType === 'ewaybill_number') {
            return (
                <div>
                    {renderEwayBillNumber()}
                </div>
            );
        }
        return null;
    };
    const renderDownloadDeclaration = () => {
        if (ewayBillType === 'no_gstn') {
            return (
                // eslint-disable-next-line max-len
                <a href="https://shipsy-public-assets.s3.us-west-2.amazonaws.com/shipsy/sample-downloads/efr/Declaration_Note.docx">
                    <DownloadOutlined
                        size={14}
                        style={{
                            marginRight: 8,
                            fontWeight: 'bold',
                        }}
                    />
                    Download Declaration
                </a>
            );
        }
        return null;
    };
    const renderEwayBillTypes = () => {
        const EwayBillTypeOptions = EwayBillType.options;
        return (
            <div className={classes.referenceItemEway}>
                <div className={classes.referenceTitle}>
                    {EwayBillType.label}
                </div>
                <Form.Item
                    name={['ewayBill', EwayBillType.key]}
                    initialValue={EwayBillType.defaultValue}
                    valuePropName={EwayBillType.valuePropName}
                >
                    <Radio.Group
                        options={EwayBillTypeOptions}
                        onChange={(e) => {
                            setEwayBillType(e.target.value);
                            setEwayData(EwayBillType.key, e.target.value);
                        }}
                        style={{
                            display: 'flex',
                            flexDirection: EwayBillType.direction,
                        }}
                    />
                </Form.Item>
                {renderEwayBill()}
                {renderDownloadDeclaration()}
            </div>
        );
    };
    const renderTransactionType = () => {
        return (
            <div className={classes.referenceItemEway}>
                <div className={classes.referenceTitle}>
                    {TransactionType.label}
                </div>
                <Form.Item
                    name={['ewayBill', TransactionType.key]}
                    initialValue={TransactionType.defaultValue}
                    valuePropName={TransactionType.valuePropName}
                    rules={ewayBillType === 'ewaybill_details' ? [formRuleRequired] : undefined}
                >
                    <Radio.Group
                        options={TransactionType.options}
                        onChange={(e) => {
                            setTransactionType(e.target.value);
                            setChangeData(TransactionType.key, e.target.value);
                        }}
                        style={{
                            display: 'flex',
                            flexDirection: TransactionType.direction,
                        }}
                    />
                </Form.Item>
            </div>
        );
    };
    let transactionTypeOptions = OutwardTransactionSubTypes;
    if (transactionType === 'inward') {
        transactionTypeOptions = InwardTransactionSubTypes;
    }

    const renderSubTransactionType = () => {
        return (
            <div className={classes.formItem}>
                <div className={classes.titleEway}>
                    {transactionType === 'inward' ? InwardTransactionSubTypes.label : OutwardTransactionSubTypes.label}
                </div>
                <Form.Item
                    name={['ewayBill', transactionTypeOptions.key]}
                    className={classes.contentType}
                    initialValue={InwardTransactionSubTypes.defaultValue}
                    valuePropName={InwardTransactionSubTypes.valuePropName}
                    rules={ewayBillType === 'ewaybill_details' ? [formRuleRequired] : undefined}
                >
                    <Select
                        placeholder={transactionType ? InwardTransactionSubTypes.label : OutwardTransactionSubTypes.label}
                        style={{ width: InwardTransactionSubTypes.width }}
                        showSearch
                        allowClear
                        options={transactionTypeOptions.options}
                        onChange={(value) => setChangeData(transactionTypeOptions.key, value)}
                    />
                </Form.Item>
            </div>
        );
    };
    const renderTransportationMode = () => {
        return (
            <div className={classes.formItem}>
                <div className={classes.titleEway}>
                    {TransportationModes.label}
                </div>
                <Form.Item
                    name={['ewayBill', TransportationModes.key]}
                    className={classes.contentType}
                    initialValue={TransportationModes.defaultValue}
                    valuePropName={TransportationModes.valuePropName}
                    rules={ewayBillType === 'ewaybill_details' ? [formRuleRequired] : undefined}
                >
                    <Select
                        placeholder={TransportationModes.label}
                        style={{ width: TransportationModes.width }}
                        showSearch
                        allowClear
                        options={TransportationModes.options}
                        onChange={(value) => setChangeData(TransportationModes.key, value)}
                    />
                </Form.Item>
            </div>
        );
    };
    const renderApproxDistance = () => {
        return (
            <div className={classes.referenceItemEway}>
                <div className={classes.titleEway}>
                    {ApproxDistance.label}
                </div>
                <Form.Item
                    name={['ewayBill', ApproxDistance.key]}
                    className={classes.contentType}
                    initialValue={ApproxDistance.defaultValue}
                    valuePropName={ApproxDistance.valuePropName}
                    rules={ewayBillType === 'ewaybill_details' ? [formRuleRequired] : undefined}
                >
                    <Input
                        placeholder={ApproxDistance.placeholder}
                        style={{ width: ApproxDistance.width }}
                        onChange={(e) => setChangeData(ApproxDistance.key, e.target.value)}
                    />
                </Form.Item>
            </div>
        );
    };
    const renderDocumentNumber = () => {
        return (
            <div className={classes.referenceItemEway}>
                <div className={classes.titleEway}>
                    {DocumentNumber.label}
                </div>
                <Form.Item
                    name={['ewayBill', DocumentNumber.key]}
                    className={classes.contentType}
                    initialValue={DocumentNumber.defaultValue}
                    valuePropName={DocumentNumber.valuePropName}
                    rules={ewayBillType === 'ewaybill_details' ? [formRuleRequired] : undefined}
                >
                    <Input
                        placeholder={DocumentNumber.placeholder}
                        style={{ width: DocumentNumber.width }}
                        onChange={(e) => setChangeData(DocumentNumber.key, e.target.value)}
                    />
                </Form.Item>
            </div>
        );
    };
    const renderDocumentType = () => {
        return (
            <div className={classes.formItem}>
                <div className={classes.titleEway}>
                    {DocumentTypes.label}
                </div>
                <Form.Item
                    name={['ewayBill', DocumentTypes.key]}
                    className={classes.contentType}
                    initialValue={DocumentTypes.defaultValue}
                    valuePropName={DocumentTypes.valuePropName}
                    rules={ewayBillType === 'ewaybill_details' ? [formRuleRequired] : undefined}
                >
                    <Select
                        placeholder={DocumentTypes.label}
                        style={{ width: DocumentTypes.width }}
                        showSearch
                        allowClear
                        options={DocumentTypes.options}
                        onChange={(value) => setChangeData(DocumentTypes.key, value)}
                    />
                </Form.Item>
            </div>
        );
    };
    const renderDocumentDate = () => {
        return (
            <div className={classes.referenceItemEway}>
                <div className={classes.titleEway}>
                    {DocumentDate.label}
                </div>
                <Form.Item
                    name={['ewayBill', DocumentDate.key]}
                    className={classes.referenceTitle}
                    initialValue={DocumentDate.defaultValue}
                    valuePropName={DocumentDate.valuePropName}
                    rules={ewayBillType === 'ewaybill_details' ? [formRuleRequired] : undefined}
                >
                    <DatePicker
                        placeholder={DocumentDate.placeholder}
                        style={{ width: DocumentDate.width }}
                        onChange={(date) => setChangeData(DocumentDate.key, date?.format('dddd, MMMM Do YYYY, h:mm:ss a'))}
                    />
                </Form.Item>
            </div>
        );
    };
    const renderSrcDetails = (param:any) => {
        return (
            <div className={classes.referenceItemEway}>
                <div className={classes.titleEway}>
                    {param.label}
                </div>
                <Form.Item
                    name={param.key}
                    className={classes.referenceTitle}
                    initialValue={param.defaultValue}
                    valuePropName={param.valuePropName}
                >
                    <Input
                        placeholder={param.placeholder}
                        style={{ width: param.width }}
                        disabled
                    />
                </Form.Item>
            </div>
        );
    };
    const renderRecieverDetails = (param:any) => {
        return (
            <div className={classes.referenceItemEway}>
                <div className={classes.titleEway}>
                    {param.label}
                </div>
                <Form.Item
                    name={param.key}
                    className={classes.referenceTitle}
                    initialValue={param.defaultValue}
                    valuePropName={param.valuePropName}
                >
                    <Input
                        placeholder={param.placeholder}
                        style={{ width: param.width }}
                        disabled
                    />
                </Form.Item>
            </div>
        );
    };
    const renderSenderGstIn = () => {
        return (
            <div className={classes.referenceItemEway}>
                <div className={classes.titleEway}>
                    {SenderGSTIN.label}
                </div>
                <Form.Item
                    name={['ewayBill', SenderGSTIN.key]}
                    className={classes.referenceTitle}
                    initialValue={SenderGSTIN.defaultValue}
                    valuePropName={SenderGSTIN.valuePropName}
                    rules={ewayBillType === 'ewaybill_details' ? [formRuleRequired] : undefined}
                >
                    <Input
                        placeholder={SenderGSTIN.placeholder}
                        style={{ width: SenderGSTIN.width }}
                        onChange={(e) => setChangeData(SenderGSTIN.key, e.target.value)}
                    />
                </Form.Item>
            </div>
        );
    };
    const renderRecieverGSTIN = () => {
        return (
            <div className={classes.referenceItemEway}>
                <div className={classes.titleEway}>
                    {RecieverGSTIN.label}
                </div>
                <Form.Item
                    name={['ewayBill', RecieverGSTIN.key]}
                    className={classes.referenceTitle}
                    initialValue={RecieverGSTIN.defaultValue}
                    valuePropName={RecieverGSTIN.valuePropName}
                    rules={ewayBillType === 'ewaybill_details' ? [formRuleRequired] : undefined}
                >
                    <Input
                        placeholder={RecieverGSTIN.placeholder}
                        style={{ width: RecieverGSTIN.width }}
                        onChange={(e) => setChangeData(RecieverGSTIN.key, e.target.value)}
                    />
                </Form.Item>
            </div>
        );
    };
    const renderProductDescription = () => {
        return (
            <div className={classes.referenceItemEway}>
                <div className={classes.titleEway}>
                    {ProductDescription.label}
                </div>
                <Form.Item
                    name={['ewayBill', ProductDescription.key]}
                    className={classes.referenceTitle}
                    initialValue={ProductDescription.defaultValue}
                    valuePropName={ProductDescription.valuePropName}
                >
                    <Input
                        placeholder={ProductDescription.placeholder}
                        style={{ width: ProductDescription.width }}
                        onChange={(e) => setChangeData(RecieverGSTIN.key, e.target.value)}
                    />
                </Form.Item>
            </div>
        );
    };
    const renderProductDetails = (field: FormField) => {
        return (
            <div className={classes.referenceItemEway}>
                <div className={classes.titleEway}>
                    {field.label}
                </div>
                <Form.Item
                    name={['ewayBill', field.key]}
                    className={classes.referenceTitle}
                    initialValue={field.defaultValue}
                    valuePropName={field.valuePropName}
                >
                    <Input
                        placeholder={field.placeholder}
                        style={{ width: field.width }}
                        onChange={(e) => setChangeData(field.key, e.target.value)}
                    />
                </Form.Item>
            </div>
        );
    };
    const renderHSNValue = (field: FormField) => {
        return (
            <div className={classes.referenceItemEway}>
                <div className={classes.titleEway}>
                    {field.label}
                </div>
                <Form.Item
                    name={['ewayBill', field.key]}
                    className={classes.referenceTitle}
                    initialValue={field.defaultValue}
                    valuePropName={field.valuePropName}
                    rules={ewayBillType === 'ewaybill_details' ? [formRuleRequired] : undefined}
                >
                    <Input
                        placeholder={field.placeholder}
                        style={{ width: field.width }}
                        onChange={(e) => setChangeData(field.key, e.target.value)}
                    />
                </Form.Item>
            </div>
        );
    };
    const renderProductValue = (field: FormField) => {
        return (
            <div className={classes.referenceItemEway}>
                <div className={classes.titleEway}>
                    {field.label}
                </div>
                <Form.Item
                    name={['ewayBill', field.key]}
                    className={classes.referenceTitle}
                    initialValue={field.defaultValue}
                    valuePropName={field.valuePropName}
                    rules={ewayBillType === 'ewaybill_details' ? [formRuleRequired] : undefined}
                >
                    <Input
                        placeholder={field.placeholder}
                        style={{ width: field.width }}
                        disabled
                    />
                </Form.Item>
            </div>
        );
    };
    const renderGSTTYPES = (params: any) => {
        return (
            <div className={classes.referenceItemEway}>
                <div className={classes.titleEway}>
                    {params.label}
                </div>
                <Form.Item
                    name={['ewayBill', params.key]}
                    className={classes.referenceTitle}
                    initialValue={params.defaultValue}
                    valuePropName={params.valuePropName}
                    rules={ewayBillType === 'ewaybill_details' ? [formRuleRequired] : undefined}
                >
                    <Input
                        placeholder={params.placeholder}
                        style={{ width: params.width }}
                        onChange={(e) => setChangeData(params.key, e.target.value)}
                    />
                </Form.Item>
            </div>
        );
    };
    const renderTransactionDetails = () => {
        return (
            <div className={classes.boxS}>
                <div>
                    {renderTransactionType()}
                </div>
                <div className={classes.boxS}>
                    <div className={classes.boxRow}>
                        <div>{renderSubTransactionType()}</div>
                        <div>{renderTransportationMode()}</div>
                        <div>{renderApproxDistance()}</div>
                    </div>
                    <div className={classes.boxDummy}>
                        <div>{renderDocumentType()}</div>
                        <div>{renderDocumentNumber()}</div>
                        <div>{renderDocumentDate()}</div>
                    </div>
                </div>
            </div>
        );
    };
    const renderSenderDetails = () => {
        return (
            <div className={classes.boxS}>
                {renderSrcDetails(SenderName)}
                <div className={classes.boxRow}>
                    {renderSrcDetails(SenderAddressLineOne)}
                    {renderSrcDetails(SenderAddressLineTwo)}
                </div>
                <div className={classes.boxRow}>
                    {renderSrcDetails(SenderPincode)}
                    {renderSrcDetails(SenderCity)}
                    {renderSrcDetails(SenderState)}
                    {renderSrcDetails(SenderCountry)}
                </div>
                <div className={classes.boxRow}>
                    {renderSrcDetails(SenderPhoneNo)}
                    {renderSenderGstIn()}
                </div>
            </div>
        );
    };
    const renderReceiverDetails = () => {
        return (
            <div className={classes.boxS}>
                <div>
                    {renderRecieverDetails(RecieverName)}
                </div>
                <div className={classes.boxRow}>
                    {renderRecieverDetails(RecieverAddressLineOne)}
                    {renderRecieverDetails(RecieverAddressLineTwo)}
                </div>
                <div className={classes.boxRow}>
                    {renderRecieverDetails(RecieverPincode)}
                    {renderRecieverDetails(RecieverCity)}
                    {renderRecieverDetails(RecieverState)}
                    {renderRecieverDetails(RecieverCountry)}
                </div>
                <div className={classes.boxRow}>
                    {renderRecieverDetails(RecieverPhoneNo)}
                    {renderRecieverGSTIN()}
                </div>
            </div>
        );
    };
    const productDetailsSection = () => {
        return (
            <div className={classes.boxS}>
                <div className={classes.boxRow}>
                    {renderProductDetails(ProductName)}
                    {renderHSNValue(HsnValue)}
                </div>
                <div>
                    {renderProductDescription()}
                </div>
                <div className={classes.boxRow}>
                    {renderProductDetails(ProductQuantity)}
                    {renderProductDetails(ProductUnit)}
                    {/* {renderProductDetails(ProductInvoiceNumber)} */}
                    {renderProductValue(ProductValue)}
                </div>
                <div className={classes.boxRow}>
                    {renderGSTTYPES(ProductCGST)}
                    {renderGSTTYPES(ProductIGST)}
                    {renderGSTTYPES(ProductSGST)}
                    {renderGSTTYPES(ProductCESS)}
                </div>
            </div>
        );
    };
    const renderEwayBillDetails = () => {
        return (
            <div className={classes.box}>
                {renderRowDescription(PrimaryIcon, 'E-way Bill Details')}
                {renderEwayBillTypes()}
                {/* {renderEwayBill()} */}
            </div>
        );
    };
    const renderTransactionDetailsDetailsSection = () => {
        if (ewayBillType === 'ewaybill_details') {
            return (
                <div className={classes.box}>
                    {renderRowDescription(ServicesIcon, 'Transaction Details')}
                    {renderTransactionDetails()}
                </div>
            );
        }
        return null;
    };
    const renderSenderDetailsSection = () => {
        if (ewayBillType === 'ewaybill_details') {
            return (
                <div className={classes.box}>
                    {renderRowDescription(AddressIcon, 'Sender Details')}
                    {renderSenderDetails()}
                </div>
            );
        }
        return null;
    };
    const renderReceiverDetailsSection = () => {
        if (ewayBillType === 'ewaybill_details') {
            return (
                <div className={classes.box}>
                    {renderRowDescription(AddressIcon, 'Receiver Details')}
                    {renderReceiverDetails()}
                </div>
            );
        }
        return null;
    };
    const renderProductDetailsSection = () => {
        if (ewayBillType === 'ewaybill_details') {
            return (
                <div className={classes.box}>
                    {renderRowDescription(ContentsIcon, 'Product Details')}
                    {productDetailsSection()}
                </div>
            );
        }
        return null;
    };
    if (declaredValue > 50000 && showEwayBill && formData.consignmentCategory !== 'international') {
        return (
            <div className={classes.main}>
                {renderPayments()}
                {renderEwayBillDetails()}
                {renderTransactionDetailsDetailsSection()}
                {renderSenderDetailsSection()}
                {renderReceiverDetailsSection()}
                {renderProductDetailsSection()}
            </div>
        );
    }
    return (
        <div className={classes.main}>
            {renderPayments()}
        </div>
    );
};


const mapStateToProps = (state: ReduxStore) => {
    const { config } = state.master;
    const modeInfo: ModeType[] = config.customer_portal_config?.cod_collection_mode_list || [];
    const modeTypes: string[] = modeInfo.map((mode) => {
        return mode.name;
    });
    return {
        showEwayBill: config.customer_portal_consignment_config?.show_eway_bill_details || false,
        currencyListDomestic: config.customer_portal_config?.cod_currency_list || ['INR'],
        modeTypes: modeTypes.length ? modeTypes : ['Cash'],
    };
};

const hocConfig: HocOptions = {
    connectJss: {
        useJss: true,
        styleSheet: stepFourStyles,
    },
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
};

export default GenericHoc(hocConfig)(StepFour);
