/* eslint-disable max-len */
import { ThemeType } from '../../theme/jss-types';

export const senderManagementStyles = (theme: ThemeType) => ({
    main: {
        width: '100%',
        maxWidth: '100%',
        padding: '5px 8px',
        '& .ant-table-thead > tr > th': {
            padding: '0 12px',
            borderRight: 'none',
            backgroundColor: '#EFF4FC',
            color: '#333333',
            fontFamily: 'Open Sans',
            fontSize: 12,
            letterSpacing: 0,
            wordWrap: 'break-word',
            whiteSpace: 'break-spaces',
        },
        '& .ant-table-tbody > tr > td': {
            padding: '12px 12px',
            whiteSpace: 'break-spaces',
            wordWrap: 'break-word',
        },
        '& .ant-dropdown-menu-submenu-popup ul, .ant-dropdown-menu-submenu-popup li': {
            width: 80,
        },
        '& .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before': {
            backgroundColor: 'inherit',
        },
        backgroundColor: theme.backgroundColor,
    },
    cellNa: {
        color: '#999999',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
    },
    cellValue: {
        color: '#111111',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    statusCellValue: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 10,
        opacity: 10,
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
        cursor: 'pointer',
        wordWrap: 'break-word',
        whiteSpace: 'break-spaces',
    },
    menuItemStyle: {
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
        color: '#333333',
    },
    leftFilters: {
        display: 'flex',
        alignItems: 'center',
    },
    search: {
        display: 'flex',
        marginRight: '10px',
    },
    customTooltip: {
        '& .ant-tooltip-inner': {
            backgroundColor: '#FCFCFC',
            color: '#909090',
        },
    },
    label: {
        width: '100px',
        marginRight: 4,
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        display: 'flex',
        alignItems: 'center',
    },
    searchInput: {
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        color: theme.primaryColor,
    },
    rightFilters: {
        display: 'flex',
        alignItems: 'center',
        '& .ant-btn': {
            boxSizing: 'border-box',
            height: 32,
            borderRadius: 3,
            fontFamily: 'Open Sans',
            fontSize: 12,
            fontWeight: 600,
            laterSpacing: 0,
        },
        '& .ant-btn-ghost': {
            border: `1px solid ${theme.primaryColor}`,
            color: theme.primaryColor,
        },
        '& .ant-btn-primary, .ant-btn-primary:hover, .ant-btn-primary:focus': {
            backgroundColor: theme.primaryColor,
            color: '#FFFFFF',
        },
    },
    extraFilters: {
        width: '100%',
        borderRadius: '0 2px 0 0',
        backgroundColor: '#FFFFFF',
        padding: '12px 16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 4,
    },
    moreFilter: {
        fontFamily: 'Open Sans',
        marginLeft: 1,
        fontWeight: 600,
        fontSize: 12,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    createBtn: {
        marginRight: 8,
    },
    downloadBtn: {
        marginRight: 8,
    },
    filters: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        '& .ant-table-container table > thead > tr:first-child th:first-child': {
            padding: '8px 0px',
        },
        '& .ant-table-thead > tr > th': {
            padding: '8px 8px',
            borderRight: 'none',
            backgroundColor: '#EFF4FC',
            color: '#333333',
            fontFamily: 'Open Sans',
            fontSize: 12,
            letterSpacing: 0,
            whiteSpace: 'pre-wrap',
            lineHeight: '12px',
        },
        '& .ant-table-tbody > tr > td': {
            padding: '12px 8px',
        },
        '& .ant-dropdown-menu-submenu-popup ul, .ant-dropdown-menu-submenu-popup li': {
            width: 80,
        },
        '& .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before': {
            backgroundColor: 'inherit',
        },
        '& .ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input': {
            width: 150,
            borderRadius: 12,
            border: '1px solid #999999',
            height: 24,
            fontSize: 12,
            position: 'relative',
            display: 'inline-flex',
            alignItems: 'center',
        },
        '& .ant-table-selection': {
            marginLeft: 8,
            marginRight: 8,
        },
        '& .ant-picker-range-separator, .ant-picker-input': {
            margin: 0,
            padding: 0,
        },
    },
    filter: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 24,
    },
    resetFilter: {
        color: '#DF1D1D',
        fontSize: 12,
        fontWeight: 600,
        cursor: 'pointer',
        marginLeft: 16,
        fontFamily: 'Open Sans',
    },
    filterText: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        marginRight: 8,
    },
    table: {
        '& td': {
            verticalAlign: 'baseline',
            cursor: 'pointer',
            border: 'none',
        },
        '& .ant-table-thead': {
            whiteSpace: 'pre-line !important',
            backgroundColor: '#',
            border: '1px solid #E8E8E8',
            color: '#262626',
            padding: 0,
        },
        '& .ant-table-selection-column': {
            borderRight: 'none !important',
        },
        '& .ant-table table': {
            backgroundColor: '#FFF',
        },
    },
    hr: {
        width: '98%',
        height: 1,
        backgroundColor: '#CCCCCC',
        marginTop: 4,
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e8e8e8',
        padding: '10px 16px',
        textAlign: 'left',
        left: 0,
        background: '#fff',
        borderRadius: '0 0 4px 4px',
        height: 56,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600,
        fontSize: 20,
        color: '#333',
        width: '100%',
        padding: 0,
        justifyContent: 'space-between',
        fontFamily: 'Open Sans',
        letterSpacing: 0,
    },
    closeIcon: {
        fontSize: 16,
        marginRight: 8,
        cursor: 'pointer',
        color: '#CCC',
    },
    moreFilters: {
        width: '100%',
        fontFamily: 'Open Sans',
        letterSpacing: 0,
        overflow: 'scroll',
        '& .ant-drawer-header': {
            padding: 12,
        },
        '& .ant-drawer-body': {
            backgroundColor: '#FFF',
            alignSelf: 'center',
            padding: 0,
            marginBottom: 56,
            width: '100%',
        },
        '& .ant-btn-primary': {
            borderRadius: 4,
            marginRight: 8,
            color: '#FFFFFF',
            fontFamily: 'Open Sans',
            fontWeight: 600,
            letterSpacing: 0,
            height: 32,
            fontSize: 12,
        },
        '& .ant-input-affix-wrapper': {
            borderRadius: 4,
            backgroundColor: '#FFFFFF',
            boxShadow: '0 0 1px 0 rgba(0,0,0,0.5)',
        },
        '& .ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input, .ant-picker': {
            width: 250,
            borderRadius: 4,
            border: '1px solid #999999',
            height: 32,
            fontSize: 11,
            position: 'relative',
            display: 'inline-flex',
            alignItems: 'center',
        },
        '& .ant-drawer-content-wrapper': {
            minWidth: 440,
        },
    },
    drawerBody: {
        padding: 24,
        paddingBottom: 8,
        width: '100%',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 24,
    },
});
