import { ThemeType } from 'theme/jss-types';
import { commonStyleSheet } from '../../library/common-styles';

const common = {
    trackingTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#111111',
        fontFamily: 'Open Sans',
        letterSpacing: 0,
    },
    hr: {
        width: '98%',
        height: 1,
        backgroundColor: '#CCCCCC',
        marginTop: 4,
    },
};
export const consignmentDetailedStyles = (theme: ThemeType) => ({
    ...commonStyleSheet(theme),
    ...common,
    arrowIcon: {
        marginRight: 8,
    },
    backText: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
        padding: '16px 0px',
        cursor: 'pointer',
    },
    address: {
        width: '49.85%',
        height: 200,
        padding: '16px 16px',
        backgroundColor: '#FFFFFF',
    },
    addresses: {
        ...commonStyleSheet(theme).flexRow,
        width: '100%',
        justifyContent: 'space-between',
        padding: '2px 0px',
    },
    referenceBox: {
        borderRadius: '0 2px 0 0',
        backgroundColor: '#FFFBE9',
        padding: 16,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    referenceValue: {
        color: '#111111',
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
    },
    referenceName: {
        color: '#666666',
        fontSize: 10,
        letterSpacing: 0,
        fontFamily: 'Open Sans',
    },
    detailsBox: {
        display: 'flex',
        width: '100%',
        marginTop: 2,
        justifyContent: 'space-between',
    },
    trackingBox: {
        width: '45%',
        backgroundColor: '#FFFFFF',
        padding: '16px 24px',
    },
    consignmentDetails: {
        width: '54.8%',
    },
    itemDetails: {
        width: '100%',
        backgroundColor: '#FFFFFF',
        padding: '16px 16px',
    },
    serviceDetails: {
        width: '100%',
        backgroundColor: '#FFFFFF',
        padding: '16px 16px',
        minHeight: '30vh',
    },
    itemsList: {
        marginTop: 20,
        width: '100%',
    },
    keyValuePair: {
        width: '30%',
        ...commonStyleSheet(theme).flexRow,
        marginBottom: 8,
    },
    itemType: {
        width: '50%',
        ...commonStyleSheet(theme).flexRow,
        marginBottom: 8,
    },
    itemKey: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '30%',
    },
    pairKey: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '50%',
    },
    pairValue: {
        color: '#111111',
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: 12,
        letterSpacing: 0,
        width: '50%',
    },
    addresDetails: {
        color: '#111111',
        fontFamily: 'Open Sans',
        fontWeight: 600,
        letterSpacing: 0,
        ...commonStyleSheet(theme).flexColumn,
    },
    addressTitle: {
        fontSize: 14,
        marginTop: 16,
        fontWeight: 600,
    },
    addressValue: {
        fontSize: 12,
        marginTop: 8,
        fontWeight: 600,
    },
});

export const consignmentDetailsIntStyles = (theme: ThemeType) => ({
    ...consignmentDetailedStyles(theme),
    ...common,
    mainDiv: {
        height: '100%',
        width: '54.8%',
        maxHeight: '75vh',
        overflow: 'scroll',
        alignItems: 'start',
    },
    bucket: {
        color: '#666666',
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0,
        padding: '6px 10px',
        marginRight: 2,
        borderRadius: '4px 4px 0 0',
        backgroundColor: '#F5F5F5',
        display: 'flex',
        cursor: 'pointer',
    },
    bucketSelected: {
        color: '#111111',
        fontWeight: 'bold',
        backgroundColor: '#FFF',
    },
    buckets: {
        ...commonStyleSheet(theme).flexRow,
        marginBottom: 16,
    },
    tabDetails: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'start',
    },
    fieldGroup: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'start',
        fontSize: 12,
        width: '33.3%',
    },
    fieldTitle: {
        display: 'inline-block',
        width: '50%',
    },
    fieldValue: {
        display: 'inline-block',
        width: '50%',
        fontWeight: 600,
    },
    box: {
        borderRadius: 4,
        margin: 16,
        backgroundColor: '#FAFAFA',
        width: '100%',
    },
    boxTitle: {
        fontWeight: 'bold',
    },
    documentGroup: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    secondaryTitle: {
        fontWeight: 600,
        color: '#111111',
        width: '100%',
    },
    viewButton: {
        padding: 0,
        margin: 0,
        fontSize: 12,
        height: 'auto',
        '& .ant-btn-link': {
            padding: 0,
            margin: 0,
        },
    },
    iframeModal: {
        width: 630,
        height: 500,
        '& .ant-modal-header': {
            borderBottom: 'none',
        },
    },
});

export const courierTrackingStyles = (theme: ThemeType) => ({
    ...common,
    mainDiv: {
        height: '100%',
        width: '100%',
        padding: 24,
        paddingTop: 0,
        maxHeight: '75vh',
        overflow: 'scroll',
    },
    referenceNumber: {
        fontSize: '18px',
        fontWeight: 'bold',
        marginTop: '5px',
        width: '10%',
        color: '#444444',
        fontFamily: 'Open Sans',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'top',
        height: '85px',
        padding: 16,
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: '13%',
        marginTop: 8,
    },
    title: {
        color: '#666666',
        lineHeight: '14px',
        marginLeft: 12,
        fontSize: 12,
    },
    value: {
        color: '#111111',
        marginLeft: 12,
        fontSize: 12,
        lineHeight: '14px',
        fontWeight: 600,
    },
    na: {
        color: '#999999',
        fontSize: 14,
        fontWeight: 600,
        letterSpacing: 0,
        lineHeight: '12px',
        width: '50%',
        marginLeft: 16,
    },
    trackingBox: {
        width: '100%',
        marginTop: 10,
        marginLeft: 10,
        backgroundColor: '#FFFFFF',
        borderRadius: 5,
        marginBottom: 50,
    },
    dot: {
        color: '#27B479',
        fontSize: 24,
    },
    timelineItem: {
        width: '6%',
        height: 90,
        marginTop: 6,
        '& .ant-timeline-item-tail': {
            borderLeft: '2px solid #D9D9D9',
            height: '100%',
        },
        '& .ant-timeline-item-head-custom': {
            padding: '0px 1px',
            top: 8,
        },
    },
    lineItemNoTail: {
        width: '6%',
        height: 90,
        marginTop: 6,
        '& .ant-timeline-item-tail': {
            display: 'none',
        },
        '& .ant-timeline-item-head-custom': {
            padding: '0px 1px',
            top: 8,
        },
    },
    timeline: {
        padding: '16px 16px 10px 0',
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    lineItemLeft: {
        display: 'flex',
        flexDirection: 'column',
    },
    lineItemRight: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 6,
        width: '60%',
    },
    date: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#444444',
        fontFamily: 'Open Sans',
        alignSelf: 'flex-start',
    },
    time: {
        fontSize: 12,
        color: '#666666',
        fontFamily: 'Open Sans',
        alignSelf: 'flex-start',
    },
    textRight: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#444444',
        fontFamily: 'Open Sans',
        alignSelf: 'flex-start',
    },
    locationRight: {
        fontSize: 12,
        color: '#666666',
        fontFamily: 'Open Sans',
    },
    reference: {
        ...commonStyleSheet(theme).flexRow,
        padding: 12,
        border: '1px solid #FAF6EF',
        borderRadius: '0 4px 4px 0',
        backgroundColor: '#FCFAF4',
        boxShadow: '2px 2px 6px 0 rgba(0,0,0,0.05)',
        justifyContent: 'space-between',
    },
    availableText: {
        color: '#111111',
        fontFamily: 'Open Sans',
        fontSize: 11,
        letterSpacing: 0,
    },
    trackText: {
        ...commonStyleSheet(theme).flexRow,
        justifyContent: 'flex-start',
    },
    referenceText: {
        color: '#111111',
        fontFamily: 'Open Sans',
        fontSize: 13,
        fontWeight: 600,
        letterSpacing: 0,
        marginLeft: 8,
        marginRight: 16,
    },
    referenceValue: {
        fontSize: 14,
        color: '#111111',
        fontWeight: 600,
    },
    referenceTitle: {
        fontSize: 10,
        color: '#666666',
    },
    referenceList: {
    },
    eddDescription: {
        marginLeft: '7px',
        cursor: 'pointer',
    },
});
