import React from 'react';
import { Route, Switch } from 'react-router-dom';
import UserMapping from './users-mapping';

const USER_MANAGEMENT = () => {
    return (
        <div>
            <Switch>
                <Route path="/user-management" component={UserMapping} />
            </Switch>
        </div>
    );
};

export default USER_MANAGEMENT;
