import * as React from 'react';
import {
    ArrowLeftOutlined,
    DownloadOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { HocOptions } from '../common/generic-hoc.types';
import { StylesProps } from '../../theme/jss-types';
import { sellerDetailedStyles } from './seller-details.styles';
import Loader from '../common/Loader';
import GenericHoc from '../common/generic-hoc';
import { fetchAuthLetterURL, fetchSellerDetails } from 'network/sender-management.api';
import { useHistory } from 'react-router-dom';
import { Routes } from 'library/constants';
import {
    Button,
    Image,
    Modal,
    Spin,
    message,
} from 'antd';
import { snakeToPretty } from 'library/lodash-helper';
import { GET } from 'network/api-hander.service';
import Helper from 'library/Helper';

interface ConsignmentDetailedProps extends StylesProps<ReturnType<typeof sellerDetailedStyles>> { }

const detailsTabs = [
    'Primary Details',
    'Bank & KYC Details',
    'Authorization Details',
];

const SellerDetailed = (props: ConsignmentDetailedProps) => {
    const {
        classes,
    } = props;
    const [seller, setSeller] = React.useState<any>({});
    const [loading, setLoading] = React.useState<boolean>(true);
    const [loadingLabel, setLoadingLabel] = React.useState<Record<string, boolean>>(
        (seller.authorization_letters || {}) as Record<string, boolean>,
    );
    const [selectedTab, setSelectedTab] = React.useState('Primary Details');
    const [iframeUrl, setIframeUrl] = React.useState('');
    const [imagePreviewURL, setImagePreviewURL] = React.useState('');
    const [imagePreviewLoading, setImagePreviewLoading] = React.useState(false);
    const [pdfPreviewURL, setPdfPreviewURL] = React.useState('');

    const history = useHistory();


    const getSellerIdFromUrl = () => {
        const { location } = history;
        const { pathname } = location;
        if (pathname.includes(Routes.CONSIGNOR_MANAGEMENT)) {
            const url = pathname.split('/');
            return url[2];
        }
        return undefined;
    };

    const loadDetails = async () => {
        setLoading(true);
        const response = await fetchSellerDetails({ seller_id: getSellerIdFromUrl() });
        setSeller(response?.data || {});
        setLoading(false);
    };

    React.useEffect(() => {
        loadDetails();
    }, []);

    const renderBack = () => {
        return (
            <div
                className={classes.backText}
                onClick={() => history.push(`/${Routes.CONSIGNOR_MANAGEMENT}`)}
            >
                <ArrowLeftOutlined
                    className={classes.arrowIcon}
                />
                Back to Consignor Admin
            </div>
        );
    };

    const renderReferenceItem = (name: string, value: string) => {
        return (
            <div className={classes.flexColumn}>
                <span className={classes.referenceValue}>{value}</span>
                <span className={classes.referenceName}>{name}</span>
            </div>
        );
    };

    const renderReference = () => {
        if (loading) {
            return (
                <Loader zIndex={5} />
            );
        }
        return (
            <div className={classes.referenceBox}>
                {renderReferenceItem('Consignor Code', seller.seller_code)}
                {renderReferenceItem('Consignor Type', snakeToPretty(seller.sender_type || '-'))}
                {renderReferenceItem('Created At', moment(seller.created_at).format('DD MMM YYYY'))}
                {renderReferenceItem('CSB V Applicable', seller.is_csbv_applicable ? 'Yes' : 'No')}
                {renderReferenceItem('Status', snakeToPretty(seller.status))}
            </div>
        );
    };


    const renderTitle = (title: string) => {
        return (
            <div className={classes.trackingTitle}>
                {title}
            </div>
        );
    };

    const renderLine = () => {
        return <div className={classes.hr} />;
    };

    const renderItemType = (key?: string, value?: any, width: string = '50%', titleWidth?: string) => {
        return (
            <div
                style={{ width }}
                className={classes.itemType}
            >
                <span style={{ width: titleWidth }} className={classes.itemKey}>{key}</span>
                <span className={classes.pairValue}>{value}</span>
            </div>
        );
    };

    const renderConsignorLocation = () => {
        const consignorLocation = seller.location_details || {};
        return (
            <div className={classes.addresDetails}>
                {renderItemType('Name', seller.seller_name, '100%', '20%')}
                {renderItemType('Company', seller.company_name, '100%', '20%')}
                <div className={classes.flexRow}>
                    {renderItemType('Address Line 1', consignorLocation.address_line_1, '50%')}
                    {renderItemType('Address Line 2', consignorLocation.address_line_2, '50%')}
                    {renderItemType('City', consignorLocation.city, '50%')}
                    {renderItemType('State', consignorLocation.state, '50%')}
                    {renderItemType('Country', consignorLocation.country, '50%')}
                    {renderItemType('Pincode', consignorLocation.pincode, '50%')}
                    {renderItemType('Phone', seller.phone, '50%')}
                    {renderItemType('Email', seller.email, '50%')}
                </div>
            </div>
        );
    };

    const renderImageButton = (image: string, ext = 'jpg') => {
        const type = ext === 'pdf' ? 'pdf' : 'image';
        if (!image) {
            return '-';
        }
        return (
            <Button
                onClick={() => {
                    if (type === 'image') {
                        setImagePreviewLoading(true);
                        setImagePreviewURL(image);
                    } else {
                        setPdfPreviewURL(image);
                    }
                }}
                type="link"
                className={classes.previewButton}
            >
                Preview
            </Button>
        );
    };

    const renderPrimaryDetails = () => {
        if (selectedTab !== 'Primary Details') return null;
        return (
            <>
                <div className={classes.itemDetails}>
                    {renderTitle('Address Details')}
                    {renderLine()}
                    {renderConsignorLocation()}
                </div>
                <div className={classes.itemDetails}>
                    {renderTitle('Address Proof')}
                    {renderLine()}
                    <div className={classes.flexRow}>
                        {renderItemType('Address Proof Type', snakeToPretty(seller?.location_details?.address_proof_type))}
                        {renderItemType('Front Image',
                            renderImageButton(seller?.location_details?.address_proof_front_image,
                                seller?.location_details?.front_image_details?.ext))}
                        {
                            seller?.location_details?.address_proof_back_image ? renderItemType('Back Image',
                                renderImageButton(seller?.location_details?.address_proof_back_image,
                                    seller?.location_details?.back_image_details?.ext))
                                : null
                        }
                    </div>
                </div>
            </>
        );
    };

    const renderBankDetails = () => {
        if (selectedTab !== 'Bank & KYC Details') return null;
        const bankDetails = seller.bank_details || {};
        return (
            <>
                <div className={classes.itemDetails}>
                    {renderTitle('Bank Details')}
                    {renderLine()}
                    {renderItemType('Account Number', bankDetails.account_number)}
                    {renderItemType('AD Code', bankDetails.ad_code)}
                    {renderItemType('IFSC Code', bankDetails.ifsc_code)}
                    {renderItemType('Bank Name', bankDetails.bank_name)}
                    {renderItemType('Bank Type', bankDetails.bank_type)}
                    {renderItemType('AD Code Certification',
                        renderImageButton(bankDetails.ad_code_certificate_image, bankDetails?.image_details?.ext))}
                </div>
                <div className={classes.itemDetails}>
                    {renderTitle('KYC Details')}
                    {renderLine()}
                    {seller?.kyc_documents?.map((doc: any, index: number) => {
                        return (
                            <div style={{ width: '100%' }}>
                                <div className={classes.flexColumn}>
                                    KYC&nbsp;
                                    {index + 1}
                                </div>
                                <div className={classes.flexRow}>
                                    {renderItemType('Document Type', snakeToPretty(doc?.document_type))}
                                    {renderItemType('Document Number', doc?.document_number)}
                                    {renderItemType('Front Image', renderImageButton(doc?.front_image,
                                        doc?.front_image_details?.ext))}
                                    {renderItemType('Back Image', renderImageButton(doc?.back_image,
                                        doc?.back_image_details?.ext))}
                                    {doc?.verification_failure_reason
                                        ? renderItemType('Failure Reason', doc?.verification_failure_reason)
                                        : null}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </>
        );
    };

    const handleDownloadAuthLetter = async (seller_code: string, letter_type: string, isPreview: boolean = false) => {
        setLoadingLabel((prev: Record<string, boolean>) => ({ ...prev, [letter_type]: true }));
        const response = await fetchAuthLetterURL({ seller_code, letter_type });
        if (response?.isSuccess) {
            if (isPreview) {
                setIframeUrl(response.data.url || '');
            } else {
                const fileBuffer = await GET(response.data.url, {}, 'blob');
                const fileName = `auth_letter_${seller_code}_${letter_type}.pdf`;
                if (fileBuffer.isSuccess) {
                    Helper.downloadFileData(
                        fileBuffer.data,
                        fileName,
                        true,
                    );
                    message.success('Downloaded Successfully');
                } else {
                    message.error(fileBuffer.errorMessage);
                }
            }
        } else {
            message.error(response.errorMessage || 'Failed to download the letter.');
        }
        setLoadingLabel((prev: Record<string, boolean>) => ({ ...prev, [letter_type]: false }));
    };

    const renderAuthLetters = () => {
        const authLetters = seller?.authorization_letters
            ?.filter((letter: any) => letter.is_verified)
            ?.map((letter: any) => letter.type);
        return authLetters.sort().map((letter: string) => {
            if (seller?.authorization_letters[letter] === false) return null;
            return (
                <div key={letter} className={classes.flexRow}>
                    <span className={classes.authLetter}>{letter}</span>
                    {loadingLabel[letter] ? <Spin /> : (
                        <>
                            <Button
                                type="link"
                                className={classes.authLetter}
                                onClick={() => handleDownloadAuthLetter(seller.seller_code, letter, true)}
                            >
                                View
                            </Button>
                            <Button
                                type="link"
                                onClick={() => handleDownloadAuthLetter(seller.seller_code, letter)}
                            >
                                <DownloadOutlined />
                            </Button>
                        </>
                    )}
                </div>
            );
        });
    };

    const renderAuthDetails = () => {
        if (selectedTab !== 'Authorization Details') return null;
        return (
            <>
                <div className={classes.itemDetails}>
                    {renderTitle('Seal and Signature')}
                    {renderItemType('Signature', renderImageButton(seller.signature_image,
                        seller?.signature_image_details?.ext))}
                    {renderItemType('Seal', renderImageButton(seller.seal_image, seller?.seal_image_details?.ext))}
                </div>
                <div className={classes.itemDetails}>
                    {renderTitle('Authorization Letters')}
                    {renderLine()}
                    {renderAuthLetters()}
                </div>
            </>
        );
    };

    const renderSingleTab = () => {
        return detailsTabs.map((tab) => {
            let bucketClass: any = classes.bucket;
            if (tab === selectedTab) {
                bucketClass = [classes.bucketSelected, classes.bucket].join(' ');
            }
            return (
                <div
                    key={tab}
                    className={bucketClass}
                    onClick={() => setSelectedTab(tab)}
                >
                    <span>
                        {tab}
                    </span>
                </div>
            );
        });
    };

    const renderTabs = () => {
        return (
            <div className={classes.buckets}>
                {renderSingleTab()}
            </div>
        );
    };

    const renderSellerDetails = () => {
        return (
            <div className={classes.consignmentDetails}>
                {renderTabs()}
                {renderPrimaryDetails()}
                {renderBankDetails()}
                {renderAuthDetails()}
            </div>
        );
    };

    const renderDetails = () => {
        if (loading) {
            return (
                null
            );
        }
        return (
            <div className={classes.detailsBox}>
                {
                    renderSellerDetails()
                }
            </div>
        );
    };

    const renderFilePreview = () => {
        if (iframeUrl) {
            return (
                <Modal
                    title="Authorization Letter"
                    visible={!!iframeUrl}
                    onCancel={() => setIframeUrl('')}
                    footer={null}
                    className={classes.iframeModal}
                    width={550}
                >
                    <iframe
                        width={500}
                        height={450}
                        src={iframeUrl}
                        title="Auth Letter Preview"
                    />
                </Modal>
            );
        }
        return null;
    };

    const renderPdfPreview = () => {
        if (pdfPreviewURL) {
            return (
                <Modal
                    title=" "
                    visible={!!pdfPreviewURL}
                    onCancel={() => setPdfPreviewURL('')}
                    footer={null}
                    className={classes.iframeModal}
                    width={550}
                >
                    <iframe
                        title="Preview"
                        width={500}
                        height={450}
                        src={pdfPreviewURL}
                    />
                </Modal>
            );
        }
        return null;
    };

    const renderImagePreview = () => {
        if (imagePreviewURL) {
            return (
                <>
                    {(!!imagePreviewLoading) && <Loader zIndex={10} />}
                    <Image
                        placeholder
                        src={imagePreviewURL}
                        style={{
                            display: 'none',
                        }}
                        onLoad={() => setImagePreviewLoading(false)}
                        onError={() => {
                            message.error('Unable to fetch Image.');
                            setImagePreviewLoading(false);
                            setImagePreviewURL('');
                        }}
                        onAbort={() => {
                            setImagePreviewLoading(false);
                            setImagePreviewURL('');
                        }}
                        preview={{
                            visible: !!imagePreviewURL && !imagePreviewLoading,
                            onVisibleChange: () => setImagePreviewURL(''),
                        }}
                    />
                </>
            );
        }
        return null;
    };

    return (
        <div className={classes.main}>
            {renderBack()}
            {renderReference()}
            {renderDetails()}
            {renderFilePreview()}
            {renderImagePreview()}
            {renderPdfPreview()}
        </div>
    );
};

const hocConfig: HocOptions = {
    connectJss: {
        useJss: true,
        styleSheet: sellerDetailedStyles,
    },
};

export default GenericHoc(hocConfig)(SellerDetailed);
