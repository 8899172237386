import {
    Button,
    Form,
    Input,
    message,
    Radio,
    Select,
    Space,
    Tooltip,
} from 'antd';
import * as React from 'react';
import Loader from '../common/Loader';
import { getCityState } from '../../network/consignments.api';
import {
    editAddress,
    saveAddress,
    fetchLocalityFromAddressMapping,
    fetchCitiesList,
    fetchAddressNodeConfig,
    fetchPincodeList,
} from '../../network/pickup.api';
import { StylesProps } from '../../theme/jss-types';
import {
    checkWhat3Word, getCities, getCountries, getStates, getCountriesNotInternational,
} from 'network/common.api';
import GenericHoc from '../common/generic-hoc';
import { ReduxStore } from '../../reducers/redux.types';
import { HocOptions } from '../common/generic-hoc.types';
import { useDebounce } from 'hooks/use-debounce';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { CustomerPortalConfig } from 'types/master-data-types';
import MapLocationCapture from 'components/create-hyperlocal/MapLocationCapture';
import { EnvironmentOutlined } from '@ant-design/icons';

const styles = {
    newAddressItem: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 16,
        marginRight: 8,
    },
    newAddressTitle: {
        color: '#333333',
        fontSize: 12,
        marginBottom: 4,
    },
    newAddressInput: {
        width: 300,
    },
    newAddress: {
        marginTop: 16,
        display: 'flex',
        flexDirection: 'column',
    },
    addressRow: {
        display: 'flex',
    },
    pincodeInput: {
        width: 100,
        marginBottom: 0,
    },
    pincodeInputDropdown: {
        '& .ant-select-item-option-content': {
            whiteSpace: 'break-spaces',
            wordBreak: 'break-word',
        },
    },
    cityInput: {
        width: 190,
        marginBottom: 0,
    },
    stateInput: {
        width: 145,
        marginBottom: 0,
    },
    countryInput: {
        width: 145,
        marginBottom: 0,
    },
    mobileInput: {
        width: 200,
    },
    mobileInputCode: {
        height: 30,
        width: 50,
        margin: 0,
        padding: 0,
        '& .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector': {
            marginLeft: 0,
            marginRight: 0,
            paddingLeft: 0,
            paddingRight: 0,
        },
        '& .ant-select-selector, ant-select-selection-item, ant-input-group-addon': {
            marginLeft: 0,
            marginRight: 0,
            paddingLeft: '0px !important',
            paddingRight: '0px !important',
        },
    },
    pincodeWithExtra: {
        '& .ant-form-item-extra': {
            fontSize: 12,
            fontStyle: 'italic',
            marginBottom: 0,
            paddingBottom: 0,
        },
    },
    wrongW3W: {
        color: 'red',
    },
    locationIdInput: {
        width: 150,
    },
};

const buttonStyle: React.CSSProperties = {
    fontWeight: 600,
    width: 115,
    boxShadow: '2px 2px 4px 0 rgba(0,0,0,0.24)',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
};

const {
    useState,
    useEffect,
} = React;

const addressFormFields: any = {
    city: {
        required: true,
    },
    pincode: {
        required: true,
    },
    country: {
        required: false,
    },
    state: {
        required: true,
    },
    phone: {
        required: true,
    },
    name: {
        required: true,
    },
    address_line_1: {
        required: true,
    },
    address_line_2: {
        required: false,
    },
};

type NewAddress = {
    id?: string;
    pincode?: string;
    city?: string;
    state?: string;
    phone?: string;
    alternatePhone?: string;
    address_line_1?: string;
    address_line_2?: string;
    name?: string;
    country?: string;
    locationId?: string;
    locationCode?: string;
    email?: string;
    isInternational?: boolean;
    addressCode?: string;
    w3wCode?: string;
    latitude?: string;
    longitude?: string;
    companyName?: string;
    addressCategory?: string;
};

interface IProps extends StylesProps<typeof styles> {
    editData?: any;
    onClose: () => void;
    phoneRegex: any,
    isInternationalAddress: boolean;
    config: any;
    customer: any;
    partsToShow: any;
    countryWisePhoneRegex: any[],
    updateFormData: any,
    showAddressType: boolean,
    customerPortalConfig: CustomerPortalConfig,
}
const CreateAddress = (props: IProps) => {
    const {
        classes,
        editData,
        onClose,
        phoneRegex,
        isInternationalAddress,
        config,
        customer,
        partsToShow,
        countryWisePhoneRegex,
        updateFormData,
        showAddressType,
        customerPortalConfig,
    } = props;

    const { t } = useTranslation();

    const [creating, setCreating] = useState<boolean>(false);
    const [newAddress, setNewAddress] = React.useState<NewAddress>({});
    const [allowedFields, setAllowedFields] = React.useState<any[]>([]);
    const [countriesListInternational, setCountriesListInternational] = React.useState<any[]>([]);
    const [countriesList, setCountriesList] = React.useState<any[]>([]);
    const [statesList, setStatesList] = React.useState<any[]>([]);
    const [citiesList, setCitiesList] = React.useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [validPhone, setValidPhone] = useState<boolean | undefined>(true);
    const [validAlternatePhone, setValidAlternatePhone] = useState<boolean | undefined>(true);
    const [validEmail, setValidEmail] = useState<boolean | undefined>(true);
    const [localityCitiesList, setLocalityCitiesList] = useState<any>();
    const [localityPincodesList, setLocalityPincodesList] = useState<any>([]);
    const [isAddressMappingAllowed, setIsAddressMappingAllowed] = useState<boolean>(false);
    const [disableLocality, setDisableLocality] = useState<boolean>(false);
    // const phoneRegExp = phoneRegex ? new RegExp(phoneRegex.replace(/^\//, '').replace(/\/$/, '')) : null;
    const emailRegex = customerPortalConfig?.email_validation_regex
        ? new RegExp(customerPortalConfig?.email_validation_regex)
        : /\S+@\S+\.\S+/;
    const showEmail = partsToShow?.show_email_in_address;
    const enableAsn = partsToShow?.enable_advance_shipping_note_feature || false;
    const [phoneRegExp, setPhoneRegExp] = useState<RegExp>(new RegExp('^(.+)?$'.replace(/^\//, '').replace(/\/$/, '')));
    const [consignmentValidator, setConsignmentValidator] = useState<any>(addressFormFields);
    const [isWhat3Word, setIsWhat3Word] = React.useState<boolean | null>(true);
    // below state to track country change in international address
    const [currentCountry, setCurrentCountry] = useState<string>('');
    const [regexForPincode, setRegexForPincode] = useState<string>('');
    const [validPincode, setValidPincode] = useState<boolean | undefined>(true);
    const [samplePincode, setSamplePincode] = useState<string | undefined | null>(undefined);
    const [isInternational, setIsInternational] = useState<boolean>(
        editData?.id ? editData?.isInternational : isInternationalAddress,
    );
    const [countryCodeList, setCountryCodeList] = useState<any>(undefined);
    const [showMapForLocation, setShowMapForLocation] = useState<boolean>(false);

    const showAddressCode = config?.customer_portal_config?.allow_address_code_in_addresses;
    //  eslint-disable-next-line max-len
    const addressCategoryTypes: {name: string, is_company_name_mandatory: boolean}[] = config?.customer_portal_config?.address_category_types;

    const isAddressBookInternational = (config.customer_portal_consignment_config
        && config.customer_portal_consignment_config.is_international_client) || false;

    const isWhat3WordEnableFlag = get(config,
        'customer_portal_consignment_config.enable_what3word_for_customer_portal', false) || false;

    const showCitiesList = (config.pickup_destination_details_config
        && config.pickup_destination_details_config.show_city_list_in_add_address) || false;
    const isLTLCustomer = get(customer, 'customer_category') === 'ltl';

    const [form] = Form.useForm();
    //  eslint-disable-next-line max-len
    const isCompanyNameMandatory = addressCategoryTypes?.find((category) => category?.name === newAddress?.addressCategory)?.is_company_name_mandatory;
    const isAddressCategoryMandatory = config?.customer_portal_config?.is_address_category_mandatory || false;
    const addressFieldConfig:any = config.address_fields_config || {};

    useEffect(() => {
        if (Object.keys(addressFieldConfig).length === 0) {
            return;
        }
        const modifiedValidator = consignmentValidator;
        if (addressFieldConfig) {
            if (addressFieldConfig?.check_for_mandatory_address_fields) {
                Object.keys(modifiedValidator).forEach((item: any) => {
                    if (addressFieldConfig.mandatory_address_fields.length > 0) {
                        if (addressFieldConfig.mandatory_address_fields.includes(item)) {
                            modifiedValidator[item].required = true;
                        } else {
                            modifiedValidator[item].required = false;
                        }
                    } else {
                        modifiedValidator[item].required = false;
                    }
                });
            } else {
                Object.keys(modifiedValidator).forEach((item: any) => {
                    modifiedValidator[item].required = false;
                });
            }
        }
        setConsignmentValidator(modifiedValidator);
    }, []);

    const updatePhoneRegex = () => {
        let countryPhoneRegex;
        const country = newAddress.country;
        const countryPhoneRegexObj = countryWisePhoneRegex?.filter((elem) => elem.name === country || elem.code === country);
        if (countryPhoneRegexObj && countryPhoneRegexObj.length && countryPhoneRegexObj[0].phone_regex) {
            countryPhoneRegex = new RegExp(countryPhoneRegexObj[0].phone_regex.replace(/^\//, '').replace(/\/$/, ''));
        } else if (phoneRegex) {
            countryPhoneRegex = new RegExp(phoneRegex.replace(/^\//, '').replace(/\/$/, ''));
        }
        if (countryPhoneRegex) {
            setPhoneRegExp(countryPhoneRegex);
        }
    };

    const updateAddressFieldWithValidations = (key: string, value: string) => {
        if (key === 'country') {
            let countryPhoneRegex;
            const countryPhoneRegexObj = countryWisePhoneRegex?.filter((elem) => elem.name === value || elem.code === value);
            if (countryPhoneRegexObj && countryPhoneRegexObj.length && countryPhoneRegexObj[0].phone_regex) {
                countryPhoneRegex = new RegExp(countryPhoneRegexObj[0].phone_regex.replace(/^\//, '').replace(/\/$/, ''));
            } else if (phoneRegex) {
                countryPhoneRegex = new RegExp(phoneRegex.replace(/^\//, '').replace(/\/$/, ''));
            }
            if (countryPhoneRegex) {
                setPhoneRegExp(countryPhoneRegex);
            }
        }
        setNewAddress({
            ...newAddress,
            [key]: value,
        });
    };

    const debounceTimePincodeString = useDebounce(newAddress.pincode);
    const debounceW3WCodeString = useDebounce(newAddress.w3wCode);

    const saveCityState = async () => {
        setLoading(true);
        const response = await getCityState({
            pincode: newAddress.pincode,
            isLTL: isLTLCustomer,
        });
        setNewAddress({
            ...newAddress,
            city: response?.data?.city || newAddress.city,
            state: response?.data?.state || newAddress.state,
            country: response?.data?.country || newAddress.country,
        });
        setLoading(false);
    };

    const checkW3WCode = async () => {
        setLoading(true);

        if ((!newAddress?.w3wCode) || newAddress?.w3wCode?.length === 0) {
            setIsWhat3Word(true);
            setLoading(false);
            return;
        }

        const response = await checkWhat3Word({
            w3wcode: newAddress.w3wCode,
        });

        if (response?.data?.locationData?.length) {
            const locationData = response?.data?.locationData[0];
            if (locationData.success) {
                setIsWhat3Word(true);
            } else {
                setIsWhat3Word(false);
            }
        } else {
            setIsWhat3Word(false);
        }

        setLoading(false);
    };

    const loadCountries = async () => {
        setLoading(true);
        const response = await getCountries();
        setCountriesListInternational(response?.data);
        setLoading(false);
    };

    const loadStates = async () => {
        setLoading(true);
        const response = await getStates({
            stateName: '',
            countryName: newAddress.country,
        });
        setStatesList(response?.data);
        setLoading(false);
    };

    const loadCities = async (city: string) => {
        if (!city || city.length === 0 || !newAddress.country) {
            setCitiesList([]);
            return;
        }
        setLoading(true);
        const response = await getCities({
            countryName: newAddress.country,
            cityName: city,
        });
        setCitiesList(response?.data);
        setLoading(false);
    };


    const fetchLocalityCities = async () => {
        setLoading(true);
        const response = await fetchCitiesList();

        if (response && response.isSuccess) {
            setLocalityCitiesList(response.data || {});
        }
        setLoading(false);
    };

    const saveLocalityFromAddressMapping = async () => {
        if (!newAddress.pincode) return;
        setLoading(true);

        const fetchLocality = await fetchLocalityFromAddressMapping(newAddress.pincode);

        if (!fetchLocality.isSuccess) {
            setNewAddress({
                ...newAddress,
                city: '',
                state: '',
                country: '',
            });
            setDisableLocality(false);
        } else {
            setNewAddress({
                ...newAddress,
                city: fetchLocality?.data?.city_name,
                state: fetchLocality?.data?.state_name,
                country: fetchLocality?.data?.country_name,
            });
            setDisableLocality(true);
        }

        setLoading(false);
    };

    const fetchAddressNodeData = async () => {
        setLoading(true);

        const response = await fetchAddressNodeConfig();
        const allowOverrideHierarchy = response?.data?.allow_override_hierarchy;
        setIsAddressMappingAllowed(allowOverrideHierarchy || false);
        if (response?.data?.fields_to_ignore) {
            const allowed_fields = response?.data?.fields_to_ignore;
            setAllowedFields(allowed_fields);
        }
        if (allowOverrideHierarchy) {
            const countriesListResp = await getCountriesNotInternational({});
            if (countriesListResp && countriesListResp.data
                && Array.isArray(countriesListResp.data) && countriesListResp.data.length > 0) {
                setCountriesList(countriesListResp.data);
            } else {
                setCountriesList([]);
            }
        }
        setLoading(false);
    };



    useEffect(() => {
        fetchAddressNodeData();
        if (phoneRegex) {
            const phoneRegexToSet = new RegExp(phoneRegex.replace(/^\//, '').replace(/\/$/, ''));
            setPhoneRegExp(phoneRegexToSet);
        }
        if (editData?.id) {
            const locality = {
                city: editData.cityName,
                state: editData.stateName,
                country: editData.countryName,
                address_line_1: editData.addressLine1,
                address_line_2: editData.addressLine2,
                w3wCode: editData.w3wCode,
            };
            setNewAddress({ ...editData, ...locality });
            form.setFieldsValue({ ...editData, ...locality });
            form.validateFields();
        } else if (showCitiesList) {
            fetchLocalityCities();
        }
        if (isInternational) {
            loadCountries();
        }
    }, []);

    useEffect(() => {
        if (isInternational) {
            loadCountries();
        } else {
            setSamplePincode(undefined);
            setRegexForPincode('');
        }
        if (!editData?.id) {
            setNewAddress({
                ...newAddress,
                city: '',
                state: '',
                country: '',
                pincode: '',
            });
            form.setFieldsValue({
                pincode: '',
                city: '',
                state: '',
                country: '',
            });
        }
        form.validateFields();
        if (!newAddress?.country) setStatesList([]);
    }, [isInternational]);

    useEffect(() => {
        if (isInternational && newAddress.country) {
            setNewAddress({
                ...newAddress,
                city: '',
                state: '',
            });
            setCitiesList([]);
            loadStates();
        }
    }, [currentCountry]);

    useEffect(() => {
        updatePhoneRegex();
        if (isInternational && newAddress.country) {
            loadStates();
        }
    }, [newAddress.country]);

    useEffect(() => {
        if (isInternational && newAddress.country && countriesListInternational) {
            countriesListInternational.forEach((country: any) => {
                if (newAddress?.country === country?.country) {
                    setRegexForPincode(country?.pincode_regex);
                    setSamplePincode(country?.sample_pincode);
                    setCountryCodeList(country?.phoneValidations?.map((validation: any) => {
                        return {
                            label: validation.code,
                            value: validation.code,
                        };
                    }));
                    let phoneRegexToSet = new RegExp(
                        country?.phoneValidations?.[0]?.lengthRegex?.replace(/^\//, '').replace(/\/$/, ''),
                    );

                    form.setFieldsValue({
                        countryCode_alternatePhone: country?.phoneValidations?.[0]?.code,
                        countryCode_phone: country?.phoneValidations?.[0]?.code,
                    });

                    country?.phoneValidations?.forEach((phoneValidation: any) => {
                        if (newAddress?.phone?.startsWith(phoneValidation.code)) {
                            const phone = newAddress?.phone?.replace(phoneValidation.code, '');
                            setNewAddress({
                                ...newAddress,
                                phone,
                            });
                            form.setFieldsValue({ phone });
                            phoneRegexToSet = new RegExp(
                                phoneValidation?.lengthRegex?.replace(/^\//, '').replace(/\/$/, ''),
                            );
                        }
                        if (newAddress?.alternatePhone?.startsWith(phoneValidation.code)) {
                            const alternatePhone = newAddress?.alternatePhone?.replace(phoneValidation.code, '');
                            setNewAddress({
                                ...newAddress,
                                alternatePhone,
                            });
                            form.setFieldsValue({ alternatePhone });
                            phoneRegexToSet = new RegExp(
                                phoneValidation?.lengthRegex?.replace(/^\//, '').replace(/\/$/, ''),
                            );
                        }
                    });
                    setPhoneRegExp(phoneRegexToSet);
                }
            });
        }
    }, [newAddress.country, countriesListInternational]);

    useEffect(() => {
        if (isInternational) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            const isValid = isValidPincode(newAddress?.pincode);
            if (isValid !== validPincode) {
                setValidPincode(isValid);
            }
        }
    }, [regexForPincode]);

    useEffect(() => {
        form.validateFields();
    }, [phoneRegExp]);

    useEffect(() => {
        if (isAddressMappingAllowed) {
            if (!(allowedFields.length > 0)) {
                saveLocalityFromAddressMapping();
            }
        } else if (newAddress?.pincode?.length === 6 && !isAddressBookInternational && !isInternational) {
            saveCityState();
        }
    }, [debounceTimePincodeString]);

    useEffect(() => {
        checkW3WCode();
    }, [debounceW3WCodeString]);

    const isEditingPage = () => {
        if (editData?.id) return true;
        return false;
    };

    const createAddress = async () => {
        setCreating(true);
        const apiBody: any = {
            id: editData?.id,
            addressLine1: newAddress.address_line_1,
            addressLine2: newAddress.address_line_2,
            cityName: newAddress.city,
            isInternational: isInternational || false,
            name: newAddress.name,
            phone: newAddress.phone && isInternational
                ? `${form.getFieldValue('countryCode_phone') || ''}${newAddress.phone}`
                : newAddress.phone,
            alternatePhone: newAddress.alternatePhone && isInternational
                ? `${form.getFieldValue('countryCode_alternatePhone') || ''}${newAddress.alternatePhone}`
                : newAddress.alternatePhone,
            pincode: newAddress.pincode,
            stateName: newAddress.state,
            countryName: newAddress.country,
            locationId: enableAsn ? newAddress.locationId : undefined,
            email: newAddress.email,
            addressCode: newAddress.addressCode,
            w3wCode: newAddress.w3wCode,
            latitude: newAddress.latitude?.toString(),
            longitude: newAddress.longitude?.toString(),
            companyName: newAddress.companyName,
            addressCategory: newAddress.addressCategory,
        };

        if (newAddress.locationId) {
            const customerCode = window.localStorage.getItem('userCode');
            apiBody.locationCode = customerCode ? `${customerCode}_${newAddress.locationId}` : '';
        }
        const isPluginAddress = ['oldDstAddress', 'oldSrcAddress'].includes(editData?.id);

        if (isPluginAddress) {
            updateFormData(apiBody);
            setCreating(false);
            onClose();
            return;
        }
        if (editData?.id) {
            const response = await editAddress(apiBody);
            setCreating(false);
            if (response.isSuccess) {
                message.success('Address successfully edited');
                onClose();
            } else {
                message.error(response.errorMessage);
            }
            return;
        }
        const response = await saveAddress(apiBody);
        setCreating(false);
        if (response.isSuccess) {
            message.success('Address successfully saved');
            onClose();
        } else {
            message.error(response.errorMessage);
        }
    };

    const updateAddressField = (key: string, value: string | undefined) => {
        if (key === 'country') {
            let countryPhoneRegex;
            const countryPhoneRegexObj = countryWisePhoneRegex?.filter((elem) => elem.name === value || elem.code === value);
            if (countryPhoneRegexObj && countryPhoneRegexObj.length && countryPhoneRegexObj[0].phone_regex) {
                countryPhoneRegex = new RegExp(countryPhoneRegexObj[0].phone_regex.replace(/^\//, '').replace(/\/$/, ''));
            } else if (phoneRegex) {
                countryPhoneRegex = new RegExp(phoneRegex.replace(/^\//, '').replace(/\/$/, ''));
            }
            if (countryPhoneRegex) {
                setPhoneRegExp(countryPhoneRegex);
            }
        }
        setNewAddress({
            ...newAddress,
            [key]: value,
        });
    };

    const isValidPhoneNumber = (num: string | undefined) => {
        if (isInternational && (Boolean(newAddress?.country) !== Boolean(countryCodeList))) return true;
        if (phoneRegExp === null || !num) return true;
        return phoneRegExp?.test(num);
    };

    const isValidEmail = (email: string | undefined) => {
        if (!email) return true;
        return emailRegex.test(email);
    };

    const isValidPincode = (pincode: string | undefined) => {
        if (!regexForPincode || !pincode) return true;
        const re = new RegExp(regexForPincode?.replace(/^\//, '')?.replace(/\/$/, ''));
        return re.test(pincode);
    };

    const textValidity = (field : any) => {
        if (field.value) {
            if (field.value.length >= 3) {
                return true;
            }
        } else if (!field.isMandatory) {
            return true;
        }
        return false;
    };

    const isValidTextFields = (addressToValidate: any) => {
        const fieldsToValidate = [
            {
                value: addressToValidate?.name,
                isMandatory: consignmentValidator.name.required,
            },
            {
                value: addressToValidate?.address_line_1,
                isMandatory: consignmentValidator.address_line_1.required,
            },
            {
                value: addressToValidate?.address_line_2,
                isMandatory: consignmentValidator.address_line_2.required,
            },
        ];

        return fieldsToValidate.every(textValidity);
    };

    const renderName = () => {
        return (
            <div className={classes.newAddressItem}>
                <span className={classes.newAddressTitle}>
                    {t('address_name')}
                    {consignmentValidator.name.required ? '*' : ''}
                </span>
                <Input
                    value={newAddress.name}
                    className={classes.newAddressInput}
                    placeholder={t('address_name')}
                    onChange={(e) => updateAddressFieldWithValidations('name', e.target.value)}
                />
            </div>
        );
    };

    const renderAddressCode = () => {
        if (!showAddressCode) return null;
        return (
            <div className={classes.newAddressItem}>
                <span className={classes.newAddressTitle}>
                    {t('address_code')}
                    *
                </span>
                <Input
                    value={newAddress.addressCode}
                    disabled={isEditingPage()}
                    className={classes.newAddressInput}
                    placeholder={t('address_code_placeholder')}
                    onChange={(e) => updateAddressFieldWithValidations('addressCode', e.target.value)}
                />
            </div>
        );
    };

    const renderAddressLine2 = () => {
        return (
            <div className={classes.newAddressItem}>
                <span className={classes.newAddressTitle}>
                    {t('address_line_2')}
                    {consignmentValidator.address_line_2.required ? '*' : ''}
                </span>
                <Input
                    value={newAddress.address_line_2}
                    className={classes.newAddressInput}
                    placeholder={t('address_line_2')}
                    onChange={(e) => updateAddressFieldWithValidations('address_line_2', e.target.value)}
                />
            </div>
        );
    };

    const renderAddressLine1 = () => {
        return (
            <div className={classes.newAddressItem}>
                <span className={classes.newAddressTitle}>
                    {t('address_line_1')}
                    {consignmentValidator.address_line_1.required ? '*' : ''}
                </span>
                <Input
                    value={newAddress.address_line_1}
                    className={classes.newAddressInput}
                    placeholder={t('address_line_1')}
                    onChange={(e) => updateAddressFieldWithValidations('address_line_1', e.target.value)}
                />
            </div>
        );
    };

    const renderAddressRow = () => {
        return (
            <div className={classes.addressRow}>
                {renderAddressLine1()}
                {renderAddressLine2()}
            </div>
        );
    };

    const loadPincodes = async (value: any) => {
        if (!value) return;

        setLoading(true);

        const response = await fetchPincodeList(value);

        if (response && response.isSuccess) {
            setLocalityPincodesList(response.data || {});
        }
        setLoading(false);
    };

    const renderPincodeBox = () => {
        if (isAddressMappingAllowed && localityPincodesList
            && !(allowedFields.length > 0 && allowedFields.includes('pincode'))) {
            return (
                <Select
                    value={newAddress.pincode}
                    className={classes.pincodeInput}
                    dropdownClassName={classes.pincodeInputDropdown}
                    placeholder={t('address_pincode')}
                    onChange={(e) => updateAddressFieldWithValidations('pincode', e)}
                    options={
                        localityPincodesList.map((pincode: any) => {
                            return {
                                label: pincode.name,
                                value: pincode.code,
                            };
                        })
                    }
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    onSearch={loadPincodes}
                    notFoundContent={null}
                    showSearch
                />
            );
        }
        return (
            <Form.Item
                className={classes.pincodeWithExtra}
                name="pincode"
                extra={samplePincode ? `Ex. ${samplePincode}` : undefined}
            >
                <Input
                    value={newAddress.pincode}
                    className={classes.pincodeInput}
                    placeholder={t('address_pincode')}
                    onChange={(e) => {
                        let pincodeValue = e.target.value || '';
                        pincodeValue = pincodeValue.toString().trim();
                        updateAddressFieldWithValidations('pincode', pincodeValue);
                        const isValid = isValidPincode(pincodeValue);
                        if (isValid !== validPincode) {
                            setValidPincode(isValid);
                        }
                    }}
                    style={validPincode ? {} : { borderColor: 'red' }}
                />
            </Form.Item>
        );
    };

    const renderPincode = () => {
        return (
            <div className={classes.newAddressItem}>
                <span className={classes.newAddressTitle}>
                    {t('address_pincode')}
                    {consignmentValidator.pincode.required && !isInternational ? '*' : ''}
                </span>
                {renderPincodeBox()}
            </div>
        );
    };

    const renderCityBox = () => {
        if (isAddressMappingAllowed && showCitiesList && localityCitiesList
            && !(allowedFields.length > 0 && allowedFields.includes('city'))) {
            return (
                <Select
                    value={newAddress.city}
                    className={classes.cityInput}
                    placeholder={t('address_city')}
                    onChange={(e: any) => updateAddressFieldWithValidations('city', e)}
                    options={
                        localityCitiesList.map((city: any) => {
                            return {
                                label: city.name,
                                value: city.name,
                            };
                        })
                    }
                    disabled={disableLocality}
                    showSearch
                />
            );
        }
        return (
            <Input
                value={newAddress.city}
                className={classes.cityInput}
                placeholder={t('address_city')}
                onChange={(e) => updateAddressFieldWithValidations('city', e.target.value)}
                disabled={disableLocality}
            />
        );
    };

    /* eslint-disable no-underscore-dangle */
    const renderCity = () => {
        return (
            <div className={classes.newAddressItem}>
                <span className={classes.newAddressTitle}>
                    {t('address_city')}
                    {consignmentValidator.city.required ? '*' : ''}
                </span>
                { isInternational
                    ? (
                        <Select
                            value={newAddress.city}
                            className={classes.cityInput}
                            placeholder={t('address_city')}
                            onChange={(e) => updateAddressField('city', e)}
                            options={
                                citiesList.map((city) => {
                                    return {
                                        key: city._id,
                                        label: city.city_name,
                                        value: city.city_name,
                                    };
                                })
                            }
                            defaultActiveFirstOption={false}
                            filterOption={false}
                            onSearch={loadCities}
                            notFoundContent={null}
                            showSearch
                        />
                    ) : (renderCityBox()) }
            </div>
        );
    };
    /* eslint-enable no-underscore-dangle */

    const renderState = () => {
        return (
            <div className={classes.newAddressItem}>
                <span className={classes.newAddressTitle}>
                    {t('address_state')}
                    {consignmentValidator.state.required && !isInternational ? '*' : ''}
                </span>
                { isInternational
                    ? (
                        <Select
                            value={newAddress.state}
                            className={classes.stateInput}
                            placeholder={t('address_state')}
                            onChange={(e) => updateAddressFieldWithValidations('state', e)}
                            showSearch
                        >
                            {
                                statesList.map((state) => {
                                    return (
                                        <Select.Option
                                            value={state.state_name}
                                            // eslint-disable-next-line no-underscore-dangle
                                            key={`${state.state_name}-${state._id}`}
                                        >
                                            {state.state_name}
                                        </Select.Option>
                                    );
                                })
                            }
                        </Select>
                    ) : (
                        <Input
                            // disabled
                            value={newAddress.state}
                            className={classes.stateInput}
                            placeholder={t('address_state')}
                            onChange={(e) => updateAddressFieldWithValidations('state', e.target.value)}
                            disabled={disableLocality}
                        />
                    )}
            </div>
        );
    };

    const renderCountryBox = () => {
        if (isAddressMappingAllowed && countriesList && !(allowedFields.length > 0 && allowedFields.includes('country'))) {
            return (
                <Select
                    value={newAddress.country}
                    showSearch
                    allowClear
                    className={classes.countryInput}
                    placeholder={t('address_country')}
                    onChange={(e) => {
                        updateAddressFieldWithValidations('country', e);
                        setCurrentCountry(e);
                    }}
                >
                    {
                        countriesList.map((opt) => {
                            return (
                                <Select.Option
                                    value={opt.code}
                                    key={`${opt.name}-${opt.id}`}
                                >
                                    {opt.name}
                                </Select.Option>
                            );
                        })
                    }
                </Select>
            );
        }
        return (
            <Input
                value={newAddress.country}
                className={classes.countryInput}
                placeholder={t('address_country')}
                onChange={(e) => updateAddressFieldWithValidations('country', e.target.value)}
                disabled={disableLocality}
            />
        );
    };

    const renderCountry = () => {
        return (
            <div className={classes.newAddressItem}>
                <span className={classes.newAddressTitle}>
                    {t('address_country')}
                    {consignmentValidator.country.required || isInternational ? '*' : ''}
                </span>
                { isInternational
                    ? (
                        <Select
                            value={newAddress.country}
                            className={classes.countryInput}
                            placeholder={t('address_country')}
                            onChange={(e) => {
                                updateAddressFieldWithValidations('country', e);
                                setCurrentCountry(e);
                            }}
                            options={
                                countriesListInternational.map((country) => {
                                    return {
                                        label: country.country,
                                        value: country.country,
                                    };
                                })
                            }
                            showSearch
                        />
                    ) : (renderCountryBox())}
            </div>
        );
    };

    const renderLocality = () => {
        if (isInternational) {
            return (
                <div className={classes.addressRow}>
                    {renderCountry()}
                    {renderState()}
                    {renderCity()}
                    {renderPincode()}
                </div>
            );
        }
        return (
            <div className={classes.addressRow}>
                {renderPincode()}
                {renderCity()}
                {renderState()}
                {renderCountry()}
            </div>
        );
    };

    const renderCountryCode = (type: string) => {
        if (!countryCodeList) return null;
        if (!isInternational) return null;
        return (
            <Form.Item
                name={`countryCode_${type}`}
                className={classes.mobileInputCode}
                initialValue={countryCodeList[0].value}
            >
                <Select
                    allowClear={false}
                    showArrow={false}
                    options={countryCodeList}
                    style={{ width: '100%' }}
                />
            </Form.Item>
        );
    };

    const renderPhoneNumber = () => {
        return (
            <div className={classes.newAddressItem}>
                <span className={classes.newAddressTitle}>
                    {t('mobile_number')}
                    {consignmentValidator.phone.required ? '*' : ''}
                </span>
                <Form.Item
                    name="phone"
                    rules={[{
                        validator: (rule, value, cb) => {
                            if (!isValidPhoneNumber(value)) {
                                return cb('Invalid phone no.');
                            }
                            return cb();
                        },
                    }]}
                >
                    <Input
                        style={validPhone ? {} : { borderColor: 'red' }}
                        className={classes.mobileInput}
                        placeholder={t('mobile_number')}
                        value={newAddress.phone}
                        addonBefore={renderCountryCode('phone')}
                        onChange={
                            (e) => {
                                updateAddressFieldWithValidations('phone', e.target.value);
                                const isValid = isValidPhoneNumber(e.target.value);
                                if (isValid !== validPhone) {
                                    setValidPhone(isValid);
                                }
                            }
                        }
                    />
                </Form.Item>
            </div>
        );
    };

    const renderAlternatePhone = () => {
        return (
            <div className={classes.newAddressItem}>
                <span className={classes.newAddressTitle}>
                    {t('alternate_mobile_number')}
                </span>
                <Form.Item
                    name="alternatePhone"
                    rules={[{
                        validator: (rule, value, cb) => {
                            if (!isValidPhoneNumber(value)) {
                                return cb('Invalid phone no.');
                            }
                            return cb();
                        },
                    }]}
                >
                    <Input
                        className={classes.mobileInput}
                        placeholder={t('alternate_mobile_number')}
                        addonBefore={renderCountryCode('alternatePhone')}
                        value={newAddress.alternatePhone}
                        onChange={
                            (e) => {
                                updateAddressFieldWithValidations('alternatePhone', e.target.value);
                                const isValid = isValidPhoneNumber(e.target.value);
                                if (isValid !== validAlternatePhone) {
                                    setValidAlternatePhone(isValid);
                                }
                            }
                        }
                        style={validAlternatePhone ? {} : { borderColor: 'red' }}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderLocationId = () => {
        return (
            <div className={classes.newAddressItem}>
                <span className={classes.newAddressTitle}>
                    Location ID*
                </span>
                <Input
                    value={newAddress.locationId}
                    className={classes.locationIdInput}
                    placeholder="Location ID"
                    onChange={(e) => updateAddressField('locationId', e.target.value)}
                />
            </div>
        );
    };

    const renderEmail = () => {
        return (
            <div className={classes.newAddressItem}>
                <span className={classes.newAddressTitle}>
                    {t('address_email')}
                    {isInternational ? '*' : ''}
                </span>
                <Input
                    className={classes.mobileInput}
                    placeholder={t('address_email')}
                    value={newAddress.email}
                    type="email"
                    onChange={
                        (e) => {
                            updateAddressField('email', e.target.value);
                            const isValid = isValidEmail(e.target.value);
                            if (isValid !== validEmail) {
                                setValidEmail(isValid);
                            }
                        }
                    }
                    style={validEmail ? {} : { borderColor: 'red' }}
                />
            </div>
        );
    };

    const renderCompanyName = () => {
        return (
            <div className={classes.newAddressItem}>
                <span className={classes.newAddressTitle}>
                    {t('company_name')}
                    {isCompanyNameMandatory ? '*' : ''}
                </span>
                <Form.Item name="companyName">
                    <Input
                        value={newAddress.companyName}
                        className={classes.newAddressInput}
                        placeholder={t('company_name')}
                        onChange={(e) => updateAddressField('companyName', e.target.value)}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderAddressCategory = () => {
        return (
            <div className={classes.newAddressItem}>
                <span className={classes.newAddressTitle}>
                    {t('address_category')}
                    {isAddressCategoryMandatory ? '*' : ''}
                </span>
                <Form.Item name="addressCategory">
                    <Space direction="horizontal">
                        <Radio.Group
                            className={classes.newAddressInput}
                            options={
                                addressCategoryTypes?.map((category) => ({
                                    label: category?.name,
                                    value: category?.name,
                                }))
                            }
                            value={newAddress.addressCategory}
                            onChange={(e) => updateAddressField('addressCategory', e.target.value)}
                        />
                    </Space>
                </Form.Item>
            </div>
        );
    };

    const renderPhoneNumbers = () => {
        return (
            <div className={classes.addressRow}>
                {renderPhoneNumber()}
                {renderAlternatePhone()}
                {enableAsn && renderLocationId()}
                {showEmail ? renderEmail() : null}
            </div>
        );
    };

    const renderCompanyAndBusinessType = () => {
        return (
            <div className={classes.addressRow}>
                {renderCompanyName()}
                {renderAddressCategory()}
            </div>
        );
    };

    const renderWhat3Word = () => {
        if (isWhat3WordEnableFlag) {
            return (
                <div className={classes.newAddressItem}>
                    <span className={classes.newAddressTitle}>
                        What3Word Code
                    </span>
                    <div>
                        <Input
                            className={classes.mobileInput}
                            placeholder="Eg. fizzle.clip.quota"
                            value={newAddress.w3wCode}
                            onChange={
                                (e) => {
                                    updateAddressFieldWithValidations('w3wCode', e.target.value);
                                }
                            }
                        />
                        <div className={classes.wrongW3W}>
                            {!isWhat3Word ? <p>please enter a valid what3word code</p> : null }
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    };

    const btnDisabled = () => {
        return (
            (consignmentValidator.name.required && !newAddress?.name)
            || (consignmentValidator.address_line_1.required && !newAddress.address_line_1)
            || (consignmentValidator.address_line_2.required && !newAddress.address_line_2)
            || (consignmentValidator.pincode.required && (!newAddress.pincode && !isInternational))
            || (consignmentValidator.state.required && (!newAddress.state && !isInternational))
            || (consignmentValidator.city.required && !newAddress.city)
            || (consignmentValidator.phone.required && !newAddress.phone)
            || (consignmentValidator.country.required && !newAddress.country)
            || (isInternational && !newAddress.email)
            || (showAddressCode && !newAddress.addressCode)
            || (enableAsn && !newAddress.locationId)
            || !isValidPhoneNumber(newAddress.phone)
            || !isValidPhoneNumber(newAddress.alternatePhone)
            || !isValidEmail(newAddress.email)
            || (isInternational && !newAddress.country)
            || !isValidTextFields(newAddress)
            || (!isWhat3Word)
            || !isValidPincode(newAddress.pincode)
            || (isAddressCategoryMandatory && !newAddress.addressCategory)
            || (isCompanyNameMandatory && !newAddress.companyName)
        );
    };

    const renderSubmit = () => {
        return (
            <div className={classes.newAddressItem}>
                <Button
                    type="primary"
                    loading={creating}
                    onClick={createAddress}
                    disabled={btnDisabled()}
                    style={buttonStyle}
                >
                    {editData?.id ? t('edit_address') : t('saved_address')}
                </Button>
            </div>
        );
    };

    const renderNameAndAddressCode = () => {
        return (
            <div className={classes.addressRow}>
                {renderName()}
                {renderAddressCode()}
            </div>
        );
    };

    const renderAddressType = () => {
        if (!showAddressType) return null;
        return (
            <div className={classes.newAddressItem}>
                <span className={classes.newAddressTitle}>
                    {t('address_type')}
                </span>
                <Form.Item name="addressType">
                    <Space direction="horizontal">
                        <Radio.Group
                            disabled={editData?.id}
                            className={classes.newAddressInput}
                            options={[
                                { label: t('domestic'), value: false },
                                { label: t('international'), value: true },
                            ]}
                            value={isInternational}
                            onChange={(e) => setIsInternational(e.target.value)}
                        />
                    </Space>
                </Form.Item>
            </div>
        );
    };

    const renderLatLng = () => {
        return (
            <div className={classes.addressRow}>
                <div className={classes.newAddressItem}>
                    <span className={classes.newAddressTitle}>
                        {t('latitude')}
                    </span>
                    <Input
                        value={newAddress.latitude}
                        className={classes.newAddressInput}
                        placeholder={t('latitude')}
                        onChange={(e) => updateAddressFieldWithValidations('latitude', e.target.value)}
                    />
                </div>
                <div className={classes.newAddressItem}>
                    <span className={classes.newAddressTitle}>
                        {t('longitude')}
                    </span>
                    <Input
                        value={newAddress.longitude}
                        className={classes.newAddressInput}
                        placeholder={t('longitude')}
                        onChange={(e) => updateAddressFieldWithValidations('longitude', e.target.value)}
                    />
                </div>
                <div
                    onClick={() => setShowMapForLocation(true)}
                    style={{
                        color: '#065492',
                        fontSize: '12px',
                        fontWeight: 700,
                        cursor: 'pointer',
                    }}
                >
                    <Tooltip title={t('Select on Map')}>
                        <EnvironmentOutlined style={{ fontSize: '24px' }} />
                    </Tooltip>
                </div>
            </div>
        );
    };

    const renderMapForLocation = () => {
        if (showMapForLocation) {
            const searchText = newAddress.address_line_1 || newAddress.address_line_2;
            return (
                <MapLocationCapture
                    search={searchText}
                    isVisible={showMapForLocation}
                    onClose={() => setShowMapForLocation(false)}
                    onSubmit={(val: any) => {
                        setNewAddress({
                            ...newAddress,
                            latitude: val.lat,
                            longitude: val.lng,
                            address_line_1: val.geocodedAddress || newAddress.address_line_1,
                        });
                        setShowMapForLocation(false);
                    }}
                    defaultLocation={{
                        lat: newAddress.latitude ? +newAddress.latitude : null,
                        lng: newAddress.longitude ? +newAddress.longitude : null,
                    }}
                />
            );
        }

        return <></>;
    };

    return (
        <div className={classes.newAddress}>
            <Form form={form}>
                {renderAddressType()}
                {renderCompanyAndBusinessType()}
                {renderNameAndAddressCode()}
                {renderAddressRow()}
                {renderLocality()}
                {renderWhat3Word()}
                {renderLatLng()}
                {renderMapForLocation()}
                {renderPhoneNumbers()}
                {renderSubmit()}
            </Form>
            {loading && <Loader zIndex={10} />}
        </div>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    return {
        config: state.master.config,
        customer: state.master.Customer,
        partsToShow: state?.master?.parts_to_show,
        showAddressType: state?.master?.parts_to_show?.allow_international_booking,
        customerPortalConfig: state?.master?.config?.customer_portal_config,
    };
};

const hocConfig: HocOptions = {
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectRouter: true,
    connectTranslession: true,
};

const CreateAddressForm = GenericHoc(hocConfig)(CreateAddress);

export default CreateAddressForm;
