/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const cross = (props: any) => (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M7.3299 6.50667C7.55697 6.73478 7.55697 7.10364 7.32827 7.33013C7.21474 7.44338 7.06552 7.5 6.91629 7.5C6.76707 7.5 6.61623 7.44338 6.50269 7.32851L4 4.82578L1.49731 7.32851C1.38377 7.44338 1.23293 7.5 1.08371 7.5C0.934485 7.5 0.785264 7.44338 0.671726 7.33013C0.443029 7.10364 0.443029 6.73478 0.670104 6.50667L3.17604 4.00071L0.670104 1.49474C0.443029 1.26663 0.443029 0.897776 0.671726 0.671285C0.900423 0.444793 1.27023 0.444793 1.49731 0.672902L4 3.17563L6.50269 0.671285C6.72977 0.443175 7.09958 0.443175 7.32827 0.669667C7.55697 0.896158 7.55697 1.26502 7.3299 1.49312L4.82396 4.00071L7.3299 6.50667Z" fill="#EA2626" />
    </svg>
);

const CrossIcon = (props: any) => (
    <Icon component={(innerProps: any) => {
        return cross({ ...innerProps, ...props });
    }}
    />
);

export default CrossIcon;
