/* eslint-disable jsx-a11y/media-has-caption */
import * as React from 'react';
import { HocOptions } from '../common/generic-hoc.types';
import { StylesProps, ThemeType } from '../../theme/jss-types';
import TableModal from '../table-modal/tableModal';
import { fetchSupplementaryServices } from '../../network/supp-services.api';
import {
    getColumnsForSupplementaryServices,
    defaultColumnsForSupplementaryServices,
} from '../supplementary-services/supplementaryServicesColumn';
import { PageKeys } from './consignment.types';
import { consignemtStyles } from './consignment.styles';
import Paginator from '../common/paginator';
import moment from 'moment';
import ConsignmentDetails from './consignment-details';
import ExcelUpload from './excel-upload';
import PrintHistory from './print-history';
import Settings from '../settings/settings';
import CancellationModal from './consignment-cancel';
import FailureList from './cancellation-failure';
import {
    Button,
    DatePicker,
    Dropdown,
    message,
    Table,
    Menu,
    Select,
    Tooltip,
    Tabs,
    Modal,
    Image,
    Popover,
    Upload,
    Row,
    Col,
    Typography,
} from 'antd';
import {
    EditOutlined,
    EyeOutlined,
    PrinterOutlined,
    InfoCircleOutlined,
    ReloadOutlined,
    SettingOutlined,
    EnvironmentOutlined,
    UploadOutlined,
    InfoCircleFilled,
    LinkOutlined,
    DeleteOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import {
    dateKeys,
    PageOptions,
    prettyMovementTypes,
    prettyStatusNames,
    ConsignmentBuckets,
    defaultConsignmentColumns,
    dateKeysPickup,
    dateKeysWithStringTimestamp,
    dateColumnKeys,
    allowedEditStatus,
} from './consignments.constants';
import {
    getLabelType,
} from '../labels/labels.constants';
import {
    downloadConsignments,
    downloadConsignments4X6Files,
    printConsignment,
    printConsignment4x6,
    printConsignment4x6Domestic,
    printInvoice,
    printPodShippingLabel,
    printRoutingLabel,
    getPublishedLabels,
    printAddressLabel,
    printBulkLabel,
} from '../../network/consignments.api';
import {
    Master,
    ConsignmentColumn,
    ConsignmentBucketKeys,
    FilterMetadata,
} from '../../types/master-data-types';
import {
    isEmpty,
    uniqBy,
    get,
    groupBy,
} from 'lodash';
import Helper from '../../library/Helper';
import FilterBlue from '../../assets/filter-blue';
import MoreFilters from './more-filters';
import { loadConsignments, getMoreFilters } from '../../actions/consignment-actions';
import {
    applyFilters,
    setFilters,
    setPagination,
} from '../../actions/generic-action';
import { bindActionCreators } from 'redux';
import { Buckets, NextOrPrev, Pagination } from '../../library/Types';
import { ReduxStore } from '../../reducers/redux.types';
import { RouteChildrenProps } from 'react-router';
import {
    getConsignmentsRoute,
    getPluginOrdersRoute,
    getDownloadsRoute,
    getExcelUploadRoute,
    getPrintHistoryRoute,
} from '../../routing/routing-helper';
import GenericHoc from '../common/generic-hoc';
import { Routes } from '../../library/constants';
import ProofOfDelivery from './pod-details';
import { snakeToPretty } from '../../library/lodash-helper';
import { NAVBAR_HEIGHT, allowedColumns } from 'library/globals';
import EditNotes from './edit-notes';
import EllipsisIcon from 'assets/EllipsisIcon';
import { useTranslation } from 'react-i18next';
import SchedulePickupModal from './consignment-schedule-pickup';
import GalleryModal from '../common/gallery-modal';
import SingleConsignment from 'components/create-consignment/single-consignment';
import SingleConsignmentNew from 'components/create-consignment/single-cn-creation/single-consignment-new';
import { getUploadFileUrls, postUploadFiles, uploadImageToS3 } from 'network/common.api';
import PluginOrders from './plugin-orders';
import { getFilters } from './consignments.helper';
import DocumentDetailsModal from './document-details-modal';
import { PAGE_NAMES, PageData } from 'types/page.types';
import { fetchViewData } from 'actions/objectpage-actions';
import BulkPrint from './bulk-print';
import { printEPodExcel } from 'network/excel-upload';

const { Text } = Typography;
const menuStyle: React.CSSProperties = {
    padding: 12,
    borderBottom: '1px solid #CCCCCC',
    fontWeight: 'bold',
    fontSize: 12,
};

const ActionMenuStyle: React.CSSProperties = {
    padding: '5px 12px',
    fontWeight: 'bold',
    fontSize: 12,
};
interface ConsignmentProps
    extends StylesProps<ReturnType<typeof consignemtStyles>>, RouteChildrenProps {
    master: Master;
    loadconsignments: () => void;
    setfilters: (bucketId: Buckets.CONSIGNMENTS, filters: any) => void;
    applyfilters: (bucketId: Buckets.CONSIGNMENTS) => void;
    setpagination: (bucketId: Buckets.CONSIGNMENTS, newPagination: Partial<Pagination>) => void;
    fetchviewdata: (data: { viewIndex: number, pageName: string, sectionIndex: number, viewId: string }) => void;
    filters: Record<string, any>;
    loading: boolean;
    consignments: any;
    summary: any;
    pagination: Pagination;
    defaultFilters: Record<string, any>;
    uiTheme: ThemeType;
    consignmentPageData: PageData;
}

interface GalleryModalData {
    images: string[];
    videos: string[];
}

interface TextModalData {
    text: string;
    title: string;
}

interface DocumentDetails {
    document_type: string; existing_document_details: { type: string, url: string }[]; new_files_uploaded: any;
}

interface DocumentDetailsUploadPayload {
    type: string,
    url: string,
}
interface RowData {
    url: string,
    new_uploaded: boolean
}

const {
    useState,
    useEffect,
} = React;

const ConsignmentPage = (props: ConsignmentProps) => {
    const {
        master,
        classes,
        filters,
        setfilters,
        applyfilters,
        fetchviewdata,
        loading,
        consignments,
        loadconsignments,
        summary,
        pagination,
        setpagination,
        history,
        defaultFilters,
        uiTheme,
        consignmentPageData,
    } = props;
    const { t } = useTranslation();
    const enable_customer_config = master?.parts_to_show?.enable_customer_config || false;
    const enable_shopify_integration = master?.parts_to_show?.enable_shopify_integration || false;
    const enable_epod_print = master?.parts_to_show?.enable_epod_print || false;
    const useMariaDb = get(master, 'config.customer_portal_config.use_mariadb_in_consignment_view');
    const storage = window?.localStorage;
    const userId = storage?.getItem('userId');
    const userCode = storage?.getItem('userCode');

    const customerProperties = master?.properties;
    let restrictPrintInvoice = false;
    let showCancelButton = true;
    let showShipmentLabelA4 = true;
    let showShippingLabelA6 = true;
    let showshippingLabelPod = true;
    let showShippingLabel4X6 = true;
    let showAddressLabelA4 = true;
    let showAddressLabel4X2 = true;
    let showRoutingLabelA4 = true;
    let showRoutingLabel4x4 = true;
    let showMultipleCnSection = true;
    let showTrackingIcon = true;
    let useThirdPartyLabel = false;
    let showPluginOrders = true;
    let showPrintHistory = true;
    if (customerProperties && enable_customer_config) {
        restrictPrintInvoice = !customerProperties?.print_invoice;
        showCancelButton = customerProperties?.cn_cancel;
        showShipmentLabelA4 = customerProperties?.a4_shipping_label;
        showShippingLabelA6 = customerProperties?.a6_shipping_label;
        showshippingLabelPod = customerProperties?.shipping_label_pod;
        showShippingLabel4X6 = customerProperties?.x46_shipping_label;
        showAddressLabelA4 = customerProperties?.a4_address_label;
        showAddressLabel4X2 = customerProperties?.x42_address_label;
        showRoutingLabelA4 = customerProperties?.a4_routing_label;
        showRoutingLabel4x4 = customerProperties?.x44_routing_label;
        showMultipleCnSection = customerProperties?.cn_multiple_creation;
        showTrackingIcon = customerProperties?.tracking_icon;
        useThirdPartyLabel = customerProperties?.use_third_party_label;
        showPluginOrders = customerProperties?.plugin_orders;
        showPrintHistory = customerProperties?.print_history;
    }
    const getInitialPage = (): PageKeys => {
        switch (history.location.pathname) {
            case `/${Routes.PLUGIN_ORDERS}`:
                if (!(enable_shopify_integration && showPluginOrders)) history.push(`/${Routes.CONSIGNMENTS}`);
                return 'PluginOrders';
            case `/${Routes.EXCEL_UPLOAD}`: return 'ExcelUpload';
            case `/${Routes.PRINT_HISTORY}`: return 'PrintHistory';
            case `/${Routes.CONSIGNMENTS}`:
            default: return 'Consignments';
        }
    };

    const getReferenceNumberFromUrl = () => {
        const { location } = history;
        const { pathname } = location;
        if (pathname.includes(Routes.CONSIGNMENT_DETAILS)) {
            const url = pathname.split('/');
            return url[3];
        }
        return undefined;
    };

    const [pageSelected, setPageSelected] = useState<PageKeys>(getInitialPage());
    const [details, setDetails] = useState<string | undefined>(
        getReferenceNumberFromUrl(),
    );
    const [settingsVisible, setSettingsVisible] = useState<boolean>(false);
    const [moreFilterVisible, setMoreFiltersVisible] = useState<boolean>(false);
    const [downloading, setDownloading] = useState<boolean>(false);
    const [consignmentsSelected, setConsignmentSelected] = useState<string[]>([]);
    const [bulkPrinting, setBulkPrinting] = useState<boolean>(false);
    const [toggle, setToggle] = React.useState<boolean>(true);
    const [podConsignment, setPodConsignment] = React.useState<any>();
    const [dateType, setDateType] = React.useState(dateKeys[0]);
    const [allPublishLabels, setPublishedLabels] = React.useState<any[]>([]);
    const [notesReferenceNumber, setNotesReferenceNumber] = React.useState<any>(null);
    const [notesValue, setNotesValue] = React.useState<any>(undefined);
    const [scheduleVisible, setScheduleVisible] = React.useState<boolean>(false);
    const [filterHeight, setFilterHeight] = React.useState(0);
    const filtersRef = React.useRef<HTMLDivElement>(null);
    const [successVisible, setSuccessVisible] = useState(false);

    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });
    const [rowupload, setRowUpload] = React.useState<any>(null);

    const [cancelVisible, setCancelVisible] = useState<any>({
        visible: false,
        action: '',
    });
    const [failures, setFailures] = React.useState<any>({
        visible: false,
        list: [],
    });
    const [isTextModalVisible, setIsTextModalVisible] = React.useState(false);
    const [isSupplementaryServiceTable, setIsSupplementaryServiceTable] = React.useState<any>(false);
    const [supplementaryServiceData, setSupplementaryServiceData] = React.useState<any>([]);
    const [textModalData, setTextModalData] = React.useState<TextModalData>({ text: '', title: '' });
    const [isGalleryModalVisible, setIsGalleryModalVisible] = React.useState(false);
    const [galleryModalData, setGalleryModalData] = React.useState<GalleryModalData>({ images: [], videos: [] });
    const [imageUrl, setImageUrl] = React.useState<string| null>(null);
    const [editFormVisible, setFormVisible] = React.useState<boolean>(false);
    const [editData, setEditData] = React.useState<any>(null);



    const { config, parts_to_show } = master;
    const { bucketSelected } = filters;
    // to subtract banner height from table
    const bannerHeight = (master?.config?.customer_portal_config?.password_policy_banner_expiry_date
        && new Date(master?.config?.customer_portal_config?.password_policy_banner_expiry_date) <= new Date()) ? 0 : 50;
    const enableCodefree = parts_to_show?.enable_code_free_label_generation || false;
    const showBucketCount = master?.config?.customer_portal_config?.fetch_customer_portal_bucket_count;
    const showPickupScheduleButton = master?.config?.customer_portal_config?.show_pickup_request_button;
    const isCancelAllowed = master?.config?.cancellation_config?.allow_customer_level_cancellation;
    // eslint-disable-next-line max-len
    const isEditAllowed = master?.config?.customer_portal_ops_config?.consignment_flow_type_config?.config_value?.allow_consignment_edit;
    const documentConfig = master?.config?.customer_portal_ops_config?.document_config?.config_value;
    const validDocumentTypes = documentConfig?.allowed_document_types_customer_portal || [];
    const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [modalRow, setModalRow] = useState<any>(null);
    const documentIdToNameMapping = master?.config?.available_document_types_id_to_name_mapping || {};
    const maxFiles = config?.allow_multiple_file_upload_in_customer_portal?.number_of_documents_allowed_in_consignment;
    const [showBulkPrintModal, setShowBulkPrintModal] = useState<boolean>(false);
    const useBulkPrintModal = true;

    const [documentDetails, setDocumentDetails] = useState<any>([]);

    React.useEffect(() => {
        const currentDocDetails: DocumentDetails[] = [];
        const recordDocumentDetails = rowupload?.document_details || [];
        const documentTypeWiseDetails = groupBy(recordDocumentDetails, 'type');
        Object.keys(documentTypeWiseDetails).forEach((key) => {
            const data = documentTypeWiseDetails[key];
            currentDocDetails.push({ document_type: key, existing_document_details: data, new_files_uploaded: [] });
        });
        setDocumentDetails(currentDocDetails);
    }, [rowupload]);

    const [imageUploadLoading, setImageUploadLoading] = useState<boolean>(false);

    const renderDocumentTypeWiseFile = (currentDocumentTypeObject: any, index: number) => {
        const currentDocumentType = currentDocumentTypeObject.document_type;
        const existingDocumentDetails = currentDocumentTypeObject.existing_document_details || [];
        const newDocumentsInCurrentRow = currentDocumentTypeObject.new_files_uploaded || [];

        const availableDocumentTypesList = validDocumentTypes.map(
            (documentType: any) => {
                return {
                    label: documentIdToNameMapping[documentType],
                    value: documentType,
                };
            },
        );

        let currentTotalFiles = 0;
        documentDetails.forEach((data: any) => {
            currentTotalFiles += data?.new_files_uploaded?.length || 0;
            currentTotalFiles += data?.existing_document_details?.length || 0;
        });

        const handleDocumentTypeChange = (value: string) => {
            const newDocumentDetails = [...documentDetails];
            newDocumentDetails[index].document_type = value;
            setDocumentDetails(newDocumentDetails);
        };

        const handleChange = (info: any) => {
            const file = info.file;
            if (file && file.size / 1024 / 1024 < 2) {
                const newDocumentDetails = [...documentDetails];
                newDocumentDetails[index].new_files_uploaded = info.fileList;
                setDocumentDetails(newDocumentDetails);
            }
        };

        const handleRemove = (file: any) => {
            const newDocumentDetails = [...documentDetails];
            newDocumentDetails[index].new_files_uploaded = newDocumentsInCurrentRow.filter((f: any) => f.uid !== file.uid);
            setDocumentDetails(newDocumentDetails);
        };

        const removeRow = (rowIndex: number) => {
            const newDocumentDetails = [...documentDetails];
            newDocumentDetails.splice(rowIndex, 1);
            setDocumentDetails(newDocumentDetails);
        };

        const removeUploadedFile = (file: RowData) => {
            const newDocumentDetails = [...documentDetails];
            if (file.new_uploaded) {
                const filteredFiles = newDocumentsInCurrentRow.filter((f: any) => f.name !== file.url);
                newDocumentDetails[index].new_files_uploaded = filteredFiles;
            } else {
                const filteredFiles = existingDocumentDetails.filter(
                    (doc: { type: string, url: string }) => doc.url !== file.url,
                );
                newDocumentDetails[index].existing_document_details = filteredFiles;
            }
            setDocumentDetails(newDocumentDetails);
        };

        const rows = [];
        const totalFiles: RowData[] = [];
        newDocumentsInCurrentRow.map((newDocument: any) => totalFiles.push({ url: newDocument.name, new_uploaded: true }));
        existingDocumentDetails.map(
            (document: { type: string, url: string }) => totalFiles.push({ url: document.url, new_uploaded: false }),
        );
        for (let i = 0; i < totalFiles.length; i += 3) {
            const rowData = totalFiles.slice(i, i + 3);
            rows.push(rowData);
        }

        return (
            <>
                <div
                    style={{
                        display: 'flex',
                        marginBottom: '20px',
                        marginTop: '10px',
                        alignItems: 'center',
                    }}
                >
                    <div className={classes.grayCircle}>{index + 1}</div>
                    <Select
                        style={{ width: '40%', marginRight: '20px' }}
                        placeholder="Choose Document Type"
                        value={currentDocumentType}
                        onChange={handleDocumentTypeChange}
                        options={availableDocumentTypesList}
                    />
                    <Upload
                        name="file"
                        disabled={
                            !currentDocumentType || currentTotalFiles >= maxFiles
                        }
                        fileList={newDocumentsInCurrentRow}
                        onChange={handleChange}
                        onRemove={handleRemove}
                        multiple
                        beforeUpload={(file) => {
                            const isLt2M = file.size / 1024 / 1024 < 2;
                            if (!isLt2M) {
                                message.error('File must be smaller than 2MB!');
                            }
                            return isLt2M;
                        }}
                        showUploadList={false}
                    >
                        <Button
                            disabled={
                                !currentDocumentType || currentTotalFiles >= maxFiles
                            }
                            icon={<UploadOutlined />}
                        >
                            Upload Files
                        </Button>
                    </Upload>
                    <div style={{ flexGrow: '1' }} />
                    <DeleteOutlined
                        style={{ color: '#666666', fontSize: '22px' }}
                        onClick={() => removeRow(index)}
                    />
                </div>
                {rows.length > 0 && (
                    <div style={{ marginLeft: '52px' }}>
                        <>
                            {rows.map((row) => (
                                <Row
                                    gutter={[16, 16]}
                                    style={{ marginBottom: '20px' }}
                                >
                                    {row.map((item: RowData) => (
                                        <Col key={item.url} span={8} style={{ maxHeight: '22px', height: '22px' }}>
                                            <div key={item.url} className={classes.customFlex}>
                                                <a
                                                    href={item.url}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    style={
                                                        item?.new_uploaded
                                                            ? {
                                                                pointerEvents: 'none',
                                                                cursor: 'not-allowed',
                                                            }
                                                            : {}
                                                    }
                                                >
                                                    <div key={item.url} className={classes.customFlex}>
                                                        <LinkOutlined
                                                            style={{
                                                                color: '#666666',
                                                            }}
                                                        />
                                                        <Text
                                                            style={{
                                                                textAlign: 'center',
                                                                marginLeft:
                                                                    '10px',
                                                                marginRight:
                                                                    '10px',
                                                                maxWidth: '80%',
                                                                overflow: 'hidden',
                                                                whiteSpace: 'nowrap',
                                                                width: '135px',
                                                                textOverflow:
                                                                    'ellipsis',
                                                                color: item.new_uploaded
                                                                    ? '#666666'
                                                                    : '#006EC3',
                                                            }}
                                                        >
                                                            {item.url}
                                                        </Text>
                                                    </div>
                                                </a>
                                                <DeleteOutlined
                                                    style={{ color: '#666666' }}
                                                    onClick={() => removeUploadedFile(item)}
                                                />
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            ))}
                        </>
                    </div>
                )}
            </>
        );
    };

    const handlemodalclose = () => {
        setDocumentDetails([]);
        setShowUploadModal(false);
        setRowUpload(null);
    };

    const fetchConsignmentData = (bucket: string) => {
        if (consignmentPageData && consignmentPageData.metadata.sectionList.length > 0) {
            const viewIndex = consignmentPageData.metadata.sectionList.flat().findIndex(
                (v) => v.id === bucket,
            );
            if (viewIndex > -1) {
                const sectionIndex = (viewIndex + 1) % consignmentPageData.metadata.sectionList.length;
                const viewData = consignmentPageData.metadata.sectionList[sectionIndex][viewIndex];
                fetchviewdata({
                    viewIndex,
                    pageName: PAGE_NAMES.CONSIGNMENTS,
                    sectionIndex,
                    viewId: viewData.id,
                });
            }
        }
    };

    const handleUploadImageSubmit = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setImageUploadLoading(true);

        // computing total new files uploaded
        const newFileList: any = [];
        documentDetails.forEach((data: any, index: number) => {
            let newFiles = data.new_files_uploaded || [];
            const documentType = data.document_type;
            newFiles = newFiles.map((newFile: any) => {
                return {
                    ...newFile,
                    document_type: documentType,
                    index,
                };
            });
            newFileList.push(...newFiles);
        });

        // promise to upload new file to s3 by computing its url from backend first
        const promises = newFileList.map(async (file: any) => {
            const bodyGetUrl = {
                content_type: file.type,
                reference_number: rowupload.reference_number,
            };

            const response = await getUploadFileUrls(bodyGetUrl);
            const upload = await uploadImageToS3(
                new Blob([file.originFileObj as BlobPart], {
                    type: file?.type,
                }),
                response?.data?.result?.imageSignedUrl,
                file?.type,
            );
            if (!upload.isSuccess) {
                message.error('Could not upload file');
            }
            const s3UrlToReturn = upload.isSuccess ? response?.data?.result?.imageUrl : null;
            return { url: s3UrlToReturn, document_type: file.document_type, index: file.index };
        });

        try {
            const fileUrls = await Promise.all(promises);
            const newlyUploaded = fileUrls.filter((file: any) => !!file.url);
            const newlyUploadedFiles = groupBy(newlyUploaded, 'index');

            // generating api payload for each document type
            const documentDetailsToUpload: DocumentDetailsUploadPayload[] = [];

            documentDetails.forEach((data: any, index: number) => {
                const documentType = data.document_type;
                const existingDocumentDetails = data.existing_document_details || [];
                if (documentType) {
                    const newFileObjects = newlyUploadedFiles[index] || [];
                    [...existingDocumentDetails, ...newFileObjects].forEach((file) => {
                        documentDetailsToUpload.push({
                            type: documentType,
                            url: file.url,
                        });
                    });
                }
            });
            const response = await postUploadFiles({
                documentDetails: documentDetailsToUpload,
                referenceNumber: rowupload.reference_number,
            });
            if (response.isSuccess) {
                message.success('Files uploaded successfully');
                fetchConsignmentData(filters.bucketSelected);
                setfilters(Buckets.CONSIGNMENTS, filters);
                applyfilters(Buckets.CONSIGNMENTS);
            } else {
                message.error(response.errorMessage || 'Could not upload files');
            }
            handlemodalclose();
        } catch (error) {
            message.error(error);
            handlemodalclose();
        }
        setImageUploadLoading(false);
    };

    const handleAddDocumentType = () => {
        const newDocumentDetails = [...documentDetails];
        newDocumentDetails.push({ document_type: '', document_urls: [] });
        setDocumentDetails(newDocumentDetails);
    };
    const showShippingLabelButton = showShipmentLabelA4 || showShippingLabelA6
    || showshippingLabelPod || showShippingLabel4X6 || enableCodefree;
    const showRoutingLabelButton = showRoutingLabelA4 || showRoutingLabel4x4;
    const showAddressLabelButton = showAddressLabelA4 || showAddressLabel4X2;

    const handleBucketChange = (bucket: string) => {
        if (bucket === filters.bucketSelected) {
            return;
        }
        const newFilters = {
            ...filters,
            bucketSelected: bucket,
        };
        fetchConsignmentData(bucket);
        setfilters(Buckets.CONSIGNMENTS, newFilters);
        applyfilters(Buckets.CONSIGNMENTS);
    };

    useEffect(() => {
        if (pageSelected !== 'Consignments') {
            return;
        }
        if (consignmentPageData && consignmentPageData.hasViews() && bucketSelected === 'customer_portal_all') {
            handleBucketChange(consignmentPageData.metadata.sectionList[0][0].id);
        }
        loadconsignments();
    }, [filters, toggle]);

    React.useEffect(() => {
        const handleResize = () => {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth,
            });
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });

    useEffect(() => {
        if (filtersRef?.current?.clientHeight !== filterHeight) {
            setFilterHeight(filtersRef?.current?.clientHeight || 0);
        }
    }, [dimensions]);

    const getAllPublishedLabels = async () => {
        const response = await getPublishedLabels();
        if (response.isSuccess) {
            const validateList = (label: any) => label.is_active;
            const updatedLabels = response.data?.metadata_list?.filter(validateList);
            if (updatedLabels && Array.isArray(updatedLabels) && updatedLabels.length) {
                setPublishedLabels(updatedLabels);
            }
        } else {
            const errorMessage = response.errorMessage || 'Something went wrong';
            message.error(errorMessage);
        }
    };

    useEffect(() => {
        getAllPublishedLabels();
    }, []);

    const renderText = (text: string, row: any) => {
        return (
            <div
                className={classes.cellValue}
                onClick={() => {
                    setDetails(row?.reference_number);
                    history.push(`/${Routes.CONSIGNMENT_DETAILS}/${row.reference_number}`);
                }}
            >
                {text}
            </div>
        );
    };

    const handlePrintLabel = (fileBuffer: any, fileName: string) => {
        if (fileBuffer.isSuccess) {
            const name = fileBuffer.filename || fileName;
            Helper.downloadFileData(
                fileBuffer.data,
                name,
                true,
                'pdf',
            );
            message.success('Success');
        } else {
            message.error('Unable to fetch label at the moment. Please try again after sometime');
        }
        setBulkPrinting(false);
    };

    const handlePrint = async (referenceNumbers: any, isSmall: boolean, fileName: string) => {
        setBulkPrinting(true);
        const fileBuffer = await printConsignment({
            isSmall,
            referenceNumbers,
            useThirdPartyLabel,
        });
        handlePrintLabel(fileBuffer, fileName);
    };

    const handlePrintPod = async (referenceNumbers: any, isSmall: boolean, fileName: string) => {
        setBulkPrinting(true);
        const fileBuffer = await printPodShippingLabel({
            isSmall,
            referenceNumbers,
        });
        handlePrintLabel(fileBuffer, fileName);
    };

    const handlePrintLabel4X6 = (fileBuffer: any, fileName: string) => {
        if (fileBuffer.isSuccess) {
            const name = fileBuffer.filename || fileName;
            Helper.downloadFileData(
                fileBuffer.data,
                name,
                true,
                'pdf',
            );
            message.success('Success');
        } else {
            message.error('Failed to generate PDF!');
        }
    };

    const handlePrint4X6 = async (referenceNumbers: any) => {
        setBulkPrinting(true);
        const fileUrls = await printConsignment4x6({
            referenceNumbers,
        });
        fileUrls?.data?.forEach((fileUrlsList: any) => {
            fileUrlsList?.forEach(async (fileUrl: any) => {
                if (fileUrl && fileUrl.length) {
                    const fileBuffer = await downloadConsignments4X6Files(fileUrl);
                    const fileUrlSplit = fileUrl.split('/');
                    handlePrintLabel4X6(fileBuffer, fileUrlSplit[fileUrlSplit.length - 1]);
                }
            });
        });
        setBulkPrinting(false);
    };
    const handlePrintvendor = async (fileUrl: string) => {
        window.open(fileUrl);
    };

    const handlePrint4X6Domestic = async (referenceNumbers: any, fileName: string) => {
        setBulkPrinting(true);
        const fileBuffer = await printConsignment4x6Domestic({
            referenceNumbers,
        });
        handlePrintLabel(fileBuffer, fileName);
    };

    const handleOnClickPrint4X6 = async (referenceNumbers: any, isInternational: boolean, fileName: string) => {
        if (isInternational) {
            await handlePrint4X6(referenceNumbers);
        } else {
            await handlePrint4X6Domestic(referenceNumbers, fileName);
        }
    };

    const handlePrintRouting = async (referenceNumbers: any, isSmall: boolean, fileName: string) => {
        setBulkPrinting(true);
        const fileBuffer = await printRoutingLabel({
            isSmall,
            referenceNumbers,
        });
        handlePrintLabel(fileBuffer, fileName);
    };

    const handlePrintAddress = async (referenceNumbers: any, isSmall: boolean, fileName: string) => {
        setBulkPrinting(true);
        const fileBuffer = await printAddressLabel({
            small: isSmall,
            referenceNumbers,
        });
        handlePrintLabel(fileBuffer, fileName);
    };
    const handlePrintCSV = async (row: any, printType: string, isSmall = false) => {
        setBulkPrinting(true);
        const fileBuffer = await printEPodExcel({
            clientId: row.customer_id || userId,
            printType,
            isSmall,
            consignmentNumbers: [row.reference_number],
            csvId: row.customer_id || userId,
        });
        const fileName = `Epod_${row.reference_number}.pdf`;
        handlePrintLabel(fileBuffer, fileName);
    };
    const handlePrintInvoice = async (referenceNumbers: any, fileName: string) => {
        setBulkPrinting(true);
        const fileBuffer = await printInvoice({
            referenceNumbers,
        });
        handlePrintLabel(fileBuffer, fileName);
    };

    const renderPrintInvoice = (row: any) => {
        if (
            (!row.is_international
            || !master.Customer.show_invoice_options
            || row.load_type === 'DOCUMENT') || (restrictPrintInvoice)
        ) {
            return null;
        }
        return (
            <Menu.Item
                style={menuStyle}
                onClick={() => handlePrintInvoice(
                    [row.reference_number],
                    `Invoice_${row.reference_number}.pdf`,
                )}
            >
                Print Invoice
            </Menu.Item>
        );
    };

    const renderPrintvendorlabel = (row: any) => {
        const fileUrls = row.international_vendor_label_url;
        const fileUrl = fileUrls?.length ? fileUrls[0] : null;
        if (
            !row.is_international || !Helper.isFirstMileConsignment(row.status) || !fileUrl
        ) {
            return null;
        }
        return (
            <Menu.Item
                style={{
                    ...menuStyle,
                    border: 'none',
                }}
                onClick={() => handlePrintvendor(fileUrl)}
            >
                Vendor Label
            </Menu.Item>
        );
    };
    const handlePageChange = (page: PageKeys) => {
        if (page === pageSelected) {
            return;
        }
        let nextPage = getConsignmentsRoute();
        switch (page) {
            case 'PluginOrders': nextPage = getPluginOrdersRoute(); break;
            case 'ExcelUpload': nextPage = getExcelUploadRoute(); break;
            case 'PrintHistory': nextPage = getPrintHistoryRoute(); break;
            case 'Consignments':
            default: nextPage = getConsignmentsRoute(); break;
        }
        setPageSelected(page);
        history.push(nextPage);
    };

    const handleEPodPrint = async (printType: string, isSmall: boolean = false) => {
        setBulkPrinting(true);
        const payload = {
            userCode,
            clientId: userId,
            printType,
            isSmall,
            consignmentNumbers: consignmentsSelected,
            csvId: userId,
        };
        try {
            const response = await printEPodExcel(payload);
            setBulkPrinting(false);

            if (response.isSuccess) {
                message.success('Requested Successfully');
                if (consignmentsSelected.length > 1) {
                    history.push(getDownloadsRoute());
                } else {
                    const fileName = 'Epod.pdf';
                    handlePrintLabel(response.fileBuffer, fileName);
                }
                handlePageChange('PrintHistory');
            } else {
                message.error(response.errorMessage);
            }
        } catch (error) {
            setBulkPrinting(false);
            message.error('Failed to process the print request.');
        }
    };
    const renderEpodLabel = (row: any) => {
        if (
            (enable_epod_print && (row.status && row.status.toLowerCase() === 'delivered'))
        ) {
            return (
                <Menu.Item
                    style={menuStyle}
                    onClick={() => handlePrintCSV(row, 'Epod_Label', false)}
                >
                    Print Epod Label
                </Menu.Item>
            );
        }
        return null;
    };

    const renderCodeFreeLabelOptions = (row: any) => {
        return allPublishLabels.map((label: any) => (
            <Menu.Item
                style={menuStyle}
                onClick={() => handlePrint(
                    [row.reference_number],
                    getLabelType(label.label_category),
                    `Shipping_Label_${row.reference_number}.pdf`,
                )}
            >
                {label.label_category}
            </Menu.Item>
        ));
    };

    const renderCodeFreeBulkLabelOptions = () => {
        return allPublishLabels.map((label: any) => (
            <Menu.Item
                style={menuStyle}
                onClick={() => handlePrint(
                    consignmentsSelected,
                    getLabelType(label.label_category),
                    'Shipping_Label_Multiple.pdf',
                )}
            >
                {label.label_category}
            </Menu.Item>
        ));
    };

    const renderPrintOptions = (row: any) => {
        return (
            <Menu>
                {showShippingLabelButton && (
                    <Menu.SubMenu
                        title={(
                            <span
                                style={{
                                    ...menuStyle,
                                    padding: 0,
                                    border: 'none',
                                }}
                            >
                                {t('shipping_label')}
                            </span>
                        )}
                        style={{
                            ...menuStyle,
                            padding: '6px 0px',
                            border: 'none',
                        }}
                    >
                        {enableCodefree ? renderCodeFreeLabelOptions(row) : (
                            <>
                                {showShipmentLabelA4 && (
                                    <Menu.Item
                                        style={menuStyle}
                                        onClick={() => handlePrint(
                                            [row.reference_number],
                                            false,
                                            `Shipping_Label_${row.reference_number}.pdf`,
                                        )}
                                    >
                                        {t('label_a4')}
                                    </Menu.Item>
                                )}
                                {showShippingLabelA6 && (
                                    <Menu.Item
                                        style={menuStyle}
                                        onClick={() => handlePrint(
                                            [row.reference_number],
                                            true,
                                            `Shipping_Label_${row.reference_number}.pdf`,
                                        )}
                                    >
                                        A6
                                    </Menu.Item>
                                )}
                                {showshippingLabelPod && (
                                    <Menu.Item
                                        style={menuStyle}
                                        onClick={() => handlePrintPod(
                                            [row.reference_number],
                                            false,
                                            `Shipping_Label_POD_${row.reference_number}.pdf`,
                                        )}
                                    >
                                        {t('shipping_label_pod')}
                                    </Menu.Item>
                                )}
                                {showShippingLabel4X6 && (
                                    <Menu.Item
                                        style={menuStyle}
                                        onClick={() => handleOnClickPrint4X6(
                                            [row.reference_number],
                                            row.is_international,
                                            `Shipping_Label_4x6_${row.reference_number}.pdf`,
                                        )}
                                    >
                                        4*6
                                    </Menu.Item>
                                )}
                            </>
                        )}
                    </Menu.SubMenu>
                )}
                {showRoutingLabelButton && (
                    <Menu.SubMenu
                        title={(
                            <span
                                style={{
                                    ...menuStyle,
                                    padding: 0,
                                    border: 'none',
                                }}
                            >
                                {t('routing_label')}
                            </span>
                        )}
                        style={{
                            ...menuStyle,
                            padding: '6px 0px',
                            border: 'none',
                        }}
                    >
                        {showRoutingLabelA4 && (
                            <Menu.Item
                                style={menuStyle}
                                onClick={() => handlePrintRouting(
                                    [row.reference_number],
                                    false,
                                    `Routing_Label_${row.reference_number}.pdf`,
                                )}
                            >
                                {t('label_a4')}
                            </Menu.Item>
                        )}
                        {showRoutingLabel4x4 && (
                            <Menu.Item
                                style={menuStyle}
                                onClick={() => handlePrintRouting(
                                    [row.reference_number],
                                    false,
                                    `Routing_Label_${row.reference_number}.pdf`,
                                )}
                            >
                                4*4
                            </Menu.Item>
                        )}
                    </Menu.SubMenu>
                )}
                {renderPrintInvoice(row)}
                {renderEpodLabel(row)}
                {showAddressLabelButton && (
                    <Menu.SubMenu
                        title={(
                            <span
                                style={{
                                    ...menuStyle,
                                    padding: 0,
                                    border: 'none',
                                }}
                            >
                                {t('address_label')}
                            </span>
                        )}
                        style={{
                            ...menuStyle,
                            padding: '6px 0px',
                            border: 'none',
                        }}
                    >
                        {showAddressLabelA4 && (
                            <Menu.Item
                                style={menuStyle}
                                onClick={() => handlePrintAddress(
                                    [row.reference_number],
                                    false,
                                    `Address_Label_${row.reference_number}.pdf`,
                                )}
                            >
                                {t('label_a4')}
                            </Menu.Item>
                        )}
                        {showAddressLabel4X2 && (
                            <Menu.Item
                                style={menuStyle}
                                onClick={() => handlePrintAddress(
                                    [row.reference_number],
                                    true,
                                    `Address_Label_${row.reference_number}.pdf`,
                                )}
                            >
                                4*2
                            </Menu.Item>
                        )}
                    </Menu.SubMenu>
                )}
                {renderPrintvendorlabel(row)}
            </Menu>
        );
    };

    const handleDownloadPod = async (consignment: any) => {
        setPodConsignment(consignment);
    };

    const renderPODIcon = (row: any) => {
        if (
            bucketSelected === 'customer_portal_delivered'
            || row.status === 'delivered'
        ) {
            return (
                <EyeOutlined
                    style={{ cursor: 'pointer', margin: '0 5px' }}
                    onClick={() => handleDownloadPod(row)}
                />
            );
        }
        return null;
    };

    const renderTrackingIcon = (row: any) => {
        if (row.tracking_url) {
            return (
                <a href={row.tracking_url} target="_blank" rel="noreferrer">
                    <EnvironmentOutlined
                        style={{ cursor: 'pointer', margin: '0 5px' }}
                    />
                </a>
            );
        }
        return null;
    };
    const handleSuccessOk = () => {
        setSuccessVisible(false);
    };
    const SuccessPopup = () => {
        return (
            <Modal
                visible={successVisible}
                onCancel={handleSuccessOk}
                footer={[
                    <Button key="ok" type="primary" onClick={handleSuccessOk}>
                        OK
                    </Button>,
                ]}
            >
                <div style={{ color: 'green', fontSize: '18px', fontWeight: 'bold' }}>
                    Success!
                </div>
                <div style={{ marginTop: '10px' }}>
                    Document uploaded successfully.
                </div>
            </Modal>
        );
    };

    const textString = `Maximum ${maxFiles} files can be uploaded. Formats supported are pdf, jpg, jpeg, excel.`;

    const RenderUploadModal = () => {
        return (
            <>
                <Modal
                    title="Upload Documents"
                    width="50%"
                    visible={showUploadModal}
                    onCancel={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handlemodalclose();
                    }}
                    destroyOnClose
                    footer={[
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <InfoCircleFilled
                                style={{ color: '#006EC3', marginRight: '5px' }}
                            />
                            <div
                                style={{
                                    fontSize: '14px',
                                    opacity: '70%',
                                    color: '#232529',
                                }}
                            >
                                {textString}
                            </div>
                            <div style={{ flexGrow: 1, marginRight: '10px' }} />
                            <Button
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handlemodalclose();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                key="submit"
                                type="primary"
                                loading={imageUploadLoading}
                                onClick={handleUploadImageSubmit}
                            >
                                Submit
                            </Button>
                        </div>,
                    ]}
                >
                    <>
                        {documentDetails.map((doc: any, index: number) => (
                            renderDocumentTypeWiseFile(doc, index)
                        ))}
                        {documentDetails.length < maxFiles && (
                            <div
                                style={{
                                    marginTop: '20px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                                onClick={handleAddDocumentType}
                            >
                                <div className={classes.grayCircle}>
                                    {(documentDetails?.length || 0) + 1}
                                </div>
                                <PlusOutlined
                                    style={{
                                        color: '#006EC3',
                                        marginRight: '5px',
                                    }}
                                />
                                <div
                                    style={{
                                        color: '#006EC3',
                                        cursor: 'pointer',
                                        fontSize: '12px',
                                    }}
                                >
                                    Add Document Type
                                </div>
                            </div>
                        )}
                    </>
                </Modal>
            </>
        );
    };
    const handleupload = (row: any) => {
        setRowUpload(row);
        setShowUploadModal(true);
    };

    const renderUpload = (row: any) => {
        return (
            <Button
                icon={<UploadOutlined />}
                style={{ border: 'none', backgroundColor: 'transparent' }}
                onClick={() => handleupload(row)}
            />
        );
    };

    const renderEditIcon = (row: any) => {
        if (isEditAllowed && allowedEditStatus.includes(row.status)) {
            return (
                <EditOutlined
                    style={{ cursor: 'pointer', marginRight: 10 }}
                    onClick={(e) => {
                        e.stopPropagation();
                        setEditData(row);
                        setFormVisible(true);
                    }}
                />
            );
        }
        return null;
    };

    const renderDropdown = (row: any) => {
        return (
            <div className={classes.dropdown}>
                {documentConfig?.allow_document_upload ? renderUpload(row) : null}
                {renderPODIcon(row)}
                {renderEditIcon(row)}
                {showTrackingIcon ? renderTrackingIcon(row) : null}
                {!useThirdPartyLabel ? (
                    <Dropdown
                        overlay={renderPrintOptions(row)}
                    >
                        <PrinterOutlined style={{ margin: '0 5px' }} />
                    </Dropdown>
                ) : (
                    <PrinterOutlined
                        style={{ margin: '0 5px' }}
                        onClick={() => {
                            handlePrint(
                                [row.reference_number],
                                false,
                                `Shipping_Label_${row.reference_number}.pdf`,
                            );
                        }}
                    />
                )}
            </div>
        );
    };

    const renderNotesDrawer = () => {
        if (!notesReferenceNumber) return null;
        return (
            <EditNotes
                referenceNumber={notesReferenceNumber}
                onClose={() => {
                    setNotesReferenceNumber(null);
                    setNotesValue(undefined);
                }}
                loadconsignments={loadconsignments}
                currentNote={notesValue}
            />
        );
    };
    const handleSupplementaryServicesConsignment = async (referenceNumber: any) => {
        if (referenceNumber) {
            const response = await fetchSupplementaryServices({
                consignment_number: referenceNumber,
                supplementary_services_to_rows: true,
                page_number: 1,
            });
            if (response?.data?.page_data && response.data.page_data.length) {
                const data = response.data.page_data;
                setIsSupplementaryServiceTable(true);
                setSupplementaryServiceData(data);
            }
        }
    };

    const renderGallery = (imageArray: any[], videoArray: any[]) => {
        if (!imageArray || !videoArray) return 'Not Available';
        if (!imageArray.length && !videoArray.length) return 'Not Available';
        return (
            <a onClick={() => {
                setGalleryModalData({
                    images: imageArray,
                    videos: videoArray,
                });
                setIsGalleryModalVisible(true);
            }}
            >
                View
            </a>
        );
    };
    const combineMultipleImagesVideo = (imageListId: string[], videoListId: string[], data: { [x: string]: any; }) => {
        const cnImages: any[] = [];
        const cnVideos: any[] = [];
        imageListId.forEach((imageId) => {
            if (Array.isArray(data[imageId]) && data[imageId].length) {
                data[imageId].forEach((image: any) => {
                    if (image) {
                        cnImages.push(image);
                    }
                });
            } else if (data[imageId] && !Array.isArray(data[imageId])) {
                cnImages.push(data[imageId]);
            }
        });
        videoListId.forEach((videoId) => {
            if (Array.isArray(data[videoId]) && data[videoId].length) {
                data[videoId].forEach((video: any) => {
                    if (video) {
                        cnVideos.push(video);
                    }
                });
            } else if (data[videoId] && !Array.isArray(data[videoId])) {
                cnVideos.push(data[videoId]);
            }
        });
        return renderGallery(cnImages, cnVideos);
    };

    const renderTextModal = (data: string) => {
        if (!data) return 'Not Available';
        return (
            <a onClick={() => {
                setTextModalData({ text: data, title: 'Customer Feedback' });
                setIsTextModalVisible(true);
            }}
            >
                View
            </a>
        );
    };

    const formatDimension = (length: string, width: string, height: string) => {
        if (length && width && height) {
            return `${length}*${width}*${height}`;
        }
        return '';
    };

    const renderAmountCollectedDetails = (data: any) => {
        const columns = [
            {
                title: 'S.No.',
                key: 'sno',
                width: '20px',
                render: (text: any, object: any, index: any) => (index + 1),
            },
            {
                title: 'Amount',
                dataIndex: 'cod_amount',
                key: 'cod_amount',
            },
            {
                title: 'Collection Mode',
                dataIndex: 'cod_collection_mode',
                key: 'cod_collection_mode',
                width: '150px',
            },
            {
                title: 'Favor of',
                dataIndex: 'cod_favor_of',
                key: 'cod_favor_of',
                width: '100px',
            },
        ];
        return (
            <Table
                dataSource={data}
                columns={columns}
                pagination={false}
                style={{
                    margin: '0 10px',
                }}
            />
        );
    };
    const renderLinkModal = (row: any) => {
        return (
            <DocumentDetailsModal
                isVisible={isVisible}
                onModalClose={() => setIsVisible(false)}
                documentDetails={row?.document_details}
            />
        );
    };

    const renderColumn = (text: any, row: any, column: ConsignmentColumn) => {
        if (dateColumnKeys.includes(column.column_id) && text) {
            const dateString = moment(text).format('DD MMM, YYYY');
            return renderText(dateString, row);
        }
        if (column.column_id === 'document_details') {
            return row?.document_details ? (
                <div>
                    <Button
                        type="link"
                        onClick={() => {
                            setModalRow(row);
                            setIsVisible(true);
                        }}
                    >
                        View
                    </Button>
                </div>
            ) : (
                'Not Available'
            );
        }
        if (dateKeysPickup.includes(column.column_id) && text) {
            const dateString = moment(text).format('DD-MM-YYYY hh:mm A');
            return renderText(dateString, row);
        }
        if (dateKeysWithStringTimestamp.includes(column.column_id) && text) {
            const dateString = moment(Number(text)).format('DD-MM-YYYY hh:mm A');
            return renderText(dateString, row);
        }
        if (column.column_id === 'status') {
            return renderText(prettyStatusNames[text] || (useMariaDb ? text : snakeToPretty(text)), row);
        }
        if (column.column_id === 'is_international') {
            return renderText(text ? 'True' : 'False', row);
        }
        if (column.column_id === 'movement_type') {
            return renderText(prettyMovementTypes[text] || text, row);
        }
        if (column.column_id === 'estimated_delivery_time') {
            const textToRender = text
                ? moment(text).format('DD MMM YYYY | hh:mm A')
                : 'Not Available';
            return renderText(textToRender, row);
        }
        if (column.column_id === 'expected_delivery_date' && text) {
            const dateString = moment(text).format('DD MMM, YYYY');
            return renderText(dateString, row);
        }
        if (column.column_id === 'actions') {
            return renderDropdown(row);
        }
        if (column.column_id === 'customer_pod') {
            if (!row[column.column_id] || !row[column.column_id].length) {
                return <div className={classes.cellNa}>-NA-</div>;
            }
            return renderGallery(row[column.column_id], []);
        }
        if (column.column_id === 'supplementary_services_added') {
            const supplementaryServices = row[column.column_id];
            let dataToRender = 'No';
            if (supplementaryServices && Array.isArray(supplementaryServices) && supplementaryServices.length) {
                let sumOfServices = 0;
                let invalidStatusRequest = 0;
                supplementaryServices.forEach((service) => {
                    if (service.supplementary_service_list_count
                        && !Number.isNaN(service.supplementary_service_list_count)) {
                        sumOfServices += service.supplementary_service_list_count;
                    }
                    if (service.status && ['REJECTED', 'CANCELLED'].includes(service.status)) {
                        invalidStatusRequest += 1;
                    }
                });
                if ((invalidStatusRequest !== supplementaryServices.length)
                    && !Number.isNaN(sumOfServices)
                    && Number(sumOfServices) > 0) {
                    dataToRender = 'Yes';
                }
            }
            return (
                <span>
                    {dataToRender}
                    {supplementaryServices && supplementaryServices.length ? (
                        <Tooltip title="">
                            <a>
                                <InfoCircleOutlined
                                    style={{ marginLeft: 5 }}
                                    onClick={() => handleSupplementaryServicesConsignment(row?.reference_number || '')}
                                />
                            </a>
                        </Tooltip>
                    ) : null}
                </span>
            );
        }
        if (['signature_image', 'pod_image_url', 'poc_image', 'latest_pickup_proof_of_pickup_image',
            'card_transaction_image'].includes(column.column_id)) {
            if (!row[column.column_id]) {
                return <div className={classes.cellNa}>-NA-</div>;
            }
            return renderGallery([row[column.column_id]], []);
        }
        if (column.column_id === 'customer_feedback') {
            return renderTextModal(row[column.column_id]);
        }
        if (column.column_id === 'dimension') {
            return formatDimension(row?.length, row?.width, row?.height);
        }
        if (column.column_id === 'given_dimension') {
            return formatDimension(row?.given_length, row?.given_width, row?.given_height);
        }
        if (column.column_id === 'customer_reference_number_2') {
            return renderText(row.customer_reference_number, row);
        }
        if (column.column_id === 'customer_code_2') {
            return renderText(row.customer_code, row);
        }
        if (column.column_id === 'customer_name_2') {
            return renderText(row.customer_name, row);
        }
        if (column.column_id === 'parent_customer_code_2') {
            return renderText(row.parent_customer_code, row);
        }
        if (column.column_id === 'parent_customer_name_2') {
            return renderText(row.parent_customer_name, row);
        }
        if (column.column_id === 'poc_image') {
            if (text) return (<Button onClick={() => setImageUrl(text)} type="link">View</Button>);
            return (<div className={classes.cellNa}>-NA-</div>);
        }
        if (column.column_id === 'notes') {
            return (
                <div
                    className={classes.notesCellValue}
                >
                    <EditOutlined
                        style={{ marginRight: 5 }}
                        onClick={() => {
                            setNotesReferenceNumber(row.reference_number);
                            setNotesValue(text);
                        }}
                    />
                    <Tooltip
                        className={classes.cellValue}
                        title={text}
                        overlayStyle={{
                            fontSize: 12,
                            margin: 0,
                            padding: 0,
                            letterSpacing: 0,
                            whiteSpace: 'pre-line',
                        }}
                    >
                        <span
                            style={{ width: 90, overflow: 'hidden', marginRight: 10 }}
                            onClick={() => {
                                setDetails(row?.reference_number);
                                history.push(`/${Routes.CONSIGNMENT_DETAILS}/${row.reference_number}`);
                            }}
                        >
                            {text}
                        </span>
                    </Tooltip>
                </div>
            );
        }
        if (column.column_id === 'cod_amount_collected') {
            if (Helper.isValidArray(text)) {
                return (
                    <Popover
                        trigger="click"
                        title="Amount collected details"
                        content={() => renderAmountCollectedDetails(text)}
                    >
                        <Button type="link">Show</Button>
                    </Popover>
                );
            }
            return <div className={classes.cellNa}>-NA-</div>;
        }
        if (column.column_id === 'delivery_timeslots') {
            if (isEmpty(text)) {
                return <div className={classes.cellNa}>-NA-</div>;
            }
            return (
                <div className={classes.cellValue}>
                    {text?.start || ''}
                    {' - '}
                    {text?.end || ''}
                </div>
            );
        }
        if (['poc_image_video_list', 'pop_image_video_list'].includes(column.column_id)) {
            const imageIdList = column.image_list || [];
            const videoIdList = column.video_list || [];
            return combineMultipleImagesVideo(imageIdList, videoIdList, row);
        }
        if (!text || text === null) {
            return <div className={classes.cellNa}>-NA-</div>;
        }
        // please don't change this default object check
        if (typeof text === 'object') {
            return <div className={classes.cellValue}>{JSON.stringify(text)}</div>;
        }
        return renderText(text, row);
    };

    const getWidth = (id: string) => {
        switch (id) {
            case 'actions': return (config?.allow_multiple_file_upload_in_customer_portal ? 110 : 90);
            case 'status':
            case 'reference_number':
            case 'destination_name':
            case 'customer_reference_number': return 150;
            case 'movement_type':
            default: return 120;
        }
    };

    const getColumns = (): any[] => {
        const { consignment_column_list } = config || {};

        const actionColumn = {
            column_id: 'actions',
            is_sortable: false,
            pretty_name: t('actions'),
        };

        let columnLables = [];

        if (consignmentPageData) {
            const viewData = consignmentPageData.metadata.sectionList.flat().find((v) => v.id === bucketSelected);
            columnLables = (viewData?.visibleFieldList || viewData?.metadata?.fieldList)?.map((field) => ({
                column_id: field.id,
                is_sortable: false,
                pretty_name: field.name,
            })) || [];
        } else if (consignment_column_list && consignment_column_list[bucketSelected as ConsignmentBucketKeys]?.length) {
            columnLables.push(...consignment_column_list[bucketSelected as ConsignmentBucketKeys]);
        } else {
            columnLables.push(...defaultConsignmentColumns);
        }

        if (useMariaDb && allowedColumns?.length) {
            columnLables = columnLables.filter((column: ConsignmentColumn) => {
                return allowedColumns.includes(column.column_id);
            });
        }

        columnLables.push(actionColumn);

        const columns: any = columnLables.map((column: ConsignmentColumn) => {
            return {
                key: column.column_id,
                title: consignmentPageData ? column.pretty_name : t(column.column_id),
                dataIndex: column.column_id,
                width: getWidth(column.column_id),
                ellipsis: column.column_id !== 'actions',
                fixed: column.column_id === 'actions' ? 'right' : undefined,
                render: (text: string, row: any) => renderColumn(text, row, column),
            };
        });
        return columns;
    };

    const renderTable = () => {
        const viewData = consignmentPageData?.getViewData(bucketSelected);
        return (
            <Table
                bordered={false}
                pagination={false}
                loading={loading || viewData?.isLoading}
                className={classes.tableRowColor}
                rowKey={(row) => row.reference_number}
                columns={getColumns()}
                scroll={{
                    y: `calc(
                        (((100vh - ${NAVBAR_HEIGHT}px) - 110px) - ${bannerHeight}px) 
                        - ${filterHeight}px)`,
                }}
                rowSelection={{
                    onSelect: (row, selected: any) => {
                        let newArray = [...consignmentsSelected];
                        if (selected && !newArray.includes(row.reference_number)) {
                            newArray.push(row.reference_number);
                        } else {
                            newArray = newArray.filter((item) => (item !== row.reference_number));
                        }
                        setConsignmentSelected(newArray);
                    },
                    onSelectAll: (selected, selectedRows) => {
                        const newArray = selectedRows.map((item) => (item.reference_number));
                        setConsignmentSelected(newArray);
                    },
                    selectedRowKeys: consignmentsSelected,
                }}
                locale={{
                    emptyText: <div className={classes.cellNa}>—NA—</div>,
                }}
                dataSource={consignments || []}
            />
        );
    };

    const renderPageOption = (page: PageKeys) => {
        return (
            <div
                key={page}
                onClick={() => handlePageChange(page)}
                className={pageSelected === page ? classes.selectedPage : classes.pageName}
            >
                {t(PageOptions[page])}
            </div>
        );
    };

    const renderPageFilter = () => {
        if (details) {
            return null;
        }
        return (
            <div className={classes.pageFilter}>
                {Object.keys(PageOptions).map((page) => {
                    if (page === 'PluginOrders' && !(enable_shopify_integration && showPluginOrders)) {
                        return null;
                    }
                    if (page === 'ExcelUpload' && !showMultipleCnSection) {
                        return null;
                    }
                    if (page === 'PrintHistory' && (!showPrintHistory)) {
                        return null;
                    }
                    return renderPageOption(page as PageKeys);
                })}
            </div>
        );
    };

    const getTabName = (bucket: string, bucketData: { name: string, width: number }) => {
        let countClass = classes.count;
        if (bucket === bucketSelected) {
            countClass = [classes.count, classes.countSelected].join(' ');
        }

        return (
            <>
                <span style={{ color: bucket === bucketSelected ? '#111' : '#666' }}>
                    {!consignmentPageData ? t(bucket) : bucketData.name}
                </span>
                {
                    !consignmentPageData && showBucketCount
                        ? (<span className={countClass}>{summary[bucket] || 0}</span>)
                        : null
                }
            </>
        );
    };

    const renderBucket = (bucket: string, bucketData: { name: string, width: number }) => {
        return (
            <Tabs.TabPane
                tab={getTabName(bucket, bucketData)}
                key={bucket}
            />
        );
    };

    const renderBuckets = () => {
        let allowedBuckets: any = ConsignmentBuckets;

        if (consignmentPageData) {
            allowedBuckets = consignmentPageData.metadata.sectionList?.flat()?.reduce((acc: any, view) => {
                if (!acc[view.id]) {
                    acc[view.id] = {
                        name: view.name,
                        width: 10,
                    };
                }
                return acc;
            }, {}) || {};
        }
        return (
            <div className={classes.tabsContainer}>
                <Tabs
                    type="card"
                    // className={classes.bucketRow}
                    activeKey={bucketSelected}
                    onChange={(bucket) => handleBucketChange(bucket)}
                    moreIcon={<EllipsisIcon className={classes.ellipsisIcon} />}
                >
                    {Object.keys(allowedBuckets).map((bucket) => renderBucket(bucket, allowedBuckets[bucket]))}
                </Tabs>
            </div>
        );
    };

    const renderDateType = (() => {
        let allowedDateKeys = dateKeys;
        if (useMariaDb) {
            allowedDateKeys = ['created_at'];
        }
        return (
            <Select
                onChange={(value) => {
                    const newFilters = {
                        ...filters,
                    };
                    newFilters.dateType = value;
                    setfilters(Buckets.CONSIGNMENTS, newFilters);
                    applyfilters(Buckets.CONSIGNMENTS);
                    setDateType(value);
                }}
                value={dateType}
                dropdownMatchSelectWidth={false}
            >
                {allowedDateKeys.map((item) => {
                    return (
                        <Select.Option
                            key={item}
                            value={item}
                        >
                            {t(item)}
                        </Select.Option>
                    );
                })}
            </Select>
        );
    });

    const disabledDate = (current: any) => {
        return current && current > moment().endOf('day');
    };

    const renderRangePicker = () => {
        return (
            <div className={classes.filter}>
                <span className={classes.filterText}>
                    {t('date')}
                </span>
                <DatePicker.RangePicker
                    value={[moment(filters.startDate), moment(filters.endDate)]}
                    allowClear={false}
                    allowEmpty={[false, false]}
                    disabledDate={disabledDate}
                    placeholder={['Select Start Date', 'Select End Date']}
                    onChange={(value) => {
                        if (!(value && value[0] && value[1])) return;
                        const newFilters = {
                            ...filters,
                        };
                        if (value[1].isSame(filters.endDate)) {
                            if (moment(filters.endDate).diff(value[0], 'days') > 30) {
                                const endDate = moment(value[0]).add(30, 'd');
                                newFilters.endDate = endDate.unix() * 1000;
                                newFilters.startDate = moment(value[0]).unix() * 1000;
                            } else {
                                newFilters.endDate = moment(value[1]).unix() * 1000;
                                newFilters.startDate = moment(value[0]).unix() * 1000;
                            }
                        } else if (value[0].isSame(filters.startDate)) {
                            if (moment(value[1]).diff(value[0], 'days') > 30) {
                                const startDate = moment(value[1]).subtract(30, 'd');
                                newFilters.startDate = startDate.unix() * 1000;
                                newFilters.endDate = moment(value[1]).unix() * 1000;
                            } else {
                                newFilters.endDate = moment(value[1]).unix() * 1000;
                                newFilters.startDate = moment(value[0]).unix() * 1000;
                            }
                        }
                        setfilters(Buckets.CONSIGNMENTS, newFilters);
                        applyfilters(Buckets.CONSIGNMENTS);
                    }}
                />
            </div>
        );
    };


    const isMoreFilterAdded = () => {
        const isFilterApplied = Object.keys(filters).some((filter) => {
            if (['endDate', 'startDate'].includes(filter)) {
                return false;
            }
            return filters[filter] !== defaultFilters[filter];
        });
        return isFilterApplied;
    };

    const renderMoreFiltersOption = () => {
        const filterApplied = isMoreFilterAdded();
        return (
            <div
                className={classes.moreFilter}
                onClick={() => setMoreFiltersVisible(true)}
                style={{
                    color: filterApplied ? uiTheme.primaryColor : '#111111',
                    fontWeight: filterApplied ? 600 : 500,
                }}
            >
                <FilterBlue style={{ marginRight: 2 }} />
                <span>{t('more_filters')}</span>
            </div>
        );
    };

    const renderResetFiltersOption = () => {
        const filterApplied = isMoreFilterAdded();
        if (!filterApplied) {
            return null;
        }
        return (
            <div
                className={classes.resetFilter}
                onClick={() => {
                    setDateType(dateKeys[0]);
                    setfilters(Buckets.CONSIGNMENTS, {
                        ...defaultFilters,
                        bucketSelected: consignmentPageData ? bucketSelected : defaultFilters.bucketSelected,
                        fromDate: filters.fromDate,
                        toDate: filters.toDate,
                    });
                    applyfilters(Buckets.CONSIGNMENTS);
                }}
            >
                {t('reset_filters')}
            </div>
        );
    };

    const renderLeftFilters = () => {
        return (
            <div className={classes.leftFilters}>
                {renderDateType()}
                {renderRangePicker()}
                {renderMoreFiltersOption()}
                {renderResetFiltersOption()}
            </div>
        );
    };

    const handleDownload = async () => {
        const dateFormat = 'YYYY-MM-DD';
        setDownloading(true);
        const moreFilters = getMoreFilters(filters);

        const filtersInfo = getFilters(
            consignmentPageData ? 'customer_portal_all' : filters.bucketSelected,
            config,
        );
        filtersInfo.forEach((filter: any) => {
            if (moreFilters[filter.id] && filter.mode === 'multiple' && typeof moreFilters[filter.id] === 'string') {
                moreFilters[filter.id] = moreFilters[filter.id].split(',').map((value: string) => value.trim());
            }
        });

        const response = await downloadConsignments({
            customerIdList: [],
            bucket: filters.bucketSelected,
            timeFilters: [
                {
                    columnName: filters.dateType || filters.sortBy,
                    fromDateString: moment(filters.startDate).format(dateFormat),
                    toDateString: moment(filters.endDate).format(dateFormat),
                },
            ],
            descendingOrder: filters.descendingOrder,
            referenceNumberList: consignmentsSelected,
            ...moreFilters,
        });
        if (response.isSuccess) {
            message.success('Requested Successfully');
            history.push(getDownloadsRoute());
        } else {
            message.error(response.errorMessage);
        }
        setDownloading(false);
    };

    const renderDownload = () => {
        return (
            <Menu.Item
                key="downloadMenu"
                disabled={downloading}
                style={ActionMenuStyle}
                onClick={handleDownload}
            >
                {t('download')}
            </Menu.Item>
        );
    };

    const renderPrintInvoiceBulk = (rows: any) => {
        if (!master.Customer.show_invoice_options || restrictPrintInvoice) {
            return null;
        }

        // const consignmentMap = keyBy(
        //     consignments,
        //     'reference_number',
        // );

        // const doNotshowOption = rows.some((consignment: any) => {
        //     const row = consignmentMap[consignment];
        //     return (
        //         !row.is_international || row.load_type === 'DOCUMENT'
        //     );
        // });

        // if (doNotshowOption) {
        //     return null;
        // }

        return (
            <Menu.Item
                style={menuStyle}
                onClick={() => handlePrintInvoice(
                    rows,
                    'Invoice_Multiple.pdf',
                )}
            >
                Print Invoice
            </Menu.Item>
        );
    };

    const handleOpenCancellation = (action: string) => {
        setCancelVisible({
            action,
            visible: true,
        });
    };

    const renderSchedulePickup = () => {
        if (!showPickupScheduleButton) return null;
        return (
            <Menu.Item
                key="schedulePickupMenu"
                onClick={() => setScheduleVisible(true)}
                style={ActionMenuStyle}
            >
                Schedule Pickup
            </Menu.Item>
        );
    };

    const renderCancel = () => {
        return (
            <Menu.SubMenu
                title={(
                    <span style={{
                        ...ActionMenuStyle,
                        padding: 0,
                        border: 'none',
                    }}
                    >
                        {t('cancel')}
                    </span>
                )}
                style={{
                    padding: 0,
                    border: 'none',
                }}
                key="CancelSubmenu"
            >
                <Menu.Item
                    key="BULK"
                    style={ActionMenuStyle}
                    onClick={() => handleOpenCancellation('BULK')}
                >
                    {t('upload_excel_bulk_update')}
                </Menu.Item>
                {showCancelButton && (
                    <Menu.Item
                        key="CANCEL"
                        style={ActionMenuStyle}
                        onClick={() => handleOpenCancellation('CANCEL')}
                    >
                        {t('mark_cancelled')}
                    </Menu.Item>
                )}
                <Menu.Item
                    style={ActionMenuStyle}
                    key="REVOKE"
                    onClick={() => handleOpenCancellation('REVOKE')}
                >
                    {t('mark_released')}
                </Menu.Item>
            </Menu.SubMenu>
        );
    };

    const handleThirdPartyBulkPrinting = async () => {
        setBulkPrinting(true);
        const payload = {
            labelType: 'THIRD_PARTY_CONSIGNMENT_LABEL',
            consignmentList: consignmentsSelected,
        };
        const response = await printBulkLabel(payload);
        setBulkPrinting(false);
        if (response.isSuccess) {
            message.success('Print Request Added');
            handlePageChange('PrintHistory');
        } else {
            message.error(response.errorMessage);
        }
    };

    const renderBulkPrintOptions = () => {
        return (
            <Menu>
                {showShippingLabelButton && (
                    <Menu.SubMenu
                        title={(
                            <span
                                style={{
                                    ...menuStyle,
                                    padding: 0,
                                    border: 'none',
                                }}
                            >
                                {t('shipping_label')}
                            </span>
                        )}
                        style={{
                            ...menuStyle,
                            padding: '6px 0px',
                            border: 'none',
                        }}
                    >
                        {enableCodefree ? renderCodeFreeBulkLabelOptions() : (
                            <>
                                {showShipmentLabelA4 && (
                                    <Menu.Item
                                        style={menuStyle}
                                        onClick={() => handlePrint(consignmentsSelected, true,
                                            'Shipping_Label_Multiple.pdf')}
                                    >
                                        {t('label_a4')}
                                    </Menu.Item>
                                )}
                                {showShippingLabelA6 && (
                                    <Menu.Item
                                        style={menuStyle}
                                        onClick={() => handlePrint(consignmentsSelected, false,
                                            'Shipping_LabelMultiple.pdf')}
                                    >
                                        {t('label_a6')}
                                    </Menu.Item>
                                )}
                                {showshippingLabelPod && (
                                    <Menu.Item
                                        style={menuStyle}
                                        onClick={() => (
                                            handlePrintPod(consignmentsSelected, false, 'Shipping_Label_PODMultiple.pdf')
                                        )}
                                    >
                                        {t('shipping_label_pod')}
                                    </Menu.Item>
                                )}
                                {showShippingLabel4X6 && (
                                    <Menu.Item
                                        style={menuStyle}
                                        onClick={() => handlePrint4X6(consignmentsSelected)}
                                    >
                                        4*6 International
                                    </Menu.Item>
                                )}
                                {showShippingLabel4X6 && (
                                    <Menu.Item
                                        style={menuStyle}
                                        onClick={() => handlePrint4X6Domestic(
                                            consignmentsSelected,
                                            'Shipping_Label_4x6_Multiple.pdf',
                                        )}
                                    >
                                        4*6 Domestic
                                    </Menu.Item>
                                )}
                            </>
                        )}
                    </Menu.SubMenu>
                )}
                {showRoutingLabelButton && (
                    <Menu.SubMenu
                        title={(
                            <span
                                style={{
                                    ...menuStyle,
                                    padding: 0,
                                    border: 'none',
                                }}
                            >
                                {t('routing_label')}
                            </span>
                        )}
                        style={{
                            ...menuStyle,
                            padding: '6px 0px',
                            border: 'none',
                        }}
                    >
                        {showRoutingLabelA4 && (
                            <Menu.Item
                                style={menuStyle}
                                onClick={() => handlePrintRouting(consignmentsSelected, false, 'Routing_Label_Multiple.pdf')}
                            >
                                {t('label_a4')}
                            </Menu.Item>
                        )}
                        {showRoutingLabel4x4 && (
                            <Menu.Item
                                style={menuStyle}
                                onClick={() => handlePrintRouting(consignmentsSelected, false, 'Routing_LabelMultiple.pdf')}
                            >
                                4*4
                            </Menu.Item>
                        )}
                    </Menu.SubMenu>
                )}
                {renderPrintInvoiceBulk(consignmentsSelected)}
                {showAddressLabelButton && (
                    <Menu.SubMenu
                        title={(
                            <span
                                style={{
                                    ...menuStyle,
                                    padding: 0,
                                    border: 'none',
                                }}
                            >
                                {t('address_label')}
                            </span>
                        )}
                        style={{
                            ...menuStyle,
                            padding: '6px 0px',
                            border: 'none',
                        }}
                    >
                        {showAddressLabelA4 && (
                            <Menu.Item
                                style={menuStyle}
                                onClick={() => handlePrintAddress(consignmentsSelected, false, 'Address_Label_Multiple.pdf')}
                            >
                                {t('label_a4')}
                            </Menu.Item>
                        )}
                        {showAddressLabel4X2 && (
                            <Menu.Item
                                style={menuStyle}
                                onClick={() => handlePrintAddress(consignmentsSelected, true, 'Address_Label_Multiple.pdf')}
                            >
                                4*2
                            </Menu.Item>
                        )}
                    </Menu.SubMenu>
                )}
            </Menu>
        );
    };

    const BulkPrintModal = () => {
        return (
            <BulkPrint
                isVisible={showBulkPrintModal}
                onClose={() => setShowBulkPrintModal(false)}
                onSuccess={() => {
                    setShowBulkPrintModal(false);
                    handlePageChange('PrintHistory');
                }}
                consignments={consignmentsSelected}
            />
        );
    };
    const handleLabelPrint = () => {
        setShowBulkPrintModal(true);
        return useBulkPrintModal ? (
            <Button
                type="primary"
                className={classes.downloadBtn}
                loading={bulkPrinting}
                onClick={() => setShowBulkPrintModal(true)}
            >
                {t('bulk_print')}
            </Button>
        ) : (
            <Dropdown
                overlay={renderBulkPrintOptions()}
            >
                <Button
                    type="primary"
                    className={classes.downloadBtn}
                    loading={bulkPrinting}
                >
                    {t('bulk_print')}
                </Button>
            </Dropdown>
        );
    };
    const renderBulkPrint = () => {
        if (bucketSelected === 'customer_portal_all' || bucketSelected === 'customer_portal_delivered') {
            const menu = (
                <Menu>
                    <Menu.Item key="label" onClick={handleLabelPrint}>
                        {t('Label')}
                    </Menu.Item>
                    <Menu.Item key="epod" onClick={() => handleEPodPrint('Epod_Label', false)}>
                        {t('E-POD')}
                    </Menu.Item>
                </Menu>
            );
            return (
                <Dropdown overlay={menu}>
                    <Button
                        type="primary"
                        className={classes.downloadBtn}
                        loading={bulkPrinting}
                    >
                        {t('bulk_print')}
                    </Button>
                </Dropdown>
            );
        }
        return useBulkPrintModal ? (
            <Button
                type="primary"
                className={classes.downloadBtn}
                loading={bulkPrinting}
                onClick={() => setShowBulkPrintModal(true)}
            >
                {t('bulk_print')}
            </Button>
        ) : (
            <Dropdown
                overlay={renderBulkPrintOptions()}
            >
                <Button
                    type="primary"
                    className={classes.downloadBtn}
                    loading={bulkPrinting}
                >
                    {t('bulk_print')}
                </Button>
            </Dropdown>
        );
    };
    const renderPrint = () => {
        if (consignmentsSelected.length < 2) {
            return (
                <Button
                    type="primary"
                    className={classes.downloadBtn}
                    loading={bulkPrinting}
                    disabled
                >
                    {t('bulk_print')}
                </Button>
            );
        }
        if (useThirdPartyLabel) {
            return (
                <Button
                    type="primary"
                    className={classes.downloadBtn}
                    loading={bulkPrinting}
                    onClick={handleThirdPartyBulkPrinting}
                >
                    {t('bulk_print')}
                </Button>
            );
        }
        return renderBulkPrint();
    };

    const handleSettingsClose = (refresh?: boolean) => {
        setSettingsVisible(false);
        if (refresh) {
            setToggle(!toggle);
        }
    };
    const renderSettingsModal = () => {
        if (!settingsVisible) {
            return null;
        }
        const filterObject: Record<string, FilterMetadata> = {};
        config?.filter_metadata.forEach((item) => {
            filterObject[item.id] = item;
        });

        let columnData = config?.full_column_list || [];

        if (consignmentPageData) {
            const viewData = consignmentPageData.metadata.sectionList.flat().find((v) => v.id === bucketSelected);
            columnData = viewData?.metadata?.fieldList?.map((field) => ({
                column_id: field.id,
                pretty_name: field.name,
                is_sortable: false,
            })) || [];
        }

        const options = uniqBy(columnData, 'column_id');
        const columns: Record<string, ConsignmentColumn> = {};
        options.forEach((item) => {
            columns[item.column_id] = item;
        });
        return (
            <Settings
                bucketSelected={bucketSelected}
                filterObject={{
                    columns,
                    filters: filterObject,
                }}
                onClose={(refresh?: boolean) => handleSettingsClose(refresh)}
            />
        );
    };

    const renderCancelModal = () => {
        if (!cancelVisible.visible) {
            return null;
        }

        return (
            <CancellationModal
                action={cancelVisible.action}
                consignments={consignmentsSelected}
                onClose={(success: boolean, params?: any) => {
                    setCancelVisible({
                        visible: false,
                    });
                    if (success) {
                        setToggle(!toggle);
                        if (params?.failures?.length) {
                            setFailures({
                                visible: true,
                                list: params.failures,
                            });
                        } else {
                            setConsignmentSelected([]);
                        }
                    }
                }}
            />
        );
    };

    const renderCancelFailure = () => {
        if (!failures.visible || !failures?.list?.length) {
            return null;
        }

        return (
            <FailureList
                failureList={failures.list}
                onClose={() => setFailures({ visible: false })}
            />
        );
    };

    const renderPodDrawer = () => {
        if (!podConsignment?.id) {
            return null;
        }
        return (
            <ProofOfDelivery
                podConsignment={podConsignment}
                onClose={() => {
                    setPodConsignment({});
                }}
            />
        );
    };

    const renderSchedulePickupDrawer = () => {
        if (!scheduleVisible) {
            return null;
        }
        return (
            <SchedulePickupModal
                consignments={consignmentsSelected}
                onClose={(success: boolean, params?: any) => {
                    setScheduleVisible(false);
                    if (success) {
                        setToggle(!toggle);
                        if (params?.failures?.length) {
                            setFailures({
                                visible: true,
                                list: params.failures,
                            });
                        } else {
                            setConsignmentSelected([]);
                        }
                    }
                }}
            />
        );
    };

    const renderSettings = () => {
        return (
            <SettingOutlined
                className={classes.settingIcon}
                onClick={() => setSettingsVisible(true)}
            />
        );
    };

    const handlePagination = (nextOrPrev: NextOrPrev) => {
        const { currentPageNumber } = pagination;
        let newPageNumber = currentPageNumber;
        if (nextOrPrev === 'first') {
            newPageNumber = 1;
        } else if (nextOrPrev === 'next') {
            newPageNumber = currentPageNumber + 1;
        } else {
            newPageNumber = currentPageNumber - 1;
        }
        const newPagination = {
            ...pagination,
            nextOrPrev,
            currentPageNumber: newPageNumber,
        };
        setpagination(Buckets.CONSIGNMENTS, newPagination);
        setToggle(!toggle);
    };

    const renderPaginator = () => {
        return (
            <div style={{ direction: 'ltr' }}>
                <Paginator
                    currentPageNumber={pagination.currentPageNumber}
                    isNextPresent={pagination.isNextPresent}
                    onNextClick={() => handlePagination('next')}
                    onPrevClick={() => handlePagination('prev')}
                    onFirstClick={() => handlePagination('first')}
                />
            </div>
        );
    };

    const renderRefresh = () => {
        return (
            <Tooltip title={t('reload')}>
                <Button
                    size="small"
                    type="ghost"
                    style={{
                        color: uiTheme.primaryColor,
                        fontSize: 14,
                    }}
                    onClick={() => {
                        handlePagination('first');
                        loadconsignments();
                    }}
                >
                    <ReloadOutlined />
                </Button>
            </Tooltip>
        );
    };

    const renderActions = () => {
        return (
            <Menu
                style={{
                    ...ActionMenuStyle,
                    padding: 0,
                    border: 'none',
                }}
            >
                {!consignmentPageData && renderDownload()}
                {renderSchedulePickup()}
                {isCancelAllowed ? renderCancel() : null}
            </Menu>
        );
    };

    const renderActionsButton = () => {
        return (
            <Dropdown
                overlay={renderActions()}
                trigger={['click']}
            >
                <Button
                    type="primary"
                    className={classes.actionButton}
                >
                    {t('actions')}
                </Button>
            </Dropdown>
        );
    };

    const renderRightFilters = () => {
        return (
            <div className={classes.rightFilters}>
                {renderActionsButton()}
                {renderPrint()}
                {renderRefresh()}
                {renderPaginator()}
                {renderSettings()}
            </div>
        );
    };

    const renderExtraFilters = () => {
        return (
            <div className={classes.extraFilters}>
                {renderLeftFilters()}
                {renderRightFilters()}
            </div>
        );
    };

    const renderFilters = () => {
        return (
            <div className={classes.filters} ref={filtersRef}>
                {renderBuckets()}
                {renderExtraFilters()}
            </div>
        );
    };

    const renderConsignmentDetailed = () => {
        if (!details) {
            return null;
        }
        return (
            <ConsignmentDetails
                referenceNumber={details}
                details={details}
                master={master}
                handleGoback={() => {
                    setDetails(undefined);
                    setToggle(!toggle);
                    history.push(`${getConsignmentsRoute()}`);
                }}
            />
        );
    };

    const renderConsignmentPage = () => {
        if (pageSelected !== 'Consignments' || details) {
            return null;
        }

        return (
            <>
                {renderFilters()}
                {renderTable()}
            </>
        );
    };

    const renderPluginOrders = () => {
        if (pageSelected !== 'PluginOrders' || details || !(enable_shopify_integration && showPluginOrders)) {
            return null;
        }
        return (
            <>
                <PluginOrders />
            </>
        );
    };

    const renderExcelUpload = () => {
        if (pageSelected !== 'ExcelUpload' || !showMultipleCnSection) {
            return null;
        }
        return (
            <ExcelUpload
                goToPrintHistory={() => setPageSelected('PrintHistory')}
            />
        );
    };

    const renderPrintHistory = () => {
        if (pageSelected !== 'PrintHistory' || !showPrintHistory) {
            return null;
        }
        return <PrintHistory />;
    };

    const renderMoreFilters = () => {
        if (!moreFilterVisible) {
            return null;
        }
        return (
            <MoreFilters
                resetVisible={isMoreFilterAdded()}
                config={config}
                bucketSelected={bucketSelected}
                onClose={() => setMoreFiltersVisible(false)}
            />
        );
    };

    const renderImage = () => {
        if (!imageUrl) return null;
        return (
            <Image
                src={imageUrl}
                width={0}
                preview={{
                    visible: !!imageUrl,
                    src: imageUrl,
                    onVisibleChange: (value) => {
                        if (!value) setImageUrl(null);
                    },
                }}
            />
        );
    };

    const renderSingleConsignment = () => {
        if (!editFormVisible) {
            return null;
        }
        const isConfigEnabled = master.config?.customer_portal_order_creation_config?.is_enabled;
        return (
            isConfigEnabled
                ? (
                    <SingleConsignmentNew
                        config={master}
                        onClose={() => setFormVisible(false)}
                        setSuccess={(data: any) => {
                            console.log(data);
                            // TODO: handle success event
                            message.success('Consignment updated successfully');
                            setFormVisible(false);
                        }}
                        loadconsignments={loadconsignments}
                        editData={editData}
                    />
                )
                : (
                    <SingleConsignment
                        config={master}
                        onClose={() => setFormVisible(false)}
                        setSuccess={(data: any) => {
                            console.log(data);
                            // TODO: handle success event
                            message.success('Consignment updated successfully');
                            setFormVisible(false);
                        }}
                        loadconsignments={loadconsignments}
                        editData={editData}
                    />
                )
        );
    };

    return (
        <div className={classes.main}>
            {renderPageFilter()}
            {renderConsignmentPage()}
            {renderPluginOrders()}
            {renderConsignmentDetailed()}
            {renderExcelUpload()}
            {renderPrintHistory()}
            {renderSettingsModal()}
            {renderSchedulePickupDrawer()}
            {renderCancelModal()}
            {renderCancelFailure()}
            {renderMoreFilters()}
            {renderPodDrawer()}
            {renderNotesDrawer()}
            {isGalleryModalVisible && (
                <GalleryModal
                    isVisible={isGalleryModalVisible}
                    imageVideoGalleryData={galleryModalData}
                    handleModalClose={() => { setIsGalleryModalVisible(false); }}
                />
            )}
            {isTextModalVisible && (
                <Modal
                    title={textModalData.title}
                    visible={isTextModalVisible}
                    onCancel={() => setIsTextModalVisible(false)}
                    width="35%"
                    footer={null}
                    centered
                >
                    {textModalData.text}
                </Modal>
            )}
            {
                isSupplementaryServiceTable && (
                    <TableModal
                        header="Supplementary Services"
                        isVisible={isSupplementaryServiceTable}
                        handleModalClose={() => {
                            setIsSupplementaryServiceTable(false);
                            setSupplementaryServiceData([]);
                        }}
                        dataSource={supplementaryServiceData}
                        useCustomGenerateDataColumns
                        columnsToShow={defaultColumnsForSupplementaryServices}
                        customGenerateDataColumns={getColumnsForSupplementaryServices}
                    />
                )
            }
            {renderImage()}
            {renderSingleConsignment()}
            {RenderUploadModal()}
            {renderLinkModal(modalRow)}
            {SuccessPopup()}
            {BulkPrintModal()}
        </div>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    const {
        generic,
        master,
        uiTheme,
        genericPage,
    } = state;
    const { CONSIGNMENTS } = generic;
    const {
        loading,
        data,
        summary,
        pagination,
        appliedFilters,
        defaultFilters,
    } = CONSIGNMENTS;

    return {
        master,
        uiTheme,
        loading,
        summary,
        pagination,
        defaultFilters,
        filters: appliedFilters,
        consignments: data,
        consignmentPageData: genericPage[PAGE_NAMES.CONSIGNMENTS],
    };
};

const mapDispatchToProps = (dispatch: any) => {
    const actions = {
        setfilters: setFilters,
        applyfilters: applyFilters,
        loadconsignments: loadConsignments,
        setpagination: setPagination,
        fetchviewdata: fetchViewData,
    };
    return bindActionCreators(actions, dispatch);
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
        mapDispatchToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: consignemtStyles,
    },
    connectRouter: true,
    connectTranslession: true,
};

export default GenericHoc(hocConfig)(ConsignmentPage);
