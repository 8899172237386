import * as React from 'react';
import { HocOptions } from '../common/generic-hoc.types';
import { StylesProps } from '../../theme/jss-types';
import { consignmentDetailsIntStyles } from './consignment-details.styles';
import GenericHoc from '../common/generic-hoc';
import { snakeToPretty } from 'library/lodash-helper';
import { Master } from 'types/master-data-types';
import {
    Button,
    Collapse,
    Divider,
    Image,
    Modal,
    Tooltip,
    message,
} from 'antd';
import {
    DetailsToShowKeysDomestic,
    DetailsToShowKeysIntl,
    DividerTitleList,
    SellerDetailsKey,
} from './consignment-details.constants';
import { useTranslation } from 'react-i18next';
import DocumentDetailsModal from './document-details-modal';
import {
    InfoCircleOutlined,
} from '@ant-design/icons';
import TableModal from '../table-modal/tableModal';
import { ReduxStore } from 'reducers/redux.types';
import { get } from 'lodash';
import Loader from 'components/common/Loader';

interface ConsignmentDetailedInternationalProps extends StylesProps<ReturnType<typeof consignmentDetailsIntStyles>> {
    consignment: Record<string, any>;
    master: Master;
}

const detailsTabs = [
    'Primary Details',
    'Consignee Details',
    'Consignor Details',
    'Service Details',
    'Other Details',
];

const ConsignmentDetailedInternational = (props: ConsignmentDetailedInternationalProps) => {
    const {
        classes,
        consignment,
        master,
    } = props;

    const [selectedTab, setSelectedTab] = React.useState('Primary Details');
    const [itemDetails, setItemDetails] = React.useState<any>(null);
    const [imageUrl, setImageUrl] = React.useState<string | null>(null);
    const [imagePreviewLoading, setImagePreviewLoading] = React.useState<boolean>(false);
    const { t, i18n } = useTranslation();
    const enable_epod_print = master?.parts_to_show?.enable_epod_print !== false;
    const [isVisible, setIsVisible] = React.useState<boolean>(false);
    const [modalRow, setModalRow] = React.useState<any>(null);
    const [isPriceDetailsModalVisible, setIsPriceDetailsModalVisible] = React.useState<boolean>(false);
    const [priceDataList, setPriceDataList] = React.useState<any>([]);
    const [pdfPreviewURL, setPdfPreviewURL] = React.useState('');

    const renderSingleTab = () => {
        return detailsTabs.map((tab) => {
            let bucketClass: any = classes.bucket;
            if (tab === selectedTab) {
                bucketClass = [classes.bucketSelected, classes.bucket].join(' ');
            }
            return (
                <div
                    key={tab}
                    className={bucketClass}
                    onClick={() => setSelectedTab(tab)}
                >
                    <span>
                        {t(tab)}
                    </span>
                </div>
            );
        });
    };

    const labelValuePair = (key: string) => {
        const data = {
            label: snakeToPretty(key),
            value: consignment[key],
        };
        switch (key) {
            case 'load_type':
                data.label = 'Item Type';
                break;
            case 'num_pieces':
                data.label = 'No. of Pieces';
                break;
            case 'meis':
                data.label = 'MEIS';
                break;
            case 'is_risk_surcharge_applicable':
                data.label = 'Risk Surcharge';
                data.value = data.value !== null && data.value !== undefined ? data.value.toString() : '';
                break;
            case 'length':
            case 'width':
            case 'height':
                data.value = data.value ? `${consignment[key]}  cm` : '';
                break;
            case 'weight':
                data.value = data.value ? `${consignment[key]}  Kg` : '';
                break;
            case 'given_length':
            case 'given_width':
            case 'given_height':
                data.value = data.value ? `${consignment[key]}  cm` : '';
                break;
            case 'given_weight':
                data.value = data.value ? `${consignment[key]}  Kg` : '';
                break;
            case 'fob_value':
            case 'freight_cost':
            case 'insurance_value':
                data.value = consignment[key]
                    ? `${consignment[key]}  ${consignment[`${key}_currency`] || ''}`
                    : '';
                break;
            case 'total_gst_paid_amount':
                data.value = consignment[key]
                    ? `${consignment[key]}  ${consignment.total_gst_paid_currency || ''}`
                    : '';
                break;
            case 'consignor_kyc_front_image':
            case 'consignor_kyc_back_image':
                if (data.value) {
                    data.value = (
                        <Button
                            type="link"
                            onClick={() => {
                                setImagePreviewLoading(true);
                                setImageUrl(consignment[key]);
                            }}
                        >
                            View
                        </Button>
                    );
                }
                break;
            case 'sender_name':
            case 'sender_phone':
            case 'sender_address_line_1':
            case 'sender_address_line_2':
            case 'sender_country':
            case 'sender_state':
            case 'sender_city':
            case 'sender_pincode':
                data.label = data.label.replace('Sender', 'Pickup');
                if (consignment.is_international) {
                    // For international heading rendered separately, so only need header name like 'Name', 'Address Line 1'
                    // eslint-disable-next-line no-param-reassign
                    key = key.replace('sender_', '');
                }
                break;
            case 'consignor_name':
            case 'consignor_phone':
            case 'consignor_address_line_1':
            case 'consignor_address_line_2':
            case 'consignor_country':
            case 'consignor_state':
            case 'consignor_city':
            case 'consignor_pincode':
                if (consignment.is_international) {
                    // For international heading rendered separately, so only need header name like 'Name', 'Address Line 1'
                    // eslint-disable-next-line no-param-reassign
                    key = key.replace('consignor_', '');
                }
                break;
            case 'return_name':
            case 'return_phone':
            case 'return_address_line_1':
            case 'return_address_line_2':
            case 'return_country':
            case 'return_state':
            case 'return_city':
            case 'return_pincode':
                if (consignment.is_international) {
                    // For international heading rendered separately, so only need header name like 'Name', 'Address Line 1'
                    // eslint-disable-next-line no-param-reassign
                    key = key.replace('return_', '');
                }
                break;
            case 'origin_type':
                data.label = data.label.replace('Origin', 'Sender');
                break;
            case 'against_bond_lut':
                data.label = 'Against Bond LUT';
                if (data.value === 'B') data.value = 'Against Bond';
                else if (data.value === 'U') data.value = 'Undertaking';
                break;
            case 'international_detail_shipment_purpose':
                data.label = 'Shipment Purpose';
                break;
            case 'delivery_terms':
                data.label = 'Shipment Terms';
                break;
            case 'consignor_pan':
                data.label = 'Consignor Pan Id';
                break;
            case 'booking_service_type_id':
                data.label = 'Service Type';
                break;
            case 'enable_epod_print':
                data.label = 'enable_epod_print';
                break;
            case 'courier_partner_reference_number':
                data.label = 'Carrier AWB No.';
                break;
            case 'courier_partner':
                data.label = 'Carrier';
                break;
            case 'transport_mode':
                data.label = 'Transport Mode';
                break;
            case 'document_list':
                data.label = 'document_list';
                break;
            case 'price_details':
                data.label = 'Customer Charges';
                break;
            case 'receiver_name':
                data.label = 'Receiver Name';
                break;
            case 'receiver_relation':
                data.label = 'Receiver Relation';
                break;
            default:
                break;
        }
        if (data.value !== false && !data.value) {
            data.value = '-';
        }
        if (i18n.exists(key)) data.label = t(key);
        return data;
    };

    const renderLinkModal = (row: any) => {
        return (
            <DocumentDetailsModal
                isVisible={isVisible}
                onModalClose={() => setIsVisible(false)}
                documentDetails={row?.document_details}
            />
        );
    };
    const renderDocumentList = () => {
        return (
            <div className={classes.documentGroup}>
                <div style={{ fontSize: '12px' }}>Document List</div>
                <div>
                    <Button
                        type="link"
                        onClick={() => {
                            setModalRow(consignment);
                            setIsVisible(true);
                        }}
                        style={{ fontSize: '12px', marginLeft: 16 }}
                    >
                        View
                    </Button>
                </div>
            </div>
        );
    };
    const renderEPodLabel = () => {
        if (enable_epod_print) {
            // const epodLink = consignment.epod_label_link;
            // return (
            //     <div className={classes.fieldGroup} style={{ width: '50%', marginTop: '10px' }}>
            //         <div className={classes.fieldTitle}> E - POD COPY</div>
            //         {
            //             epodLink ? (
            //                 <div className={classes.fieldValue}>
            //                     <a href={epodLink}>Link</a>
            //                 </div>
            //             )
            //                 : ''
            //         }
            //     </div>
            // );
            return null;
        }
        return null;
    };
    const renderCustomValue = (key: string) => {
        switch (key) {
            case 'document_list':
                return (
                    <>
                        {renderDocumentList()}
                        {renderLinkModal(modalRow)}
                    </>
                );
            case 'enable_epod_print':
                return renderEPodLabel();
            default:
                return null;
        }
    };

    const priceDetailsModalColumns = [
        {
            column_id: 'name',
            pretty_name: 'Charge Name',
            width: 50,
        },
        {
            column_id: 'value',
            pretty_name: 'Charge Value',
            width: 50,
        },
    ];


    const handlePriceDetailsModal = (priceDetailsList: any[]) => {
        setIsPriceDetailsModalVisible(true);
        setPriceDataList(priceDetailsList);
    };

    const renderFinalPrice = (finalPrice: string, priceDetailsList: any[]) => {
        return (
            <span>
                {(finalPrice || 0)?.toString()}
                <Tooltip title="">
                    <a>
                        <InfoCircleOutlined
                            style={{ marginLeft: 5 }}
                            onClick={() => handlePriceDetailsModal(priceDetailsList)}
                        />
                    </a>
                </Tooltip>
            </span>
        );
    };


    const renderPriceDetailsData = (label: string, priceDetails: any, width: string, chargeKey: string | undefined) => {
        let finalPriceValue = '';
        let priceDetailsList = [];
        if (Array.isArray(priceDetails) && priceDetails.length) {
            const finalPriceObject: any = priceDetails.find((price) => (price.id === 'finalPrice'));
            if (finalPriceObject) {
                finalPriceValue = finalPriceObject.value;
                priceDetailsList = priceDetails.filter((price) => (price.id !== 'finalPrice'));
            }
        }
        return (
            <span className={classes.fieldGroup} key={chargeKey} style={width ? { width } : undefined}>
                <div className={classes.fieldTitle}>
                    {label}
                </div>
                <div className={classes.fieldValue}>
                    {finalPriceValue ? renderFinalPrice(finalPriceValue, priceDetailsList) : '-'}
                </div>
            </span>
        );
    };

    const renderField = (key: string | undefined, width: string) => {
        if (key === undefined) {
            return (
                <div className={classes.fieldGroup} style={width ? { width } : undefined} />
            );
        }
        if (DividerTitleList.includes(key)) {
            return (
                <>
                    <Divider orientation="left" plain style={{ fontSize: 12 }} />
                    <div className={classes.secondaryTitle}>
                        {t(key)}
                    </div>
                </>
            );
        }
        const { label, value } = labelValuePair(key);
        if (label === 'enable_epod_print') {
            return renderCustomValue(key);
        }
        if (key === 'document_list') {
            return renderCustomValue(key);
        }

        if (key === 'price_details') {
            return renderPriceDetailsData(label, value, width, key);
        }
        return (
            <span className={classes.fieldGroup} key={key} style={width ? { width } : undefined}>
                <div className={classes.fieldTitle}>
                    {label}
                </div>
                <div className={classes.fieldValue}>
                    {value}
                </div>
            </span>
        );
    };

    const renderPieceField = (idx: any, key: string | undefined | null) => {
        if (!key) return null;
        let value = consignment.pieces_detail[idx][key];
        if (value === undefined || value === null) value = '-';
        else {
            switch (key) {
                case 'length':
                case 'width':
                case 'height':
                    value = `${value}  ${consignment.pieces_detail[idx]?.dimension_unit || 'cm'}`;
                    break;
                case 'declared_value':
                    value = `${value}  ${consignment.pieces_detail[idx]?.declared_currency || ''}`;
                    break;
                case 'weight':
                    value = `${value}  ${consignment.pieces_detail[idx]?.weight_unit || ''}`;
                    break;
                default:
                    break;
            }
        }
        return (
            <div className={classes.fieldGroup} key={key}>
                <div className={classes.fieldTitle}>
                    {i18n.exists(key) ? t(key) : snakeToPretty(key)}
                </div>
                <div className={classes.fieldValue}>
                    {value}
                </div>
            </div>
        );
    };

    const renderItemField = (item: any, key: string | undefined | null) => {
        if (!key) return <div className={classes.fieldGroup} key={key} style={{ width: '50%' }} />;
        let name = snakeToPretty(key);
        switch (key) {
            case 'duty_value': name = 'Total Value';
                break;
            case 'item_value': name = 'Item Rate';
                break;
            default:
                break;
        }
        return (
            <div className={classes.fieldGroup} key={key} style={{ width: '50%' }}>
                <div className={classes.fieldTitle}>
                    {name}
                </div>
                <div className={classes.fieldValue}>
                    {item[key]}
                </div>
            </div>
        );
    };

    const renderTabs = () => {
        return (
            <div className={classes.buckets}>
                {renderSingleTab()}
            </div>
        );
    };

    const renderPrimaryDetails = () => {
        const keys = (consignment?.is_international ? DetailsToShowKeysIntl : DetailsToShowKeysDomestic).primaryDetails;
        return keys.map((key) => {
            return renderField(key, '33.3%');
        });
    };

    const labelValuePairSeller = (key: string) => {
        const data = {
            label: snakeToPretty(key),
            value: get(consignment?.sellerDetails, key, ''),
        };
        if (key.includes('location_details.')) {
            data.label = snakeToPretty(key.replace('location_details.', ''));
        }
        if (key.includes('bank_details.')) {
            data.label = snakeToPretty(key.replace('bank_details.', ''));
        }
        if (data.value !== false && !data.value) {
            data.value = '';
        }
        return data;
    };

    const renderKeyValuePair = (label: string, value: any, width: string = '100%') => {
        return (
            <span className={classes.fieldGroup} style={{ width }}>
                <div className={classes.fieldTitle}>
                    {label}
                </div>
                <div className={classes.fieldValue}>
                    {value}
                </div>
            </span>
        );
    };

    const renderButtonImage = (url: string, ext: string = 'jpg') => { // todo
        return (
            <Button
                type="link"
                onClick={() => {
                    if (ext === 'pdf') {
                        setPdfPreviewURL(url);
                    } else {
                        setImagePreviewLoading(true);
                        setImageUrl(url);
                    }
                }}
                className={classes.viewButton}
            >
                View
            </Button>
        );
    };

    const renderFieldSeller = (key: string | undefined, width: string) => {
        if (key === undefined) {
            return (
                <div className={classes.fieldGroup} style={width ? { width } : undefined} />
            );
        }
        let { label, value } = labelValuePairSeller(key);
        switch (key) {
            case 'Bank Details':
                return (
                    <div className={classes.fieldGroup} style={{ width: '100%' }}>
                        <div className={classes.hr} style={{ margin: '16px 0' }} />
                        <div className={classes.secondaryTitle}>
                            {key}
                        </div>
                    </div>
                );
            case 'KYC Details':
                return (
                    <div className={classes.fieldGroup} style={{ width: '100%' }}>
                        <div className={classes.hr} style={{ margin: '16px 0' }} />
                        <div className={classes.secondaryTitle}>
                            KYC Details
                        </div>
                        <div className={classes.flexRow} style={{ width: '100%' }}>
                            {(consignment?.sellerDetails?.kyc_documents || []).map((kyc: any, index: number) => {
                                return (
                                    <div style={{ width: '50%' }}>
                                        <div className={classes.secondaryTitle}>
                                            KYC
                                            &nbsp;
                                            {index + 1}
                                        </div>
                                        {renderKeyValuePair('Type', snakeToPretty(kyc?.document_type) || '-')}
                                        {renderKeyValuePair('Number', kyc?.document_number || '-')}
                                        {renderKeyValuePair('Front Image',
                                            kyc?.front_image
                                                ? renderButtonImage(kyc?.front_image, kyc?.front_image_details?.ext) : '-')}
                                        {renderKeyValuePair('Back Image',
                                            kyc?.back_image
                                                ? renderButtonImage(kyc?.back_image, kyc?.back_image_details?.ext) : '-')}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            case 'bank_details.ad_code_certificate_image':
                label = 'AD Certificate';
                value = value ? renderButtonImage(value, consignment?.sellerDetails?.bank_details?.image_details?.ext) : '-';
                break;
            case 'bank_details.ifsc_code':
                label = 'IFSC Code';
                break;
            case 'bank_details.ad_code':
                label = 'AD Code';
                break;
            default:
        }

        return (
            <span className={classes.fieldGroup} key={key} style={width ? { width } : undefined}>
                <div className={classes.fieldTitle}>
                    {label}
                </div>
                <div className={classes.fieldValue}>
                    {value || '-'}
                </div>
            </span>
        );
    };

    const renderSellerDetails = () => {
        return SellerDetailsKey.map((key) => {
            return renderFieldSeller(key, '50%');
        });
    };

    const renderOtherDetails = () => {
        const keys = (consignment?.is_international ? DetailsToShowKeysIntl : DetailsToShowKeysDomestic).otherDetails;
        return keys.map((key) => {
            return renderField(key, '50%');
        });
    };

    const renderPieceDetails = (piece: any, idx: number) => {
        const pieceKeys = (consignment?.is_international ? DetailsToShowKeysIntl : DetailsToShowKeysDomestic).pieceDetails;
        return (
            <div className={classes.box}>
                <Collapse expandIconPosition="right" bordered={false} ghost>
                    <Collapse.Panel
                        key={piece.id}
                        header={`${consignment.is_international ? 'Box' : t('Piece')} ${idx + 1}`}
                    >
                        <div className={classes.hr} style={{ margin: '0 0 16px 0' }} />
                        <div className={classes.tabDetails}>
                            {
                                pieceKeys.map((key) => renderPieceField(idx, key))
                            }
                        </div>
                        {

                            piece?.item_details ? (
                                <>
                                    <div className={classes.hr} style={{ margin: '16px 0' }} />
                                    Item Details:
                                    {
                                        piece?.item_details?.map((item: any) => (
                                            <Button
                                                type="primary"
                                                style={{ margin: '0 8px' }}
                                                onClick={() => { setItemDetails(item); }}
                                            >
                                                Item&nbsp;
                                                {piece.item_details.indexOf(item) + 1}
                                            </Button>
                                        ))
                                    }
                                </>
                            ) : null
                        }
                    </Collapse.Panel>
                </Collapse>
            </div>
        );
    };

    const renderPiecesDetails = () => {
        return consignment?.pieces_detail?.map((piece: any, idx: number) => {
            return renderPieceDetails(piece, idx);
        });
    };

    const renderItemDetailModal = () => {
        if (!itemDetails) return null;
        const keys = (consignment?.is_international ? DetailsToShowKeysIntl : DetailsToShowKeysDomestic).itemDetails;
        return (
            <Modal
                title="Item Details"
                closable
                onCancel={() => setItemDetails(null)}
                maskStyle={{
                    backgroundColor: 'rgba(0, 0, 0, 0.25)',
                }}
                visible={itemDetails}
                footer={null}
            >
                <div className={classes.tabDetails}>
                    {
                        keys.map((key: string | undefined | null) => (renderItemField(itemDetails, key)))
                    }
                </div>
            </Modal>
        );
    };

    const renderDetails = () => {
        let consignorDetailsKeys = (consignment?.is_international
            ? DetailsToShowKeysIntl : DetailsToShowKeysDomestic).consignorDetails;
        const consigneeDetailsKeys = (consignment?.is_international
            ? DetailsToShowKeysIntl : DetailsToShowKeysDomestic).consigneeDetails;
        consignorDetailsKeys = consignment?.extra_details?.seller_id
            ? DetailsToShowKeysIntl.consignorDetailsMandate : consignorDetailsKeys;
        switch (selectedTab) {
            case 'Primary Details':
                return (
                    <>
                        <div className={classes.tabDetails}>
                            {renderPrimaryDetails()}
                        </div>
                        <div className={classes.hr} style={{ margin: '16px 0' }} />
                        {renderPiecesDetails()}
                    </>
                );
            case 'Consignee Details':
                return (
                    <div className={classes.tabDetails}>
                        {
                            consigneeDetailsKeys.map((key) => {
                                return renderField(key, '60%');
                            })
                        }
                    </div>
                );
            case 'Consignor Details':
                return (
                    <div className={classes.tabDetails}>
                        {consignment?.extra_details?.seller_id ? renderSellerDetails() : null}
                        {
                            consignorDetailsKeys.map((key) => {
                                return renderField(key, consignment.is_international ? '50%' : '60%');
                            })
                        }
                    </div>
                );
            case 'Service Details':
                return (
                    <div className={classes.tabDetails}>
                        {renderField(
                            consignment.is_international ? 'booking_service_type_id' : 'service_type',
                            '100%',
                        )}
                    </div>
                );
            case 'Other Details':
                return (
                    <div className={classes.tabDetails}>
                        {renderOtherDetails()}
                    </div>
                );
            default:
                return (<h3>Tab details not handled yet</h3>);
        }
    };

    const renderPdfPreview = () => {
        if (pdfPreviewURL) {
            return (
                <Modal
                    title=" "
                    visible={!!pdfPreviewURL}
                    onCancel={() => setPdfPreviewURL('')}
                    footer={null}
                    className={classes.iframeModal}
                    width={550}
                >
                    <iframe
                        title="Preview"
                        width={500}
                        height={450}
                        src={pdfPreviewURL}
                    />
                </Modal>
            );
        }
        return null;
    };

    const renderImagePreview = () => {
        if (!imageUrl) return null;
        return (
            <>
                {(!!imagePreviewLoading) && <Loader zIndex={10} />}
                <Image
                    src={imageUrl}
                    width={0}
                    placeholder
                    onLoad={() => setImagePreviewLoading(false)}
                    onError={() => {
                        message.error('Unable to fetch Image.');
                        setImagePreviewLoading(false);
                        setImageUrl('');
                    }}
                    onAbort={() => {
                        setImagePreviewLoading(false);
                        setImageUrl('');
                    }}
                    preview={{
                        visible: !!imageUrl && !imagePreviewLoading,
                        onVisibleChange: () => setImageUrl(''),
                    }}
                />
            </>
        );
    };

    return (
        <>
            <div className={classes.mainDiv}>
                <div className={classes.itemDetails}>
                    {renderTabs()}
                    {renderDetails()}
                    {renderImagePreview()}
                    {renderPdfPreview()}
                </div>
            </div>
            {renderItemDetailModal()}
            {isPriceDetailsModalVisible && (
                <TableModal
                    width={500}
                    header="Details"
                    isVisible={isPriceDetailsModalVisible}
                    handleModalClose={() => {
                        setIsPriceDetailsModalVisible(false);
                        setPriceDataList([]);
                    }}
                    dataSource={priceDataList}
                    columnsToShow={priceDetailsModalColumns}
                />
            )}
        </>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    const {
        master,
    } = state;

    const profileMandateComm = master?.config?.profile_mandate_intl_comm_cp;
    const profileMandateNonComm = master?.config?.profile_mandate_intl_noncomm_cp;

    return {
        profileMandateNonComm,
        profileMandateComm,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: consignmentDetailsIntStyles,
    },
};

export default GenericHoc(hocConfig)(ConsignmentDetailedInternational);
