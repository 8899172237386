import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps } from 'theme/jss-types';
import { consignorFormFields, formFields } from 'components/create-consignment/create-modal.constants';
import {
    Button,
    Col,
    Form,
    FormInstance,
    message,
} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { getCustomerDetails } from 'network/settings-product';

const styles = () => ({});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    form: FormInstance;
}

const LoadConsignorDataControl = (props: IProps) => {
    const {
        form,
    } = props;
    const {
        ConsignmentCategory,
    } = formFields;

    const {
        ConsignorIECNo,
        ConsignorGSTINo,
        Sender_Bank_Account_No,
        Sender_Bank_AD_Code,
        Sender_Bank_Name,
        Sender_Bank_IFSC_Code,
        ConsignorKycDocType,
        ConsignorKycDocNo,
    } = consignorFormFields;

    const consignmentCategory = Form.useWatch(ConsignmentCategory.key, form);
    const isInternational = consignmentCategory === 'international';
    const [loading, setLoading] = React.useState(false);

    if (!isInternational) {
        return null;
    }

    const prefillData = (data: any) => {
        form.setFieldsValue({
            [Sender_Bank_Account_No.key]: data?.bank_details?.account_number,
            [Sender_Bank_IFSC_Code.key]: data?.bank_details?.branch_code,
            [Sender_Bank_AD_Code.key]: data?.bank_details?.bank_code,
            [Sender_Bank_Name.key]: data?.bank_details?.bank_name,
            [ConsignorKycDocNo.key]: data?.kyc_details?.kyc_doc_number,
            consignorKycFrontImage: data?.kyc_details?.kyc_url,
            consignorKycBackImage: data?.kyc_details?.kyc_back_url,
            [ConsignorKycDocType.key]: data?.kyc_details?.kyc_doc_type,
            [ConsignorGSTINo.key]: data?.customer_gstin_number,
            [ConsignorIECNo.key]: data?.customer_iec_number,
        });
    };

    const loadDetails = async () => {
        setLoading(true);
        const response = await getCustomerDetails();
        if (!response.isSuccess) {
            message.error(response.errorMessage);
        } else {
            prefillData(response?.data[0] || {});
        }
        setLoading(false);
    };

    return (
        <Col span={12}>
            <Button type="link" onClick={loadDetails} loading={loading}>
                <DownloadOutlined />
                {' '}
                Import Details from Customer Profile
            </Button>
        </Col>
    );
};

const mapStateToProps = () => {
    return {};
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(LoadConsignorDataControl);
