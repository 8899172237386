import { SELLER_PROFILE_API_URL, CONSIGNMENT_LABEL_API_URL } from '../library/globals';
import {
    GET, POST, PUT, DELETE,
} from './api-hander.service';
import {
    CREATE_SELLER_PROFILE,
    UPDATE_SELLER_PROFILE,
    FETCH_SELLER,
    DEACTIVATE_SELLER,
    SEND_OTP_TO_VERIFY,
    VERIFY_OTP,
    GET_AUTHORISATION_LETTER,
    GET_UNSIGNED_AUTHORISATION_LETTER,
    CHECK_CODE_AVAILABILITY,
    FETCH_SELLER_MASTER_DATA,
    VALIDATE_INDIVIDUAL_KYC,
    FETCH_SELLERS,
    FETCH_SELLER_DETAILS,
    GET_AUTH_LETTER_URLS,
    IMAGE_UPLOAD_LINKS,
    IMAGE_DOWNLOAD_LINKS,
    DELETE_IMAGE,
    GET_BOOKING_FILTERS_SELLER,
    SET_DEFAULT_SELLER,
} from './api.constants';


export const createSellerProfile = async (body: any) => {
    return POST(`${SELLER_PROFILE_API_URL}${CREATE_SELLER_PROFILE}`, body);
};

export const updateSellerProfile = async (body: any) => {
    return POST(`${SELLER_PROFILE_API_URL}${UPDATE_SELLER_PROFILE}`, body);
};

export const fetchSellers = async (body: any) => {
    return POST(`${SELLER_PROFILE_API_URL}${FETCH_SELLER}`, body);
};

export const deactivateSeller = async (body: any) => {
    return POST(`${SELLER_PROFILE_API_URL}${DEACTIVATE_SELLER}`, body);
};

export const setDefaultSeller = async (body: any) => {
    return POST(`${SELLER_PROFILE_API_URL}${SET_DEFAULT_SELLER}`, body);
};

export const sendOTPToVerify = async (body: any) => {
    return POST(`${SELLER_PROFILE_API_URL}${SEND_OTP_TO_VERIFY}`, body);
};

export const verifyOTP = async (body: any) => {
    return POST(`${SELLER_PROFILE_API_URL}${VERIFY_OTP}`, body);
};

export const checkCodeAvailaibility = async (body: any) => {
    return POST(
        `${SELLER_PROFILE_API_URL}${CHECK_CODE_AVAILABILITY}`, body,
    );
};

export const getAuthorisationLetter = async (body: any) => {
    return GET(`${SELLER_PROFILE_API_URL}${GET_AUTHORISATION_LETTER}`, body);
};

export const getUnsignedAuthorisationLetter = async (body: any) => {
    return GET(`${CONSIGNMENT_LABEL_API_URL}${GET_UNSIGNED_AUTHORISATION_LETTER}`, body, 'blob', {});
};

export const fetchSellerMasterData = async () => {
    return POST(
        `${SELLER_PROFILE_API_URL}${FETCH_SELLER_MASTER_DATA}`, {},
    );
};

export const validateIndividualKyc = async (body: any) => {
    return POST(
        `${SELLER_PROFILE_API_URL}${VALIDATE_INDIVIDUAL_KYC}`, body,
    );
};

export const getImageUploadUrls = async (params: any) => {
    return POST(`${SELLER_PROFILE_API_URL}${IMAGE_UPLOAD_LINKS}`, params);
};

export const deleteImage = async (params: any) => {
    return POST(`${SELLER_PROFILE_API_URL}${DELETE_IMAGE}`, params);
};

export const downloadImage = async (params: any) => {
    return POST(`${SELLER_PROFILE_API_URL}${IMAGE_DOWNLOAD_LINKS}`, params);
};

export const uploadImage = async (file: any, url: string, type: string) => {
    return PUT(url, file, type);
};

export const fetchSellerList = async (params: any) => {
    return POST(`${SELLER_PROFILE_API_URL}${FETCH_SELLERS}`, params);
};

export const fetchSellerDetails = async (params: any) => {
    return POST(`${SELLER_PROFILE_API_URL}${FETCH_SELLER_DETAILS}`, params);
};

export const fetchAuthLetterURL = async (params: any) => {
    return GET(`${SELLER_PROFILE_API_URL}${GET_AUTH_LETTER_URLS}`, params);
};

export const callDeleteImage = async (url: any) => {
    return DELETE(url);
};

export const fetchSellerBookingFilter = async (params: any) => {
    return POST(`${SELLER_PROFILE_API_URL}${GET_BOOKING_FILTERS_SELLER}`, params);
};
