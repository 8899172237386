/* eslint-disable no-nested-ternary */
import {
    getChildCustomerDetails,
    getCustomerDetails,
} from 'network/settings-product';
import * as React from 'react';
import { StylesProps } from 'theme/jss-types';
import { Customer, TableColumn } from './settings.types';
import {
    Button,
    Image,
    message,
    Spin,
    Table,
    Modal,
} from 'antd';
import { subAccountColumns } from './settings.constants';
import { accountStyles } from './settings.styles';
import EditAccount from './edit-account';
import { HocOptions } from 'components/common/generic-hoc.types';
import GenericHoc from 'components/common/generic-hoc';
import { ReduxStore } from 'reducers/redux.types';
import { CustomerPortalConfig, Master } from 'types/master-data-types';
import { useTranslation } from 'react-i18next';
import { getRoles } from 'network/user-management';
import EditUser from '../user-management/EditCustomerUser';
import { User } from '../user-management/user.types';

interface AccountProps extends StylesProps<ReturnType<typeof accountStyles>> {
    master: Master;
    phone_regex: RegExp;
    customer_portal_config: CustomerPortalConfig;
    password_policy: any;
}

const AccountSettings = (props: AccountProps) => {
    const {
        classes,
        customer_portal_config,
        phone_regex,
        password_policy,
        master,
    } = props;
    const { t } = useTranslation();
    const [customer, setCustomer] = React.useState<Partial<Customer>>({});
    const [childs, setChilds] = React.useState<any>([]);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [editVisible, setEditVisible] = React.useState<boolean>(false);
    const [editCustomerUserVisible, setEditCustomerUserVisible] = React.useState<boolean>(false);
    const [changePassword, setChangePassword] = React.useState<boolean>(false);
    const [toggle, setToggle] = React.useState<boolean>(false);
    const customerDetails = master?.CustomerUserDetails;
    const showUserManagement = customerDetails?.is_customer_user;
    const customerUser = customerDetails?.is_customer_user;
    const customerPermissions = master?.customerUserPermissions;
    const isLTLCustomer = master?.Customer?.customer_category === 'ltl';
    const [editData, setEditData] = React.useState<User | null>(null);

    const loadDetails = async () => {
        setLoading(true);
        const response = await getCustomerDetails();
        if (!response.isSuccess) {
            message.error(response.errorMessage);
        } else {
            setCustomer(response?.data[0] || {});
        }
        setLoading(false);
    };

    const loadChilds = async () => {
        setLoading(true);
        const response = await getChildCustomerDetails();
        if (!response.isSuccess) {
            message.error(response.errorMessage);
        } else {
            setChilds(response?.data || []);
        }
        setLoading(false);
    };

    const openEditForm = () => {
        setEditVisible(true);
    };

    const fetchUserRolesedit = async (userId: number) => {
        const rolesData = await getRoles(userId);
        if (rolesData.isSuccess) {
            setEditData(rolesData?.data?.userRoleGroupArr);
            setCustomer(rolesData?.data?.userRoleGroupArr[0] || {});
        } else {
            message.error(rolesData.errorMessage);
        }
        setLoading(false);
    };

    React.useEffect(() => {
        if (customerUser) {
            const customerUserId = customerDetails?.id;
            fetchUserRolesedit(customerUserId);
        } else {
            loadDetails();
        }
        loadChilds();
    }, [toggle]);

    const openCustomerUserEditForm = async () => {
        setLoading(true);
        const customerUserId = customerDetails?.id;
        await fetchUserRolesedit(customerUserId);
        setEditCustomerUserVisible(true);
    };

    const openChangePasswordForm = () => {
        setChangePassword(true);
        setEditVisible(true);
    };

    const renderTitle = () => {
        return (
            <div className={classes.title}>
                <span>{t('account_settings_heading')}</span>
                <div>
                    {/* Conditionally render the buttons based on showUserManagement */}
                    {!showUserManagement && (
                        <Button
                            type="primary"
                            onClick={() => openChangePasswordForm()}
                            className={classes.newButton}
                        >
                            {t('change_password')}
                        </Button>
                    )}
                    <Button
                        type="primary"
                        onClick={() => {
                            if (showUserManagement) {
                                openCustomerUserEditForm();
                            } else {
                                openEditForm();
                            }
                        }}
                        className={classes.newButton}
                    >
                        {showUserManagement ? t('edit') : t('edit')}
                    </Button>
                </div>
            </div>
        );
    };

    const renderBankTitle = () => {
        if (!customer_portal_config?.show_bank_details
            || (customerPermissions?.setting && customerDetails?.is_customer_user)
        ) {
            return null;
        }
        return (
            <div className={classes.title}>
                {t('bank_details_heading')}
            </div>
        );
    };

    const renderKycTitle = () => {
        if (!customer_portal_config?.show_kyc_details
            || (customerPermissions?.setting && customerDetails?.is_customer_user)
        ) {
            return null;
        }
        return (
            <div className={classes.title}>
                {t('Consignor Details')}
            </div>
        );
    };

    const renderBasic = () => {
        return (
            <div className={classes.row}>
                <div className={classes.pair}>
                    <span className={classes.pairTitle}>{t('account_code')}</span>
                    <span className={classes.pairValue}>
                        {!customerUser ? customer.code || '-' : customer.user_code || '-'}
                    </span>
                </div>
                <div className={classes.pair}>
                    <span className={classes.pairTitle}>{t('address_name')}</span>
                    <span className={classes.pairValue}>{customer.name || '-'}</span>
                </div>
                <div className={classes.pair}>
                    <span className={classes.pairTitle}>{t('account_username')}</span>
                    <span className={classes.pairValue}>{customer.username || '-'}</span>
                </div>
                <div className={classes.pair}>
                    <span className={classes.pairTitle}>{t('account_parent_name')}</span>
                    <span className={classes.pairValue}>{customer.parent_name || '-'}</span>
                </div>
                <div className={classes.pair}>
                    <span className={classes.pairTitle}>{t('account_created_on')}</span>
                    <span className={classes.pairValue}>N/A</span>
                </div>
            </div>
        );
    };

    const renderImage = (url?: string) => {
        if (!url) {
            return '-';
        }
        return (
            <Image
                height={32}
                width={100}
                src={url}
                alt={url}
            />
        );
    };

    const renderContacts = () => {
        return (
            <div className={classes.row}>
                <div className={classes.pair}>
                    <span className={classes.pairTitle}>{t('account_phone')}</span>
                    <span className={classes.pairValue}>
                        {!customerUser ? customer?.phone_list?.join(', ') || '-' : customer.phone || '-'}
                    </span>
                </div>
                <div className={classes.pair}>
                    <span className={classes.pairTitle}>{t('account_email')}</span>
                    <span className={classes.pairValue}>
                        {!customerUser ? customer?.email_list?.join(', ') || '-' : customer.email || '-'}
                    </span>
                </div>
                <div className={classes.pair}>
                    <span className={classes.pairTitle}>{t('account_status')}</span>
                    <span className={classes.pairValue}>
                        {!customerUser ? (customer.is_active ? 'Active' : 'Inactive') : 'Active'}
                    </span>
                </div>
                <div className={classes.pair}>
                    <span className={classes.pairTitle}>{t('account_signature')}</span>
                    <span className={classes.pairValue}>
                        {renderImage(customer.signature_url)}
                    </span>
                </div>
                <div className={classes.pair}>
                    <span className={classes.pairTitle}>{t('account_seal')}</span>
                    <span className={classes.pairValue}>
                        {renderImage(customer.stamp_url)}
                    </span>
                </div>
            </div>
        );
    };

    const renderGstNumberRow = () => {
        return (
            <div className={classes.row}>
                { customer_portal_config?.show_gst_details ? (
                    <div className={classes.pair}>
                        <span className={classes.pairTitle}>{t('account_gstin')}</span>
                        <span className={classes.pairValue}>{customer.customer_gstin_number || '-'}</span>
                    </div>
                ) : null }
                { customer_portal_config?.show_customer_iec_number ? (
                    <div className={classes.pair}>
                        <span className={classes.pairTitle}>{t('account_iec')}</span>
                        <span className={classes.pairValue}>{customer?.customer_iec_number || '-'}</span>
                    </div>
                ) : null }
                { customer_portal_config?.show_customer_pan_number ? (
                    <div className={classes.pair}>
                        <span className={classes.pairTitle}>{t('pan_number')}</span>
                        <span className={classes.pairValue}>{customer?.customer_pan || '-'}</span>
                    </div>
                ) : null }
            </div>
        );
    };

    const handleAddUserCancel = () => {
        setEditCustomerUserVisible(false);
        setEditData(null); // Reset edit data
    };


    const handleAddUserSubmit = () => {
        setEditData(null); // Reset edit data
        setEditCustomerUserVisible(false);
    };
    const renderBody = () => {
        if (loading) {
            return (
                <Spin
                    style={{
                        marginTop: '6%',
                        marginBottom: '2%',
                    }}
                />
            );
        }
        if (editVisible) {
            return (
                <EditAccount
                    phone_regex={phone_regex}
                    config={customer_portal_config}
                    isLTLCustomer={isLTLCustomer}
                    customerDetails={customer}
                    changePassword={changePassword}
                    password_policy={password_policy}
                    onClose={(reload) => {
                        setChangePassword(false);
                        setEditVisible(false);
                        if (reload) {
                            setToggle(!toggle);
                        }
                    }}
                />
            );
        }

        if (editCustomerUserVisible) {
            return (
                <Modal
                    title={t('Edit User')}
                    visible={editCustomerUserVisible}
                    onCancel={handleAddUserCancel}
                    style={{ left: '460px', top: '-8px' }}
                    footer={null}
                >
                    <EditUser
                        editData={editData}
                        onSubmit={handleAddUserSubmit}
                        onCancel={handleAddUserCancel}
                        password_policy={password_policy}
                    />
                </Modal>
            );
        }
        return (
            <>
                {renderBasic()}
                {renderContacts()}
                {renderGstNumberRow()}
            </>
        );
    };

    const renderBankDetails = () => {
        if (!customer_portal_config?.show_bank_details
            || (customerPermissions?.setting && customerDetails?.is_customer_user)) {
            return null;
        }
        return (
            <div className={classes.row}>
                <div className={classes.bankpair}>
                    <span className={classes.bankTitle}>{t('bank_account_number')}</span>
                    <span className={classes.pairValue}>{customer.bank_details?.account_number || '-'}</span>
                </div>
                <div className={classes.bankpair}>
                    <span className={classes.bankTitle}>{t('bank_code')}</span>
                    <span className={classes.pairValue}>{customer.bank_details?.bank_code || '-'}</span>
                </div>
                <div className={classes.bankpair}>
                    <span className={classes.bankTitle}>{t('branch_code')}</span>
                    <span className={classes.pairValue}>{customer.bank_details?.branch_code || '-'}</span>
                </div>
                <div className={classes.bankpair}>
                    <span className={classes.bankTitle}>{t('bank_name')}</span>
                    <span className={classes.pairValue}>{customer.bank_details?.bank_name || '-'}</span>
                </div>
            </div>
        );
    };

    const renderKycDetails = () => {
        if (!customer_portal_config?.show_kyc_details
            || (customerPermissions?.setting && customerDetails?.is_customer_user)
        ) {
            return null;
        }
        return (
            <>
                <div className={classes.row}>
                    <div className={classes.bankpair}>
                        <span className={classes.bankTitle}>{t('name')}</span>
                        <span className={classes.pairValue}>{customer.consignor_address?.name || '-'}</span>
                    </div>
                    <div className={classes.bankpair}>
                        <span className={classes.bankTitle}>{t('addressLine1')}</span>
                        <span className={classes.pairValue}>{customer.consignor_address?.addressLine1 || '-'}</span>
                    </div>
                    <div className={classes.bankpair}>
                        <span className={classes.bankTitle}>{t('addressLine2')}</span>
                        <span className={classes.pairValue}>{customer.consignor_address?.addressLine2 || '-'}</span>
                    </div>
                </div>
                <div className={classes.row}>
                    <div className={classes.bankpair}>
                        <span className={classes.bankTitle}>{t('cityName')}</span>
                        <span className={classes.pairValue}>{customer.consignor_address?.city || '-'}</span>
                    </div>
                    <div className={classes.bankpair}>
                        <span className={classes.bankTitle}>{t('stateName')}</span>
                        <span className={classes.pairValue}>{customer.consignor_address?.state || '-'}</span>
                    </div>
                    <div className={classes.bankpair}>
                        <span className={classes.bankTitle}>{t('pincode')}</span>
                        <span className={classes.pairValue}>{customer.consignor_address?.pincode || '-'}</span>
                    </div>
                </div>
                <div className={classes.row}>
                    <div className={classes.bankpair}>
                        <span className={classes.bankTitle}>{t('countryName')}</span>
                        <span className={classes.pairValue}>{customer.consignor_address?.country || '-'}</span>
                    </div>
                    <div className={classes.bankpair}>
                        <span className={classes.bankTitle}>{t('phone')}</span>
                        <span className={classes.pairValue}>{customer.consignor_address?.phone || '-'}</span>
                    </div>
                    <div className={classes.bankpair}>
                        <span className={classes.bankTitle}>{t('address_email')}</span>
                        <span className={classes.pairValue}>{customer.consignor_address?.email || '-'}</span>
                    </div>
                </div>
                <div className={classes.row}>
                    <div className={classes.bankpair}>
                        <span className={classes.bankTitle}>{t('kyc_doc_type')}</span>
                        <span className={classes.pairValue}>{customer.kyc_details?.kyc_doc_type || '-'}</span>
                    </div>
                    <div className={classes.bankpair}>
                        <span className={classes.bankTitle}>{t('kyc_doc_no')}</span>
                        <span className={classes.pairValue}>{customer.kyc_details?.kyc_doc_number || '-'}</span>
                    </div>
                    <div className={classes.bankpair}>
                        <span className={classes.bankTitle}>{t('kyc_doc_front')}</span>
                        <span className={classes.pairValue}>
                            {renderImage(customer.kyc_details?.kyc_url)}
                        </span>
                    </div>
                    <div className={classes.bankpair}>
                        <span className={classes.bankTitle}>{t('kyc_doc_back')}</span>
                        <span className={classes.pairValue}>
                            {renderImage(customer.kyc_details?.kyc_back_url)}
                        </span>
                    </div>
                </div>
            </>
        );
    };

    const renderSubTitle = () => {
        return (
            <div className={classes.subTitle}>
                <span>{t('sub_accounts_heading')}</span>
                <Button
                    disabled
                    type="primary"
                    className={classes.newSubButton}
                >
                    {t('add_sub_account')}
                </Button>
            </div>
        );
    };

    const renderText = (text: string | number) => {
        return (
            <div
                className={classes.cellValue}
            >
                {text}
            </div>
        );
    };

    const renderColumn = (
        text: string,
        row: Customer,
        column: string,
        index: number,
    ) => {
        switch (column) {
            case 'index': return renderText(index + 1);
            case 'phone_list': return renderText(row.phone_list[0]);
            case 'is_active': return renderText(row.is_active ? 'Active' : 'Inactive');
            default: return renderText(text as string);
        }
    };

    const renderColumns = () => {
        const columns: any = subAccountColumns.map((column: TableColumn) => {
            return {
                key: column.key,
                title: t(column.pretty_name),
                dataIndex: column.key,
                ellipsis: true,
                bordered: false,
                render: (text: string, row: any, idx: number) => renderColumn(text, row, column.key, idx),
            };
        });
        return columns;
    };

    const renderTable = () => {
        return (
            <Table
                columns={renderColumns()}
                loading={loading}
                dataSource={childs}
                pagination={false}
                className={classes.table}
            />
        );
    };

    return (
        <div className={classes.accountPage}>
            <div className={classes.main}>
                {renderTitle()}
                {renderBody()}
                {renderBankTitle()}
                {renderBankDetails()}
                {renderKycTitle()}
                {renderKycDetails()}
            </div>
            <div className={classes.subAccount}>
                {renderSubTitle()}
                {renderTable()}
            </div>
        </div>
    );
};

const mapStateToProps = (state: ReduxStore) => {
    const {
        customer_portal_config,
        phone_regex,
        password_policy,
    } = state?.master?.config || {};
    const { master } = state;
    return {
        master,
        customer_portal_config,
        phone_regex,
        password_policy,
    };
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: accountStyles,
    },
    connectRouter: true,
    connectTranslession: true,
};

export default GenericHoc(hocConfig)(AccountSettings);
