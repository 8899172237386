/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const KYCIconUtil = (props: any) => (
    <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M17.4851 18.7374C20.423 18.7374 22.8055 17.476 22.8425 15.9136H22.8429C23.1691 15.9425 23.4903 15.9672 23.8053 15.9914C27.8275 16.3008 30.8556 16.5337 30.8558 24.4504C30.8558 28.6726 27.7117 28.9234 23.5741 28.9282V33.3256C23.5741 34.8004 22.3391 35.9999 20.8214 35.9999H14.152C12.6338 35.9999 11.3991 34.8004 11.3991 33.3256V28.9282C7.26095 28.9238 4.11768 28.6726 4.11768 24.4504C4.11768 16.5346 7.1425 16.3016 11.165 15.9917C11.4806 15.9674 11.8023 15.9426 12.1292 15.9136C12.1642 17.4763 14.5478 18.7374 17.4851 18.7374ZM20.8208 34.7138C21.6101 34.7138 22.25 34.0919 22.25 33.3252H22.2503V24.5312C22.2503 23.7645 21.6103 23.1426 20.8211 23.1426H14.1508C13.3615 23.1426 12.7216 23.7645 12.7216 24.5312V33.3252C12.7216 34.0919 13.3616 34.7138 14.1508 34.7138H20.8208Z" fill="black" />
        <path d="M17.4862 17.033C21.5509 17.033 24.8441 11.096 24.8441 7.14903C24.8441 3.20053 21.5509 0 17.4862 0C13.4222 0 10.1284 3.20024 10.1284 7.14832C10.1281 11.0955 13.4231 17.0322 17.4862 17.0322V17.033Z" fill="black" />
        <path d="M20.3939 25.071H14.5773C14.2116 25.071 13.9155 25.359 13.9155 25.7139C13.9155 26.0688 14.2116 26.3562 14.5773 26.3562H20.3936C20.7592 26.3562 21.0553 26.0682 21.0553 25.7139C21.0553 25.3584 20.7589 25.071 20.3939 25.071H20.3939Z" fill="black" />
        <path d="M20.3939 28.2856H14.5773C14.2116 28.2856 13.9155 28.5736 13.9155 28.9285C13.9155 29.2834 14.2116 29.5713 14.5773 29.5713H20.3936C20.7592 29.5713 21.0553 29.2834 21.0553 28.9285C21.0553 28.5736 20.7589 28.2856 20.3939 28.2856H20.3939Z" fill="black" />
        <path d="M17.4861 31.4998H14.5778C14.2121 31.4998 13.916 31.7877 13.916 32.1426C13.916 32.4975 14.2121 32.7849 14.5778 32.7849H17.4861C17.8517 32.7849 18.1478 32.4972 18.1478 32.1426C18.1476 31.7877 17.8517 31.4998 17.4861 31.4998Z" fill="black" />
    </svg>
);

const KYCIcon = (props: any) => (
    <Icon component={(innerProps: any) => {
        return KYCIconUtil({ ...innerProps, ...props });
    }}
    />
);

export default KYCIcon;
