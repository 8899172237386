import * as React from 'react';
import withStyles from 'react-jss';
import { StylesProps } from '../../../theme/jss-types';
import { sellerDetailsStyles } from '../single-consignment.styles';
import {
    Button,
    Form,
    FormInstance,
    Image,
    Modal,
    Select,
    Spin,
    message,
} from 'antd';
import AddressIcon from '../single-consignment-icons/address';
import { consignorFormFields, formFields } from '../create-modal.constants';
import { Routes, formRules } from 'library/constants';
import Loader from 'components/common/Loader';
import { fetchSellerBookingFilter, fetchSellerDetails, fetchSellerList } from 'network/sender-management.api';
import { PlusOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { snakeToPretty } from 'library/lodash-helper';

interface StepTwoProps extends StylesProps<ReturnType<typeof sellerDetailsStyles>> {
    form: FormInstance;
    setFormData: any;
    formData: any;
    onClose: any;
}

const {
    Cust_Seller_code,
} = formFields;

const {
    ConsignorName,
    ConsignorAddressLine1,
    ConsignorAddressLine2,
    ConsignorCity,
    ConsignorState,
    ConsignorCountry,
    ConsignorPincode,
    ConsignorPhone,
    ConsignorEmail,
} = consignorFormFields;

const SellerDetailsInternational = (props: StepTwoProps) => {
    const {
        form,
        classes,
        formData,
        setFormData,
        onClose,
    } = props;
    const history = useHistory();

    const [loading, setLoading] = React.useState<boolean>(false);
    const [sellerList, setSellerList] = React.useState<any>([]);
    const [defaultSellerList, setDefaultSellerList] = React.useState<any>([]);
    const [sellerData, setSellerData] = React.useState<any>(formData.sellerDetails);
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [imagePreviewURL, setImagePreviewURL] = React.useState('');
    const [imagePreviewLoading, setImagePreviewLoading] = React.useState(false);
    const [pdfPreviewURL, setPdfPreviewURL] = React.useState('');

    const sellerCodeValue = form.getFieldValue(Cust_Seller_code.key);
    let handler: NodeJS.Timeout | null = null;


    const fetchSelectedSellerDetails = async (seller_id: string) => {
        setLoading(true);
        const response = await fetchSellerDetails({
            seller_id,
        });
        if (response.isSuccess) {
            setSellerData(response?.data || []);
            setFormData({
                ...formData,
                sellerDetails: response?.data,
                [ConsignorName.key]: response?.data?.seller_name,
                [ConsignorAddressLine1.key]: response?.data?.location_details?.address_line_1,
                [ConsignorAddressLine2.key]: response?.data?.location_details?.address_line_2,
                [ConsignorCity.key]: response?.data?.location_details?.city,
                [ConsignorState.key]: response?.data?.location_details?.state,
                [ConsignorCountry.key]: response?.data?.location_details?.country,
                [ConsignorPincode.key]: response?.data?.location_details?.pincode,
                [ConsignorPhone.key]: response?.data?.phone,
                [ConsignorEmail.key]: response?.data?.email,
            });
        } else {
            message.error(response?.errorMessage || 'something went wrong!');
        }
        setLoading(false);
    };

    const fetchSellerCode = async () => {
        setLoading(true);
        const response = await fetchSellerList({
            status_list: ['verified'],
        });
        if (response.isSuccess) {
            setSellerList(response?.data || []);
            setDefaultSellerList(response?.data || []);
            if (!sellerCodeValue) {
                (response?.data || []).find((seller: any) => {
                    if (seller.is_default) {
                        form.setFieldsValue({
                            [Cust_Seller_code.key]: seller.seller_id,
                        });
                        fetchSelectedSellerDetails(seller.seller_id);
                        return true;
                    }
                    return false;
                });
            }
        } else {
            message.error(response?.errorMessage || 'Error while fetching consignor code!');
        }
        setLoading(false);
    };

    React.useEffect(() => {
        fetchSellerCode();
    }, []);

    const handleSearch = async (value: string) => {
        if (!value) {
            setSellerList(defaultSellerList);
            return;
        }
        const response = await fetchSellerBookingFilter({
            filter_string: value,
        });
        if (response.isSuccess) {
            setSellerList(response?.data || []);
        } else {
            message.error(response?.errorMessage || 'Error while fetching consignor code!');
        }
    };

    const handleSearchDebounce = (value: string) => {
        if (handler) {
            clearTimeout(handler);
        }
        handler = setTimeout(() => {
            handleSearch(value);
        }, 500);
    };

    const renderSellerCode = () => {
        return (
            <div className={classes.flexColumn}>
                <div className={classes.referenceTitle}>
                    {Cust_Seller_code.label}
                    *
                </div>
                <Form.Item
                    className={classes.textValue}
                    name={Cust_Seller_code.key}
                    initialValue={Cust_Seller_code.defaultValue}
                    valuePropName={Cust_Seller_code.valuePropName}
                    rules={[formRules.required]}
                >
                    <Select
                        options={sellerList.map((seller: any) => ({ label: seller.seller_code, value: seller.seller_id }))}
                        placeholder={Cust_Seller_code.placeholder}
                        style={{ width: Cust_Seller_code.width }}
                        filterOption={false}
                        notFoundContent={loading ? <Spin size="small" /> : null}
                        showSearch
                        onSearch={handleSearchDebounce}
                        onChange={(code) => fetchSelectedSellerDetails(code)}
                    />
                </Form.Item>
            </div>
        );
    };

    const renderAddSeller = () => {
        return (
            <Button
                type="link"
                onClick={() => setShowModal(true)}
                style={{
                    marginTop: 20,
                }}
            >
                <PlusOutlined />
                Add Consignor
            </Button>
        );
    };

    const renderDetail = (title: string, value: string, width?: string, isRow?: boolean) => {
        return (
            <div className={isRow ? classes.flexRow : classes.flexColumn} style={{ width }}>
                <div className={classes.referenceTitle}>{title}</div>
                <div className={classes.textValue}>{value || '-'}</div>
            </div>
        );
    };

    const renderPdfPreview = () => {
        if (pdfPreviewURL) {
            return (
                <Modal
                    title=" "
                    visible={!!pdfPreviewURL}
                    onCancel={() => setPdfPreviewURL('')}
                    footer={null}
                    className={classes.iframeModal}
                    width={550}
                >
                    <iframe
                        title="Preview"
                        width={500}
                        height={450}
                        src={pdfPreviewURL}
                    />
                </Modal>
            );
        }
        return null;
    };

    const renderImageButton = (image: string, ext: string) => {
        if (!image) {
            return '-';
        }
        return (
            <Button
                onClick={() => {
                    if (ext === 'pdf') {
                        setPdfPreviewURL(image);
                    } else {
                        setImagePreviewLoading(true);
                        setImagePreviewURL(image);
                    }
                }}
                type="link"
                className={classes.previewButton}
            >
                Preview
            </Button>
        );
    };

    const renderImage = (title: string, url: string, ext: string = 'jpg', width: string, isRow?: boolean) => {
        return (
            <div className={isRow ? classes.flexRow : classes.flexColumn} style={{ width }}>
                <div className={classes.referenceTitle}>{title}</div>
                <div className={classes.textValue}>
                    {renderImageButton(url, ext)}
                </div>
            </div>
        );
    };

    const renderKycDetails = (kycData: any[]) => {
        return (kycData || [])?.map((kyc: any, index: number) => (
            <div className={classes.flexColumn} style={{ width: '100%' }}>
                <div style={{ color: '#777777', fontWeight: 600 }}>
                    KYC&nbsp;
                    {index + 1}
                </div>
                <div className={classes.detailsRowKYC} style={{ width: '100%' }}>
                    {renderDetail('KYC Doc Type', snakeToPretty(kyc?.document_type), '25%')}
                    {renderDetail('KYC Doc No.', kyc?.document_number, '25%')}
                    {renderImage('KYC Doc Front', kyc?.front_image, kyc?.front_image_details?.ext, '25%')}
                    {renderImage('KYC Doc Back', kyc?.back_image, kyc?.back_image_details?.ext, '25%')}
                </div>
            </div>
        ));
    };

    const renderShowModal = () => {
        return (
            <Modal
                title={(
                    <>
                        <ExclamationCircleFilled style={{ color: '#FA5F26' }} />
                        {' '}
                        Add New Consignor
                    </>
                )}
                visible={showModal}
                okText="Yes, Confirm"
                cancelText="No"
                onOk={() => {
                    history.push(`/${Routes.CONSIGNOR_MANAGEMENT}`);
                    onClose();
                }}
                onCancel={() => setShowModal(false)}
            >
                <p>
                    On Confirming, you will be exiting the consignment creation process
                    and will be redirected to creating a new profile. Are you sure?
                </p>
            </Modal>
        );
    };

    const renderImagePreview = () => {
        if (imagePreviewURL) {
            return (
                <>
                    {(!!imagePreviewLoading) && <Loader zIndex={10} />}
                    <Image
                        placeholder
                        src={imagePreviewURL}
                        style={{
                            display: 'none',
                        }}
                        onLoad={() => setImagePreviewLoading(false)}
                        onError={() => {
                            message.error('Unable to fetch Image.');
                            setImagePreviewLoading(false);
                            setImagePreviewURL('');
                        }}
                        onAbort={() => {
                            setImagePreviewLoading(false);
                            setImagePreviewURL('');
                        }}
                        preview={{
                            visible: !!imagePreviewURL && !imagePreviewLoading,
                            onVisibleChange: () => setImagePreviewURL(''),
                        }}
                    />
                </>
            );
        }
        return null;
    };

    return (
        <div className={classes.main}>
            {loading && <Loader zIndex={10} />}
            {renderShowModal()}
            {renderImagePreview()}
            {renderPdfPreview()}
            <div className={classes.box}>
                <div className={classes.boxTitle}>
                    <div className={classes.boxIcon}>
                        <AddressIcon className={classes.boxIcon} />
                    </div>
                    <span>Consignor Details</span>
                </div>
                <div className={classes.addressDetails}>
                    <div style={{ width: '100%' }}>
                        <Form form={form}>
                            <div className={classes.flexRow}>
                                {renderSellerCode()}
                                {renderAddSeller()}
                            </div>
                        </Form>
                        {
                            sellerData && (
                                <>
                                    <div className={classes.sellerDetails}>
                                        <div className={classes.rowTitle}>Primary Details</div>
                                        <div className={classes.detailsRowAdd}>
                                            {renderDetail('Name', sellerData?.seller_name, '33%')}
                                            {renderDetail('Company Name', sellerData?.company_name, '33%')}
                                            {renderDetail('Address Line 1',
                                                sellerData?.location_details?.address_line_1, '33%')}
                                            {renderDetail('Address Line 2',
                                                sellerData?.location_details?.address_line_2, '33%')}
                                            {renderDetail('City', sellerData?.location_details?.city, '33%')}
                                            {renderDetail('State', sellerData?.location_details?.state, '33%')}
                                            {renderDetail('Country', sellerData?.location_details?.country, '33%')}
                                            {renderDetail('Pincode', sellerData?.location_details?.pincode, '33%')}
                                            {renderDetail('Phone', sellerData?.phone, '33%')}
                                            {renderDetail('Email', sellerData?.email, '33%')}
                                        </div>
                                    </div>
                                    <div className={classes.sellerDetails}>
                                        <div className={classes.rowTitle}>KYC Details</div>
                                        <div className={classes.detailsRowAdd}>
                                            <div className={classes.flexColumn} style={{ width: '100%' }}>
                                                {renderKycDetails(sellerData?.kyc_documents)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.sellerDetails}>
                                        <div className={classes.rowTitle}>Bank Details</div>
                                        <div className={classes.detailsRowAdd}>
                                            {renderDetail('Account No.',
                                                sellerData?.bank_details?.account_number, '33%')}
                                            {renderDetail('IFSC Code',
                                                sellerData?.bank_details?.ifsc_code, '33%')}
                                            {renderDetail('AD Code',
                                                sellerData?.bank_details?.ad_code, '33%')}
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withStyles(sellerDetailsStyles)(SellerDetailsInternational);
