import * as React from 'react';
import GenericHoc from 'components/common/generic-hoc';
import { HocOptions } from 'components/common/generic-hoc.types';
import { StylesProps, ThemeType } from 'theme/jss-types';
import { formFields } from 'components/create-consignment/create-modal.constants';
import { useTranslation } from 'react-i18next';
import { Col, Form } from 'antd';
import { commonStyleSheet } from 'library/common-styles';
import TextArea from 'antd/lib/input/TextArea';
import { formRules } from 'library/constants';

const styles = (theme: ThemeType) => ({
    formItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        '& .ant-checkbox + span': {
            color: '#111111',
            fontSize: 14,
            fontWeight: 600,
        },
    },
    title: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
        marginLeft: 4,
    },
    referenceTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
        width: '100%',
        marginBottom: 4,
    },
    descriptionTitle: {
        color: '#333333',
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0,
    },
    description: {
        ...commonStyleSheet(theme).flexColumn,
    },
});

interface IProps extends StylesProps<ReturnType<typeof styles>>, ReturnType<typeof mapStateToProps> {
    params: any;
}

const DescriptionField = (props: IProps) => {
    const { t, i18n } = useTranslation();
    const { classes, params } = props;
    const { Description } = formFields;
    const { pieceIndex, isRequired } = params;
    const isPieceLevel = pieceIndex !== null && pieceIndex !== undefined;

    const formRuleRequired = {
        ...formRules.required,
        message: i18n.exists('required') ? t('required') : 'Required',
    };

    return (
        <Col span={12}>
            <div className={classes.description}>
                <div className={classes.descriptionTitle}>
                    {t(Description.key)}
                </div>
                <Form.Item
                    name={isPieceLevel ? ['pieces', pieceIndex, Description.key] : Description.key}
                    initialValue={Description.defaultValue}
                    valuePropName={Description.valuePropName}
                    rules={isRequired(isPieceLevel, Description.key) ? [formRuleRequired] : undefined}
                >
                    <TextArea
                        rows={1}
                        placeholder={t(Description.key)}
                        style={{ width: Description.width }}
                    />
                </Form.Item>
            </div>
        </Col>
    );
};

const mapStateToProps = () => {
    return {};
};

const hocConfig: HocOptions = {
    connectRedux: {
        useRedux: true,
        mapStateToProps,
    },
    connectJss: {
        useJss: true,
        styleSheet: styles,
    },
};

export default GenericHoc(hocConfig)(DescriptionField);
