import React from 'react';

import {
    Form, Input, Button, message,
} from 'antd';
import { updateUser } from 'network/user-management';

type EditUserProps = {
    editData: any;
    onSubmit: (values: any) => void;
    onCancel: () => void;
    password_policy: any;
};

const EditUser: React.FC<EditUserProps> = ({
    editData,
    onSubmit,
    onCancel,
    password_policy,
}) => {
    const [form] = Form.useForm();
    const isEditMode = Boolean(editData);

    React.useEffect(() => {
        if (isEditMode) {
            form.setFieldsValue({
                user_code: editData[0].user_code,
                username: editData[0].username,
                name: editData[0].name,
                email: editData[0].email,
                phone: editData[0].phone,
                city: editData[0].city,
            });
        } else {
            form.resetFields();
        }
    }, [editData, form, isEditMode]);

    const validatePassword = (password: any) => {
        // Implement your password validation logic based on the password_policy
        console.log(password);
        return true; // Placeholder, replace with actual validation
    };

    const handleFormSubmit = async (values: any) => {
    // Validation for password policy can be added here
        if (password_policy && !validatePassword(values.password)) {
            message.error('Password does not meet policy requirements');
            return;
        }
        const parent_id = window.localStorage.getItem('childCustomerId');

        if (parent_id) {
            // eslint-disable-next-line no-param-reassign
            values.parent_id = parent_id;
        }
        const additionalData = {
            user_roles: editData[0].rolesList.map((role: any) => role.role_id),
            is_active: true,
        };
        const payload = { ...values, ...additionalData };
        const result = await updateUser(payload);
        if (result.isSuccess) {
            message.success('User updated successfully');
        } else {
            message.error('Failed to update user');
        }
        onSubmit(values);
        form.resetFields();
    };


    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={handleFormSubmit}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '16px' }}>
                <Form.Item label="User Code" name="user_code" style={{ flex: 1 }}>
                    <Input disabled />
                </Form.Item>
                <Form.Item label="Login Username" name="username" style={{ flex: 1 }}>
                    <Input disabled />
                </Form.Item>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '16px' }}>
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: 'Please enter the name' }]}
                    style={{ flex: 1 }}
                >
                    <Input placeholder="Name" />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        { required: true, message: 'Please enter the email' },
                        { type: 'email', message: 'Please enter a valid email' },
                    ]}
                    style={{ flex: 1 }}
                >
                    <Input placeholder="Email" />
                </Form.Item>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '16px' }}>
                <Form.Item
                    label="Mobile No."
                    name="phone"
                    rules={[{ required: true, message: 'Please enter the mobile number' }]}
                    style={{ flex: 1 }}
                >
                    <Input placeholder="Mobile No." />
                </Form.Item>
                <Form.Item label="City" name="city" style={{ flex: 1 }}>
                    <Input placeholder="City" />
                </Form.Item>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '16px' }}>
                <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: !isEditMode, message: 'Please enter the password' }]}
                    style={{ flex: 1 }}
                >
                    <Input.Password placeholder="Password" />
                </Form.Item>
                <Form.Item
                    label="Confirm Password"
                    name="conPassword"
                    dependencies={['password']}
                    rules={[
                        { required: !isEditMode, message: 'Please confirm the password' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Passwords do not match!'));
                            },
                        }),
                    ]}
                    style={{ flex: 1 }}
                >
                    <Input.Password placeholder="Confirm Password" />
                </Form.Item>
            </div>

            <Form.Item>
                <Button type="primary" htmlType="submit">
                    {isEditMode ? 'Update User' : 'Add User'}
                </Button>
                <Button style={{ marginLeft: '8px' }} onClick={onCancel}>
                    Cancel
                </Button>
            </Form.Item>
        </Form>
    );
};

export default EditUser;
